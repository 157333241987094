@import '../../Variables';

.post-block-thumbnail {
  img {
    max-height: 200px;
    object-fit: cover;

    background-size: fill;
    border-radius: 12px 12px 0px 0px;
    width: 100%;
  }

  &.is-feed {
    margin: 0px -16px 0px -16px;
  }
}
