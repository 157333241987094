.poke-jimo-label {
  height: 100%;
  flex: 1 auto;

  display: flex;

  &.light {
    background: rgba(0, 0, 0, 0.03);
    color: #44495c;

    img {
      filter: brightness(0) saturate(100%) invert(23%) sepia(3%) saturate(5506%)
        hue-rotate(190deg) brightness(92%) contrast(74%);
    }
  }
  &.dark {
    background: rgba(255, 255, 255, 0.04);
    color: #afafaf;

    img {
      filter: brightness(0) saturate(100%) invert(83%) sepia(3%) saturate(7%)
        hue-rotate(314deg) brightness(85%) contrast(86%);
    }
  }

  &:hover {
    &.light {
      span {
        background: rgba(0, 0, 0, 0.04);
      }
    }
    &.dark {
      span {
        background: rgba(255, 255, 255, 0.04);
      }
    }
  }

  a {
    display: flex;
    flex: 1 auto;
  }

  span {
    margin: auto;
    display: flex;
    gap: 4px;
    font-weight: 300;
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
    padding: 4px 6px;
    border-radius: 6px;
    transition: all 200ms ease;
  }
}
