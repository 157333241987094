.widget-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    display: flex;
    align-items: center;
  }
  i {
    position: relative;
    top: 2px;
    margin-right: 8px;
    font-size: 20px;
  }
}
