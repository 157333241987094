@import './../../../Responsive';

.s-standalone-settings {
  &>.row {
    margin: 0;
    text-align: left;
  }

  .row {
    display: flex;
    justify-content: center;
  }

  .toggle-wrapper {
    display: flex;
    align-items: center;
    margin: 24px 0px;

    .jimo-toggle {
      margin-right: 12px;
    }
  }

  .card-account-wrapper {
    flex: 1;
    position: relative;

    .btn-back {
      position: absolute;
      z-index: 2;
      left: -72px;
    }

    .card {
      .title {
        font-size: 24px;
        font-weight: 500;
      }

      p {
        max-width: 312px;
        margin-bottom: 32px;
        margin-top: 8px;
      }

      .divider {
        margin: 12px 0;
      }

      .input-wrapper {
        display: flex;
        align-items: center;

        .label-custom {
          flex: 0 0 144px;
          font-weight: 500;
          text-transform: capitalize;
        }

        .input-group {
          flex: 1 1 auto;

          &.labeled-right {
            input {
              border-right-color: transparent;
            }
          }

          .label {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.3);
            font-weight: 400;
            padding-left: 4px;
            padding-right: 12px;
            font-style: italic;
          }
        }
      }

      .btn {
        margin-top: 48px;
        margin-left: auto;
        text-transform: capitalize;
      }
    }
  }

  @media (max-width: $screen-md) {
    background-color: #ffffff;
    height: 100%;

    .card-account-wrapper {
      padding-left: 0;
      padding-right: 0;

      .btn-back {
        background-color: rgba(7, 19, 49, 0.3);
        left: 16px;
        top: 16px;
      }

      .card {
        border: none;
        box-shadow: none;
        padding-top: 69px;

        .divider {
          display: none;
        }

        .input-wrapper {
          display: block;
          margin-bottom: 12px;

          .label-custom {
            margin-bottom: 4px;
          }

          .input-group.labeled {
            width: 100%;
          }
        }

        .btn {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}
