.navigation-bar {
  display: flex;
  flex-shrink: 0;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  align-items: center;
  z-index: 9;

  i {
    padding-right: 10px;
  }

  .nav-btn {
    padding: 4px 8px 4px 8px;
    font-weight: 600;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
    cursor: pointer;
    &.selected {
      color: #fff;
    }
    &:hover {
      opacity: 0.7;
    }
  }

  .btn-close-view {
    margin-left: auto;
    background: rgba(7, 19, 49, 0.3);
    width: 24px;
    height: 24px;
    z-index: 3;

    i {
      padding: 0;
    }
  }
}

.widget-header {
  color: #fff;
  padding: 12px 16px;
  overflow: hidden;
  &.is-preview {
    pointer-events: none;
  }

  .header-title {
    font-size: 24px;
    font-weight: 300;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.widget-header-announcement {
  width: calc(100% - 48px);
  display: flex;
  flex-direction: column;
  animation-delay: 500ms !important;
  z-index: 2;
  background-color: RGB(var(--widgetSecondaryColor));

  .title-closer-wrapper {
    display: flex;
    justify-content: space-between;
    padding-right: 24px;

    .btn {
      padding: 0;
      background: rgba(7, 19, 49, 0.3);
      width: 24px;
      height: 24px;
      z-index: 3;
      position: fixed;
      right: 24px;
    }
  }

  p {
    color: rgba(255, 255, 255, 0.7);
    font-weight: 300;
    font-size: 16px;
  }
}

.widget-header-feedback {
  position: fixed;
  z-index: 2;
  width: calc(100% - 48px);
  display: flex;
  justify-content: flex-end;
  &.has-announcements-enabled {
    align-items: center;
    margin-bottom: 24px;
    justify-content: space-between;
  }

  .btn-back-announcements {
    padding: 12px 24px;
    background-color: rgba(0, 0, 0, 0.2);
    font-size: 16px;
    -webkit-animation: 500ms fade-in-right 200ms ease-out both;
    animation: 500ms fade-in-right 200ms ease-out both;
    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
    &:active {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .btn-close-view {
    padding: 0;
    background: rgba(7, 19, 49, 0.3);
    flex: 0 0 24px;
    height: 24px;
    z-index: 3;
  }
}

.widget-header-evolution {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 70;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: white;
  border-bottom: 1px solid var(--border-minimal, #E5E5E5);

  .detail-title {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    color: black;
  }

  .btn-back-feedback {
    background-color: rgba(0, 0, 0, 0.4);
    flex: 0 0 27px;
    height: 27px;
    z-index: 3;
    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
    &:active {
      background-color: rgba(0, 0, 0, 0.2);
    }

    i {
      font-size: 12px;
    }
  }

  .btn-close-view {
    background-color: rgba(0, 0, 0, 0.4);
    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
    &:active {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

.modal-widget-feedback-card-submit {
  width: 96%;
  left: 2%;
  padding: 0;
}
