.copyright {
  text-align: center;
  color: #000;
  i {
    margin-right: 8px;
    font-size: 18px;
  }
  a {
    color: inherit;
    display: flex;
    align-items: center;
  }
  img {
    position: relative;
    top: 4px;
    margin-right: 5px;
  }
}

.footer.footer__basic {
  position: fixed;
  bottom: 0px;
  background-color: #071331f6;
  width: 100%;
  padding: 12px 0;
  &.is-light {
    padding: 6px 0;
    .copyright {
      font-size: 12px;
      i {
        font-size: 12px;
      }
    }
  }
}
