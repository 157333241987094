@font-face {
  font-family: 'iconsax';
  src: url('fonts/iconsax.eot?yjegnn');
  src: url('fonts/iconsax.eot?yjegnn#iefix') format('embedded-opentype'),
    url('fonts/iconsax.ttf?yjegnn') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.isax {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconsax' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.isax-dcube:before {
  content: '\e901';
}

.isax-d-cube-scan:before {
  content: '\e902';
}

.isax-d-rotate:before {
  content: '\e903';
}

.isax-d-square:before {
  content: '\e904';
}

.isax-square:before {
  content: '\e905';
}

.isax-support:before {
  content: '\e906';
}

.isax-activity:before {
  content: '\e907';
}

.isax-add:before {
  content: '\e908';
}

.isax-add-circle:before {
  content: '\e909';
}

.isax-additem:before {
  content: '\e90a';
}

.isax-add-square:before {
  content: '\e90b';
}

.isax-airdrop:before {
  content: '\e90c';
}

.isax-airplane:before {
  content: '\e90d';
}

.isax-airplane-square:before {
  content: '\e90e';
}

.isax-airpod:before {
  content: '\e90f';
}

.isax-airpods:before {
  content: '\e910';
}

.isax-alarm:before {
  content: '\e911';
}

.isax-align-bottom:before {
  content: '\e912';
}

.isax-align-horizontally:before {
  content: '\e913';
}

.isax-align-left:before {
  content: '\e914';
}

.isax-align-right:before {
  content: '\e915';
}

.isax-align-vertically:before {
  content: '\e916';
}

.isax-aquarius:before {
  content: '\e917';
}

.isax-archive:before {
  content: '\e918';
}

.isax-archive-1:before {
  content: '\e919';
}

.isax-archive-2:before {
  content: '\e91a';
}

.isax-archive-add:before {
  content: '\e91b';
}

.isax-archive-book:before {
  content: '\e91c';
}

.isax-archive-minus:before {
  content: '\e91d';
}

.isax-archive-slash:before {
  content: '\e91e';
}

.isax-archive-tick:before {
  content: '\e91f';
}

.isax-arrange-circle:before {
  content: '\e920';
}

.isax-arrange-circle-2:before {
  content: '\e921';
}

.isax-arrange-square:before {
  content: '\e922';
}

.isax-arrange-square-2:before {
  content: '\e923';
}

.isax-arrow:before {
  content: '\e924';
}

.isax-arrow-2:before {
  content: '\e925';
}

.isax-arrow-3:before {
  content: '\e926';
}

.isax-arrow-bottom:before {
  content: '\e927';
}

.isax-arrow-circle-down:before {
  content: '\e928';
}

.isax-arrow-circle-left:before {
  content: '\e929';
}

.isax-arrow-circle-right:before {
  content: '\e92a';
}

.isax-arrow-circle-up:before {
  content: '\e92b';
}

.isax-arrow-down:before {
  content: '\e92c';
}

.isax-arrow-down-1:before {
  content: '\e92d';
}

.isax-arrow-down-2:before {
  content: '\e92e';
}

.isax-arrow-left:before {
  content: '\e92f';
}

.isax-arrow-left-1:before {
  content: '\e930';
}

.isax-arrow-left-2:before {
  content: '\e931';
}

.isax-arrow-left-3:before {
  content: '\e932';
}

.isax-arrow-right:before {
  content: '\e933';
}

.isax-arrow-right-1:before {
  content: '\e934';
}

.isax-arrow-right-2:before {
  content: '\e935';
}

.isax-arrow-right-3:before {
  content: '\e936';
}

.isax-arrow-right-4:before {
  content: '\e937';
}

.isax-arrow-square:before {
  content: '\e938';
}

.isax-arrow-square-down:before {
  content: '\e939';
}

.isax-arrow-square-left:before {
  content: '\e93a';
}

.isax-arrow-square-up:before {
  content: '\e93b';
}

.isax-arrow-swap:before {
  content: '\e93c';
}

.isax-arrow-swap-horizontal:before {
  content: '\e93d';
}

.isax-arrow-up:before {
  content: '\e93e';
}

.isax-arrow-up-1:before {
  content: '\e93f';
}

.isax-arrow-up-2:before {
  content: '\e940';
}

.isax-arrow-up-3:before {
  content: '\e941';
}

.isax-attach-circle:before {
  content: '\e942';
}

.isax-attach-square:before {
  content: '\e943';
}

.isax-audio-square:before {
  content: '\e944';
}

.isax-autobrightness:before {
  content: '\e945';
}

.isax-award:before {
  content: '\e946';
}

.isax-back-square:before {
  content: '\e947';
}

.isax-backward:before {
  content: '\e948';
}

.isax-backward-5-seconds:before {
  content: '\e949';
}

.isax-backward-10-seconds:before {
  content: '\e94a';
}

.isax-backward-15-seconds:before {
  content: '\e94b';
}

.isax-backward-item:before {
  content: '\e94c';
}

.isax-bag:before {
  content: '\e94d';
}

.isax-bag-2:before {
  content: '\e94e';
}

.isax-bag-cross:before {
  content: '\e94f';
}

.isax-bag-cross-1:before {
  content: '\e950';
}

.isax-bag-happy:before {
  content: '\e951';
}

.isax-bag-tick:before {
  content: '\e952';
}

.isax-bag-tick-2:before {
  content: '\e953';
}

.isax-bag-timer:before {
  content: '\e954';
}

.isax-bank:before {
  content: '\e955';
}

.isax-barcode:before {
  content: '\e956';
}

.isax-battery-3full:before {
  content: '\e957';
}

.isax-battery-charging:before {
  content: '\e958';
}

.isax-battery-disable:before {
  content: '\e959';
}

.isax-battery-empty:before {
  content: '\e95a';
}

.isax-battery-empty-1:before {
  content: '\e95b';
}

.isax-battery-full:before {
  content: '\e95c';
}

.isax-bezier:before {
  content: '\e95d';
}

.isax-bill:before {
  content: '\e95e';
}

.isax-bitcoin-card:before {
  content: '\e95f';
}

.isax-bitcoin-convert:before {
  content: '\e960';
}

.isax-bitcoin-refresh:before {
  content: '\e961';
}

.isax-blend:before {
  content: '\e962';
}

.isax-blend-2:before {
  content: '\e963';
}

.isax-bluetooth:before {
  content: '\e964';
}

.isax-bluetooth-2:before {
  content: '\e965';
}

.isax-bluetooth-circle:before {
  content: '\e966';
}

.isax-bluetooth-rectangle:before {
  content: '\e967';
}

.isax-blur:before {
  content: '\e968';
}

.isax-book:before {
  content: '\e969';
}

.isax-book-1:before {
  content: '\e96a';
}

.isax-bookmark:before {
  content: '\e96b';
}

.isax-bookmark-2:before {
  content: '\e96c';
}

.isax-book-saved:before {
  content: '\e96d';
}

.isax-book-square:before {
  content: '\e96e';
}

.isax-box:before {
  content: '\e96f';
}

.isax-box-1:before {
  content: '\e970';
}

.isax-box-2:before {
  content: '\e971';
}

.isax-box-add:before {
  content: '\e972';
}

.isax-box-remove:before {
  content: '\e973';
}

.isax-box-search:before {
  content: '\e974';
}

.isax-box-tick:before {
  content: '\e975';
}

.isax-box-time:before {
  content: '\e976';
}

.isax-briefcase:before {
  content: '\e977';
}

.isax-brifecase-cross:before {
  content: '\e978';
}

.isax-brifecase-tick:before {
  content: '\e979';
}

.isax-brifecase-timer:before {
  content: '\e97a';
}

.isax-broom:before {
  content: '\e97b';
}

.isax-brush:before {
  content: '\e97c';
}

.isax-brush-1:before {
  content: '\e97d';
}

.isax-brush-2:before {
  content: '\e97e';
}

.isax-brush-3:before {
  content: '\e97f';
}

.isax-brush-4:before {
  content: '\e980';
}

.isax-bubble:before {
  content: '\e981';
}

.isax-bucket:before {
  content: '\e982';
}

.isax-bucket-circle:before {
  content: '\e983';
}

.isax-bucket-square:before {
  content: '\e984';
}

.isax-building:before {
  content: '\e985';
}

.isax-building-3:before {
  content: '\e986';
}

.isax-building-4:before {
  content: '\e987';
}

.isax-buildings:before {
  content: '\e988';
}

.isax-buildings-2:before {
  content: '\e989';
}

.isax-buliding:before {
  content: '\e98a';
}

.isax-bus:before {
  content: '\e98b';
}

.isax-buy-crypto:before {
  content: '\e98c';
}

.isax-cake:before {
  content: '\e98d';
}

.isax-calculator:before {
  content: '\e98e';
}

.isax-calendar:before {
  content: '\e98f';
}

.isax-calendar-1:before {
  content: '\e990';
}

.isax-calendar-2:before {
  content: '\e991';
}

.isax-calendar-add:before {
  content: '\e992';
}

.isax-calendar-circle:before {
  content: '\e993';
}

.isax-calendar-edit:before {
  content: '\e994';
}

.isax-calendar-remove:before {
  content: '\e995';
}

.isax-calendar-search:before {
  content: '\e996';
}

.isax-calendar-tick:before {
  content: '\e997';
}

.isax-call:before {
  content: '\e998';
}

.isax-call-add:before {
  content: '\e999';
}

.isax-call-calling:before {
  content: '\e99a';
}

.isax-call-incoming:before {
  content: '\e99b';
}

.isax-call-minus:before {
  content: '\e99c';
}

.isax-call-outgoing:before {
  content: '\e99d';
}

.isax-call-received:before {
  content: '\e99e';
}

.isax-call-remove:before {
  content: '\e99f';
}

.isax-call-slash:before {
  content: '\e9a0';
}

.isax-camera:before {
  content: '\e9a1';
}

.isax-camera-slash:before {
  content: '\e9a2';
}

.isax-candle:before {
  content: '\e9a3';
}

.isax-candle-2:before {
  content: '\e9a4';
}

.isax-car:before {
  content: '\e9a5';
}

.isax-card:before {
  content: '\e9a6';
}

.isax-card-add:before {
  content: '\e9a7';
}

.isax-card-coin:before {
  content: '\e9a8';
}

.isax-card-edit:before {
  content: '\e9a9';
}

.isax-card-pos:before {
  content: '\e9aa';
}

.isax-card-receive:before {
  content: '\e9ab';
}

.isax-card-remove:before {
  content: '\e9ac';
}

.isax-card-remove-1:before {
  content: '\e9ad';
}

.isax-cards:before {
  content: '\e9ae';
}

.isax-card-send:before {
  content: '\e9af';
}

.isax-card-slash:before {
  content: '\e9b0';
}

.isax-card-tick:before {
  content: '\e9b1';
}

.isax-card-tick-1:before {
  content: '\e9b2';
}

.isax-category:before {
  content: '\e9b3';
}

.isax-category-2:before {
  content: '\e9b4';
}

.isax-cd:before {
  content: '\e9b5';
}

.isax-chart:before {
  content: '\e9b6';
}

.isax-chart-1:before {
  content: '\e9b7';
}

.isax-chart-2:before {
  content: '\e9b8';
}

.isax-chart-3:before {
  content: '\e9b9';
}

.isax-chart-21:before {
  content: '\e9ba';
}

.isax-chart-fail:before {
  content: '\e9bb';
}

.isax-chart-square:before {
  content: '\e9bc';
}

.isax-chart-success:before {
  content: '\e9bd';
}

.isax-check:before {
  content: '\e9be';
}

.isax-chrome:before {
  content: '\e9bf';
}

.isax-clipboard:before {
  content: '\e9c0';
}

.isax-clipboard-close:before {
  content: '\e9c1';
}

.isax-clipboard-export:before {
  content: '\e9c2';
}

.isax-clipboard-import:before {
  content: '\e9c3';
}

.isax-clipboard-text:before {
  content: '\e9c4';
}

.isax-clipboard-tick:before {
  content: '\e9c5';
}

.isax-clock:before {
  content: '\e9c6';
}

.isax-clock-1:before {
  content: '\e9c7';
}

.isax-close-circle:before {
  content: '\e9c8';
}

.isax-close-square:before {
  content: '\e9c9';
}

.isax-cloud:before {
  content: '\e9ca';
}

.isax-cloud-add:before {
  content: '\e9cb';
}

.isax-cloud-change:before {
  content: '\e9cc';
}

.isax-cloud-connection:before {
  content: '\e9cd';
}

.isax-cloud-cross:before {
  content: '\e9ce';
}

.isax-cloud-drizzle:before {
  content: '\e9cf';
}

.isax-cloud-fog:before {
  content: '\e9d0';
}

.isax-cloud-lightning:before {
  content: '\e9d1';
}

.isax-cloud-minus:before {
  content: '\e9d2';
}

.isax-cloud-notif:before {
  content: '\e9d3';
}

.isax-cloud-plus:before {
  content: '\e9d4';
}

.isax-cloud-remove:before {
  content: '\e9d5';
}

.isax-cloud-snow:before {
  content: '\e9d6';
}

.isax-cloud-sunny:before {
  content: '\e9d7';
}

.isax-code:before {
  content: '\e9d8';
}

.isax-code-1:before {
  content: '\e9d9';
}

.isax-code-circle:before {
  content: '\e9da';
}

.isax-coffee:before {
  content: '\e9db';
}

.isax-coin:before {
  content: '\e9dc';
}

.isax-coin-1:before {
  content: '\e9dd';
}

.isax-colorfilter:before {
  content: '\e9de';
}

.isax-colors-square:before {
  content: '\e9df';
}

.isax-color-swatch:before {
  content: '\e9e0';
}

.isax-command:before {
  content: '\e9e1';
}

.isax-command-square:before {
  content: '\e9e2';
}

.isax-component:before {
  content: '\e9e3';
}

.isax-computing:before {
  content: '\e9e4';
}

.isax-convert:before {
  content: '\e9e5';
}

.isax-convert-3d-cube:before {
  content: '\e9e6';
}

.isax-convert-card:before {
  content: '\e9e7';
}

.isax-convertshape:before {
  content: '\e9e8';
}

.isax-convertshape-2:before {
  content: '\e9e9';
}

.isax-copy:before {
  content: '\e9ea';
}

.isax-copyright:before {
  content: '\e9eb';
}

.isax-copy-success:before {
  content: '\e9ec';
}

.isax-courthouse:before {
  content: '\e9ed';
}

.isax-cpu:before {
  content: '\e9ee';
}

.isax-cpu-charge:before {
  content: '\e9ef';
}

.isax-cpu-setting:before {
  content: '\e9f0';
}

.isax-creative-commons:before {
  content: '\e9f1';
}

.isax-crop:before {
  content: '\e9f2';
}

.isax-crown:before {
  content: '\e9f3';
}

.isax-crown-1:before {
  content: '\e9f4';
}

.isax-cup:before {
  content: '\e9f5';
}

.isax-danger:before {
  content: '\e9f6';
}

.isax-data:before {
  content: '\e9f7';
}

.isax-data-2 .path1:before {
  content: '\e9f8';
  color: rgb(41, 45, 50);
}

.isax-data-2 .path2:before {
  content: '\e9f9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-data-2 .path3:before {
  content: '\e9fa';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-data-2 .path4:before {
  content: '\e9fb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-data-2 .path5:before {
  content: '\e9fc';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.isax-data-2 .path6:before {
  content: '\e9fd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-data-2 .path7:before {
  content: '\e9fe';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-data-2 .path8:before {
  content: '\e9ff';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-designtools:before {
  content: '\ea00';
}

.isax-device-message:before {
  content: '\ea01';
}

.isax-devices:before {
  content: '\ea02';
}

.isax-devices-1:before {
  content: '\ea03';
}

.isax-diagram:before {
  content: '\ea04';
}

.isax-diamonds:before {
  content: '\ea05';
}

.isax-direct:before {
  content: '\ea06';
}

.isax-directbox-default:before {
  content: '\ea07';
}

.isax-directbox-notif:before {
  content: '\ea08';
}

.isax-directbox-receive:before {
  content: '\ea09';
}

.isax-directbox-send:before {
  content: '\ea0a';
}

.isax-direct-down:before {
  content: '\ea0b';
}

.isax-direct-inbox:before {
  content: '\ea0c';
}

.isax-direct-left:before {
  content: '\ea0d';
}

.isax-direct-normal:before {
  content: '\ea0e';
}

.isax-direct-notification:before {
  content: '\ea0f';
}

.isax-direct-right:before {
  content: '\ea10';
}

.isax-direct-send:before {
  content: '\ea11';
}

.isax-direct-up:before {
  content: '\ea12';
}

.isax-discount-circle:before {
  content: '\ea13';
}

.isax-discount-shape:before {
  content: '\ea14';
}

.isax-discover:before {
  content: '\ea15';
}

.isax-discover-1:before {
  content: '\ea16';
}

.isax-dislike:before {
  content: '\ea17';
}

.isax-document:before {
  content: '\ea18';
}

.isax-document-1:before {
  content: '\ea19';
}

.isax-document-cloud:before {
  content: '\ea1a';
}

.isax-document-code:before {
  content: '\ea1b';
}

.isax-document-code-2:before {
  content: '\ea1c';
}

.isax-document-copy:before {
  content: '\ea1d';
}

.isax-document-download:before {
  content: '\ea1e';
}

.isax-document-favorite:before {
  content: '\ea1f';
}

.isax-document-filter:before {
  content: '\ea20';
}

.isax-document-forward:before {
  content: '\ea21';
}

.isax-document-like:before {
  content: '\ea22';
}

.isax-document-normal:before {
  content: '\ea23';
}

.isax-document-previous:before {
  content: '\ea24';
}

.isax-document-sketch:before {
  content: '\ea25';
}

.isax-document-text:before {
  content: '\ea26';
}

.isax-document-text-1:before {
  content: '\ea27';
}

.isax-document-upload:before {
  content: '\ea28';
}

.isax-dollar-circle:before {
  content: '\ea29';
}

.isax-dollar-square:before {
  content: '\ea2a';
}

.isax-driver:before {
  content: '\ea2b';
}

.isax-driver-2:before {
  content: '\ea2c';
}

.isax-driver-refresh:before {
  content: '\ea2d';
}

.isax-driving:before {
  content: '\ea2e';
}

.isax-edit:before {
  content: '\ea2f';
}

.isax-edit-2:before {
  content: '\ea30';
}

.isax-electricity:before {
  content: '\ea31';
}

.isax-element-2:before {
  content: '\ea32';
}

.isax-element-3:before {
  content: '\ea33';
}

.isax-element-4:before {
  content: '\ea34';
}

.isax-element-equal:before {
  content: '\ea35';
}

.isax-element-plus:before {
  content: '\ea36';
}

.isax-emoji-happy:before {
  content: '\ea37';
}

.isax-emoji-normal:before {
  content: '\ea38';
}

.isax-emoji-sad:before {
  content: '\ea39';
}

.isax-empty-wallet:before {
  content: '\ea3a';
}

.isax-empty-wallet-add:before {
  content: '\ea3b';
}

.isax-empty-wallet-change:before {
  content: '\ea3c';
}

.isax-empty-wallet-remove:before {
  content: '\ea3d';
}

.isax-empty-wallet-tick:before {
  content: '\ea3e';
}

.isax-empty-wallet-time:before {
  content: '\ea3f';
}

.isax-eraser:before {
  content: '\ea40';
}

.isax-eraser-1:before {
  content: '\ea41';
}

.isax-export:before {
  content: '\ea42';
}

.isax-export-1:before {
  content: '\ea43';
}

.isax-export-2:before {
  content: '\ea44';
}

.isax-export-3:before {
  content: '\ea45';
}

.isax-external-drive:before {
  content: '\ea46';
}

.isax-eye:before {
  content: '\ea47';
}

.isax-eye-slash:before {
  content: '\ea48';
}

.isax-fatrows:before {
  content: '\ea49';
}

.isax-favorite-chart:before {
  content: '\ea4a';
}

.isax-filter:before {
  content: '\ea4b';
}

.isax-filter-add:before {
  content: '\ea4c';
}

.isax-filter-edit:before {
  content: '\ea4d';
}

.isax-filter-remove:before {
  content: '\ea4e';
}

.isax-filter-search:before {
  content: '\ea4f';
}

.isax-filter-square:before {
  content: '\ea50';
}

.isax-filter-tick:before {
  content: '\ea51';
}

.isax-finger-cricle:before {
  content: '\ea52';
}

.isax-finger-scan:before {
  content: '\ea53';
}

.isax-firstline:before {
  content: '\ea54';
}

.isax-flag:before {
  content: '\ea55';
}

.isax-flag-2:before {
  content: '\ea56';
}

.isax-flash:before {
  content: '\ea57';
}

.isax-flash-1:before {
  content: '\ea58';
}

.isax-flash-circle:before {
  content: '\ea59';
}

.isax-flash-circle-1:before {
  content: '\ea5a';
}

.isax-flash-slash:before {
  content: '\ea5b';
}

.isax-folder:before {
  content: '\ea5c';
}

.isax-folder-2:before {
  content: '\ea5d';
}

.isax-folder-add:before {
  content: '\ea5e';
}

.isax-folder-cloud:before {
  content: '\ea5f';
}

.isax-folder-connection:before {
  content: '\ea60';
}

.isax-folder-cross:before {
  content: '\ea61';
}

.isax-folder-favorite:before {
  content: '\ea62';
}

.isax-folder-minus:before {
  content: '\ea63';
}

.isax-folder-open:before {
  content: '\ea64';
}

.isax-forbidden:before {
  content: '\ea65';
}

.isax-forbidden-2:before {
  content: '\ea66';
}

.isax-format-circle:before {
  content: '\ea67';
}

.isax-format-square:before {
  content: '\ea68';
}

.isax-forward:before {
  content: '\ea69';
}

.isax-forward-5-seconds:before {
  content: '\ea6a';
}

.isax-forward-10-seconds:before {
  content: '\ea6b';
}

.isax-forward-15-seconds:before {
  content: '\ea6c';
}

.isax-forward-item:before {
  content: '\ea6d';
}

.isax-forward-square:before {
  content: '\ea6e';
}

.isax-frame:before {
  content: '\ea6f';
}

.isax-frame-1:before {
  content: '\ea70';
}

.isax-frame-2:before {
  content: '\ea71';
}

.isax-frame-3:before {
  content: '\ea72';
}

.isax-frame-4:before {
  content: '\ea73';
}

.isax-gallery:before {
  content: '\ea74';
}

.isax-gallery-add:before {
  content: '\ea75';
}

.isax-gallery-edit:before {
  content: '\ea76';
}

.isax-gallery-export:before {
  content: '\ea77';
}

.isax-gallery-favorite:before {
  content: '\ea78';
}

.isax-gallery-import:before {
  content: '\ea79';
}

.isax-gallery-remove:before {
  content: '\ea7a';
}

.isax-gallery-slash:before {
  content: '\ea7b';
}

.isax-gallery-tick:before {
  content: '\ea7c';
}

.isax-game:before {
  content: '\ea7d';
}

.isax-gameboy:before {
  content: '\ea7e';
}

.isax-gas-station:before {
  content: '\ea7f';
}

.isax-gemini:before {
  content: '\ea80';
}

.isax-gemini-2:before {
  content: '\ea81';
}

.isax-ghost:before {
  content: '\ea82';
}

.isax-gift:before {
  content: '\ea83';
}

.isax-glass:before {
  content: '\ea84';
}

.isax-glass-1:before {
  content: '\ea85';
}

.isax-global:before {
  content: '\ea86';
}

.isax-global-edit:before {
  content: '\ea87';
}

.isax-global-refresh:before {
  content: '\ea88';
}

.isax-global-search:before {
  content: '\ea89';
}

.isax-gps:before {
  content: '\ea8a';
}

.isax-gps-slash:before {
  content: '\ea8b';
}

.isax-grammerly:before {
  content: '\ea8c';
}

.isax-graph:before {
  content: '\ea8d';
}

.isax-grid-1:before {
  content: '\ea8e';
}

.isax-grid-2:before {
  content: '\ea8f';
}

.isax-grid-3:before {
  content: '\ea90';
}

.isax-grid-4:before {
  content: '\ea91';
}

.isax-grid-5:before {
  content: '\ea92';
}

.isax-grid-6:before {
  content: '\ea93';
}

.isax-grid-7:before {
  content: '\ea94';
}

.isax-grid-8:before {
  content: '\ea95';
}

.isax-grid-9:before {
  content: '\ea96';
}

.isax-grid-edit:before {
  content: '\ea97';
}

.isax-grid-eraser:before {
  content: '\ea98';
}

.isax-grid-lock:before {
  content: '\ea99';
}

.isax-happyemoji:before {
  content: '\ea9a';
}

.isax-hashtag:before {
  content: '\ea9b';
}

.isax-hashtag-1:before {
  content: '\ea9c';
}

.isax-hashtag-down:before {
  content: '\ea9d';
}

.isax-hashtag-up:before {
  content: '\ea9e';
}

.isax-headphone:before {
  content: '\ea9f';
}

.isax-headphones:before {
  content: '\eaa0';
}

.isax-health:before {
  content: '\eaa1';
}

.isax-heart:before {
  content: '\eaa2';
}

.isax-heart-add:before {
  content: '\eaa3';
}

.isax-heart-circle:before {
  content: '\eaa4';
}

.isax-heart-edit:before {
  content: '\eaa5';
}

.isax-heart-remove:before {
  content: '\eaa6';
}

.isax-heart-search:before {
  content: '\eaa7';
}

.isax-heart-slash:before {
  content: '\eaa8';
}

.isax-heart-tick:before {
  content: '\eaa9';
}

.isax-hierarchy:before {
  content: '\eaaa';
}

.isax-hierarchy-2:before {
  content: '\eaab';
}

.isax-hierarchy-3:before {
  content: '\eaac';
}

.isax-hierarchy-square:before {
  content: '\eaad';
}

.isax-hierarchy-square-2:before {
  content: '\eaae';
}

.isax-hierarchy-square-3:before {
  content: '\eaaf';
}

.isax-home:before {
  content: '\eab0';
}

.isax-home-1:before {
  content: '\eab1';
}

.isax-home-2:before {
  content: '\eab2';
}

.isax-home-hashtag:before {
  content: '\eab3';
}

.isax-home-trend-down:before {
  content: '\eab4';
}

.isax-home-trend-up:before {
  content: '\eab5';
}

.isax-home-wifi:before {
  content: '\eab6';
}

.isax-hospital:before {
  content: '\eab7';
}

.isax-house:before {
  content: '\eab8';
}

.isax-house-2:before {
  content: '\eab9';
}

.isax-icon:before {
  content: '\eaba';
}

.isax-image:before {
  content: '\eabb';
}

.isax-import:before {
  content: '\eabc';
}

.isax-import-1:before {
  content: '\eabd';
}

.isax-import-2:before {
  content: '\eabe';
}

.isax-info-circle:before {
  content: '\eabf';
}

.isax-information:before {
  content: '\eac0';
}

.isax-instagram:before {
  content: '\eac1';
}

.isax-judge:before {
  content: '\eac2';
}

.isax-kanban:before {
  content: '\eac3';
}

.isax-key:before {
  content: '\eac4';
}

.isax-keyboard:before {
  content: '\eac5';
}

.isax-keyboard-open:before {
  content: '\eac6';
}

.isax-key-square:before {
  content: '\eac7';
}

.isax-lamp:before {
  content: '\eac8';
}

.isax-lamp-1:before {
  content: '\eac9';
}

.isax-lamp-charge:before {
  content: '\eaca';
}

.isax-lamp-on:before {
  content: '\eacb';
}

.isax-lamp-slash:before {
  content: '\eacc';
}

.isax-language-circle:before {
  content: '\eacd';
}

.isax-language-square:before {
  content: '\eace';
}

.isax-layer:before {
  content: '\eacf';
}

.isax-level:before {
  content: '\ead0';
}

.isax-lifebuoy:before {
  content: '\ead1';
}

.isax-like:before {
  content: '\ead2';
}

.isax-like-1:before {
  content: '\ead3';
}

.isax-like-dislike:before {
  content: '\ead4';
}

.isax-like-shapes:before {
  content: '\ead5';
}

.isax-like-tag:before {
  content: '\ead6';
}

.isax-link:before {
  content: '\ead7';
}

.isax-link-1:before {
  content: '\ead8';
}

.isax-link-2:before {
  content: '\ead9';
}

.isax-link-21:before {
  content: '\eada';
}

.isax-link-circle:before {
  content: '\eadb';
}

.isax-link-square:before {
  content: '\eadc';
}

.isax-location:before {
  content: '\eadd';
}

.isax-location-add:before {
  content: '\eade';
}

.isax-location-cross:before {
  content: '\eadf';
}

.isax-location-minus:before {
  content: '\eae0';
}

.isax-location-slash:before {
  content: '\eae1';
}

.isax-location-tick:before {
  content: '\eae2';
}

.isax-lock:before {
  content: '\eae3';
}

.isax-lock-1:before {
  content: '\eae4';
}

.isax-lock-circle:before {
  content: '\eae5';
}

.isax-lock-slash:before {
  content: '\eae6';
}

.isax-login:before {
  content: '\eae7';
}

.isax-login-1:before {
  content: '\eae8';
}

.isax-logout:before {
  content: '\eae9';
}

.isax-logout-1:before {
  content: '\eaea';
}

.isax-lovely:before {
  content: '\eaeb';
}

.isax-magicpen:before {
  content: '\eaec';
}

.isax-magic-star:before {
  content: '\eaed';
}

.isax-main-component:before {
  content: '\eaee';
}

.isax-man:before {
  content: '\eaef';
}

.isax-map:before {
  content: '\eaf0';
}

.isax-map-1:before {
  content: '\eaf1';
}

.isax-mask:before {
  content: '\eaf2';
}

.isax-mask-1:before {
  content: '\eaf3';
}

.isax-mask-2:before {
  content: '\eaf4';
}

.isax-math:before {
  content: '\eaf5';
}

.isax-maximize:before {
  content: '\eaf6';
}

.isax-maximize-1:before {
  content: '\eaf7';
}

.isax-maximize-2:before {
  content: '\eaf8';
}

.isax-maximize-3:before {
  content: '\eaf9';
}

.isax-maximize-4:before {
  content: '\eafa';
}

.isax-maximize-21:before {
  content: '\eafb';
}

.isax-maximize-circle:before {
  content: '\eafc';
}

.isax-medal:before {
  content: '\eafd';
}

.isax-medal-star:before {
  content: '\eafe';
}

.isax-menu:before {
  content: '\eaff';
}

.isax-menu-1:before {
  content: '\eb00';
}

.isax-menu-board:before {
  content: '\eb01';
}

.isax-message:before {
  content: '\eb02';
}

.isax-message-2:before {
  content: '\eb03';
}

.isax-message-add:before {
  content: '\eb04';
}

.isax-message-add-1:before {
  content: '\eb05';
}

.isax-message-circle:before {
  content: '\eb06';
}

.isax-message-edit:before {
  content: '\eb07';
}

.isax-message-favorite:before {
  content: '\eb08';
}

.isax-message-minus:before {
  content: '\eb09';
}

.isax-message-notif:before {
  content: '\eb0a';
}

.isax-message-programming:before {
  content: '\eb0b';
}

.isax-message-question:before {
  content: '\eb0c';
}

.isax-message-remove:before {
  content: '\eb0d';
}

.isax-messages:before {
  content: '\eb0e';
}

.isax-messages-1:before {
  content: '\eb0f';
}

.isax-messages-2:before {
  content: '\eb10';
}

.isax-messages-3:before {
  content: '\eb11';
}

.isax-message-search:before {
  content: '\eb12';
}

.isax-message-square:before {
  content: '\eb13';
}

.isax-message-text:before {
  content: '\eb14';
}

.isax-message-text-1:before {
  content: '\eb15';
}

.isax-message-tick:before {
  content: '\eb16';
}

.isax-message-time:before {
  content: '\eb17';
}

.isax-microphone:before {
  content: '\eb18';
}

.isax-microphone-2:before {
  content: '\eb19';
}

.isax-microphone-slash:before {
  content: '\eb1a';
}

.isax-microphone-slash-1:before {
  content: '\eb1b';
}

.isax-microscope:before {
  content: '\eb1c';
}

.isax-milk:before {
  content: '\eb1d';
}

.isax-mini-music-sqaure:before {
  content: '\eb1e';
}

.isax-minus:before {
  content: '\eb1f';
}

.isax-minus-cirlce:before {
  content: '\eb20';
}

.isax-minus-square:before {
  content: '\eb21';
}

.isax-mirror:before {
  content: '\eb22';
}

.isax-mirroring-screen:before {
  content: '\eb23';
}

.isax-mobile:before {
  content: '\eb24';
}

.isax-mobile-programming:before {
  content: '\eb25';
}

.isax-money:before {
  content: '\eb26';
}

.isax-money-2:before {
  content: '\eb27';
}

.isax-money-3:before {
  content: '\eb28';
}

.isax-money-4:before {
  content: '\eb29';
}

.isax-money-add:before {
  content: '\eb2a';
}

.isax-money-change:before {
  content: '\eb2b';
}

.isax-money-forbidden:before {
  content: '\eb2c';
}

.isax-money-recive:before {
  content: '\eb2d';
}

.isax-money-remove:before {
  content: '\eb2e';
}

.isax-moneys:before {
  content: '\eb2f';
}

.isax-money-send:before {
  content: '\eb30';
}

.isax-money-tick:before {
  content: '\eb31';
}

.isax-money-time:before {
  content: '\eb32';
}

.isax-monitor:before {
  content: '\eb33';
}

.isax-monitor-mobbile:before {
  content: '\eb34';
}

.isax-monitor-recorder:before {
  content: '\eb35';
}

.isax-moon:before {
  content: '\eb36';
}

.isax-more:before {
  content: '\eb37';
}

.isax-more-2:before {
  content: '\eb38';
}

.isax-more-circle:before {
  content: '\eb39';
}

.isax-more-square:before {
  content: '\eb3a';
}

.isax-mouse:before {
  content: '\eb3b';
}

.isax-mouse-1:before {
  content: '\eb3c';
}

.isax-mouse-circle:before {
  content: '\eb3d';
}

.isax-mouse-square:before {
  content: '\eb3e';
}

.isax-music:before {
  content: '\eb3f';
}

.isax-music-circle:before {
  content: '\eb40';
}

.isax-music-dashboard:before {
  content: '\eb41';
}

.isax-music-filter:before {
  content: '\eb42';
}

.isax-music-library-2:before {
  content: '\eb43';
}

.isax-musicnote:before {
  content: '\eb44';
}

.isax-music-play:before {
  content: '\eb45';
}

.isax-music-playlist:before {
  content: '\eb46';
}

.isax-music-square:before {
  content: '\eb47';
}

.isax-music-square-add:before {
  content: '\eb48';
}

.isax-music-square-remove:before {
  content: '\eb49';
}

.isax-music-square-search:before {
  content: '\eb4a';
}

.isax-next:before {
  content: '\eb4b';
}

.isax-note:before {
  content: '\eb4c';
}

.isax-note-1:before {
  content: '\eb4d';
}

.isax-note-2:before {
  content: '\eb4e';
}

.isax-note-21:before {
  content: '\eb4f';
}

.isax-note-add:before {
  content: '\eb50';
}

.isax-note-favorite:before {
  content: '\eb51';
}

.isax-note-remove:before {
  content: '\eb52';
}

.isax-note-square:before {
  content: '\eb53';
}

.isax-note-text:before {
  content: '\eb54';
}

.isax-notification:before {
  content: '\eb55';
}

.isax-notification-1:before {
  content: '\eb56';
}

.isax-notification-bing:before {
  content: '\eb57';
}

.isax-notification-circle:before {
  content: '\eb58';
}

.isax-notification-favorite:before {
  content: '\eb59';
}

.isax-notification-status:before {
  content: '\eb5a';
}

.isax-omega-circle:before {
  content: '\eb5b';
}

.isax-omega-square:before {
  content: '\eb5c';
}

.isax-paintbucket:before {
  content: '\eb5d';
}

.isax-paperclip:before {
  content: '\eb5e';
}

.isax-paperclip-2:before {
  content: '\eb5f';
}

.isax-password-check:before {
  content: '\eb60';
}

.isax-path:before {
  content: '\eb61';
}

.isax-path-2:before {
  content: '\eb62';
}

.isax-path-square:before {
  content: '\eb63';
}

.isax-pause:before {
  content: '\eb64';
}

.isax-pause-circle:before {
  content: '\eb65';
}

.isax-pen-add:before {
  content: '\eb66';
}

.isax-pen-close:before {
  content: '\eb67';
}

.isax-pen-remove:before {
  content: '\eb68';
}

.isax-pen-tool:before {
  content: '\eb69';
}

.isax-pen-tool-2:before {
  content: '\eb6a';
}

.isax-people:before {
  content: '\eb6b';
}

.isax-percentage-circle:before {
  content: '\eb6c';
}

.isax-percentage-square:before {
  content: '\eb6d';
}

.isax-personalcard:before {
  content: '\eb6e';
}

.isax-pet:before {
  content: '\eb6f';
}

.isax-pharagraphspacing:before {
  content: '\eb70';
}

.isax-picture-frame:before {
  content: '\eb71';
}

.isax-play:before {
  content: '\eb72';
}

.isax-play-add:before {
  content: '\eb73';
}

.isax-play-circle:before {
  content: '\eb74';
}

.isax-play-cricle:before {
  content: '\eb75';
}

.isax-play-remove:before {
  content: '\eb76';
}

.isax-presention-chart:before {
  content: '\eb77';
}

.isax-previous:before {
  content: '\eb78';
}

.isax-printer:before {
  content: '\eb79';
}

.isax-printer-slash:before {
  content: '\eb7a';
}

.isax-profile-2user:before {
  content: '\eb7b';
}

.isax-profile-add:before {
  content: '\eb7c';
}

.isax-profile-circle:before {
  content: '\eb7d';
}

.isax-profile-delete:before {
  content: '\eb7e';
}

.isax-profile-remove:before {
  content: '\eb7f';
}

.isax-profile-tick:before {
  content: '\eb80';
}

.isax-programming-arrow:before {
  content: '\eb81';
}

.isax-programming-arrows:before {
  content: '\eb82';
}

.isax-quote-down:before {
  content: '\eb83';
}

.isax-quote-down-circle:before {
  content: '\eb84';
}

.isax-quote-down-square:before {
  content: '\eb85';
}

.isax-quote-up:before {
  content: '\eb86';
}

.isax-quote-up-circle:before {
  content: '\eb87';
}

.isax-quote-up-square:before {
  content: '\eb88';
}

.isax-radar:before {
  content: '\eb89';
}

.isax-radar-1:before {
  content: '\eb8a';
}

.isax-radar-2:before {
  content: '\eb8b';
}

.isax-radio:before {
  content: '\eb8c';
}

.isax-ram:before {
  content: '\eb8d';
}

.isax-ram-2:before {
  content: '\eb8e';
}

.isax-ranking:before {
  content: '\eb8f';
}

.isax-ranking-1:before {
  content: '\eb90';
}

.isax-receipt:before {
  content: '\eb91';
}

.isax-receipt-1:before {
  content: '\eb92';
}

.isax-receipt-2:before {
  content: '\eb93';
}

.isax-receipt-2-1:before {
  content: '\eb94';
}

.isax-receipt-add:before {
  content: '\eb95';
}

.isax-receipt-discount:before {
  content: '\eb96';
}

.isax-receipt-disscount:before {
  content: '\eb97';
}

.isax-receipt-edit:before {
  content: '\eb98';
}

.isax-receipt-item:before {
  content: '\eb99';
}

.isax-receipt-minus:before {
  content: '\eb9a';
}

.isax-receipt-search:before {
  content: '\eb9b';
}

.isax-receipt-square:before {
  content: '\eb9c';
}

.isax-receipt-text:before {
  content: '\eb9d';
}

.isax-received:before {
  content: '\eb9e';
}

.isax-receive-square:before {
  content: '\eb9f';
}

.isax-receive-square-2:before {
  content: '\eba0';
}

.isax-record:before {
  content: '\eba1';
}

.isax-record-circle:before {
  content: '\eba2';
}

.isax-recovery-convert:before {
  content: '\eba3';
}

.isax-redo:before {
  content: '\eba4';
}

.isax-refresh:before {
  content: '\eba5';
}

.isax-refresh-2:before {
  content: '\eba6';
}

.isax-refresh-circle:before {
  content: '\eba7';
}

.isax-refresh-left-square:before {
  content: '\eba8';
}

.isax-refresh-right-square:before {
  content: '\eba9';
}

.isax-refresh-square-2:before {
  content: '\ebaa';
}

.isax-repeat:before {
  content: '\ebab';
}

.isax-repeat-circle:before {
  content: '\ebac';
}

.isax-repeate-music:before {
  content: '\ebad';
}

.isax-repeate-one:before {
  content: '\ebae';
}

.isax-reserve:before {
  content: '\ebaf';
}

.isax-rotate-left:before {
  content: '\ebb0';
}

.isax-rotate-left-1:before {
  content: '\ebb1';
}

.isax-rotate-right:before {
  content: '\ebb2';
}

.isax-rotate-right-1:before {
  content: '\ebb3';
}

.isax-route-square:before {
  content: '\ebb4';
}

.isax-routing:before {
  content: '\ebb5';
}

.isax-routing-2:before {
  content: '\ebb6';
}

.isax-row-horizontal:before {
  content: '\ebb7';
}

.isax-row-vertical:before {
  content: '\ebb8';
}

.isax-rulerpen:before {
  content: '\ebb9';
}

.isax-ruler:before {
  content: '\ebba';
}

.isax-safe-home:before {
  content: '\ebbb';
}

.isax-sagittarius:before {
  content: '\ebbc';
}

.isax-save-2:before {
  content: '\ebbd';
}

.isax-save-add:before {
  content: '\ebbe';
}

.isax-save-minus:before {
  content: '\ebbf';
}

.isax-save-remove:before {
  content: '\ebc0';
}

.isax-scan:before {
  content: '\ebc1';
}

.isax-scan-barcode:before {
  content: '\ebc2';
}

.isax-scanner:before {
  content: '\ebc3';
}

.isax-scanning:before {
  content: '\ebc4';
}

.isax-scissor:before {
  content: '\ebc5';
}

.isax-scissor-1:before {
  content: '\ebc6';
}

.isax-screenmirroring:before {
  content: '\ebc7';
}

.isax-scroll:before {
  content: '\ebc8';
}

.isax-search-favorite:before {
  content: '\ebc9';
}

.isax-search-favorite-1:before {
  content: '\ebca';
}

.isax-search-normal:before {
  content: '\ebcb';
}

.isax-search-normal-1:before {
  content: '\ebcc';
}

.isax-search-status:before {
  content: '\ebcd';
}

.isax-search-status-1:before {
  content: '\ebce';
}

.isax-search-zoom-in:before {
  content: '\ebcf';
}

.isax-search-zoom-in-1:before {
  content: '\ebd0';
}

.isax-search-zoom-out:before {
  content: '\ebd1';
}

.isax-search-zoom-out-1:before {
  content: '\ebd2';
}

.isax-security:before {
  content: '\ebd3';
}

.isax-security-card:before {
  content: '\ebd4';
}

.isax-security-safe:before {
  content: '\ebd5';
}

.isax-security-time:before {
  content: '\ebd6';
}

.isax-security-user:before {
  content: '\ebd7';
}

.isax-send:before {
  content: '\ebd8';
}

.isax-send-1:before {
  content: '\ebd9';
}

.isax-send-2:before {
  content: '\ebda';
}

.isax-send-sqaure-2:before {
  content: '\ebdb';
}

.isax-send-square:before {
  content: '\ebdc';
}

.isax-setting:before {
  content: '\ebdd';
}

.isax-setting-2:before {
  content: '\ebde';
}

.isax-setting-3:before {
  content: '\ebdf';
}

.isax-setting-4:before {
  content: '\ebe0';
}

.isax-setting-5:before {
  content: '\ebe1';
}

.isax-settings:before {
  content: '\ebe2';
}

.isax-shapes:before {
  content: '\ebe3';
}

.isax-shapes-1:before {
  content: '\ebe4';
}

.isax-share:before {
  content: '\ebe5';
}

.isax-shield:before {
  content: '\ebe6';
}

.isax-shield-cross:before {
  content: '\ebe7';
}

.isax-shield-search:before {
  content: '\ebe8';
}

.isax-shield-slash:before {
  content: '\ebe9';
}

.isax-shield-tick:before {
  content: '\ebea';
}

.isax-ship:before {
  content: '\ebeb';
}

.isax-shop:before {
  content: '\ebec';
}

.isax-shop-add:before {
  content: '\ebed';
}

.isax-shopping-bag:before {
  content: '\ebee';
}

.isax-shopping-cart:before {
  content: '\ebef';
}

.isax-shop-remove:before {
  content: '\ebf0';
}

.isax-shuffle:before {
  content: '\ebf1';
}

.isax-sidebar-bottom:before {
  content: '\ebf2';
}

.isax-sidebar-left:before {
  content: '\ebf3';
}

.isax-sidebar-right:before {
  content: '\ebf4';
}

.isax-sidebar-top:before {
  content: '\ebf5';
}

.isax-signpost:before {
  content: '\ebf6';
}

.isax-simcard:before {
  content: '\ebf7';
}

.isax-simcard-1:before {
  content: '\ebf8';
}

.isax-simcard-2:before {
  content: '\ebf9';
}

.isax-size:before {
  content: '\ebfa';
}

.isax-slash:before {
  content: '\ebfb';
}

.isax-slider:before {
  content: '\ebfc';
}

.isax-slider-horizontal:before {
  content: '\ebfd';
}

.isax-slider-horizontal-1:before {
  content: '\ebfe';
}

.isax-slider-vertical:before {
  content: '\ebff';
}

.isax-slider-vertical-1:before {
  content: '\ec00';
}

.isax-smallcaps:before {
  content: '\ec01';
}

.isax-smart-car:before {
  content: '\ec02';
}

.isax-smart-home:before {
  content: '\ec03';
}

.isax-smileys:before {
  content: '\ec04';
}

.isax-sms:before {
  content: '\ec05';
}

.isax-sms-edit:before {
  content: '\ec06';
}

.isax-sms-notification:before {
  content: '\ec07';
}

.isax-sms-search:before {
  content: '\ec08';
}

.isax-sms-star:before {
  content: '\ec09';
}

.isax-sms-tracking:before {
  content: '\ec0a';
}

.isax-sort:before {
  content: '\ec0b';
}

.isax-sound:before {
  content: '\ec0c';
}

.isax-speaker:before {
  content: '\ec0d';
}

.isax-speedometer:before {
  content: '\ec0e';
}

.isax-star:before {
  content: '\ec0f';
}

.isax-star-1:before {
  content: '\ec10';
}

.isax-star-slash:before {
  content: '\ec11';
}

.isax-status:before {
  content: '\ec12';
}

.isax-status-up:before {
  content: '\ec13';
}

.isax-sticker:before {
  content: '\ec14';
}

.isax-stickynote:before {
  content: '\ec15';
}

.isax-stop:before {
  content: '\ec16';
}

.isax-stop-circle:before {
  content: '\ec17';
}

.isax-story:before {
  content: '\ec18';
}

.isax-strongbox:before {
  content: '\ec19';
}

.isax-strongbox-2:before {
  content: '\ec1a';
}

.isax-subtitle:before {
  content: '\ec1b';
}

.isax-sun:before {
  content: '\ec1c';
}

.isax-sun-1:before {
  content: '\ec1d';
}

.isax-sun-fog:before {
  content: '\ec1e';
}

.isax-tag:before {
  content: '\ec1f';
}

.isax-tag-2:before {
  content: '\ec20';
}

.isax-tag-cross:before {
  content: '\ec21';
}

.isax-tag-right:before {
  content: '\ec22';
}

.isax-tag-user:before {
  content: '\ec23';
}

.isax-task:before {
  content: '\ec24';
}

.isax-task-square:before {
  content: '\ec25';
}

.isax-teacher:before {
  content: '\ec26';
}

.isax-text:before {
  content: '\ec27';
}

.isax-textalign-center:before {
  content: '\ec28';
}

.isax-textalign-justifycenter:before {
  content: '\ec29';
}

.isax-textalign-justifyleft:before {
  content: '\ec2a';
}

.isax-textalign-justifyright:before {
  content: '\ec2b';
}

.isax-textalign-left:before {
  content: '\ec2c';
}

.isax-textalign-right:before {
  content: '\ec2d';
}

.isax-text-block:before {
  content: '\ec2e';
}

.isax-text-bold:before {
  content: '\ec2f';
}

.isax-text-italic:before {
  content: '\ec30';
}

.isax-text-underline:before {
  content: '\ec31';
}

.isax-tick-circle:before {
  content: '\ec32';
}

.isax-ticket:before {
  content: '\ec33';
}

.isax-ticket-2:before {
  content: '\ec34';
}

.isax-ticket-discount:before {
  content: '\ec35';
}

.isax-ticket-expired:before {
  content: '\ec36';
}

.isax-ticket-star:before {
  content: '\ec37';
}

.isax-tick-square:before {
  content: '\ec38';
}

.isax-timer:before {
  content: '\ec39';
}

.isax-timer-1:before {
  content: '\ec3a';
}

.isax-timer-pause:before {
  content: '\ec3b';
}

.isax-timer-start:before {
  content: '\ec3c';
}

.isax-toggle-off:before {
  content: '\ec3d';
}

.isax-toggle-off-circle:before {
  content: '\ec3e';
}

.isax-toggle-on:before {
  content: '\ec3f';
}

.isax-toggle-on-circle:before {
  content: '\ec40';
}

.isax-trade:before {
  content: '\ec41';
}

.isax-transaction-minus:before {
  content: '\ec42';
}

.isax-translate:before {
  content: '\ec43';
}

.isax-trash:before {
  content: '\ec44';
}

.isax-tree:before {
  content: '\ec45';
}

.isax-trend-down:before {
  content: '\ec46';
}

.isax-trend-up:before {
  content: '\ec47';
}

.isax-triangle:before {
  content: '\ec48';
}

.isax-truck:before {
  content: '\ec49';
}

.isax-truck-fast:before {
  content: '\ec4a';
}

.isax-truck-remove:before {
  content: '\ec4b';
}

.isax-truck-tick:before {
  content: '\ec4c';
}

.isax-truck-time:before {
  content: '\ec4d';
}

.isax-trush-square:before {
  content: '\ec4e';
}

.isax-undo:before {
  content: '\ec4f';
}

.isax-unlimited:before {
  content: '\ec50';
}

.isax-unlock:before {
  content: '\ec51';
}

.isax-user:before {
  content: '\ec52';
}

.isax-user-add:before {
  content: '\ec53';
}

.isax-user-cirlce-add:before {
  content: '\ec54';
}

.isax-user-edit:before {
  content: '\ec55';
}

.isax-user-minus:before {
  content: '\ec56';
}

.isax-user-octagon:before {
  content: '\ec57';
}

.isax-user-remove:before {
  content: '\ec58';
}

.isax-user-search:before {
  content: '\ec59';
}

.isax-user-square:before {
  content: '\ec5a';
}

.isax-user-tag:before {
  content: '\ec5b';
}

.isax-user-tick:before {
  content: '\ec5c';
}

.isax-verify:before {
  content: '\ec5d';
}

.isax-video:before {
  content: '\ec5e';
}

.isax-video-add:before {
  content: '\ec5f';
}

.isax-video-circle:before {
  content: '\ec60';
}

.isax-video-horizontal:before {
  content: '\ec61';
}

.isax-video-octagon:before {
  content: '\ec62';
}

.isax-video-play:before {
  content: '\ec63';
}

.isax-video-remove:before {
  content: '\ec64';
}

.isax-video-slash:before {
  content: '\ec65';
}

.isax-video-square:before {
  content: '\ec66';
}

.isax-video-tick:before {
  content: '\ec67';
}

.isax-video-time:before {
  content: '\ec68';
}

.isax-video-vertical:before {
  content: '\ec69';
}

.isax-voice-cricle:before {
  content: '\ec6a';
}

.isax-voice-square:before {
  content: '\ec6b';
}

.isax-volume-cross:before {
  content: '\ec6c';
}

.isax-volume-high:before {
  content: '\ec6d';
}

.isax-volume-low:before {
  content: '\ec6e';
}

.isax-volume-low-1:before {
  content: '\ec6f';
}

.isax-volume-mute:before {
  content: '\ec70';
}

.isax-volume-slash:before {
  content: '\ec71';
}

.isax-volume-up:before {
  content: '\ec72';
}

.isax-wallet:before {
  content: '\ec73';
}

.isax-wallet-1:before {
  content: '\ec74';
}

.isax-wallet-2:before {
  content: '\ec75';
}

.isax-wallet-3:before {
  content: '\ec76';
}

.isax-wallet-add:before {
  content: '\ec77';
}

.isax-wallet-add-1:before {
  content: '\ec78';
}

.isax-wallet-check:before {
  content: '\ec79';
}

.isax-wallet-minus:before {
  content: '\ec7a';
}

.isax-wallet-money:before {
  content: '\ec7b';
}

.isax-wallet-remove:before {
  content: '\ec7c';
}

.isax-wallet-search:before {
  content: '\ec7d';
}

.isax-warning-2:before {
  content: '\ec7e';
}

.isax-watch:before {
  content: '\ec7f';
}

.isax-watch-status:before {
  content: '\ec80';
}

.isax-weight:before {
  content: '\ec81';
}

.isax-weight-1:before {
  content: '\ec82';
}

.isax-wifi:before {
  content: '\ec83';
}

.isax-wifi-square:before {
  content: '\ec84';
}

.isax-wind:before {
  content: '\ec85';
}

.isax-wind-2:before {
  content: '\ec86';
}

.isax-woman:before {
  content: '\ec87';
}

.isax-dcube1 .path1:before {
  content: '\ec88';
  color: rgb(41, 45, 50);
}

.isax-dcube1 .path2:before {
  content: '\ec89';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-dcube1 .path3:before {
  content: '\ec8a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-d-cube-scan1 .path1:before {
  content: '\ec8b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-d-cube-scan1 .path2:before {
  content: '\ec8c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-d-cube-scan1 .path3:before {
  content: '\ec8d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-d-cube-scan1 .path4:before {
  content: '\ec8e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-d-cube-scan1 .path5:before {
  content: '\ec8f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-d-rotate1 .path1:before {
  content: '\ec90';
  color: rgb(41, 45, 50);
}

.isax-d-rotate1 .path2:before {
  content: '\ec91';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-d-rotate1 .path3:before {
  content: '\ec92';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-d-rotate1 .path4:before {
  content: '\ec93';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-d-square1 .path1:before {
  content: '\ec94';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-d-square1 .path2:before {
  content: '\ec95';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-d-square1 .path3:before {
  content: '\ec96';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-d-square1 .path4:before {
  content: '\ec97';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-square1 .path1:before {
  content: '\ec98';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-square1 .path2:before {
  content: '\ec99';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-square1 .path3:before {
  content: '\ec9a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-support1 .path1:before {
  content: '\ec9b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-support1 .path2:before {
  content: '\ec9c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-support1 .path3:before {
  content: '\ec9d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-activity1 .path1:before {
  content: '\ec9e';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-activity1 .path2:before {
  content: '\ec9f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-add1 .path1:before {
  content: '\eca0';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-add1 .path2:before {
  content: '\eca1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-add-circle1 .path1:before {
  content: '\eca2';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-add-circle1 .path2:before {
  content: '\eca3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-additem1 .path1:before {
  content: '\eca4';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-additem1 .path2:before {
  content: '\eca5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-additem1 .path3:before {
  content: '\eca6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-add-square1 .path1:before {
  content: '\eca7';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-add-square1 .path2:before {
  content: '\eca8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-airdrop1 .path1:before {
  content: '\eca9';
  color: rgb(41, 45, 50);
}

.isax-airdrop1 .path2:before {
  content: '\ecaa';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-airdrop1 .path3:before {
  content: '\ecab';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-airdrop1 .path4:before {
  content: '\ecac';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-airplane1 .path1:before {
  content: '\ecad';
  color: rgb(41, 45, 50);
}

.isax-airplane1 .path2:before {
  content: '\ecae';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-airplane-square1 .path1:before {
  content: '\ecaf';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-airplane-square1 .path2:before {
  content: '\ecb0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-airpod1 .path1:before {
  content: '\ecb1';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-airpod1 .path2:before {
  content: '\ecb2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-airpods1 .path1:before {
  content: '\ecb3';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-airpods1 .path2:before {
  content: '\ecb4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-airpods1 .path3:before {
  content: '\ecb5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-airpods1 .path4:before {
  content: '\ecb6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-airpods1 .path5:before {
  content: '\ecb7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-airpods1 .path6:before {
  content: '\ecb8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-alarm1 .path1:before {
  content: '\ecb9';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-alarm1 .path2:before {
  content: '\ecba';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-alarm1 .path3:before {
  content: '\ecbb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-alarm1 .path4:before {
  content: '\ecbc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-alarm1 .path5:before {
  content: '\ecbd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-align-bottom1 .path1:before {
  content: '\ecbe';
  color: rgb(41, 45, 50);
}

.isax-align-bottom1 .path2:before {
  content: '\ecbf';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-align-bottom1 .path3:before {
  content: '\ecc0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-align-horizontally1 .path1:before {
  content: '\ecc1';
  color: rgb(41, 45, 50);
}

.isax-align-horizontally1 .path2:before {
  content: '\ecc2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-align-horizontally1 .path3:before {
  content: '\ecc3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-align-horizontally1 .path4:before {
  content: '\ecc4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-align-horizontally1 .path5:before {
  content: '\ecc5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-align-left1 .path1:before {
  content: '\ecc6';
  color: rgb(41, 45, 50);
}

.isax-align-left1 .path2:before {
  content: '\ecc7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-align-left1 .path3:before {
  content: '\ecc8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-align-right1 .path1:before {
  content: '\ecc9';
  color: rgb(41, 45, 50);
}

.isax-align-right1 .path2:before {
  content: '\ecca';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-align-right1 .path3:before {
  content: '\eccb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-align-vertically1 .path1:before {
  content: '\eccc';
  color: rgb(41, 45, 50);
}

.isax-align-vertically1 .path2:before {
  content: '\eccd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-align-vertically1 .path3:before {
  content: '\ecce';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-align-vertically1 .path4:before {
  content: '\eccf';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-align-vertically1 .path5:before {
  content: '\ecd0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-aquarius1 .path1:before {
  content: '\ecd1';
  color: rgb(41, 45, 50);
}

.isax-aquarius1 .path2:before {
  content: '\ecd2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-archive1 .path1:before {
  content: '\ecd3';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-archive1 .path2:before {
  content: '\ecd4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-archive1 .path3:before {
  content: '\ecd5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-archive-11 .path1:before {
  content: '\ecd6';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-archive-11 .path2:before {
  content: '\ecd7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-archive-21 .path1:before {
  content: '\ecd8';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-archive-21 .path2:before {
  content: '\ecd9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-archive-add1 .path1:before {
  content: '\ecda';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-archive-add1 .path2:before {
  content: '\ecdb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-archive-book1 .path1:before {
  content: '\ecdc';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-archive-book1 .path2:before {
  content: '\ecdd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-archive-book1 .path3:before {
  content: '\ecde';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-archive-book1 .path4:before {
  content: '\ecdf';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-archive-minus1 .path1:before {
  content: '\ece0';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-archive-minus1 .path2:before {
  content: '\ece1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-archive-slash1 .path1:before {
  content: '\ece2';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-archive-slash1 .path2:before {
  content: '\ece3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-archive-slash1 .path3:before {
  content: '\ece4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-archive-tick1 .path1:before {
  content: '\ece5';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-archive-tick1 .path2:before {
  content: '\ece6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrange-circle1 .path1:before {
  content: '\ece7';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrange-circle1 .path2:before {
  content: '\ece8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrange-circle1 .path3:before {
  content: '\ece9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrange-circle-21 .path1:before {
  content: '\ecea';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrange-circle-21 .path2:before {
  content: '\eceb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrange-circle-21 .path3:before {
  content: '\ecec';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrange-square1 .path1:before {
  content: '\eced';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrange-square1 .path2:before {
  content: '\ecee';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrange-square1 .path3:before {
  content: '\ecef';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrange-square-21 .path1:before {
  content: '\ecf0';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrange-square-21 .path2:before {
  content: '\ecf1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrange-square-21 .path3:before {
  content: '\ecf2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow1 .path1:before {
  content: '\ecf3';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow1 .path2:before {
  content: '\ecf4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow1 .path3:before {
  content: '\ecf5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow1 .path4:before {
  content: '\ecf6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow1 .path5:before {
  content: '\ecf7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow1 .path6:before {
  content: '\ecf8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-21 .path1:before {
  content: '\ecf9';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-21 .path2:before {
  content: '\ecfa';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-31 .path1:before {
  content: '\ecfb';
  color: rgb(41, 45, 50);
}

.isax-arrow-31 .path2:before {
  content: '\ecfc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-bottom1 .path1:before {
  content: '\ecfd';
  color: rgb(41, 45, 50);
}

.isax-arrow-bottom1 .path2:before {
  content: '\ecfe';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-circle-down1 .path1:before {
  content: '\ecff';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-circle-down1 .path2:before {
  content: '\ed00';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-circle-left1 .path1:before {
  content: '\ed01';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-circle-left1 .path2:before {
  content: '\ed02';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-circle-right1 .path1:before {
  content: '\ed03';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-circle-right1 .path2:before {
  content: '\ed04';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-circle-up1 .path1:before {
  content: '\ed05';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-circle-up1 .path2:before {
  content: '\ed06';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-down1 .path1:before {
  content: '\ed07';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-down1 .path2:before {
  content: '\ed08';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-down-11 .path1:before {
  content: '\ed09';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-down-11 .path2:before {
  content: '\ed0a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-down-21 .path1:before {
  content: '\ed0b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-down-21 .path2:before {
  content: '\ed0c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-left1 .path1:before {
  content: '\ed0d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-left1 .path2:before {
  content: '\ed0e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-left-11 .path1:before {
  content: '\ed0f';
  color: rgb(41, 45, 50);
}

.isax-arrow-left-11 .path2:before {
  content: '\ed10';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-left-21 .path1:before {
  content: '\ed11';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-left-21 .path2:before {
  content: '\ed12';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-left-31 .path1:before {
  content: '\ed13';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-left-31 .path2:before {
  content: '\ed14';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-right1 .path1:before {
  content: '\ed15';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-right1 .path2:before {
  content: '\ed16';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-right-11 .path1:before {
  content: '\ed17';
  color: rgb(41, 45, 50);
}

.isax-arrow-right-11 .path2:before {
  content: '\ed18';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-right-21 .path1:before {
  content: '\ed19';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-right-21 .path2:before {
  content: '\ed1a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-right-31 .path1:before {
  content: '\ed1b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-right-31 .path2:before {
  content: '\ed1c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-square1 .path1:before {
  content: '\ed1d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-square1 .path2:before {
  content: '\ed1e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-square-down1 .path1:before {
  content: '\ed1f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-square-down1 .path2:before {
  content: '\ed20';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-square-left1 .path1:before {
  content: '\ed21';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-square-left1 .path2:before {
  content: '\ed22';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-square-right .path1:before {
  content: '\ed23';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-square-right .path2:before {
  content: '\ed24';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-square-up1 .path1:before {
  content: '\ed25';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-square-up1 .path2:before {
  content: '\ed26';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-swap1 .path1:before {
  content: '\ed27';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-swap1 .path2:before {
  content: '\ed28';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-swap1 .path3:before {
  content: '\ed29';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-swap-horizontal1 .path1:before {
  content: '\ed2a';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-swap-horizontal1 .path2:before {
  content: '\ed2b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-swap-horizontal1 .path3:before {
  content: '\ed2c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-up1 .path1:before {
  content: '\ed2d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-up1 .path2:before {
  content: '\ed2e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-up-11 .path1:before {
  content: '\ed2f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-up-11 .path2:before {
  content: '\ed30';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-up-21 .path1:before {
  content: '\ed31';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-arrow-up-21 .path2:before {
  content: '\ed32';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-arrow-up-31 .path1:before {
  content: '\ed33';
  color: rgb(41, 45, 50);
}

.isax-arrow-up-31 .path2:before {
  content: '\ed34';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-attach-circle1 .path1:before {
  content: '\ed35';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-attach-circle1 .path2:before {
  content: '\ed36';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-attach-square1 .path1:before {
  content: '\ed37';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-attach-square1 .path2:before {
  content: '\ed38';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-audio-square1 .path1:before {
  content: '\ed39';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-audio-square1 .path2:before {
  content: '\ed3a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-autobrightness1 .path1:before {
  content: '\ed3b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-autobrightness1 .path2:before {
  content: '\ed3c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-autobrightness1 .path3:before {
  content: '\ed3d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-award1 .path1:before {
  content: '\ed3e';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-award1 .path2:before {
  content: '\ed3f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-award1 .path3:before {
  content: '\ed40';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-back-square1 .path1:before {
  content: '\ed41';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-back-square1 .path2:before {
  content: '\ed42';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-backward1 .path1:before {
  content: '\ed43';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-backward1 .path2:before {
  content: '\ed44';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-backward-5-seconds1 .path1:before {
  content: '\ed45';
  color: rgb(41, 45, 50);
}

.isax-backward-5-seconds1 .path2:before {
  content: '\ed46';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-backward-10-seconds1 .path1:before {
  content: '\ed47';
  color: rgb(41, 45, 50);
}

.isax-backward-10-seconds1 .path2:before {
  content: '\ed48';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-backward-10-seconds1 .path3:before {
  content: '\ed49';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-backward-15-seconds1 .path1:before {
  content: '\ed4a';
  color: rgb(41, 45, 50);
}

.isax-backward-15-seconds1 .path2:before {
  content: '\ed4b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-backward-15-seconds1 .path3:before {
  content: '\ed4c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-backward-item1 .path1:before {
  content: '\ed4d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-backward-item1 .path2:before {
  content: '\ed4e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-backward-item1 .path3:before {
  content: '\ed4f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bag1 .path1:before {
  content: '\ed50';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bag1 .path2:before {
  content: '\ed51';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bag-21 .path1:before {
  content: '\ed52';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bag-21 .path2:before {
  content: '\ed53';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bag-cross1 .path1:before {
  content: '\ed54';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bag-cross1 .path2:before {
  content: '\ed55';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bag-cross-11 .path1:before {
  content: '\ed56';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bag-cross-11 .path2:before {
  content: '\ed57';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bag-cross-11 .path3:before {
  content: '\ed58';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bag-cross-11 .path4:before {
  content: '\ed59';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bag-happy1 .path1:before {
  content: '\ed5a';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bag-happy1 .path2:before {
  content: '\ed5b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bag-tick1 .path1:before {
  content: '\ed5c';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bag-tick1 .path2:before {
  content: '\ed5d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bag-tick-21 .path1:before {
  content: '\ed5e';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bag-tick-21 .path2:before {
  content: '\ed5f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bag-tick-21 .path3:before {
  content: '\ed60';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bag-tick-21 .path4:before {
  content: '\ed61';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bag-timer1 .path1:before {
  content: '\ed62';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bag-timer1 .path2:before {
  content: '\ed63';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bag-timer1 .path3:before {
  content: '\ed64';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bag-timer1 .path4:before {
  content: '\ed65';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bank1 .path1:before {
  content: '\ed66';
  color: rgb(41, 45, 50);
}

.isax-bank1 .path2:before {
  content: '\ed67';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-bank1 .path3:before {
  content: '\ed68';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bank1 .path4:before {
  content: '\ed69';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-bank1 .path5:before {
  content: '\ed6a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bank1 .path6:before {
  content: '\ed6b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bank1 .path7:before {
  content: '\ed6c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-barcode1 .path1:before {
  content: '\ed6d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-barcode1 .path2:before {
  content: '\ed6e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-barcode1 .path3:before {
  content: '\ed6f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-barcode1 .path4:before {
  content: '\ed70';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-barcode1 .path5:before {
  content: '\ed71';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-barcode1 .path6:before {
  content: '\ed72';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-barcode1 .path7:before {
  content: '\ed73';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-barcode1 .path8:before {
  content: '\ed74';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-battery-3full1 .path1:before {
  content: '\ed75';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-battery-3full1 .path2:before {
  content: '\ed76';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-battery-3full1 .path3:before {
  content: '\ed77';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-battery-3full1 .path4:before {
  content: '\ed78';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-battery-charging1 .path1:before {
  content: '\ed79';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-battery-charging1 .path2:before {
  content: '\ed7a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-battery-charging1 .path3:before {
  content: '\ed7b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-battery-disable1 .path1:before {
  content: '\ed7c';
  color: rgb(41, 45, 50);
}

.isax-battery-disable1 .path2:before {
  content: '\ed7d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-battery-disable1 .path3:before {
  content: '\ed7e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-battery-disable1 .path4:before {
  content: '\ed7f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-battery-empty1 .path1:before {
  content: '\ed80';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-battery-empty1 .path2:before {
  content: '\ed81';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-battery-empty-11 .path1:before {
  content: '\ed82';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-battery-empty-11 .path2:before {
  content: '\ed83';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-battery-empty-11 .path3:before {
  content: '\ed84';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-battery-full1 .path1:before {
  content: '\ed85';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-battery-full1 .path2:before {
  content: '\ed86';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-battery-full1 .path3:before {
  content: '\ed87';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-battery-full1 .path4:before {
  content: '\ed88';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-battery-full1 .path5:before {
  content: '\ed89';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bezier1 .path1:before {
  content: '\ed8a';
  color: rgb(41, 45, 50);
}

.isax-bezier1 .path2:before {
  content: '\ed8b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bezier1 .path3:before {
  content: '\ed8c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bezier1 .path4:before {
  content: '\ed8d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bezier1 .path5:before {
  content: '\ed8e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bezier1 .path6:before {
  content: '\ed8f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.16;
}

.isax-bezier1 .path7:before {
  content: '\ed90';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.16;
}

.isax-bill1 .path1:before {
  content: '\ed91';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bill1 .path2:before {
  content: '\ed92';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bill1 .path3:before {
  content: '\ed93';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bill1 .path4:before {
  content: '\ed94';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bitcoin-card1 .path1:before {
  content: '\ed95';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bitcoin-card1 .path2:before {
  content: '\ed96';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bitcoin-card1 .path3:before {
  content: '\ed97';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bitcoin-convert1 .path1:before {
  content: '\ed98';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bitcoin-convert1 .path2:before {
  content: '\ed99';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bitcoin-convert1 .path3:before {
  content: '\ed9a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bitcoin-convert1 .path4:before {
  content: '\ed9b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bitcoin-convert1 .path5:before {
  content: '\ed9c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bitcoin-refresh1 .path1:before {
  content: '\ed9d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bitcoin-refresh1 .path2:before {
  content: '\ed9e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bitcoin-refresh1 .path3:before {
  content: '\ed9f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-blend1 .path1:before {
  content: '\eda0';
  color: rgb(41, 45, 50);
}

.isax-blend1 .path2:before {
  content: '\eda1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-blend-21 .path1:before {
  content: '\eda2';
  color: rgb(41, 45, 50);
}

.isax-blend-21 .path2:before {
  content: '\eda3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-blend-21 .path3:before {
  content: '\eda4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-blend-21 .path4:before {
  content: '\eda5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-blend-21 .path5:before {
  content: '\eda6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bluetooth1 .path1:before {
  content: '\eda7';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bluetooth1 .path2:before {
  content: '\eda8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bluetooth-21 .path1:before {
  content: '\eda9';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bluetooth-21 .path2:before {
  content: '\edaa';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bluetooth-21 .path3:before {
  content: '\edab';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bluetooth-21 .path4:before {
  content: '\edac';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bluetooth-circle1 .path1:before {
  content: '\edad';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bluetooth-circle1 .path2:before {
  content: '\edae';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bluetooth-rectangle1 .path1:before {
  content: '\edaf';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bluetooth-rectangle1 .path2:before {
  content: '\edb0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-blur1 .path1:before {
  content: '\edb1';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-blur1 .path2:before {
  content: '\edb2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-book1 .path1:before {
  content: '\edb3';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-book1 .path2:before {
  content: '\edb4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-book1 .path3:before {
  content: '\edb5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-book1 .path4:before {
  content: '\edb6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-book-11 .path1:before {
  content: '\edb7';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-book-11 .path2:before {
  content: '\edb8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-book-11 .path3:before {
  content: '\edb9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-book-11 .path4:before {
  content: '\edba';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bookmark1 .path1:before {
  content: '\edbb';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bookmark1 .path2:before {
  content: '\edbc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bookmark-21 .path1:before {
  content: '\edbd';
  color: rgb(41, 45, 50);
}

.isax-bookmark-21 .path2:before {
  content: '\edbe';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-book-saved1 .path1:before {
  content: '\edbf';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-book-saved1 .path2:before {
  content: '\edc0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-book-saved1 .path3:before {
  content: '\edc1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-book-square1 .path1:before {
  content: '\edc2';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-book-square1 .path2:before {
  content: '\edc3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-book-square1 .path3:before {
  content: '\edc4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-box1 .path1:before {
  content: '\edc5';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-box1 .path2:before {
  content: '\edc6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-box1 .path3:before {
  content: '\edc7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-box1 .path4:before {
  content: '\edc8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-box1 .path5:before {
  content: '\edc9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-box-11 .path1:before {
  content: '\edca';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-box-11 .path2:before {
  content: '\edcb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-box-11 .path3:before {
  content: '\edcc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-box-21 .path1:before {
  content: '\edcd';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-box-21 .path2:before {
  content: '\edce';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-box-21 .path3:before {
  content: '\edcf';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-box-21 .path4:before {
  content: '\edd0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-box-21 .path5:before {
  content: '\edd1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-box-21 .path6:before {
  content: '\edd2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-box-21 .path7:before {
  content: '\edd3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-box-add1 .path1:before {
  content: '\edd4';
  color: rgb(41, 45, 50);
}

.isax-box-add1 .path2:before {
  content: '\edd5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-box-add1 .path3:before {
  content: '\edd6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-box-add1 .path4:before {
  content: '\edd7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-box-remove1 .path1:before {
  content: '\edd8';
  color: rgb(41, 45, 50);
}

.isax-box-remove1 .path2:before {
  content: '\edd9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-box-remove1 .path3:before {
  content: '\edda';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-box-remove1 .path4:before {
  content: '\eddb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-box-search1 .path1:before {
  content: '\eddc';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-box-search1 .path2:before {
  content: '\eddd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-box-search1 .path3:before {
  content: '\edde';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-box-search1 .path4:before {
  content: '\eddf';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-box-tick1 .path1:before {
  content: '\ede0';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-box-tick1 .path2:before {
  content: '\ede1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-box-tick1 .path3:before {
  content: '\ede2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-box-tick1 .path4:before {
  content: '\ede3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-box-time1 .path1:before {
  content: '\ede4';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-box-time1 .path2:before {
  content: '\ede5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-box-time1 .path3:before {
  content: '\ede6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-box-time1 .path4:before {
  content: '\ede7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-briefcase1 .path1:before {
  content: '\ede8';
  color: rgb(41, 45, 50);
}

.isax-briefcase1 .path2:before {
  content: '\ede9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-brifecase-cross1 .path1:before {
  content: '\edea';
  color: rgb(41, 45, 50);
}

.isax-brifecase-cross1 .path2:before {
  content: '\edeb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-brifecase-cross1 .path3:before {
  content: '\edec';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-brifecase-tick1 .path1:before {
  content: '\eded';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-brifecase-tick1 .path2:before {
  content: '\edee';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-brifecase-tick1 .path3:before {
  content: '\edef';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-brifecase-timer1 .path1:before {
  content: '\edf0';
  color: rgb(41, 45, 50);
}

.isax-brifecase-timer1 .path2:before {
  content: '\edf1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-brifecase-timer1 .path3:before {
  content: '\edf2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-broom1 .path1:before {
  content: '\edf3';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-broom1 .path2:before {
  content: '\edf4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-broom1 .path3:before {
  content: '\edf5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-broom1 .path4:before {
  content: '\edf6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-broom1 .path5:before {
  content: '\edf7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-broom1 .path6:before {
  content: '\edf8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-brush1 .path1:before {
  content: '\edf9';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-brush1 .path2:before {
  content: '\edfa';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-brush1 .path3:before {
  content: '\edfb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-brush1 .path4:before {
  content: '\edfc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-brush-11 .path1:before {
  content: '\edfd';
  color: rgb(41, 45, 50);
}

.isax-brush-11 .path2:before {
  content: '\edfe';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-brush-21 .path1:before {
  content: '\edff';
  color: rgb(41, 45, 50);
}

.isax-brush-21 .path2:before {
  content: '\ee00';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-brush-21 .path3:before {
  content: '\ee01';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-brush-21 .path4:before {
  content: '\ee02';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-brush-31 .path1:before {
  content: '\ee03';
  color: rgb(41, 45, 50);
}

.isax-brush-31 .path2:before {
  content: '\ee04';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-brush-31 .path3:before {
  content: '\ee05';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-brush-41 .path1:before {
  content: '\ee06';
  color: rgb(41, 45, 50);
}

.isax-brush-41 .path2:before {
  content: '\ee07';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-brush-41 .path3:before {
  content: '\ee08';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bubble1 .path1:before {
  content: '\ee09';
  color: rgb(41, 45, 50);
}

.isax-bubble1 .path2:before {
  content: '\ee0a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bubble1 .path3:before {
  content: '\ee0b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bucket1 .path1:before {
  content: '\ee0c';
  color: rgb(41, 45, 50);
}

.isax-bucket1 .path2:before {
  content: '\ee0d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bucket1 .path3:before {
  content: '\ee0e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bucket1 .path4:before {
  content: '\ee0f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bucket-circle1 .path1:before {
  content: '\ee10';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bucket-circle1 .path2:before {
  content: '\ee11';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bucket-circle1 .path3:before {
  content: '\ee12';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bucket-square1 .path1:before {
  content: '\ee13';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bucket-square1 .path2:before {
  content: '\ee14';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bucket-square1 .path3:before {
  content: '\ee15';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-building1 .path1:before {
  content: '\ee16';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-building1 .path2:before {
  content: '\ee17';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-building1 .path3:before {
  content: '\ee18';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-building1 .path4:before {
  content: '\ee19';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-building1 .path5:before {
  content: '\ee1a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-building1 .path6:before {
  content: '\ee1b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-building-31 .path1:before {
  content: '\ee1c';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-building-31 .path2:before {
  content: '\ee1d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-building-31 .path3:before {
  content: '\ee1e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-building-31 .path4:before {
  content: '\ee1f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-building-31 .path5:before {
  content: '\ee20';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-building-41 .path1:before {
  content: '\ee21';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-building-41 .path2:before {
  content: '\ee22';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-building-41 .path3:before {
  content: '\ee23';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-building-41 .path4:before {
  content: '\ee24';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-building-41 .path5:before {
  content: '\ee25';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-buildings1 .path1:before {
  content: '\ee26';
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-buildings1 .path2:before {
  content: '\ee27';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-buildings1 .path3:before {
  content: '\ee28';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-buildings1 .path4:before {
  content: '\ee29';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-buildings1 .path5:before {
  content: '\ee2a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-buildings1 .path6:before {
  content: '\ee2b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-buildings-21 .path1:before {
  content: '\ee2c';
  color: rgb(41, 45, 50);
}

.isax-buildings-21 .path2:before {
  content: '\ee2d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-buildings-21 .path3:before {
  content: '\ee2e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-buildings-21 .path4:before {
  content: '\ee2f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-buildings-21 .path5:before {
  content: '\ee30';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-buildings-21 .path6:before {
  content: '\ee31';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-buliding1 .path1:before {
  content: '\ee32';
  color: rgb(41, 45, 50);
}

.isax-buliding1 .path2:before {
  content: '\ee33';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-buliding1 .path3:before {
  content: '\ee34';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-buliding1 .path4:before {
  content: '\ee35';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-buliding1 .path5:before {
  content: '\ee36';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-buliding1 .path6:before {
  content: '\ee37';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-buliding1 .path7:before {
  content: '\ee38';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-buliding1 .path8:before {
  content: '\ee39';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bus1 .path1:before {
  content: '\ee3a';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-bus1 .path2:before {
  content: '\ee3b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bus1 .path3:before {
  content: '\ee3c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bus1 .path4:before {
  content: '\ee3d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-bus1 .path5:before {
  content: '\ee3e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-buy-crypto1 .path1:before {
  content: '\ee3f';
  color: rgb(41, 45, 50);
}

.isax-buy-crypto1 .path2:before {
  content: '\ee40';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-buy-crypto1 .path3:before {
  content: '\ee41';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cake1 .path1:before {
  content: '\ee42';
  color: rgb(41, 45, 50);
}

.isax-cake1 .path2:before {
  content: '\ee43';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cake1 .path3:before {
  content: '\ee44';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cake1 .path4:before {
  content: '\ee45';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cake1 .path5:before {
  content: '\ee46';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cake1 .path6:before {
  content: '\ee47';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-calculator1 .path1:before {
  content: '\ee48';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-calculator1 .path2:before {
  content: '\ee49';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calculator1 .path3:before {
  content: '\ee4a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calculator1 .path4:before {
  content: '\ee4b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calculator1 .path5:before {
  content: '\ee4c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calculator1 .path6:before {
  content: '\ee4d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calculator1 .path7:before {
  content: '\ee4e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calculator1 .path8:before {
  content: '\ee4f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar1 .path1:before {
  content: '\ee50';
  color: rgb(41, 45, 50);
}

.isax-calendar1 .path2:before {
  content: '\ee51';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-calendar1 .path3:before {
  content: '\ee52';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar1 .path4:before {
  content: '\ee53';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar1 .path5:before {
  content: '\ee54';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar1 .path6:before {
  content: '\ee55';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar1 .path7:before {
  content: '\ee56';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar1 .path8:before {
  content: '\ee57';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar-11 .path1:before {
  content: '\ee58';
  color: rgb(41, 45, 50);
}

.isax-calendar-11 .path2:before {
  content: '\ee59';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar-11 .path3:before {
  content: '\ee5a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-calendar-11 .path4:before {
  content: '\ee5b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar-11 .path5:before {
  content: '\ee5c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar-21 .path1:before {
  content: '\ee5d';
  color: rgb(41, 45, 50);
}

.isax-calendar-21 .path2:before {
  content: '\ee5e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-calendar-21 .path3:before {
  content: '\ee5f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar-21 .path4:before {
  content: '\ee60';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar-21 .path5:before {
  content: '\ee61';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar-add1 .path1:before {
  content: '\ee62';
  color: rgb(41, 45, 50);
}

.isax-calendar-add1 .path2:before {
  content: '\ee63';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-calendar-add1 .path3:before {
  content: '\ee64';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar-add1 .path4:before {
  content: '\ee65';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar-add1 .path5:before {
  content: '\ee66';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar-add1 .path6:before {
  content: '\ee67';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar-circle1 .path1:before {
  content: '\ee68';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-calendar-circle1 .path2:before {
  content: '\ee69';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar-circle1 .path3:before {
  content: '\ee6a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar-edit1 .path1:before {
  content: '\ee6b';
  color: rgb(41, 45, 50);
}

.isax-calendar-edit1 .path2:before {
  content: '\ee6c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-calendar-edit1 .path3:before {
  content: '\ee6d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar-remove1 .path1:before {
  content: '\ee6e';
  color: rgb(41, 45, 50);
}

.isax-calendar-remove1 .path2:before {
  content: '\ee6f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-calendar-remove1 .path3:before {
  content: '\ee70';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar-remove1 .path4:before {
  content: '\ee71';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar-remove1 .path5:before {
  content: '\ee72';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar-remove1 .path6:before {
  content: '\ee73';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar-search1 .path1:before {
  content: '\ee74';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-calendar-search1 .path2:before {
  content: '\ee75';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar-search1 .path3:before {
  content: '\ee76';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar-tick1 .path1:before {
  content: '\ee77';
  color: rgb(41, 45, 50);
}

.isax-calendar-tick1 .path2:before {
  content: '\ee78';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-calendar-tick1 .path3:before {
  content: '\ee79';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar-tick1 .path4:before {
  content: '\ee7a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar-tick1 .path5:before {
  content: '\ee7b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-calendar-tick1 .path6:before {
  content: '\ee7c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-call1 .path1:before {
  content: '\ee7d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-call1 .path2:before {
  content: '\ee7e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-call-add1 .path1:before {
  content: '\ee7f';
  color: rgb(41, 45, 50);
}

.isax-call-add1 .path2:before {
  content: '\ee80';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-call-add1 .path3:before {
  content: '\ee81';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-call-calling1 .path1:before {
  content: '\ee82';
  color: rgb(41, 45, 50);
}

.isax-call-calling1 .path2:before {
  content: '\ee83';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-call-calling1 .path3:before {
  content: '\ee84';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-call-calling1 .path4:before {
  content: '\ee85';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-call-incoming1 .path1:before {
  content: '\ee86';
  color: rgb(41, 45, 50);
}

.isax-call-incoming1 .path2:before {
  content: '\ee87';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-call-incoming1 .path3:before {
  content: '\ee88';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-call-minus1 .path1:before {
  content: '\ee89';
  color: rgb(41, 45, 50);
}

.isax-call-minus1 .path2:before {
  content: '\ee8a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-call-minus1 .path3:before {
  content: '\ee8b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-call-outgoing1 .path1:before {
  content: '\ee8c';
  color: rgb(41, 45, 50);
}

.isax-call-outgoing1 .path2:before {
  content: '\ee8d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-call-outgoing1 .path3:before {
  content: '\ee8e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-call-received1 .path1:before {
  content: '\ee8f';
  color: rgb(41, 45, 50);
}

.isax-call-received1 .path2:before {
  content: '\ee90';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-call-received1 .path3:before {
  content: '\ee91';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-call-remove1 .path1:before {
  content: '\ee92';
  color: rgb(41, 45, 50);
}

.isax-call-remove1 .path2:before {
  content: '\ee93';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-call-remove1 .path3:before {
  content: '\ee94';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-call-slash1 .path1:before {
  content: '\ee95';
  color: rgb(41, 45, 50);
}

.isax-call-slash1 .path2:before {
  content: '\ee96';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-call-slash1 .path3:before {
  content: '\ee97';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-camera1 .path1:before {
  content: '\ee98';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-camera1 .path2:before {
  content: '\ee99';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-camera1 .path3:before {
  content: '\ee9a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-camera-slash1 .path1:before {
  content: '\ee9b';
  color: rgb(41, 45, 50);
}

.isax-camera-slash1 .path2:before {
  content: '\ee9c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-camera-slash1 .path3:before {
  content: '\ee9d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-camera-slash1 .path4:before {
  content: '\ee9e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-camera-slash1 .path5:before {
  content: '\ee9f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-camera-slash1 .path6:before {
  content: '\eea0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-candle1 .path1:before {
  content: '\eea1';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-candle1 .path2:before {
  content: '\eea2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-candle-21 .path1:before {
  content: '\eea3';
  color: rgb(41, 45, 50);
}

.isax-candle-21 .path2:before {
  content: '\eea4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-car1 .path1:before {
  content: '\eea5';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-car1 .path2:before {
  content: '\eea6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-car1 .path3:before {
  content: '\eea7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-car1 .path4:before {
  content: '\eea8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card1 .path1:before {
  content: '\eea9';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-card1 .path2:before {
  content: '\eeaa';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card1 .path3:before {
  content: '\eeab';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card1 .path4:before {
  content: '\eeac';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-add1 .path1:before {
  content: '\eead';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-card-add1 .path2:before {
  content: '\eeae';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-add1 .path3:before {
  content: '\eeaf';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-coin1 .path1:before {
  content: '\eeb0';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-card-coin1 .path2:before {
  content: '\eeb1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-coin1 .path3:before {
  content: '\eeb2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-card-coin1 .path4:before {
  content: '\eeb3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-card-coin1 .path5:before {
  content: '\eeb4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-card-coin1 .path6:before {
  content: '\eeb5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-edit1 .path1:before {
  content: '\eeb6';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-card-edit1 .path2:before {
  content: '\eeb7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-edit1 .path3:before {
  content: '\eeb8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-pos1 .path1:before {
  content: '\eeb9';
  color: rgb(41, 45, 50);
}

.isax-card-pos1 .path2:before {
  content: '\eeba';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-card-pos1 .path3:before {
  content: '\eebb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-pos1 .path4:before {
  content: '\eebc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-pos1 .path5:before {
  content: '\eebd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-receive1 .path1:before {
  content: '\eebe';
  color: rgb(41, 45, 50);
}

.isax-card-receive1 .path2:before {
  content: '\eebf';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-card-receive1 .path3:before {
  content: '\eec0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-remove1 .path1:before {
  content: '\eec1';
  color: rgb(41, 45, 50);
}

.isax-card-remove1 .path2:before {
  content: '\eec2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-remove1 .path3:before {
  content: '\eec3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-card-remove1 .path4:before {
  content: '\eec4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-remove-11 .path1:before {
  content: '\eec5';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-card-remove-11 .path2:before {
  content: '\eec6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-remove-11 .path3:before {
  content: '\eec7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cards1 .path1:before {
  content: '\eec8';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cards1 .path2:before {
  content: '\eec9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cards1 .path3:before {
  content: '\eeca';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cards1 .path4:before {
  content: '\eecb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cards1 .path5:before {
  content: '\eecc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-send1 .path1:before {
  content: '\eecd';
  color: rgb(41, 45, 50);
}

.isax-card-send1 .path2:before {
  content: '\eece';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-card-send1 .path3:before {
  content: '\eecf';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-slash1 .path1:before {
  content: '\eed0';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-card-slash1 .path2:before {
  content: '\eed1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-card-slash1 .path3:before {
  content: '\eed2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-slash1 .path4:before {
  content: '\eed3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-slash1 .path5:before {
  content: '\eed4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-slash1 .path6:before {
  content: '\eed5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-slash1 .path7:before {
  content: '\eed6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-tick1 .path1:before {
  content: '\eed7';
  color: rgb(41, 45, 50);
}

.isax-card-tick1 .path2:before {
  content: '\eed8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-tick1 .path3:before {
  content: '\eed9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-card-tick1 .path4:before {
  content: '\eeda';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-tick-11 .path1:before {
  content: '\eedb';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-card-tick-11 .path2:before {
  content: '\eedc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-card-tick-11 .path3:before {
  content: '\eedd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-category1 .path1:before {
  content: '\eede';
  color: rgb(41, 45, 50);
}

.isax-category1 .path2:before {
  content: '\eedf';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-category1 .path3:before {
  content: '\eee0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-category1 .path4:before {
  content: '\eee1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-category-21 .path1:before {
  content: '\eee2';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-category-21 .path2:before {
  content: '\eee3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-category-21 .path3:before {
  content: '\eee4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-category-21 .path4:before {
  content: '\eee5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cd1 .path1:before {
  content: '\eee6';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cd1 .path2:before {
  content: '\eee7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-chart1 .path1:before {
  content: '\eee8';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-chart1 .path2:before {
  content: '\eee9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-chart1 .path3:before {
  content: '\eeea';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-chart1 .path4:before {
  content: '\eeeb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-chart-11 .path1:before {
  content: '\eeec';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-chart-11 .path2:before {
  content: '\eeed';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-chart-11 .path3:before {
  content: '\eeee';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-chart-11 .path4:before {
  content: '\eeef';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-chart-22 .path1:before {
  content: '\eef0';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-chart-22 .path2:before {
  content: '\eef1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-chart-22 .path3:before {
  content: '\eef2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-chart-31 .path1:before {
  content: '\eef3';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-chart-31 .path2:before {
  content: '\eef4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-chart-31 .path3:before {
  content: '\eef5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-chart-211 .path1:before {
  content: '\eef6';
  color: rgb(41, 45, 50);
}

.isax-chart-211 .path2:before {
  content: '\eef7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-chart-211 .path3:before {
  content: '\eef8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-chart-211 .path4:before {
  content: '\eef9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-chart-square1 .path1:before {
  content: '\eefa';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-chart-square1 .path2:before {
  content: '\eefb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-chart-square1 .path3:before {
  content: '\eefc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-chart-square1 .path4:before {
  content: '\eefd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-chart-success1 .path1:before {
  content: '\eefe';
  color: rgb(41, 45, 50);
}

.isax-chart-success1 .path2:before {
  content: '\eeff';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-chart-success1 .path3:before {
  content: '\ef00';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-chart-success1 .path4:before {
  content: '\ef01';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-chart-success1 .path5:before {
  content: '\ef02';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-check1 .path1:before {
  content: '\ef03';
  color: rgb(41, 45, 50);
}

.isax-check1 .path2:before {
  content: '\ef04';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-check1 .path3:before {
  content: '\ef05';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-check1 .path4:before {
  content: '\ef06';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-chrome1 .path1:before {
  content: '\ef07';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-chrome1 .path2:before {
  content: '\ef08';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-clipboard1 .path1:before {
  content: '\ef09';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-clipboard1 .path2:before {
  content: '\ef0a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-clipboard1 .path3:before {
  content: '\ef0b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-clipboard-close1 .path1:before {
  content: '\ef0c';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-clipboard-close1 .path2:before {
  content: '\ef0d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-clipboard-close1 .path3:before {
  content: '\ef0e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-clipboard-export1 .path1:before {
  content: '\ef0f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-clipboard-export1 .path2:before {
  content: '\ef10';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-clipboard-export1 .path3:before {
  content: '\ef11';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-clipboard-import1 .path1:before {
  content: '\ef12';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-clipboard-import1 .path2:before {
  content: '\ef13';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-clipboard-import1 .path3:before {
  content: '\ef14';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-clipboard-text1 .path1:before {
  content: '\ef15';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-clipboard-text1 .path2:before {
  content: '\ef16';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-clipboard-text1 .path3:before {
  content: '\ef17';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-clipboard-text1 .path4:before {
  content: '\ef18';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-clipboard-tick1 .path1:before {
  content: '\ef19';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-clipboard-tick1 .path2:before {
  content: '\ef1a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-clipboard-tick1 .path3:before {
  content: '\ef1b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-clock1 .path1:before {
  content: '\ef1c';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-clock1 .path2:before {
  content: '\ef1d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-clock1 .path3:before {
  content: '\ef1e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-clock1 .path4:before {
  content: '\ef1f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-clock-11 .path1:before {
  content: '\ef20';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-clock-11 .path2:before {
  content: '\ef21';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-close-circle1 .path1:before {
  content: '\ef22';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-close-circle1 .path2:before {
  content: '\ef23';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-close-square1 .path1:before {
  content: '\ef24';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-close-square1 .path2:before {
  content: '\ef25';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cloud1 .path1:before {
  content: '\ef26';
  color: rgb(41, 45, 50);
}

.isax-cloud1 .path2:before {
  content: '\ef27';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cloud-add1 .path1:before {
  content: '\ef28';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cloud-add1 .path2:before {
  content: '\ef29';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cloud-change1 .path1:before {
  content: '\ef2a';
  color: rgb(41, 45, 50);
}

.isax-cloud-change1 .path2:before {
  content: '\ef2b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cloud-change1 .path3:before {
  content: '\ef2c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cloud-connection1 .path1:before {
  content: '\ef2d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cloud-connection1 .path2:before {
  content: '\ef2e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cloud-cross1 .path1:before {
  content: '\ef2f';
  color: rgb(41, 45, 50);
}

.isax-cloud-cross1 .path2:before {
  content: '\ef30';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cloud-drizzle1 .path1:before {
  content: '\ef31';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cloud-drizzle1 .path2:before {
  content: '\ef32';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cloud-fog1 .path1:before {
  content: '\ef33';
  color: rgb(41, 45, 50);
}

.isax-cloud-fog1 .path2:before {
  content: '\ef34';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cloud-fog1 .path3:before {
  content: '\ef35';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cloud-fog1 .path4:before {
  content: '\ef36';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cloud-lightning1 .path1:before {
  content: '\ef37';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cloud-lightning1 .path2:before {
  content: '\ef38';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cloud-minus1 .path1:before {
  content: '\ef39';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cloud-minus1 .path2:before {
  content: '\ef3a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cloud-notif1 .path1:before {
  content: '\ef3b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cloud-notif1 .path2:before {
  content: '\ef3c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cloud-plus1 .path1:before {
  content: '\ef3d';
  color: rgb(41, 45, 50);
}

.isax-cloud-plus1 .path2:before {
  content: '\ef3e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cloud-remove1 .path1:before {
  content: '\ef3f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cloud-remove1 .path2:before {
  content: '\ef40';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cloud-snow1 .path1:before {
  content: '\ef41';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cloud-snow1 .path2:before {
  content: '\ef42';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cloud-sunny1 .path1:before {
  content: '\ef43';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cloud-sunny1 .path2:before {
  content: '\ef44';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-code1 .path1:before {
  content: '\ef45';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-code1 .path2:before {
  content: '\ef46';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-code1 .path3:before {
  content: '\ef47';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-code-11 .path1:before {
  content: '\ef48';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-code-11 .path2:before {
  content: '\ef49';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-code-11 .path3:before {
  content: '\ef4a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-code-11 .path4:before {
  content: '\ef4b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-code-circle1 .path1:before {
  content: '\ef4c';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-code-circle1 .path2:before {
  content: '\ef4d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-code-circle1 .path3:before {
  content: '\ef4e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-code-circle1 .path4:before {
  content: '\ef4f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-coffee1 .path1:before {
  content: '\ef50';
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-coffee1 .path2:before {
  content: '\ef51';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-coffee1 .path3:before {
  content: '\ef52';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-coffee1 .path4:before {
  content: '\ef53';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-coffee1 .path5:before {
  content: '\ef54';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-coffee1 .path6:before {
  content: '\ef55';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-coin1 .path1:before {
  content: '\ef56';
  color: rgb(41, 45, 50);
}

.isax-coin1 .path2:before {
  content: '\ef57';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-coin1 .path3:before {
  content: '\ef58';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-coin-11 .path1:before {
  content: '\ef59';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-coin-11 .path2:before {
  content: '\ef5a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-coin-11 .path3:before {
  content: '\ef5b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-colorfilter1 .path1:before {
  content: '\ef5c';
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-colorfilter1 .path2:before {
  content: '\ef5d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-colorfilter1 .path3:before {
  content: '\ef5e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-colors-square1 .path1:before {
  content: '\ef5f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-colors-square1 .path2:before {
  content: '\ef60';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-colors-square1 .path3:before {
  content: '\ef61';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-colors-square1 .path4:before {
  content: '\ef62';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-color-swatch1 .path1:before {
  content: '\ef63';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-color-swatch1 .path2:before {
  content: '\ef64';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-color-swatch1 .path3:before {
  content: '\ef65';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-command1 .path1:before {
  content: '\ef66';
  color: rgb(41, 45, 50);
}

.isax-command1 .path2:before {
  content: '\ef67';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-command1 .path3:before {
  content: '\ef68';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-command1 .path4:before {
  content: '\ef69';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-command1 .path5:before {
  content: '\ef6a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-command-square1 .path1:before {
  content: '\ef6b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-command-square1 .path2:before {
  content: '\ef6c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-command-square1 .path3:before {
  content: '\ef6d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-command-square1 .path4:before {
  content: '\ef6e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-command-square1 .path5:before {
  content: '\ef6f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-command-square1 .path6:before {
  content: '\ef70';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-component1:before {
  content: '\ef71';
}

.isax-computing1 .path1:before {
  content: '\ef72';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-computing1 .path2:before {
  content: '\ef73';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-computing1 .path3:before {
  content: '\ef74';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-computing1 .path4:before {
  content: '\ef75';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-convert1 .path1:before {
  content: '\ef76';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-convert1 .path2:before {
  content: '\ef77';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-convert1 .path3:before {
  content: '\ef78';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-convert-3d-cube1 .path1:before {
  content: '\ef79';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-convert-3d-cube1 .path2:before {
  content: '\ef7a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-convert-3d-cube1 .path3:before {
  content: '\ef7b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-convert-3d-cube1 .path4:before {
  content: '\ef7c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-convert-3d-cube1 .path5:before {
  content: '\ef7d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-convert-3d-cube1 .path6:before {
  content: '\ef7e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-convert-3d-cube1 .path7:before {
  content: '\ef7f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-convert-3d-cube1 .path8:before {
  content: '\ef80';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-convert-card1 .path1:before {
  content: '\ef81';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-convert-card1 .path2:before {
  content: '\ef82';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-convert-card1 .path3:before {
  content: '\ef83';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-convert-card1 .path4:before {
  content: '\ef84';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-convert-card1 .path5:before {
  content: '\ef85';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-convert-card1 .path6:before {
  content: '\ef86';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-convertshape1 .path1:before {
  content: '\ef87';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-convertshape1 .path2:before {
  content: '\ef88';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-convertshape1 .path3:before {
  content: '\ef89';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-convertshape1 .path4:before {
  content: '\ef8a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-convertshape-21 .path1:before {
  content: '\ef8b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-convertshape-21 .path2:before {
  content: '\ef8c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-convertshape-21 .path3:before {
  content: '\ef8d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-convertshape-21 .path4:before {
  content: '\ef8e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-copy1 .path1:before {
  content: '\ef8f';
  color: rgb(41, 45, 50);
}

.isax-copy1 .path2:before {
  content: '\ef90';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-copyright1 .path1:before {
  content: '\ef91';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-copyright1 .path2:before {
  content: '\ef92';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-copy-success1 .path1:before {
  content: '\ef93';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-copy-success1 .path2:before {
  content: '\ef94';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-courthouse1 .path1:before {
  content: '\ef95';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-courthouse1 .path2:before {
  content: '\ef96';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu1 .path1:before {
  content: '\ef97';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cpu1 .path2:before {
  content: '\ef98';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu1 .path3:before {
  content: '\ef99';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu1 .path4:before {
  content: '\ef9a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu1 .path5:before {
  content: '\ef9b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu1 .path6:before {
  content: '\ef9c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu1 .path7:before {
  content: '\ef9d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu1 .path8:before {
  content: '\ef9e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu1 .path9:before {
  content: '\ef9f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu1 .path10:before {
  content: '\efa0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu1 .path11:before {
  content: '\efa1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu1 .path12:before {
  content: '\efa2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu1 .path13:before {
  content: '\efa3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu1 .path14:before {
  content: '\efa4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu-charge1 .path1:before {
  content: '\efa5';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cpu-charge1 .path2:before {
  content: '\efa6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu-charge1 .path3:before {
  content: '\efa7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu-charge1 .path4:before {
  content: '\efa8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu-charge1 .path5:before {
  content: '\efa9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu-charge1 .path6:before {
  content: '\efaa';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu-charge1 .path7:before {
  content: '\efab';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu-charge1 .path8:before {
  content: '\efac';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu-charge1 .path9:before {
  content: '\efad';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu-charge1 .path10:before {
  content: '\efae';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu-charge1 .path11:before {
  content: '\efaf';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu-charge1 .path12:before {
  content: '\efb0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu-charge1 .path13:before {
  content: '\efb1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu-charge1 .path14:before {
  content: '\efb2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu-setting1 .path1:before {
  content: '\efb3';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cpu-setting1 .path2:before {
  content: '\efb4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu-setting1 .path3:before {
  content: '\efb5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu-setting1 .path4:before {
  content: '\efb6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu-setting1 .path5:before {
  content: '\efb7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu-setting1 .path6:before {
  content: '\efb8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu-setting1 .path7:before {
  content: '\efb9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu-setting1 .path8:before {
  content: '\efba';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu-setting1 .path9:before {
  content: '\efbb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu-setting1 .path10:before {
  content: '\efbc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cpu-setting1 .path11:before {
  content: '\efbd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cpu-setting1 .path12:before {
  content: '\efbe';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-creative-commons1 .path1:before {
  content: '\efbf';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-creative-commons1 .path2:before {
  content: '\efc0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-creative-commons1 .path3:before {
  content: '\efc1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-crop1 .path1:before {
  content: '\efc2';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-crop1 .path2:before {
  content: '\efc3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-crop1 .path3:before {
  content: '\efc4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-crown1 .path1:before {
  content: '\efc5';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-crown1 .path2:before {
  content: '\efc6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-crown1 .path3:before {
  content: '\efc7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-crown-11 .path1:before {
  content: '\efc8';
  color: rgb(41, 45, 50);
}

.isax-crown-11 .path2:before {
  content: '\efc9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cup1 .path1:before {
  content: '\efca';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cup1 .path2:before {
  content: '\efcb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cup1 .path3:before {
  content: '\efcc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-cup1 .path4:before {
  content: '\efcd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-cup1 .path5:before {
  content: '\efce';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-danger1 .path1:before {
  content: '\efcf';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-danger1 .path2:before {
  content: '\efd0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-danger1 .path3:before {
  content: '\efd1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-data1 .path1:before {
  content: '\efd2';
  color: rgb(41, 45, 50);
}

.isax-data1 .path2:before {
  content: '\efd3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-data1 .path3:before {
  content: '\efd4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-data1 .path4:before {
  content: '\efd5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-data1 .path5:before {
  content: '\efd6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-data-21 .path1:before {
  content: '\efd7';
  color: rgb(41, 45, 50);
}

.isax-data-21 .path2:before {
  content: '\efd8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-data-21 .path3:before {
  content: '\efd9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-data-21 .path4:before {
  content: '\efda';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.37;
}

.isax-data-21 .path5:before {
  content: '\efdb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-designtools1 .path1:before {
  content: '\efdc';
  color: rgb(41, 45, 50);
}

.isax-designtools1 .path2:before {
  content: '\efdd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-designtools1 .path3:before {
  content: '\efde';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-designtools1 .path4:before {
  content: '\efdf';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-designtools1 .path5:before {
  content: '\efe0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-device-message1 .path1:before {
  content: '\efe1';
  color: rgb(41, 45, 50);
}

.isax-device-message1 .path2:before {
  content: '\efe2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-device-message1 .path3:before {
  content: '\efe3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-device-message1 .path4:before {
  content: '\efe4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-device-message1 .path5:before {
  content: '\efe5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-device-message1 .path6:before {
  content: '\efe6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-devices1 .path1:before {
  content: '\efe7';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-devices1 .path2:before {
  content: '\efe8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-devices1 .path3:before {
  content: '\efe9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-devices1 .path4:before {
  content: '\efea';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-devices-11 .path1:before {
  content: '\efeb';
  color: rgb(41, 45, 50);
}

.isax-devices-11 .path2:before {
  content: '\efec';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-devices-11 .path3:before {
  content: '\efed';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-devices-11 .path4:before {
  content: '\efee';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-devices-11 .path5:before {
  content: '\efef';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-devices-11 .path6:before {
  content: '\eff0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-devices-11 .path7:before {
  content: '\eff1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-devices-11 .path8:before {
  content: '\eff2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-diagram1 .path1:before {
  content: '\eff3';
  color: rgb(41, 45, 50);
}

.isax-diagram1 .path2:before {
  content: '\eff4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-diamonds1 .path1:before {
  content: '\eff5';
  color: rgb(41, 45, 50);
}

.isax-diamonds1 .path2:before {
  content: '\eff6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-direct1 .path1:before {
  content: '\eff7';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-direct1 .path2:before {
  content: '\eff8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-direct1 .path3:before {
  content: '\eff9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-direct1 .path4:before {
  content: '\effa';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-directbox-default1 .path1:before {
  content: '\effb';
  color: rgb(41, 45, 50);
}

.isax-directbox-default1 .path2:before {
  content: '\effc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-directbox-default1 .path3:before {
  content: '\effd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-directbox-default1 .path4:before {
  content: '\effe';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-directbox-notif1 .path1:before {
  content: '\efff';
  color: rgb(41, 45, 50);
}

.isax-directbox-notif1 .path2:before {
  content: '\f000';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-directbox-notif1 .path3:before {
  content: '\f001';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-directbox-receive1 .path1:before {
  content: '\f002';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-directbox-receive1 .path2:before {
  content: '\f003';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-directbox-receive1 .path3:before {
  content: '\f004';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-directbox-receive1 .path4:before {
  content: '\f005';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-directbox-send1 .path1:before {
  content: '\f006';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-directbox-send1 .path2:before {
  content: '\f007';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-directbox-send1 .path3:before {
  content: '\f008';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-directbox-send1 .path4:before {
  content: '\f009';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-direct-down1 .path1:before {
  content: '\f00a';
  color: rgb(41, 45, 50);
}

.isax-direct-down1 .path2:before {
  content: '\f00b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-direct-inbox1 .path1:before {
  content: '\f00c';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-direct-inbox1 .path2:before {
  content: '\f00d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-direct-inbox1 .path3:before {
  content: '\f00e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-direct-left1 .path1:before {
  content: '\f00f';
  color: rgb(41, 45, 50);
}

.isax-direct-left1 .path2:before {
  content: '\f010';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-direct-normal1 .path1:before {
  content: '\f011';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-direct-normal1 .path2:before {
  content: '\f012';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-direct-notification1 .path1:before {
  content: '\f013';
  color: rgb(41, 45, 50);
}

.isax-direct-notification1 .path2:before {
  content: '\f014';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-direct-notification1 .path3:before {
  content: '\f015';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-direct-right1 .path1:before {
  content: '\f016';
  color: rgb(41, 45, 50);
}

.isax-direct-right1 .path2:before {
  content: '\f017';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-direct-send1 .path1:before {
  content: '\f018';
  color: rgb(41, 45, 50);
}

.isax-direct-send1 .path2:before {
  content: '\f019';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-direct-send1 .path3:before {
  content: '\f01a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-direct-up1 .path1:before {
  content: '\f01b';
  color: rgb(41, 45, 50);
}

.isax-direct-up1 .path2:before {
  content: '\f01c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-discount-circle1 .path1:before {
  content: '\f01d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-discount-circle1 .path2:before {
  content: '\f01e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-discount-circle1 .path3:before {
  content: '\f01f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-discount-circle1 .path4:before {
  content: '\f020';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-discount-shape1 .path1:before {
  content: '\f021';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-discount-shape1 .path2:before {
  content: '\f022';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-discount-shape1 .path3:before {
  content: '\f023';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-discount-shape1 .path4:before {
  content: '\f024';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-discover1 .path1:before {
  content: '\f025';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-discover1 .path2:before {
  content: '\f026';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-discover-11 .path1:before {
  content: '\f027';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-discover-11 .path2:before {
  content: '\f028';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-dislike1 .path1:before {
  content: '\f029';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-dislike1 .path2:before {
  content: '\f02a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document1 .path1:before {
  content: '\f02b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-document1 .path2:before {
  content: '\f02c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-11 .path1:before {
  content: '\f02d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-document-11 .path2:before {
  content: '\f02e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-11 .path3:before {
  content: '\f02f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-cloud1 .path1:before {
  content: '\f030';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-document-cloud1 .path2:before {
  content: '\f031';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-cloud1 .path3:before {
  content: '\f032';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-code1 .path1:before {
  content: '\f033';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-document-code1 .path2:before {
  content: '\f034';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-code1 .path3:before {
  content: '\f035';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-code1 .path4:before {
  content: '\f036';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-code-21 .path1:before {
  content: '\f037';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-document-code-21 .path2:before {
  content: '\f038';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-code-21 .path3:before {
  content: '\f039';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-copy1 .path1:before {
  content: '\f03a';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-document-copy1 .path2:before {
  content: '\f03b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-copy1 .path3:before {
  content: '\f03c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-download1 .path1:before {
  content: '\f03d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-document-download1 .path2:before {
  content: '\f03e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-download1 .path3:before {
  content: '\f03f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-favorite1 .path1:before {
  content: '\f040';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-document-favorite1 .path2:before {
  content: '\f041';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-favorite1 .path3:before {
  content: '\f042';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-filter1 .path1:before {
  content: '\f043';
  color: rgb(41, 45, 50);
}

.isax-document-filter1 .path2:before {
  content: '\f044';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-document-filter1 .path3:before {
  content: '\f045';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-filter1 .path4:before {
  content: '\f046';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-forward1 .path1:before {
  content: '\f047';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-document-forward1 .path2:before {
  content: '\f048';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-forward1 .path3:before {
  content: '\f049';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-like1 .path1:before {
  content: '\f04a';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-document-like1 .path2:before {
  content: '\f04b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-like1 .path3:before {
  content: '\f04c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-normal1 .path1:before {
  content: '\f04d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-document-normal1 .path2:before {
  content: '\f04e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-previous1 .path1:before {
  content: '\f04f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-document-previous1 .path2:before {
  content: '\f050';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-previous1 .path3:before {
  content: '\f051';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-sketch1 .path1:before {
  content: '\f052';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-document-sketch1 .path2:before {
  content: '\f053';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-sketch1 .path3:before {
  content: '\f054';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-text1 .path1:before {
  content: '\f055';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-document-text1 .path2:before {
  content: '\f056';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-text1 .path3:before {
  content: '\f057';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-text1 .path4:before {
  content: '\f058';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-text-11 .path1:before {
  content: '\f059';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-document-text-11 .path2:before {
  content: '\f05a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-text-11 .path3:before {
  content: '\f05b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-text-11 .path4:before {
  content: '\f05c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-upload1 .path1:before {
  content: '\f05d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-document-upload1 .path2:before {
  content: '\f05e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-document-upload1 .path3:before {
  content: '\f05f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-dollar-circle1 .path1:before {
  content: '\f060';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-dollar-circle1 .path2:before {
  content: '\f061';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-dollar-square1 .path1:before {
  content: '\f062';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-dollar-square1 .path2:before {
  content: '\f063';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driver1 .path1:before {
  content: '\f064';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-driver1 .path2:before {
  content: '\f065';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-driver1 .path3:before {
  content: '\f066';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driver1 .path4:before {
  content: '\f067';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driver1 .path5:before {
  content: '\f068';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driver1 .path6:before {
  content: '\f069';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driver1 .path7:before {
  content: '\f06a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driver1 .path8:before {
  content: '\f06b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driver-21 .path1:before {
  content: '\f06c';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-driver-21 .path2:before {
  content: '\f06d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driver-21 .path3:before {
  content: '\f06e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driver-21 .path4:before {
  content: '\f06f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driver-21 .path5:before {
  content: '\f070';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driver-21 .path6:before {
  content: '\f071';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driver-21 .path7:before {
  content: '\f072';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driver-21 .path8:before {
  content: '\f073';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driver-refresh1 .path1:before {
  content: '\f074';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-driver-refresh1 .path2:before {
  content: '\f075';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driver-refresh1 .path3:before {
  content: '\f076';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driver-refresh1 .path4:before {
  content: '\f077';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driver-refresh1 .path5:before {
  content: '\f078';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driver-refresh1 .path6:before {
  content: '\f079';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driver-refresh1 .path7:before {
  content: '\f07a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driving1 .path1:before {
  content: '\f07b';
  color: rgb(41, 45, 50);
}

.isax-driving1 .path2:before {
  content: '\f07c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driving1 .path3:before {
  content: '\f07d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driving1 .path4:before {
  content: '\f07e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driving1 .path5:before {
  content: '\f07f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-driving1 .path6:before {
  content: '\f080';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driving1 .path7:before {
  content: '\f081';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-driving1 .path8:before {
  content: '\f082';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-drop .path1:before {
  content: '\f083';
  color: rgb(41, 45, 50);
}

.isax-drop .path2:before {
  content: '\f084';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-edit1 .path1:before {
  content: '\f085';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-edit1 .path2:before {
  content: '\f086';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-edit1 .path3:before {
  content: '\f087';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-edit-21 .path1:before {
  content: '\f088';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-edit-21 .path2:before {
  content: '\f089';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-edit-21 .path3:before {
  content: '\f08a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-electricity1 .path1:before {
  content: '\f08b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-electricity1 .path2:before {
  content: '\f08c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-electricity1 .path3:before {
  content: '\f08d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-electricity1 .path4:before {
  content: '\f08e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-element-1 .path1:before {
  content: '\f08f';
  color: rgb(41, 45, 50);
}

.isax-element-1 .path2:before {
  content: '\f090';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-element-1 .path3:before {
  content: '\f091';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-element-21 .path1:before {
  content: '\f092';
  color: rgb(41, 45, 50);
}

.isax-element-21 .path2:before {
  content: '\f093';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-element-21 .path3:before {
  content: '\f094';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-element-31 .path1:before {
  content: '\f095';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-element-31 .path2:before {
  content: '\f096';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-element-31 .path3:before {
  content: '\f097';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-element-31 .path4:before {
  content: '\f098';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-element-41 .path1:before {
  content: '\f099';
  color: rgb(41, 45, 50);
}

.isax-element-41 .path2:before {
  content: '\f09a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-element-41 .path3:before {
  content: '\f09b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-element-41 .path4:before {
  content: '\f09c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-element-equal1 .path1:before {
  content: '\f09d';
  color: rgb(41, 45, 50);
}

.isax-element-equal1 .path2:before {
  content: '\f09e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-element-equal1 .path3:before {
  content: '\f09f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-element-equal1 .path4:before {
  content: '\f0a0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-element-equal1 .path5:before {
  content: '\f0a1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-element-plus1 .path1:before {
  content: '\f0a2';
  color: rgb(41, 45, 50);
}

.isax-element-plus1 .path2:before {
  content: '\f0a3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-element-plus1 .path3:before {
  content: '\f0a4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-element-plus1 .path4:before {
  content: '\f0a5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-emoji-happy1 .path1:before {
  content: '\f0a6';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-emoji-happy1 .path2:before {
  content: '\f0a7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-emoji-happy1 .path3:before {
  content: '\f0a8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-emoji-happy1 .path4:before {
  content: '\f0a9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-emoji-normal1 .path1:before {
  content: '\f0aa';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-emoji-normal1 .path2:before {
  content: '\f0ab';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-emoji-normal1 .path3:before {
  content: '\f0ac';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-emoji-normal1 .path4:before {
  content: '\f0ad';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-emoji-sad1 .path1:before {
  content: '\f0ae';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-emoji-sad1 .path2:before {
  content: '\f0af';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-emoji-sad1 .path3:before {
  content: '\f0b0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-emoji-sad1 .path4:before {
  content: '\f0b1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-empty-wallet1 .path1:before {
  content: '\f0b2';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-empty-wallet1 .path2:before {
  content: '\f0b3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-empty-wallet1 .path3:before {
  content: '\f0b4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-empty-wallet1 .path4:before {
  content: '\f0b5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-empty-wallet-add1 .path1:before {
  content: '\f0b6';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-empty-wallet-add1 .path2:before {
  content: '\f0b7';
  margin-left: -1.0419921875em;
  color: rgb(41, 45, 50);
}

.isax-empty-wallet-add1 .path3:before {
  content: '\f0b8';
  margin-left: -1.0419921875em;
  color: rgb(41, 45, 50);
}

.isax-empty-wallet-add1 .path4:before {
  content: '\f0b9';
  margin-left: -1.0419921875em;
  color: rgb(41, 45, 50);
}

.isax-empty-wallet-add1 .path5:before {
  content: '\f0ba';
  margin-left: -1.0419921875em;
  color: rgb(41, 45, 50);
}

.isax-empty-wallet-change1 .path1:before {
  content: '\f0bb';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-empty-wallet-change1 .path2:before {
  content: '\f0bc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-empty-wallet-change1 .path3:before {
  content: '\f0bd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-empty-wallet-change1 .path4:before {
  content: '\f0be';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-empty-wallet-change1 .path5:before {
  content: '\f0bf';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-empty-wallet-remove1 .path1:before {
  content: '\f0c0';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-empty-wallet-remove1 .path2:before {
  content: '\f0c1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-empty-wallet-remove1 .path3:before {
  content: '\f0c2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-empty-wallet-remove1 .path4:before {
  content: '\f0c3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-empty-wallet-remove1 .path5:before {
  content: '\f0c4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-empty-wallet-tick1 .path1:before {
  content: '\f0c5';
  color: rgb(41, 45, 50);
}

.isax-empty-wallet-tick1 .path2:before {
  content: '\f0c6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-empty-wallet-tick1 .path3:before {
  content: '\f0c7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-empty-wallet-tick1 .path4:before {
  content: '\f0c8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-empty-wallet-tick1 .path5:before {
  content: '\f0c9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-empty-wallet-time1 .path1:before {
  content: '\f0ca';
  color: rgb(41, 45, 50);
}

.isax-empty-wallet-time1 .path2:before {
  content: '\f0cb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-empty-wallet-time1 .path3:before {
  content: '\f0cc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-empty-wallet-time1 .path4:before {
  content: '\f0cd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-empty-wallet-time1 .path5:before {
  content: '\f0ce';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-eraser1 .path1:before {
  content: '\f0cf';
  color: rgb(41, 45, 50);
}

.isax-eraser1 .path2:before {
  content: '\f0d0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-eraser1 .path3:before {
  content: '\f0d1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-eraser-11 .path1:before {
  content: '\f0d2';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-eraser-11 .path2:before {
  content: '\f0d3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-eraser-11 .path3:before {
  content: '\f0d4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-export1 .path1:before {
  content: '\f0d5';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-export1 .path2:before {
  content: '\f0d6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-export-11 .path1:before {
  content: '\f0d7';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-export-11 .path2:before {
  content: '\f0d8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-export-21 .path1:before {
  content: '\f0d9';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-export-21 .path2:before {
  content: '\f0da';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-export-31 .path1:before {
  content: '\f0db';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-export-31 .path2:before {
  content: '\f0dc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-external-drive1 .path1:before {
  content: '\f0dd';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-external-drive1 .path2:before {
  content: '\f0de';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-external-drive1 .path3:before {
  content: '\f0df';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-external-drive1 .path4:before {
  content: '\f0e0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-external-drive1 .path5:before {
  content: '\f0e1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-eye-slash1 .path1:before {
  content: '\f0e2';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-eye-slash1 .path2:before {
  content: '\f0e3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-eye-slash1 .path3:before {
  content: '\f0e4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-eye-slash1 .path4:before {
  content: '\f0e5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-eye-slash1 .path5:before {
  content: '\f0e6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-fatrows1 .path1:before {
  content: '\f0e7';
  color: rgb(41, 45, 50);
}

.isax-fatrows1 .path2:before {
  content: '\f0e8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-favorite-chart1 .path1:before {
  content: '\f0e9';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-favorite-chart1 .path2:before {
  content: '\f0ea';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-favorite-chart1 .path3:before {
  content: '\f0eb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-filter1 .path1:before {
  content: '\f0ec';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-filter1 .path2:before {
  content: '\f0ed';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-filter-add1 .path1:before {
  content: '\f0ee';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-filter-add1 .path2:before {
  content: '\f0ef';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-filter-edit1 .path1:before {
  content: '\f0f0';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-filter-edit1 .path2:before {
  content: '\f0f1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-filter-remove1 .path1:before {
  content: '\f0f2';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-filter-remove1 .path2:before {
  content: '\f0f3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-filter-search1 .path1:before {
  content: '\f0f4';
  color: rgb(41, 45, 50);
}

.isax-filter-search1 .path2:before {
  content: '\f0f5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-filter-square1 .path1:before {
  content: '\f0f6';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-filter-square1 .path2:before {
  content: '\f0f7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-filter-tick1 .path1:before {
  content: '\f0f8';
  color: rgb(41, 45, 50);
}

.isax-filter-tick1 .path2:before {
  content: '\f0f9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-finger-cricle1 .path1:before {
  content: '\f0fa';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-finger-cricle1 .path2:before {
  content: '\f0fb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-finger-cricle1 .path3:before {
  content: '\f0fc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-finger-scan1 .path1:before {
  content: '\f0fd';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-finger-scan1 .path2:before {
  content: '\f0fe';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-finger-scan1 .path3:before {
  content: '\f0ff';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-finger-scan1 .path4:before {
  content: '\f100';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-finger-scan1 .path5:before {
  content: '\f101';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-finger-scan1 .path6:before {
  content: '\f102';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-firstline1 .path1:before {
  content: '\f103';
  color: rgb(41, 45, 50);
}

.isax-firstline1 .path2:before {
  content: '\f104';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-firstline1 .path3:before {
  content: '\f105';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-firstline1 .path4:before {
  content: '\f106';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-firstline1 .path5:before {
  content: '\f107';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-flag1 .path1:before {
  content: '\f108';
  color: rgb(41, 45, 50);
}

.isax-flag1 .path2:before {
  content: '\f109';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-flag-21 .path1:before {
  content: '\f10a';
  color: rgb(41, 45, 50);
}

.isax-flag-21 .path2:before {
  content: '\f10b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-flash1 .path1:before {
  content: '\f10c';
  color: rgb(41, 45, 50);
}

.isax-flash1 .path2:before {
  content: '\f10d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-flash-11 .path1:before {
  content: '\f10e';
  color: rgb(41, 45, 50);
}

.isax-flash-11 .path2:before {
  content: '\f10f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-flash-11 .path3:before {
  content: '\f110';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-flash-11 .path4:before {
  content: '\f111';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-flash-circle1 .path1:before {
  content: '\f112';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-flash-circle1 .path2:before {
  content: '\f113';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-flash-circle-11 .path1:before {
  content: '\f114';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-flash-circle-11 .path2:before {
  content: '\f115';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-flash-slash1 .path1:before {
  content: '\f116';
  color: rgb(41, 45, 50);
}

.isax-flash-slash1 .path2:before {
  content: '\f117';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-flash-slash1 .path3:before {
  content: '\f118';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-folder1 .path1:before {
  content: '\f119';
  color: rgb(41, 45, 50);
}

.isax-folder1 .path2:before {
  content: '\f11a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-folder-21 .path1:before {
  content: '\f11b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-folder-21 .path2:before {
  content: '\f11c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-folder-add1 .path1:before {
  content: '\f11d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-folder-add1 .path2:before {
  content: '\f11e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-folder-cloud1 .path1:before {
  content: '\f11f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-folder-cloud1 .path2:before {
  content: '\f120';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-folder-connection1 .path1:before {
  content: '\f121';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-folder-connection1 .path2:before {
  content: '\f122';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-folder-cross1 .path1:before {
  content: '\f123';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-folder-cross1 .path2:before {
  content: '\f124';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-folder-favorite1 .path1:before {
  content: '\f125';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-folder-favorite1 .path2:before {
  content: '\f126';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-folder-minus1 .path1:before {
  content: '\f127';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-folder-minus1 .path2:before {
  content: '\f128';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-folder-open1 .path1:before {
  content: '\f129';
  color: rgb(41, 45, 50);
}

.isax-folder-open1 .path2:before {
  content: '\f12a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-forbidden1 .path1:before {
  content: '\f12b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-forbidden1 .path2:before {
  content: '\f12c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-forbidden-21 .path1:before {
  content: '\f12d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-forbidden-21 .path2:before {
  content: '\f12e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-format-circle1 .path1:before {
  content: '\f12f';
  color: rgb(41, 45, 50);
}

.isax-format-circle1 .path2:before {
  content: '\f130';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-format-circle1 .path3:before {
  content: '\f131';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-format-circle1 .path4:before {
  content: '\f132';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-format-circle1 .path5:before {
  content: '\f133';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-format-square1 .path1:before {
  content: '\f134';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-format-square1 .path2:before {
  content: '\f135';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-format-square1 .path3:before {
  content: '\f136';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-format-square1 .path4:before {
  content: '\f137';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-format-square1 .path5:before {
  content: '\f138';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-forward1 .path1:before {
  content: '\f139';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-forward1 .path2:before {
  content: '\f13a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-forward-5-seconds1 .path1:before {
  content: '\f13b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-forward-5-seconds1 .path2:before {
  content: '\f13c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-forward-10-seconds1 .path1:before {
  content: '\f13d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-forward-10-seconds1 .path2:before {
  content: '\f13e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-forward-10-seconds1 .path3:before {
  content: '\f13f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-forward-15-seconds1 .path1:before {
  content: '\f140';
  color: rgb(41, 45, 50);
}

.isax-forward-15-seconds1 .path2:before {
  content: '\f141';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-forward-15-seconds1 .path3:before {
  content: '\f142';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-forward-item1 .path1:before {
  content: '\f143';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-forward-item1 .path2:before {
  content: '\f144';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-forward-item1 .path3:before {
  content: '\f145';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-forward-square1 .path1:before {
  content: '\f146';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-forward-square1 .path2:before {
  content: '\f147';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-frame1 .path1:before {
  content: '\f148';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-frame1 .path2:before {
  content: '\f149';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-frame1 .path3:before {
  content: '\f14a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-frame1 .path4:before {
  content: '\f14b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-frame1 .path5:before {
  content: '\f14c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-frame-11 .path1:before {
  content: '\f14d';
  color: rgb(41, 45, 50);
}

.isax-frame-11 .path2:before {
  content: '\f14e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-frame-11 .path3:before {
  content: '\f14f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-frame-21 .path1:before {
  content: '\f150';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-frame-21 .path2:before {
  content: '\f151';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-frame-31 .path1:before {
  content: '\f152';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-frame-31 .path2:before {
  content: '\f153';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-frame-41 .path1:before {
  content: '\f154';
  color: rgb(41, 45, 50);
}

.isax-frame-41 .path2:before {
  content: '\f155';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-frame-41 .path3:before {
  content: '\f156';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-frame-41 .path4:before {
  content: '\f157';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gallery1 .path1:before {
  content: '\f158';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-gallery1 .path2:before {
  content: '\f159';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gallery1 .path3:before {
  content: '\f15a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gallery-add1 .path1:before {
  content: '\f15b';
  color: rgb(41, 45, 50);
}

.isax-gallery-add1 .path2:before {
  content: '\f15c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-gallery-add1 .path3:before {
  content: '\f15d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gallery-add1 .path4:before {
  content: '\f15e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gallery-edit1 .path1:before {
  content: '\f15f';
  color: rgb(41, 45, 50);
}

.isax-gallery-edit1 .path2:before {
  content: '\f160';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-gallery-edit1 .path3:before {
  content: '\f161';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gallery-edit1 .path4:before {
  content: '\f162';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gallery-favorite1 .path1:before {
  content: '\f163';
  color: rgb(41, 45, 50);
}

.isax-gallery-favorite1 .path2:before {
  content: '\f164';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gallery-favorite1 .path3:before {
  content: '\f165';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-gallery-favorite1 .path4:before {
  content: '\f166';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gallery-import1 .path1:before {
  content: '\f167';
  color: rgb(41, 45, 50);
}

.isax-gallery-import1 .path2:before {
  content: '\f168';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-gallery-import1 .path3:before {
  content: '\f169';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gallery-import1 .path4:before {
  content: '\f16a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gallery-remove1 .path1:before {
  content: '\f16b';
  color: rgb(41, 45, 50);
}

.isax-gallery-remove1 .path2:before {
  content: '\f16c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-gallery-remove1 .path3:before {
  content: '\f16d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gallery-remove1 .path4:before {
  content: '\f16e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gallery-slash1 .path1:before {
  content: '\f16f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-gallery-slash1 .path2:before {
  content: '\f170';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-gallery-slash1 .path3:before {
  content: '\f171';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gallery-slash1 .path4:before {
  content: '\f172';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gallery-slash1 .path5:before {
  content: '\f173';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gallery-tick1 .path1:before {
  content: '\f174';
  color: rgb(41, 45, 50);
}

.isax-gallery-tick1 .path2:before {
  content: '\f175';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-gallery-tick1 .path3:before {
  content: '\f176';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gallery-tick1 .path4:before {
  content: '\f177';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-game1 .path1:before {
  content: '\f178';
  color: rgb(41, 45, 50);
}

.isax-game1 .path2:before {
  content: '\f179';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-game1 .path3:before {
  content: '\f17a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-game1 .path4:before {
  content: '\f17b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-game1 .path5:before {
  content: '\f17c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-game1 .path6:before {
  content: '\f17d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-game1 .path7:before {
  content: '\f17e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gameboy1 .path1:before {
  content: '\f17f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-gameboy1 .path2:before {
  content: '\f180';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gameboy1 .path3:before {
  content: '\f181';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gameboy1 .path4:before {
  content: '\f182';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gameboy1 .path5:before {
  content: '\f183';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gas-station1 .path1:before {
  content: '\f184';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-gas-station1 .path2:before {
  content: '\f185';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gas-station1 .path3:before {
  content: '\f186';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gas-station1 .path4:before {
  content: '\f187';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gas-station1 .path5:before {
  content: '\f188';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gemini1 .path1:before {
  content: '\f189';
  color: rgb(41, 45, 50);
}

.isax-gemini1 .path2:before {
  content: '\f18a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-gemini1 .path3:before {
  content: '\f18b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-gemini1 .path4:before {
  content: '\f18c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-gemini1 .path5:before {
  content: '\f18d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-gemini-21 .path1:before {
  content: '\f18e';
  color: rgb(41, 45, 50);
}

.isax-gemini-21 .path2:before {
  content: '\f18f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-ghost1 .path1:before {
  content: '\f190';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-ghost1 .path2:before {
  content: '\f191';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-ghost1 .path3:before {
  content: '\f192';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gift1 .path1:before {
  content: '\f193';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-gift1 .path2:before {
  content: '\f194';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gift1 .path3:before {
  content: '\f195';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-gift1 .path4:before {
  content: '\f196';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-gift1 .path5:before {
  content: '\f197';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-glass1 .path1:before {
  content: '\f198';
  color: rgb(41, 45, 50);
}

.isax-glass1 .path2:before {
  content: '\f199';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-glass1 .path3:before {
  content: '\f19a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-glass1 .path4:before {
  content: '\f19b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-glass1 .path5:before {
  content: '\f19c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-glass-11 .path1:before {
  content: '\f19d';
  color: rgb(41, 45, 50);
}

.isax-glass-11 .path2:before {
  content: '\f19e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global1 .path1:before {
  content: '\f19f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global1 .path2:before {
  content: '\f1a0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global1 .path3:before {
  content: '\f1a1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global1 .path4:before {
  content: '\f1a2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global1 .path5:before {
  content: '\f1a3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-global1 .path6:before {
  content: '\f1a4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-global1 .path7:before {
  content: '\f1a5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-global1 .path8:before {
  content: '\f1a6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-global1 .path9:before {
  content: '\f1a7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-global-edit1 .path1:before {
  content: '\f1a8';
  color: rgb(41, 45, 50);
}

.isax-global-edit1 .path2:before {
  content: '\f1a9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-global-edit1 .path3:before {
  content: '\f1aa';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global-edit1 .path4:before {
  content: '\f1ab';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global-edit1 .path5:before {
  content: '\f1ac';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global-edit1 .path6:before {
  content: '\f1ad';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global-edit1 .path7:before {
  content: '\f1ae';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global-edit1 .path8:before {
  content: '\f1af';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global-edit1 .path9:before {
  content: '\f1b0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global-edit1 .path10:before {
  content: '\f1b1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global-refresh1 .path1:before {
  content: '\f1b2';
  color: rgb(41, 45, 50);
}

.isax-global-refresh1 .path2:before {
  content: '\f1b3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global-refresh1 .path3:before {
  content: '\f1b4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global-refresh1 .path4:before {
  content: '\f1b5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global-refresh1 .path5:before {
  content: '\f1b6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global-refresh1 .path6:before {
  content: '\f1b7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global-refresh1 .path7:before {
  content: '\f1b8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global-refresh1 .path8:before {
  content: '\f1b9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global-refresh1 .path9:before {
  content: '\f1ba';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global-search1 .path1:before {
  content: '\f1bb';
  color: rgb(41, 45, 50);
}

.isax-global-search1 .path2:before {
  content: '\f1bc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global-search1 .path3:before {
  content: '\f1bd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global-search1 .path4:before {
  content: '\f1be';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global-search1 .path5:before {
  content: '\f1bf';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global-search1 .path6:before {
  content: '\f1c0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global-search1 .path7:before {
  content: '\f1c1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global-search1 .path8:before {
  content: '\f1c2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-global-search1 .path9:before {
  content: '\f1c3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-gps1 .path1:before {
  content: '\f1c4';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-gps1 .path2:before {
  content: '\f1c5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gps1 .path3:before {
  content: '\f1c6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gps1 .path4:before {
  content: '\f1c7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gps1 .path5:before {
  content: '\f1c8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gps1 .path6:before {
  content: '\f1c9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gps-slash1 .path1:before {
  content: '\f1ca';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-gps-slash1 .path2:before {
  content: '\f1cb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-gps-slash1 .path3:before {
  content: '\f1cc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gps-slash1 .path4:before {
  content: '\f1cd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gps-slash1 .path5:before {
  content: '\f1ce';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gps-slash1 .path6:before {
  content: '\f1cf';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gps-slash1 .path7:before {
  content: '\f1d0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-gps-slash1 .path8:before {
  content: '\f1d1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grammerly1 .path1:before {
  content: '\f1d2';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grammerly1 .path2:before {
  content: '\f1d3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-graph1 .path1:before {
  content: '\f1d4';
  color: rgb(41, 45, 50);
}

.isax-graph1 .path2:before {
  content: '\f1d5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-11 .path1:before {
  content: '\f1d6';
  color: rgb(41, 45, 50);
}

.isax-grid-11 .path2:before {
  content: '\f1d7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-11 .path3:before {
  content: '\f1d8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-11 .path4:before {
  content: '\f1d9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-11 .path5:before {
  content: '\f1da';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-11 .path6:before {
  content: '\f1db';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-11 .path7:before {
  content: '\f1dc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-11 .path8:before {
  content: '\f1dd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-11 .path9:before {
  content: '\f1de';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-21 .path1:before {
  content: '\f1df';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-21 .path2:before {
  content: '\f1e0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-21 .path3:before {
  content: '\f1e1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-21 .path4:before {
  content: '\f1e2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-31 .path1:before {
  content: '\f1e3';
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-grid-31 .path2:before {
  content: '\f1e4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-31 .path3:before {
  content: '\f1e5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-31 .path4:before {
  content: '\f1e6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-41 .path1:before {
  content: '\f1e7';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-41 .path2:before {
  content: '\f1e8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-51 .path1:before {
  content: '\f1e9';
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-grid-51 .path2:before {
  content: '\f1ea';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-51 .path3:before {
  content: '\f1eb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-61 .path1:before {
  content: '\f1ec';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-61 .path2:before {
  content: '\f1ed';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-61 .path3:before {
  content: '\f1ee';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-61 .path4:before {
  content: '\f1ef';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-grid-71 .path1:before {
  content: '\f1f0';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-71 .path2:before {
  content: '\f1f1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-71 .path3:before {
  content: '\f1f2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-grid-81 .path1:before {
  content: '\f1f3';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-81 .path2:before {
  content: '\f1f4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-81 .path3:before {
  content: '\f1f5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-81 .path4:before {
  content: '\f1f6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-81 .path5:before {
  content: '\f1f7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-81 .path6:before {
  content: '\f1f8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-91 .path1:before {
  content: '\f1f9';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-91 .path2:before {
  content: '\f1fa';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-edit1 .path1:before {
  content: '\f1fb';
  color: rgb(41, 45, 50);
}

.isax-grid-edit1 .path2:before {
  content: '\f1fc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-edit1 .path3:before {
  content: '\f1fd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-edit1 .path4:before {
  content: '\f1fe';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-edit1 .path5:before {
  content: '\f1ff';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-edit1 .path6:before {
  content: '\f200';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-edit1 .path7:before {
  content: '\f201';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-eraser1 .path1:before {
  content: '\f202';
  color: rgb(41, 45, 50);
}

.isax-grid-eraser1 .path2:before {
  content: '\f203';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-eraser1 .path3:before {
  content: '\f204';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-eraser1 .path4:before {
  content: '\f205';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-eraser1 .path5:before {
  content: '\f206';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-eraser1 .path6:before {
  content: '\f207';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-eraser1 .path7:before {
  content: '\f208';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-eraser1 .path8:before {
  content: '\f209';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-lock1 .path1:before {
  content: '\f20a';
  color: rgb(41, 45, 50);
}

.isax-grid-lock1 .path2:before {
  content: '\f20b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-lock1 .path3:before {
  content: '\f20c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-lock1 .path4:before {
  content: '\f20d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-lock1 .path5:before {
  content: '\f20e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-grid-lock1 .path6:before {
  content: '\f20f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-grid-lock1 .path7:before {
  content: '\f210';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-group .path1:before {
  content: '\f211';
  color: rgb(41, 45, 50);
}

.isax-group .path2:before {
  content: '\f212';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-group .path3:before {
  content: '\f213';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-group .path4:before {
  content: '\f214';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-group .path5:before {
  content: '\f215';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-group-1 .path1:before {
  content: '\f216';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-group-1 .path2:before {
  content: '\f217';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-group-1 .path3:before {
  content: '\f218';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-group-1 .path4:before {
  content: '\f219';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-group-1 .path5:before {
  content: '\f21a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-group-1 .path6:before {
  content: '\f21b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-group-1 .path7:before {
  content: '\f21c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-group-1 .path8:before {
  content: '\f21d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-happyemoji1 .path1:before {
  content: '\f21e';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-happyemoji1 .path2:before {
  content: '\f21f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-hashtag1 .path1:before {
  content: '\f220';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-hashtag1 .path2:before {
  content: '\f221';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-hashtag-11 .path1:before {
  content: '\f222';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-hashtag-11 .path2:before {
  content: '\f223';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-hashtag-down1 .path1:before {
  content: '\f224';
  color: rgb(41, 45, 50);
}

.isax-hashtag-down1 .path2:before {
  content: '\f225';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-hashtag-down1 .path3:before {
  content: '\f226';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-hashtag-up1 .path1:before {
  content: '\f227';
  color: rgb(41, 45, 50);
}

.isax-hashtag-up1 .path2:before {
  content: '\f228';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-hashtag-up1 .path3:before {
  content: '\f229';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-headphone1 .path1:before {
  content: '\f22a';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-headphone1 .path2:before {
  content: '\f22b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-headphone1 .path3:before {
  content: '\f22c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-headphones1 .path1:before {
  content: '\f22d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-headphones1 .path2:before {
  content: '\f22e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-headphones1 .path3:before {
  content: '\f22f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-headphones1 .path4:before {
  content: '\f230';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-headphones1 .path5:before {
  content: '\f231';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-headphones1 .path6:before {
  content: '\f232';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-headphones1 .path7:before {
  content: '\f233';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-headphones1 .path8:before {
  content: '\f234';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-health1 .path1:before {
  content: '\f235';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-health1 .path2:before {
  content: '\f236';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-health1 .path3:before {
  content: '\f237';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-heart1 .path1:before {
  content: '\f238';
  color: rgb(41, 45, 50);
}

.isax-heart1 .path2:before {
  content: '\f239';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-heart-add1 .path1:before {
  content: '\f23a';
  color: rgb(41, 45, 50);
}

.isax-heart-add1 .path2:before {
  content: '\f23b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-heart-circle1 .path1:before {
  content: '\f23c';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-heart-circle1 .path2:before {
  content: '\f23d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-heart-edit1 .path1:before {
  content: '\f23e';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-heart-edit1 .path2:before {
  content: '\f23f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-heart-remove1 .path1:before {
  content: '\f240';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-heart-remove1 .path2:before {
  content: '\f241';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-heart-search1 .path1:before {
  content: '\f242';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-heart-search1 .path2:before {
  content: '\f243';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-heart-slash1 .path1:before {
  content: '\f244';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-heart-slash1 .path2:before {
  content: '\f245';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-heart-tick1 .path1:before {
  content: '\f246';
  color: rgb(41, 45, 50);
}

.isax-heart-tick1 .path2:before {
  content: '\f247';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-hierarchy1 .path1:before {
  content: '\f248';
  color: rgb(41, 45, 50);
}

.isax-hierarchy1 .path2:before {
  content: '\f249';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-hierarchy1 .path3:before {
  content: '\f24a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-hierarchy1 .path4:before {
  content: '\f24b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-hierarchy-21 .path1:before {
  content: '\f24c';
  color: rgb(41, 45, 50);
}

.isax-hierarchy-21 .path2:before {
  content: '\f24d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-hierarchy-21 .path3:before {
  content: '\f24e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-hierarchy-21 .path4:before {
  content: '\f24f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-hierarchy-31 .path1:before {
  content: '\f250';
  color: rgb(41, 45, 50);
}

.isax-hierarchy-31 .path2:before {
  content: '\f251';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-hierarchy-31 .path3:before {
  content: '\f252';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-hierarchy-31 .path4:before {
  content: '\f253';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-hierarchy-square1 .path1:before {
  content: '\f254';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-hierarchy-square1 .path2:before {
  content: '\f255';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-hierarchy-square1 .path3:before {
  content: '\f256';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-hierarchy-square-21 .path1:before {
  content: '\f257';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-hierarchy-square-21 .path2:before {
  content: '\f258';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-hierarchy-square-31 .path1:before {
  content: '\f259';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-hierarchy-square-31 .path2:before {
  content: '\f25a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-home1 .path1:before {
  content: '\f25b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-home1 .path2:before {
  content: '\f25c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-home-11 .path1:before {
  content: '\f25d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-home-11 .path2:before {
  content: '\f25e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-home-21 .path1:before {
  content: '\f25f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-home-21 .path2:before {
  content: '\f260';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-home-hashtag1 .path1:before {
  content: '\f261';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-home-hashtag1 .path2:before {
  content: '\f262';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-home-trend-down1 .path1:before {
  content: '\f263';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-home-trend-down1 .path2:before {
  content: '\f264';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-home-trend-up1 .path1:before {
  content: '\f265';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-home-trend-up1 .path2:before {
  content: '\f266';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-home-wifi1 .path1:before {
  content: '\f267';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-home-wifi1 .path2:before {
  content: '\f268';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-home-wifi1 .path3:before {
  content: '\f269';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-home-wifi1 .path4:before {
  content: '\f26a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-hospital1 .path1:before {
  content: '\f26b';
  color: rgb(41, 45, 50);
}

.isax-hospital1 .path2:before {
  content: '\f26c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-hospital1 .path3:before {
  content: '\f26d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-hospital1 .path4:before {
  content: '\f26e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-house1 .path1:before {
  content: '\f26f';
  color: rgb(41, 45, 50);
}

.isax-house1 .path2:before {
  content: '\f270';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-house1 .path3:before {
  content: '\f271';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-house1 .path4:before {
  content: '\f272';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-house1 .path5:before {
  content: '\f273';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-house1 .path6:before {
  content: '\f274';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-house-21 .path1:before {
  content: '\f275';
  color: rgb(41, 45, 50);
}

.isax-house-21 .path2:before {
  content: '\f276';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-house-21 .path3:before {
  content: '\f277';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-house-21 .path4:before {
  content: '\f278';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-image1 .path1:before {
  content: '\f279';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-image1 .path2:before {
  content: '\f27a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-import1 .path1:before {
  content: '\f27b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-import1 .path2:before {
  content: '\f27c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-import-11 .path1:before {
  content: '\f27d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-import-11 .path2:before {
  content: '\f27e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-import-21 .path1:before {
  content: '\f27f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-import-21 .path2:before {
  content: '\f280';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-info-circle1 .path1:before {
  content: '\f281';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-info-circle1 .path2:before {
  content: '\f282';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-info-circle1 .path3:before {
  content: '\f283';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-information1 .path1:before {
  content: '\f284';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-information1 .path2:before {
  content: '\f285';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-information1 .path3:before {
  content: '\f286';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-instagram1 .path1:before {
  content: '\f287';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-instagram1 .path2:before {
  content: '\f288';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-instagram1 .path3:before {
  content: '\f289';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-judge1 .path1:before {
  content: '\f28a';
  color: rgb(41, 45, 50);
}

.isax-judge1 .path2:before {
  content: '\f28b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-judge1 .path3:before {
  content: '\f28c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-judge1 .path4:before {
  content: '\f28d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-kanban1 .path1:before {
  content: '\f28e';
  color: rgb(41, 45, 50);
}

.isax-kanban1 .path2:before {
  content: '\f28f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-key1 .path1:before {
  content: '\f290';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-key1 .path2:before {
  content: '\f291';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-keyboard1 .path1:before {
  content: '\f292';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-keyboard1 .path2:before {
  content: '\f293';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-keyboard1 .path3:before {
  content: '\f294';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-keyboard1 .path4:before {
  content: '\f295';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-keyboard1 .path5:before {
  content: '\f296';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-keyboard-open1 .path1:before {
  content: '\f297';
  color: rgb(41, 45, 50);
}

.isax-keyboard-open1 .path2:before {
  content: '\f298';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-keyboard-open1 .path3:before {
  content: '\f299';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-keyboard-open1 .path4:before {
  content: '\f29a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-keyboard-open1 .path5:before {
  content: '\f29b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-keyboard-open1 .path6:before {
  content: '\f29c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-key-square1 .path1:before {
  content: '\f29d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-key-square1 .path2:before {
  content: '\f29e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-lamp1 .path1:before {
  content: '\f29f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-lamp1 .path2:before {
  content: '\f2a0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-lamp-11 .path1:before {
  content: '\f2a1';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-lamp-11 .path2:before {
  content: '\f2a2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-lamp-11 .path3:before {
  content: '\f2a3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-lamp-charge1 .path1:before {
  content: '\f2a4';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-lamp-charge1 .path2:before {
  content: '\f2a5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-lamp-charge1 .path3:before {
  content: '\f2a6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-lamp-on1 .path1:before {
  content: '\f2a7';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-lamp-on1 .path2:before {
  content: '\f2a8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-lamp-slash1 .path1:before {
  content: '\f2a9';
  color: rgb(41, 45, 50);
}

.isax-lamp-slash1 .path2:before {
  content: '\f2aa';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-lamp-slash1 .path3:before {
  content: '\f2ab';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-lamp-slash1 .path4:before {
  content: '\f2ac';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-language-circle1 .path1:before {
  content: '\f2ad';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-language-circle1 .path2:before {
  content: '\f2ae';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-language-square1 .path1:before {
  content: '\f2af';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-language-square1 .path2:before {
  content: '\f2b0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-layer1 .path1:before {
  content: '\f2b1';
  color: rgb(41, 45, 50);
}

.isax-layer1 .path2:before {
  content: '\f2b2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-layer1 .path3:before {
  content: '\f2b3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-level1 .path1:before {
  content: '\f2b4';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-level1 .path2:before {
  content: '\f2b5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-level1 .path3:before {
  content: '\f2b6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-level1 .path4:before {
  content: '\f2b7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-lifebuoy1 .path1:before {
  content: '\f2b8';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-lifebuoy1 .path2:before {
  content: '\f2b9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-like1 .path1:before {
  content: '\f2ba';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-like1 .path2:before {
  content: '\f2bb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-like-11 .path1:before {
  content: '\f2bc';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-like-11 .path2:before {
  content: '\f2bd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-like-dislike1 .path1:before {
  content: '\f2be';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-like-dislike1 .path2:before {
  content: '\f2bf';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-like-dislike1 .path3:before {
  content: '\f2c0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-like-dislike1 .path4:before {
  content: '\f2c1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-like-shapes1 .path1:before {
  content: '\f2c2';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-like-shapes1 .path2:before {
  content: '\f2c3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-like-tag1 .path1:before {
  content: '\f2c4';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-like-tag1 .path2:before {
  content: '\f2c5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-link1 .path1:before {
  content: '\f2c6';
  color: rgb(41, 45, 50);
}

.isax-link1 .path2:before {
  content: '\f2c7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-link-11 .path1:before {
  content: '\f2c8';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-link-11 .path2:before {
  content: '\f2c9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-link-11 .path3:before {
  content: '\f2ca';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-link-22 .path1:before {
  content: '\f2cb';
  color: rgb(41, 45, 50);
}

.isax-link-22 .path2:before {
  content: '\f2cc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-link-22 .path3:before {
  content: '\f2cd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-link-22 .path4:before {
  content: '\f2ce';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.5;
}

.isax-link-211 .path1:before {
  content: '\f2cf';
  color: rgb(41, 45, 50);
}

.isax-link-211 .path2:before {
  content: '\f2d0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-link-211 .path3:before {
  content: '\f2d1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-link-211 .path4:before {
  content: '\f2d2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.5;
}

.isax-link-circle1 .path1:before {
  content: '\f2d3';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-link-circle1 .path2:before {
  content: '\f2d4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-link-circle1 .path3:before {
  content: '\f2d5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-link-square1 .path1:before {
  content: '\f2d6';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-link-square1 .path2:before {
  content: '\f2d7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-link-square1 .path3:before {
  content: '\f2d8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-location1 .path1:before {
  content: '\f2d9';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-location1 .path2:before {
  content: '\f2da';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-location-add1 .path1:before {
  content: '\f2db';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-location-add1 .path2:before {
  content: '\f2dc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-location-cross1 .path1:before {
  content: '\f2dd';
  color: rgb(41, 45, 50);
}

.isax-location-cross1 .path2:before {
  content: '\f2de';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-location-minus1 .path1:before {
  content: '\f2df';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-location-minus1 .path2:before {
  content: '\f2e0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-location-slash1 .path1:before {
  content: '\f2e1';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-location-slash1 .path2:before {
  content: '\f2e2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-location-slash1 .path3:before {
  content: '\f2e3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-location-tick1 .path1:before {
  content: '\f2e4';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-location-tick1 .path2:before {
  content: '\f2e5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-lock1 .path1:before {
  content: '\f2e6';
  color: rgb(41, 45, 50);
}

.isax-lock1 .path2:before {
  content: '\f2e7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-lock1 .path3:before {
  content: '\f2e8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-lock1 .path4:before {
  content: '\f2e9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-lock1 .path5:before {
  content: '\f2ea';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-lock-11 .path1:before {
  content: '\f2eb';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-lock-11 .path2:before {
  content: '\f2ec';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-lock-11 .path3:before {
  content: '\f2ed';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-lock-circle1 .path1:before {
  content: '\f2ee';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-lock-circle1 .path2:before {
  content: '\f2ef';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-lock-slash1 .path1:before {
  content: '\f2f0';
  color: rgb(41, 45, 50);
}

.isax-lock-slash1 .path2:before {
  content: '\f2f1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-lock-slash1 .path3:before {
  content: '\f2f2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-lock-slash1 .path4:before {
  content: '\f2f3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-lock-slash1 .path5:before {
  content: '\f2f4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-login1 .path1:before {
  content: '\f2f5';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-login1 .path2:before {
  content: '\f2f6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-login-11 .path1:before {
  content: '\f2f7';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-login-11 .path2:before {
  content: '\f2f8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-logout1 .path1:before {
  content: '\f2f9';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-logout1 .path2:before {
  content: '\f2fa';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-logout-11 .path1:before {
  content: '\f2fb';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-logout-11 .path2:before {
  content: '\f2fc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-lovely1 .path1:before {
  content: '\f2fd';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-lovely1 .path2:before {
  content: '\f2fe';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-magicpen1 .path1:before {
  content: '\f2ff';
  color: rgb(41, 45, 50);
}

.isax-magicpen1 .path2:before {
  content: '\f300';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-magicpen1 .path3:before {
  content: '\f301';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-magicpen1 .path4:before {
  content: '\f302';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-magicpen1 .path5:before {
  content: '\f303';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-magic-star1 .path1:before {
  content: '\f304';
  color: rgb(41, 45, 50);
}

.isax-magic-star1 .path2:before {
  content: '\f305';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-main-component1 .path1:before {
  content: '\f306';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-main-component1 .path2:before {
  content: '\f307';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-man1 .path1:before {
  content: '\f308';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-man1 .path2:before {
  content: '\f309';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-map1 .path1:before {
  content: '\f30a';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-map1 .path2:before {
  content: '\f30b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-map1 .path3:before {
  content: '\f30c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-map1 .path4:before {
  content: '\f30d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-map1 .path5:before {
  content: '\f30e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-map-11 .path1:before {
  content: '\f30f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-map-11 .path2:before {
  content: '\f310';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-map-11 .path3:before {
  content: '\f311';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-mask1 .path1:before {
  content: '\f312';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-mask1 .path2:before {
  content: '\f313';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-mask-11 .path1:before {
  content: '\f314';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-mask-11 .path2:before {
  content: '\f315';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-mask-21 .path1:before {
  content: '\f316';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-mask-21 .path2:before {
  content: '\f317';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-math1 .path1:before {
  content: '\f318';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-math1 .path2:before {
  content: '\f319';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-math1 .path3:before {
  content: '\f31a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-math1 .path4:before {
  content: '\f31b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-math1 .path5:before {
  content: '\f31c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-math1 .path6:before {
  content: '\f31d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-maximize1 .path1:before {
  content: '\f31e';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-maximize1 .path2:before {
  content: '\f31f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-maximize-11 .path1:before {
  content: '\f320';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-maximize-11 .path2:before {
  content: '\f321';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-maximize-11 .path3:before {
  content: '\f322';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-maximize-22 .path1:before {
  content: '\f323';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-maximize-22 .path2:before {
  content: '\f324';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-maximize-22 .path3:before {
  content: '\f325';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-maximize-22 .path4:before {
  content: '\f326';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-maximize-22 .path5:before {
  content: '\f327';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-maximize-31 .path1:before {
  content: '\f328';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-maximize-31 .path2:before {
  content: '\f329';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-maximize-41 .path1:before {
  content: '\f32a';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-maximize-41 .path2:before {
  content: '\f32b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-maximize-41 .path3:before {
  content: '\f32c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-maximize-211 .path1:before {
  content: '\f32d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-maximize-211 .path2:before {
  content: '\f32e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-medal1 .path1:before {
  content: '\f32f';
  color: rgb(41, 45, 50);
}

.isax-medal1 .path2:before {
  content: '\f330';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-medal-star1 .path1:before {
  content: '\f331';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-medal-star1 .path2:before {
  content: '\f332';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-medal-star1 .path3:before {
  content: '\f333';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-menu1 .path1:before {
  content: '\f334';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-menu1 .path2:before {
  content: '\f335';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-menu1 .path3:before {
  content: '\f336';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-menu1 .path4:before {
  content: '\f337';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-menu-11 .path1:before {
  content: '\f338';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-menu-11 .path2:before {
  content: '\f339';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-menu-11 .path3:before {
  content: '\f33a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-menu-11 .path4:before {
  content: '\f33b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-menu-board1 .path1:before {
  content: '\f33c';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-menu-board1 .path2:before {
  content: '\f33d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-menu-board1 .path3:before {
  content: '\f33e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-menu-board1 .path4:before {
  content: '\f33f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-menu-board1 .path5:before {
  content: '\f340';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-menu-board1 .path6:before {
  content: '\f341';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message1 .path1:before {
  content: '\f342';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-message1 .path2:before {
  content: '\f343';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message1 .path3:before {
  content: '\f344';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message1 .path4:before {
  content: '\f345';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-21 .path1:before {
  content: '\f346';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-message-21 .path2:before {
  content: '\f347';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-add1 .path1:before {
  content: '\f348';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-message-add1 .path2:before {
  content: '\f349';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-add1 .path3:before {
  content: '\f34a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-add-11 .path1:before {
  content: '\f34b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-message-add-11 .path2:before {
  content: '\f34c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-circle1 .path1:before {
  content: '\f34d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-message-circle1 .path2:before {
  content: '\f34e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-edit1 .path1:before {
  content: '\f34f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-message-edit1 .path2:before {
  content: '\f350';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-edit1 .path3:before {
  content: '\f351';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-favorite1 .path1:before {
  content: '\f352';
  color: rgb(41, 45, 50);
}

.isax-message-favorite1 .path2:before {
  content: '\f353';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-message-favorite1 .path3:before {
  content: '\f354';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-favorite1 .path4:before {
  content: '\f355';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-favorite1 .path5:before {
  content: '\f356';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-minus1 .path1:before {
  content: '\f357';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-message-minus1 .path2:before {
  content: '\f358';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-notif1 .path1:before {
  content: '\f359';
  color: rgb(41, 45, 50);
}

.isax-message-notif1 .path2:before {
  content: '\f35a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-message-notif1 .path3:before {
  content: '\f35b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-notif1 .path4:before {
  content: '\f35c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-notif1 .path5:before {
  content: '\f35d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-programming1 .path1:before {
  content: '\f35e';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-message-programming1 .path2:before {
  content: '\f35f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-programming1 .path3:before {
  content: '\f360';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-programming1 .path4:before {
  content: '\f361';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-question1 .path1:before {
  content: '\f362';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-message-question1 .path2:before {
  content: '\f363';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-question1 .path3:before {
  content: '\f364';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-remove1 .path1:before {
  content: '\f365';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-message-remove1 .path2:before {
  content: '\f366';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-remove1 .path3:before {
  content: '\f367';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-messages1 .path1:before {
  content: '\f368';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-messages1 .path2:before {
  content: '\f369';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-messages1 .path3:before {
  content: '\f36a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-messages-11 .path1:before {
  content: '\f36b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-messages-11 .path2:before {
  content: '\f36c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-messages-11 .path3:before {
  content: '\f36d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-messages-11 .path4:before {
  content: '\f36e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-messages-11 .path5:before {
  content: '\f36f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-messages-21 .path1:before {
  content: '\f370';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-messages-21 .path2:before {
  content: '\f371';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-messages-31 .path1:before {
  content: '\f372';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-messages-31 .path2:before {
  content: '\f373';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-search1 .path1:before {
  content: '\f374';
  color: rgb(41, 45, 50);
}

.isax-message-search1 .path2:before {
  content: '\f375';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-message-search1 .path3:before {
  content: '\f376';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-search1 .path4:before {
  content: '\f377';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-search1 .path5:before {
  content: '\f378';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-square1 .path1:before {
  content: '\f379';
  color: rgb(41, 45, 50);
}

.isax-message-square1 .path2:before {
  content: '\f37a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-message-text1 .path1:before {
  content: '\f37b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-message-text1 .path2:before {
  content: '\f37c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-text1 .path3:before {
  content: '\f37d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-text-11 .path1:before {
  content: '\f37e';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-message-text-11 .path2:before {
  content: '\f37f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-text-11 .path3:before {
  content: '\f380';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-tick1 .path1:before {
  content: '\f381';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-message-tick1 .path2:before {
  content: '\f382';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-tick1 .path3:before {
  content: '\f383';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-time1 .path1:before {
  content: '\f384';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-message-time1 .path2:before {
  content: '\f385';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-message-time1 .path3:before {
  content: '\f386';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-microphone1 .path1:before {
  content: '\f387';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-microphone1 .path2:before {
  content: '\f388';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-microphone-21 .path1:before {
  content: '\f389';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-microphone-21 .path2:before {
  content: '\f38a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-microphone-slash1 .path1:before {
  content: '\f38b';
  color: rgb(41, 45, 50);
}

.isax-microphone-slash1 .path2:before {
  content: '\f38c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-microphone-slash1 .path3:before {
  content: '\f38d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-microphone-slash1 .path4:before {
  content: '\f38e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-microphone-slash1 .path5:before {
  content: '\f38f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-microphone-slash-11 .path1:before {
  content: '\f390';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-microphone-slash-11 .path2:before {
  content: '\f391';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-microphone-slash-11 .path3:before {
  content: '\f392';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-microphone-slash-11 .path4:before {
  content: '\f393';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-microscope1 .path1:before {
  content: '\f394';
  color: rgb(41, 45, 50);
}

.isax-microscope1 .path2:before {
  content: '\f395';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-microscope1 .path3:before {
  content: '\f396';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-microscope1 .path4:before {
  content: '\f397';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-milk1 .path1:before {
  content: '\f398';
  color: rgb(41, 45, 50);
}

.isax-milk1 .path2:before {
  content: '\f399';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-milk1 .path3:before {
  content: '\f39a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-mini-music-sqaure1 .path1:before {
  content: '\f39b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-mini-music-sqaure1 .path2:before {
  content: '\f39c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-mini-music-sqaure1 .path3:before {
  content: '\f39d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-minus1 .path1:before {
  content: '\f39e';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-minus1 .path2:before {
  content: '\f39f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-minus-cirlce1 .path1:before {
  content: '\f3a0';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-minus-cirlce1 .path2:before {
  content: '\f3a1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-minus-square1 .path1:before {
  content: '\f3a2';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-minus-square1 .path2:before {
  content: '\f3a3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-mirror1 .path1:before {
  content: '\f3a4';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-mirror1 .path2:before {
  content: '\f3a5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-mirroring-screen1 .path1:before {
  content: '\f3a6';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-mirroring-screen1 .path2:before {
  content: '\f3a7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-mirroring-screen1 .path3:before {
  content: '\f3a8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-mirroring-screen1 .path4:before {
  content: '\f3a9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-mobile1 .path1:before {
  content: '\f3aa';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-mobile1 .path2:before {
  content: '\f3ab';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-mobile1 .path3:before {
  content: '\f3ac';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-mobile-programming1 .path1:before {
  content: '\f3ad';
  color: rgb(41, 45, 50);
}

.isax-mobile-programming1 .path2:before {
  content: '\f3ae';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-mobile-programming1 .path3:before {
  content: '\f3af';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money1 .path1:before {
  content: '\f3b0';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-money1 .path2:before {
  content: '\f3b1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money1 .path3:before {
  content: '\f3b2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money1 .path4:before {
  content: '\f3b3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-21 .path1:before {
  content: '\f3b4';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-money-21 .path2:before {
  content: '\f3b5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-21 .path3:before {
  content: '\f3b6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-21 .path4:before {
  content: '\f3b7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-21 .path5:before {
  content: '\f3b8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-21 .path6:before {
  content: '\f3b9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-31 .path1:before {
  content: '\f3ba';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-money-31 .path2:before {
  content: '\f3bb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-31 .path3:before {
  content: '\f3bc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-31 .path4:before {
  content: '\f3bd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-41 .path1:before {
  content: '\f3be';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-money-41 .path2:before {
  content: '\f3bf';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-41 .path3:before {
  content: '\f3c0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-41 .path4:before {
  content: '\f3c1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-41 .path5:before {
  content: '\f3c2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-41 .path6:before {
  content: '\f3c3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-add1 .path1:before {
  content: '\f3c4';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-money-add1 .path2:before {
  content: '\f3c5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-add1 .path3:before {
  content: '\f3c6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-add1 .path4:before {
  content: '\f3c7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-change1 .path1:before {
  content: '\f3c8';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-money-change1 .path2:before {
  content: '\f3c9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-change1 .path3:before {
  content: '\f3ca';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-change1 .path4:before {
  content: '\f3cb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-forbidden1 .path1:before {
  content: '\f3cc';
  color: rgb(41, 45, 50);
}

.isax-money-forbidden1 .path2:before {
  content: '\f3cd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-forbidden1 .path3:before {
  content: '\f3ce';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-money-forbidden1 .path4:before {
  content: '\f3cf';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-forbidden1 .path5:before {
  content: '\f3d0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-recive1 .path1:before {
  content: '\f3d1';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-money-recive1 .path2:before {
  content: '\f3d2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-money-recive1 .path3:before {
  content: '\f3d3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-money-recive1 .path4:before {
  content: '\f3d4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-recive1 .path5:before {
  content: '\f3d5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-recive1 .path6:before {
  content: '\f3d6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-recive1 .path7:before {
  content: '\f3d7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-remove1 .path1:before {
  content: '\f3d8';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-money-remove1 .path2:before {
  content: '\f3d9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-remove1 .path3:before {
  content: '\f3da';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-remove1 .path4:before {
  content: '\f3db';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-moneys1 .path1:before {
  content: '\f3dc';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-moneys1 .path2:before {
  content: '\f3dd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-moneys1 .path3:before {
  content: '\f3de';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-moneys1 .path4:before {
  content: '\f3df';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-moneys1 .path5:before {
  content: '\f3e0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-send1 .path1:before {
  content: '\f3e1';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-money-send1 .path2:before {
  content: '\f3e2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-send1 .path3:before {
  content: '\f3e3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-tick1 .path1:before {
  content: '\f3e4';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-money-tick1 .path2:before {
  content: '\f3e5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-tick1 .path3:before {
  content: '\f3e6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-tick1 .path4:before {
  content: '\f3e7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-time1 .path1:before {
  content: '\f3e8';
  color: rgb(41, 45, 50);
}

.isax-money-time1 .path2:before {
  content: '\f3e9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-money-time1 .path3:before {
  content: '\f3ea';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-money-time1 .path4:before {
  content: '\f3eb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-monitor1 .path1:before {
  content: '\f3ec';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-monitor1 .path2:before {
  content: '\f3ed';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-monitor-mobbile1 .path1:before {
  content: '\f3ee';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-monitor-mobbile1 .path2:before {
  content: '\f3ef';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-monitor-mobbile1 .path3:before {
  content: '\f3f0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-monitor-recorder1 .path1:before {
  content: '\f3f1';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-monitor-recorder1 .path2:before {
  content: '\f3f2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-monitor-recorder1 .path3:before {
  content: '\f3f3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-moon1 .path1:before {
  content: '\f3f4';
  color: rgb(41, 45, 50);
}

.isax-moon1 .path2:before {
  content: '\f3f5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-more1 .path1:before {
  content: '\f3f6';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-more1 .path2:before {
  content: '\f3f7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-more1 .path3:before {
  content: '\f3f8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-more1 .path4:before {
  content: '\f3f9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-more-21 .path1:before {
  content: '\f3fa';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-more-21 .path2:before {
  content: '\f3fb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.97;
}

.isax-more-21 .path3:before {
  content: '\f3fc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.97;
}

.isax-more-21 .path4:before {
  content: '\f3fd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.97;
}

.isax-more-circle1 .path1:before {
  content: '\f3fe';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-more-circle1 .path2:before {
  content: '\f3ff';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-more-circle1 .path3:before {
  content: '\f400';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-more-circle1 .path4:before {
  content: '\f401';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-more-square1 .path1:before {
  content: '\f402';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-more-square1 .path2:before {
  content: '\f403';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-more-square1 .path3:before {
  content: '\f404';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-more-square1 .path4:before {
  content: '\f405';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-mouse1 .path1:before {
  content: '\f406';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-mouse1 .path2:before {
  content: '\f407';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-mouse-11 .path1:before {
  content: '\f408';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-mouse-11 .path2:before {
  content: '\f409';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-mouse-circle1 .path1:before {
  content: '\f40a';
  color: rgb(41, 45, 50);
}

.isax-mouse-circle1 .path2:before {
  content: '\f40b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-mouse-square1 .path1:before {
  content: '\f40c';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-mouse-square1 .path2:before {
  content: '\f40d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-music1 .path1:before {
  content: '\f40e';
  color: rgb(41, 45, 50);
}

.isax-music1 .path2:before {
  content: '\f40f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-music1 .path3:before {
  content: '\f410';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-music-circle1 .path1:before {
  content: '\f411';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-music-circle1 .path2:before {
  content: '\f412';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-music-circle1 .path3:before {
  content: '\f413';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-music-circle1 .path4:before {
  content: '\f414';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-music-circle1 .path5:before {
  content: '\f415';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-music-circle1 .path6:before {
  content: '\f416';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-music-dashboard1 .path1:before {
  content: '\f417';
  color: rgb(41, 45, 50);
}

.isax-music-dashboard1 .path2:before {
  content: '\f418';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-music-dashboard1 .path3:before {
  content: '\f419';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-music-filter1 .path1:before {
  content: '\f41a';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-music-filter1 .path2:before {
  content: '\f41b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-music-filter1 .path3:before {
  content: '\f41c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-music-filter1 .path4:before {
  content: '\f41d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-music-filter1 .path5:before {
  content: '\f41e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-music-library-21 .path1:before {
  content: '\f41f';
  color: rgb(41, 45, 50);
}

.isax-music-library-21 .path2:before {
  content: '\f420';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-music-library-21 .path3:before {
  content: '\f421';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-music-library-21 .path4:before {
  content: '\f422';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-musicnote1 .path1:before {
  content: '\f423';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-musicnote1 .path2:before {
  content: '\f424';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-music-play1 .path1:before {
  content: '\f425';
  color: rgb(41, 45, 50);
}

.isax-music-play1 .path2:before {
  content: '\f426';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-music-play1 .path3:before {
  content: '\f427';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-music-play1 .path4:before {
  content: '\f428';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-music-playlist1 .path1:before {
  content: '\f429';
  color: rgb(41, 45, 50);
}

.isax-music-playlist1 .path2:before {
  content: '\f42a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-music-playlist1 .path3:before {
  content: '\f42b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-music-playlist1 .path4:before {
  content: '\f42c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-music-square1 .path1:before {
  content: '\f42d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-music-square1 .path2:before {
  content: '\f42e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-music-square-add1 .path1:before {
  content: '\f42f';
  color: rgb(41, 45, 50);
}

.isax-music-square-add1 .path2:before {
  content: '\f430';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-music-square-add1 .path3:before {
  content: '\f431';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-music-square-remove1 .path1:before {
  content: '\f432';
  color: rgb(41, 45, 50);
}

.isax-music-square-remove1 .path2:before {
  content: '\f433';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-music-square-remove1 .path3:before {
  content: '\f434';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-music-square-search1 .path1:before {
  content: '\f435';
  color: rgb(41, 45, 50);
}

.isax-music-square-search1 .path2:before {
  content: '\f436';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-music-square-search1 .path3:before {
  content: '\f437';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-next1 .path1:before {
  content: '\f438';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-next1 .path2:before {
  content: '\f439';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note1 .path1:before {
  content: '\f43a';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-note1 .path2:before {
  content: '\f43b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note1 .path3:before {
  content: '\f43c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note1 .path4:before {
  content: '\f43d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note-11 .path1:before {
  content: '\f43e';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-note-11 .path2:before {
  content: '\f43f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note-11 .path3:before {
  content: '\f440';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note-11 .path4:before {
  content: '\f441';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note-11 .path5:before {
  content: '\f442';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note-22 .path1:before {
  content: '\f443';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-note-22 .path2:before {
  content: '\f444';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note-22 .path3:before {
  content: '\f445';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note-22 .path4:before {
  content: '\f446';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note-211 .path1:before {
  content: '\f447';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-note-211 .path2:before {
  content: '\f448';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note-add1 .path1:before {
  content: '\f449';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-note-add1 .path2:before {
  content: '\f44a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note-add1 .path3:before {
  content: '\f44b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note-add1 .path4:before {
  content: '\f44c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note-favorite1 .path1:before {
  content: '\f44d';
  color: rgb(41, 45, 50);
}

.isax-note-favorite1 .path2:before {
  content: '\f44e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note-favorite1 .path3:before {
  content: '\f44f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-note-favorite1 .path4:before {
  content: '\f450';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note-favorite1 .path5:before {
  content: '\f451';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note-favorite1 .path6:before {
  content: '\f452';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note-remove1 .path1:before {
  content: '\f453';
  color: rgb(41, 45, 50);
}

.isax-note-remove1 .path2:before {
  content: '\f454';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-note-remove1 .path3:before {
  content: '\f455';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note-remove1 .path4:before {
  content: '\f456';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note-remove1 .path5:before {
  content: '\f457';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note-square1 .path1:before {
  content: '\f458';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-note-square1 .path2:before {
  content: '\f459';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note-text1 .path1:before {
  content: '\f45a';
  color: rgb(41, 45, 50);
}

.isax-note-text1 .path2:before {
  content: '\f45b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note-text1 .path3:before {
  content: '\f45c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-note-text1 .path4:before {
  content: '\f45d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-note-text1 .path5:before {
  content: '\f45e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-notification1 .path1:before {
  content: '\f45f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-notification1 .path2:before {
  content: '\f460';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-notification1 .path3:before {
  content: '\f461';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-notification-11 .path1:before {
  content: '\f462';
  color: rgb(41, 45, 50);
}

.isax-notification-11 .path2:before {
  content: '\f463';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-notification-bing1 .path1:before {
  content: '\f464';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-notification-bing1 .path2:before {
  content: '\f465';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-notification-bing1 .path3:before {
  content: '\f466';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-notification-circle1 .path1:before {
  content: '\f467';
  color: rgb(41, 45, 50);
}

.isax-notification-circle1 .path2:before {
  content: '\f468';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-notification-favorite1 .path1:before {
  content: '\f469';
  color: rgb(41, 45, 50);
}

.isax-notification-favorite1 .path2:before {
  content: '\f46a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-notification-favorite1 .path3:before {
  content: '\f46b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-notification-status1 .path1:before {
  content: '\f46c';
  color: rgb(41, 45, 50);
}

.isax-notification-status1 .path2:before {
  content: '\f46d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-notification-status1 .path3:before {
  content: '\f46e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-notification-status1 .path4:before {
  content: '\f46f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-omega-circle1 .path1:before {
  content: '\f470';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-omega-circle1 .path2:before {
  content: '\f471';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-omega-square1 .path1:before {
  content: '\f472';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-omega-square1 .path2:before {
  content: '\f473';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-paintbucket1 .path1:before {
  content: '\f474';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-paintbucket1 .path2:before {
  content: '\f475';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-paintbucket1 .path3:before {
  content: '\f476';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-paperclip1 .path1:before {
  content: '\f477';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-paperclip1 .path2:before {
  content: '\f478';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-paperclip-21 .path1:before {
  content: '\f479';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-paperclip-21 .path2:before {
  content: '\f47a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-password-check1 .path1:before {
  content: '\f47b';
  color: rgb(41, 45, 50);
}

.isax-password-check1 .path2:before {
  content: '\f47c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-password-check1 .path3:before {
  content: '\f47d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-password-check1 .path4:before {
  content: '\f47e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-password-check1 .path5:before {
  content: '\f47f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-path1 .path1:before {
  content: '\f480';
  color: rgb(41, 45, 50);
}

.isax-path1 .path2:before {
  content: '\f481';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-path1 .path3:before {
  content: '\f482';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-path-21 .path1:before {
  content: '\f483';
  color: rgb(41, 45, 50);
}

.isax-path-21 .path2:before {
  content: '\f484';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-path-21 .path3:before {
  content: '\f485';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-path-square1 .path1:before {
  content: '\f486';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-path-square1 .path2:before {
  content: '\f487';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-path-square1 .path3:before {
  content: '\f488';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-path-square1 .path4:before {
  content: '\f489';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pause1 .path1:before {
  content: '\f48a';
  color: rgb(41, 45, 50);
}

.isax-pause1 .path2:before {
  content: '\f48b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-pause-circle1 .path1:before {
  content: '\f48c';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-pause-circle1 .path2:before {
  content: '\f48d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pause-circle1 .path3:before {
  content: '\f48e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pen-add1 .path1:before {
  content: '\f48f';
  color: rgb(41, 45, 50);
}

.isax-pen-add1 .path2:before {
  content: '\f490';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-pen-add1 .path3:before {
  content: '\f491';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pen-add1 .path4:before {
  content: '\f492';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pen-close1 .path1:before {
  content: '\f493';
  color: rgb(41, 45, 50);
}

.isax-pen-close1 .path2:before {
  content: '\f494';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-pen-close1 .path3:before {
  content: '\f495';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pen-close1 .path4:before {
  content: '\f496';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pen-remove1 .path1:before {
  content: '\f497';
  color: rgb(41, 45, 50);
}

.isax-pen-remove1 .path2:before {
  content: '\f498';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pen-remove1 .path3:before {
  content: '\f499';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-pen-remove1 .path4:before {
  content: '\f49a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pen-tool1 .path1:before {
  content: '\f49b';
  color: rgb(41, 45, 50);
}

.isax-pen-tool1 .path2:before {
  content: '\f49c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pen-tool1 .path3:before {
  content: '\f49d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pen-tool1 .path4:before {
  content: '\f49e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pen-tool1 .path5:before {
  content: '\f49f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pen-tool1 .path6:before {
  content: '\f4a0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pen-tool1 .path7:before {
  content: '\f4a1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pen-tool1 .path8:before {
  content: '\f4a2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pen-tool1 .path9:before {
  content: '\f4a3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pen-tool1 .path10:before {
  content: '\f4a4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-pen-tool-21 .path1:before {
  content: '\f4a5';
  color: rgb(41, 45, 50);
}

.isax-pen-tool-21 .path2:before {
  content: '\f4a6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pen-tool-21 .path3:before {
  content: '\f4a7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pen-tool-21 .path4:before {
  content: '\f4a8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pen-tool-21 .path5:before {
  content: '\f4a9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pen-tool-21 .path6:before {
  content: '\f4aa';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pen-tool-21 .path7:before {
  content: '\f4ab';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-people1 .path1:before {
  content: '\f4ac';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-people1 .path2:before {
  content: '\f4ad';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-people1 .path3:before {
  content: '\f4ae';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-people1 .path4:before {
  content: '\f4af';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-people1 .path5:before {
  content: '\f4b0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-people1 .path6:before {
  content: '\f4b1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-percentage-circle1 .path1:before {
  content: '\f4b2';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-percentage-circle1 .path2:before {
  content: '\f4b3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-percentage-circle1 .path3:before {
  content: '\f4b4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-percentage-circle1 .path4:before {
  content: '\f4b5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-percentage-square1 .path1:before {
  content: '\f4b6';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-percentage-square1 .path2:before {
  content: '\f4b7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-percentage-square1 .path3:before {
  content: '\f4b8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-percentage-square1 .path4:before {
  content: '\f4b9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-personalcard1 .path1:before {
  content: '\f4ba';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-personalcard1 .path2:before {
  content: '\f4bb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-personalcard1 .path3:before {
  content: '\f4bc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-personalcard1 .path4:before {
  content: '\f4bd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-personalcard1 .path5:before {
  content: '\f4be';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-personalcard1 .path6:before {
  content: '\f4bf';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pet1 .path1:before {
  content: '\f4c0';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-pet1 .path2:before {
  content: '\f4c1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pet1 .path3:before {
  content: '\f4c2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pet1 .path4:before {
  content: '\f4c3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pet1 .path5:before {
  content: '\f4c4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-pharagraphspacing1 .path1:before {
  content: '\f4c5';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-pharagraphspacing1 .path2:before {
  content: '\f4c6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-pharagraphspacing1 .path3:before {
  content: '\f4c7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-picture-frame1 .path1:before {
  content: '\f4c8';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-picture-frame1 .path2:before {
  content: '\f4c9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-play1 .path1:before {
  content: '\f4ca';
  color: rgb(41, 45, 50);
}

.isax-play1 .path2:before {
  content: '\f4cb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-play-add1 .path1:before {
  content: '\f4cc';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-play-add1 .path2:before {
  content: '\f4cd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-play-add1 .path3:before {
  content: '\f4ce';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-play-circle1 .path1:before {
  content: '\f4cf';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-play-circle1 .path2:before {
  content: '\f4d0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-play-cricle1 .path1:before {
  content: '\f4d1';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-play-cricle1 .path2:before {
  content: '\f4d2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-play-cricle1 .path3:before {
  content: '\f4d3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-play-cricle1 .path4:before {
  content: '\f4d4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-play-cricle1 .path5:before {
  content: '\f4d5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-play-remove1 .path1:before {
  content: '\f4d6';
  color: rgb(41, 45, 50);
}

.isax-play-remove1 .path2:before {
  content: '\f4d7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-play-remove1 .path3:before {
  content: '\f4d8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-presention-chart1 .path1:before {
  content: '\f4d9';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-presention-chart1 .path2:before {
  content: '\f4da';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-presention-chart1 .path3:before {
  content: '\f4db';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-presention-chart1 .path4:before {
  content: '\f4dc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-previous1 .path1:before {
  content: '\f4dd';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-previous1 .path2:before {
  content: '\f4de';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-printer1 .path1:before {
  content: '\f4df';
  color: rgb(41, 45, 50);
}

.isax-printer1 .path2:before {
  content: '\f4e0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-printer1 .path3:before {
  content: '\f4e1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-printer1 .path4:before {
  content: '\f4e2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-printer1 .path5:before {
  content: '\f4e3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-printer-slash1 .path1:before {
  content: '\f4e4';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-printer-slash1 .path2:before {
  content: '\f4e5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-printer-slash1 .path3:before {
  content: '\f4e6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-printer-slash1 .path4:before {
  content: '\f4e7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-printer-slash1 .path5:before {
  content: '\f4e8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-printer-slash1 .path6:before {
  content: '\f4e9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-printer-slash1 .path7:before {
  content: '\f4ea';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-printer-slash1 .path8:before {
  content: '\f4eb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-profile .path1:before {
  content: '\f4ec';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-profile .path2:before {
  content: '\f4ed';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-profile-2user1 .path1:before {
  content: '\f4ee';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-profile-2user1 .path2:before {
  content: '\f4ef';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-profile-2user1 .path3:before {
  content: '\f4f0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-profile-2user1 .path4:before {
  content: '\f4f1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-profile-add1 .path1:before {
  content: '\f4f2';
  color: rgb(41, 45, 50);
}

.isax-profile-add1 .path2:before {
  content: '\f4f3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-profile-add1 .path3:before {
  content: '\f4f4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-profile-circle1 .path1:before {
  content: '\f4f5';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-profile-circle1 .path2:before {
  content: '\f4f6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-profile-circle1 .path3:before {
  content: '\f4f7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-profile-delete1 .path1:before {
  content: '\f4f8';
  color: rgb(41, 45, 50);
}

.isax-profile-delete1 .path2:before {
  content: '\f4f9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-profile-delete1 .path3:before {
  content: '\f4fa';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-profile-remove1 .path1:before {
  content: '\f4fb';
  color: rgb(41, 45, 50);
}

.isax-profile-remove1 .path2:before {
  content: '\f4fc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-profile-remove1 .path3:before {
  content: '\f4fd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-profile-tick1 .path1:before {
  content: '\f4fe';
  color: rgb(41, 45, 50);
}

.isax-profile-tick1 .path2:before {
  content: '\f4ff';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-profile-tick1 .path3:before {
  content: '\f500';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-programming-arrow1 .path1:before {
  content: '\f501';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-programming-arrow1 .path2:before {
  content: '\f502';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-programming-arrows1 .path1:before {
  content: '\f503';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-programming-arrows1 .path2:before {
  content: '\f504';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-programming-arrows1 .path3:before {
  content: '\f505';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-programming-arrows1 .path4:before {
  content: '\f506';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-quote-down1 .path1:before {
  content: '\f507';
  color: rgb(41, 45, 50);
}

.isax-quote-down1 .path2:before {
  content: '\f508';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-quote-down-circle1 .path1:before {
  content: '\f509';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-quote-down-circle1 .path2:before {
  content: '\f50a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-quote-down-circle1 .path3:before {
  content: '\f50b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-quote-down-square1 .path1:before {
  content: '\f50c';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-quote-down-square1 .path2:before {
  content: '\f50d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-quote-down-square1 .path3:before {
  content: '\f50e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-quote-up1 .path1:before {
  content: '\f50f';
  color: rgb(41, 45, 50);
}

.isax-quote-up1 .path2:before {
  content: '\f510';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-quote-up-circle1 .path1:before {
  content: '\f511';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-quote-up-circle1 .path2:before {
  content: '\f512';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-quote-up-circle1 .path3:before {
  content: '\f513';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-quote-up-square1 .path1:before {
  content: '\f514';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-quote-up-square1 .path2:before {
  content: '\f515';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-quote-up-square1 .path3:before {
  content: '\f516';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-radar1 .path1:before {
  content: '\f517';
  color: rgb(41, 45, 50);
}

.isax-radar1 .path2:before {
  content: '\f518';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-radar1 .path3:before {
  content: '\f519';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-radar1 .path4:before {
  content: '\f51a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-radar1 .path5:before {
  content: '\f51b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-radar-11 .path1:before {
  content: '\f51c';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-radar-11 .path2:before {
  content: '\f51d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-radar-21 .path1:before {
  content: '\f51e';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-radar-21 .path2:before {
  content: '\f51f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-radio1 .path1:before {
  content: '\f520';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-radio1 .path2:before {
  content: '\f521';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-radio1 .path3:before {
  content: '\f522';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-radio1 .path4:before {
  content: '\f523';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-radio1 .path5:before {
  content: '\f524';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-radio1 .path6:before {
  content: '\f525';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-ram1 .path1:before {
  content: '\f526';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-ram1 .path2:before {
  content: '\f527';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-ram1 .path3:before {
  content: '\f528';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-ram-21 .path1:before {
  content: '\f529';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-ram-21 .path2:before {
  content: '\f52a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-ram-21 .path3:before {
  content: '\f52b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-ram-21 .path4:before {
  content: '\f52c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-ram-21 .path5:before {
  content: '\f52d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-ranking1 .path1:before {
  content: '\f52e';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-ranking1 .path2:before {
  content: '\f52f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-ranking1 .path3:before {
  content: '\f530';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-ranking1 .path4:before {
  content: '\f531';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-ranking-11 .path1:before {
  content: '\f532';
  color: rgb(41, 45, 50);
}

.isax-ranking-11 .path2:before {
  content: '\f533';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-ranking-11 .path3:before {
  content: '\f534';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-ranking-11 .path4:before {
  content: '\f535';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-receipt1 .path1:before {
  content: '\f536';
  color: rgb(41, 45, 50);
}

.isax-receipt1 .path2:before {
  content: '\f537';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-receipt-11 .path1:before {
  content: '\f538';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-receipt-11 .path2:before {
  content: '\f539';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receipt-11 .path3:before {
  content: '\f53a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receipt-21 .path1:before {
  content: '\f53b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-receipt-21 .path2:before {
  content: '\f53c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receipt-21 .path3:before {
  content: '\f53d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receipt-2-11 .path1:before {
  content: '\f53e';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-receipt-2-11 .path2:before {
  content: '\f53f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receipt-2-11 .path3:before {
  content: '\f540';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receipt-2-11 .path4:before {
  content: '\f541';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receipt-2-11 .path5:before {
  content: '\f542';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receipt-add1 .path1:before {
  content: '\f543';
  color: rgb(41, 45, 50);
}

.isax-receipt-add1 .path2:before {
  content: '\f544';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-receipt-add1 .path3:before {
  content: '\f545';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receipt-discount1 .path1:before {
  content: '\f546';
  color: rgb(41, 45, 50);
}

.isax-receipt-discount1 .path2:before {
  content: '\f547';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-receipt-discount1 .path3:before {
  content: '\f548';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receipt-discount1 .path4:before {
  content: '\f549';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receipt-discount1 .path5:before {
  content: '\f54a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receipt-disscount1 .path1:before {
  content: '\f54b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-receipt-disscount1 .path2:before {
  content: '\f54c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receipt-disscount1 .path3:before {
  content: '\f54d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receipt-disscount1 .path4:before {
  content: '\f54e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receipt-edit1 .path1:before {
  content: '\f54f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-receipt-edit1 .path2:before {
  content: '\f550';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receipt-item1 .path1:before {
  content: '\f551';
  color: rgb(41, 45, 50);
}

.isax-receipt-item1 .path2:before {
  content: '\f552';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-receipt-item1 .path3:before {
  content: '\f553';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receipt-item1 .path4:before {
  content: '\f554';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receipt-item1 .path5:before {
  content: '\f555';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receipt-item1 .path6:before {
  content: '\f556';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receipt-minus1 .path1:before {
  content: '\f557';
  color: rgb(41, 45, 50);
}

.isax-receipt-minus1 .path2:before {
  content: '\f558';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-receipt-minus1 .path3:before {
  content: '\f559';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receipt-search1 .path1:before {
  content: '\f55a';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-receipt-search1 .path2:before {
  content: '\f55b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receipt-square1 .path1:before {
  content: '\f55c';
  color: rgb(41, 45, 50);
}

.isax-receipt-square1 .path2:before {
  content: '\f55d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-receipt-text1 .path1:before {
  content: '\f55e';
  color: rgb(41, 45, 50);
}

.isax-receipt-text1 .path2:before {
  content: '\f55f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-receipt-text1 .path3:before {
  content: '\f560';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receipt-text1 .path4:before {
  content: '\f561';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-received1 .path1:before {
  content: '\f562';
  color: rgb(41, 45, 50);
}

.isax-received1 .path2:before {
  content: '\f563';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-received1 .path3:before {
  content: '\f564';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-receive-square1 .path1:before {
  content: '\f565';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-receive-square1 .path2:before {
  content: '\f566';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receive-square1 .path3:before {
  content: '\f567';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receive-square-21 .path1:before {
  content: '\f568';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-receive-square-21 .path2:before {
  content: '\f569';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-receive-square-21 .path3:before {
  content: '\f56a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-record1 .path1:before {
  content: '\f56b';
  color: rgb(41, 45, 50);
}

.isax-record1 .path2:before {
  content: '\f56c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-record-circle1 .path1:before {
  content: '\f56d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-record-circle1 .path2:before {
  content: '\f56e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-recovery-convert1 .path1:before {
  content: '\f56f';
  color: rgb(41, 45, 50);
}

.isax-recovery-convert1 .path2:before {
  content: '\f570';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-recovery-convert1 .path3:before {
  content: '\f571';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-recovery-convert1 .path4:before {
  content: '\f572';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-redo1 .path1:before {
  content: '\f573';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-redo1 .path2:before {
  content: '\f574';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-refresh1 .path1:before {
  content: '\f575';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-refresh1 .path2:before {
  content: '\f576';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-refresh1 .path3:before {
  content: '\f577';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-refresh-21 .path1:before {
  content: '\f578';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-refresh-21 .path2:before {
  content: '\f579';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-refresh-circle1 .path1:before {
  content: '\f57a';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-refresh-circle1 .path2:before {
  content: '\f57b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-refresh-circle1 .path3:before {
  content: '\f57c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-refresh-left-square1 .path1:before {
  content: '\f57d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-refresh-left-square1 .path2:before {
  content: '\f57e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-refresh-right-square1 .path1:before {
  content: '\f57f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-refresh-right-square1 .path2:before {
  content: '\f580';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-refresh-square-21 .path1:before {
  content: '\f581';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-refresh-square-21 .path2:before {
  content: '\f582';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-refresh-square-21 .path3:before {
  content: '\f583';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-repeat1 .path1:before {
  content: '\f584';
  color: rgb(41, 45, 50);
}

.isax-repeat1 .path2:before {
  content: '\f585';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-repeat-circle1 .path1:before {
  content: '\f586';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-repeat-circle1 .path2:before {
  content: '\f587';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-repeat-circle1 .path3:before {
  content: '\f588';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-repeate-music1 .path1:before {
  content: '\f589';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-repeate-music1 .path2:before {
  content: '\f58a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-repeate-music1 .path3:before {
  content: '\f58b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-repeate-one1 .path1:before {
  content: '\f58c';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-repeate-one1 .path2:before {
  content: '\f58d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-repeate-one1 .path3:before {
  content: '\f58e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-reserve1 .path1:before {
  content: '\f58f';
  color: rgb(41, 45, 50);
}

.isax-reserve1 .path2:before {
  content: '\f590';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-reserve1 .path3:before {
  content: '\f591';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-reserve1 .path4:before {
  content: '\f592';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-rotate-left1 .path1:before {
  content: '\f593';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-rotate-left1 .path2:before {
  content: '\f594';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-rotate-left-11 .path1:before {
  content: '\f595';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-rotate-left-11 .path2:before {
  content: '\f596';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-rotate-right1 .path1:before {
  content: '\f597';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-rotate-right1 .path2:before {
  content: '\f598';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-rotate-right-11 .path1:before {
  content: '\f599';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-rotate-right-11 .path2:before {
  content: '\f59a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-route-square1 .path1:before {
  content: '\f59b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-route-square1 .path2:before {
  content: '\f59c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-routing1 .path1:before {
  content: '\f59d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-routing1 .path2:before {
  content: '\f59e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-routing1 .path3:before {
  content: '\f59f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-routing1 .path4:before {
  content: '\f5a0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-routing1 .path5:before {
  content: '\f5a1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-routing-21 .path1:before {
  content: '\f5a2';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-routing-21 .path2:before {
  content: '\f5a3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-routing-21 .path3:before {
  content: '\f5a4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-routing-21 .path4:before {
  content: '\f5a5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-routing-21 .path5:before {
  content: '\f5a6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-row-horizontal1 .path1:before {
  content: '\f5a7';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-row-horizontal1 .path2:before {
  content: '\f5a8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-row-vertical1 .path1:before {
  content: '\f5a9';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-row-vertical1 .path2:before {
  content: '\f5aa';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-rulerpen1 .path1:before {
  content: '\f5ab';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-rulerpen1 .path2:before {
  content: '\f5ac';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-rulerpen1 .path3:before {
  content: '\f5ad';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-rulerpen1 .path4:before {
  content: '\f5ae';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-rulerpen1 .path5:before {
  content: '\f5af';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-rulerpen1 .path6:before {
  content: '\f5b0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-rulerpen1 .path7:before {
  content: '\f5b1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-ruler1 .path1:before {
  content: '\f5b2';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-ruler1 .path2:before {
  content: '\f5b3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-ruler1 .path3:before {
  content: '\f5b4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-ruler1 .path4:before {
  content: '\f5b5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-ruler1 .path5:before {
  content: '\f5b6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-safe-home1 .path1:before {
  content: '\f5b7';
  color: rgb(41, 45, 50);
}

.isax-safe-home1 .path2:before {
  content: '\f5b8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sagittarius1 .path1:before {
  content: '\f5b9';
  color: rgb(41, 45, 50);
}

.isax-sagittarius1 .path2:before {
  content: '\f5ba';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-save-21 .path1:before {
  content: '\f5bb';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-save-21 .path2:before {
  content: '\f5bc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-save-add1 .path1:before {
  content: '\f5bd';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-save-add1 .path2:before {
  content: '\f5be';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-save-add1 .path3:before {
  content: '\f5bf';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-save-minus1 .path1:before {
  content: '\f5c0';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-save-minus1 .path2:before {
  content: '\f5c1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-save-minus1 .path3:before {
  content: '\f5c2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-save-remove1 .path1:before {
  content: '\f5c3';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-save-remove1 .path2:before {
  content: '\f5c4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-save-remove1 .path3:before {
  content: '\f5c5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-scan1 .path1:before {
  content: '\f5c6';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-scan1 .path2:before {
  content: '\f5c7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-scan1 .path3:before {
  content: '\f5c8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-scan1 .path4:before {
  content: '\f5c9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-scan1 .path5:before {
  content: '\f5ca';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-scan1 .path6:before {
  content: '\f5cb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-scan1 .path7:before {
  content: '\f5cc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-scan-barcode1 .path1:before {
  content: '\f5cd';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-scan-barcode1 .path2:before {
  content: '\f5ce';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-scan-barcode1 .path3:before {
  content: '\f5cf';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-scan-barcode1 .path4:before {
  content: '\f5d0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-scan-barcode1 .path5:before {
  content: '\f5d1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-scan-barcode1 .path6:before {
  content: '\f5d2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-scan-barcode1 .path7:before {
  content: '\f5d3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-scan-barcode1 .path8:before {
  content: '\f5d4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-scanner1 .path1:before {
  content: '\f5d5';
  color: rgb(41, 45, 50);
}

.isax-scanner1 .path2:before {
  content: '\f5d6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-scanner1 .path3:before {
  content: '\f5d7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-scanner1 .path4:before {
  content: '\f5d8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-scanner1 .path5:before {
  content: '\f5d9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-scanning1 .path1:before {
  content: '\f5da';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-scanning1 .path2:before {
  content: '\f5db';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-scanning1 .path3:before {
  content: '\f5dc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-scanning1 .path4:before {
  content: '\f5dd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-scanning1 .path5:before {
  content: '\f5de';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-scanning1 .path6:before {
  content: '\f5df';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-scanning1 .path7:before {
  content: '\f5e0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-scanning1 .path8:before {
  content: '\f5e1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-scissor1 .path1:before {
  content: '\f5e2';
  color: rgb(41, 45, 50);
}

.isax-scissor1 .path2:before {
  content: '\f5e3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-scissor1 .path3:before {
  content: '\f5e4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-scissor-11 .path1:before {
  content: '\f5e5';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-scissor-11 .path2:before {
  content: '\f5e6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-screenmirroring1 .path1:before {
  content: '\f5e7';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-screenmirroring1 .path2:before {
  content: '\f5e8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-scroll1 .path1:before {
  content: '\f5e9';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-scroll1 .path2:before {
  content: '\f5ea';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-scroll1 .path3:before {
  content: '\f5eb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-search-favorite1 .path1:before {
  content: '\f5ec';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-search-favorite1 .path2:before {
  content: '\f5ed';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-search-favorite1 .path3:before {
  content: '\f5ee';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-search-favorite-11 .path1:before {
  content: '\f5ef';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-search-favorite-11 .path2:before {
  content: '\f5f0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-search-favorite-11 .path3:before {
  content: '\f5f1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-search-normal1 .path1:before {
  content: '\f5f2';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-search-normal1 .path2:before {
  content: '\f5f3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-search-normal-11 .path1:before {
  content: '\f5f4';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-search-normal-11 .path2:before {
  content: '\f5f5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-search-status1 .path1:before {
  content: '\f5f6';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-search-status1 .path2:before {
  content: '\f5f7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-search-status1 .path3:before {
  content: '\f5f8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-search-status1 .path4:before {
  content: '\f5f9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-search-status-11 .path1:before {
  content: '\f5fa';
  color: rgb(41, 45, 50);
}

.isax-search-status-11 .path2:before {
  content: '\f5fb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-search-status-11 .path3:before {
  content: '\f5fc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-search-status-11 .path4:before {
  content: '\f5fd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-search-zoom-in1 .path1:before {
  content: '\f5fe';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-search-zoom-in1 .path2:before {
  content: '\f5ff';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-search-zoom-in1 .path3:before {
  content: '\f600';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-search-zoom-in-11 .path1:before {
  content: '\f601';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-search-zoom-in-11 .path2:before {
  content: '\f602';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-search-zoom-in-11 .path3:before {
  content: '\f603';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-search-zoom-out1 .path1:before {
  content: '\f604';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-search-zoom-out1 .path2:before {
  content: '\f605';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-search-zoom-out1 .path3:before {
  content: '\f606';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-search-zoom-out-11 .path1:before {
  content: '\f607';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-search-zoom-out-11 .path2:before {
  content: '\f608';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-search-zoom-out-11 .path3:before {
  content: '\f609';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-security1 .path1:before {
  content: '\f60a';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-security1 .path2:before {
  content: '\f60b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-security-card1 .path1:before {
  content: '\f60c';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-security-card1 .path2:before {
  content: '\f60d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-security-card1 .path3:before {
  content: '\f60e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-security-safe1 .path1:before {
  content: '\f60f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-security-safe1 .path2:before {
  content: '\f610';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-security-time1 .path1:before {
  content: '\f611';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-security-time1 .path2:before {
  content: '\f612';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-security-time1 .path3:before {
  content: '\f613';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-security-user1 .path1:before {
  content: '\f614';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-security-user1 .path2:before {
  content: '\f615';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-security-user1 .path3:before {
  content: '\f616';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-send1 .path1:before {
  content: '\f617';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-send1 .path2:before {
  content: '\f618';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-send-11 .path1:before {
  content: '\f619';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-send-11 .path2:before {
  content: '\f61a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-send-11 .path3:before {
  content: '\f61b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-send-21 .path1:before {
  content: '\f61c';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-send-21 .path2:before {
  content: '\f61d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-send-21 .path3:before {
  content: '\f61e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-send-sqaure-21 .path1:before {
  content: '\f61f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-send-sqaure-21 .path2:before {
  content: '\f620';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-send-sqaure-21 .path3:before {
  content: '\f621';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-send-square1 .path1:before {
  content: '\f622';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-send-square1 .path2:before {
  content: '\f623';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-send-square1 .path3:before {
  content: '\f624';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-send-square1 .path4:before {
  content: '\f625';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-setting1 .path1:before {
  content: '\f626';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-setting1 .path2:before {
  content: '\f627';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-setting-21 .path1:before {
  content: '\f628';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-setting-21 .path2:before {
  content: '\f629';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-setting-31 .path1:before {
  content: '\f62a';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-setting-31 .path2:before {
  content: '\f62b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-setting-31 .path3:before {
  content: '\f62c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-setting-31 .path4:before {
  content: '\f62d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-setting-31 .path5:before {
  content: '\f62e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-setting-31 .path6:before {
  content: '\f62f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-setting-31 .path7:before {
  content: '\f630';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-setting-41 .path1:before {
  content: '\f631';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-setting-41 .path2:before {
  content: '\f632';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-setting-41 .path3:before {
  content: '\f633';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-setting-41 .path4:before {
  content: '\f634';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-setting-41 .path5:before {
  content: '\f635';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-setting-41 .path6:before {
  content: '\f636';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-setting-51 .path1:before {
  content: '\f637';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-setting-51 .path2:before {
  content: '\f638';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-setting-51 .path3:before {
  content: '\f639';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-setting-51 .path4:before {
  content: '\f63a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-setting-51 .path5:before {
  content: '\f63b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-setting-51 .path6:before {
  content: '\f63c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-settings1 .path1:before {
  content: '\f63d';
  color: rgb(41, 45, 50);
}

.isax-settings1 .path2:before {
  content: '\f63e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.16;
}

.isax-settings1 .path3:before {
  content: '\f63f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.16;
}

.isax-shapes1 .path1:before {
  content: '\f640';
  color: rgb(41, 45, 50);
}

.isax-shapes1 .path2:before {
  content: '\f641';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-shapes-11 .path1:before {
  content: '\f642';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-shapes-11 .path2:before {
  content: '\f643';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-share1 .path1:before {
  content: '\f644';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-share1 .path2:before {
  content: '\f645';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-share1 .path3:before {
  content: '\f646';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-share1 .path4:before {
  content: '\f647';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-share1 .path5:before {
  content: '\f648';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-share1 .path6:before {
  content: '\f649';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-shield1 .path1:before {
  content: '\f64a';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-shield1 .path2:before {
  content: '\f64b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-shield-cross1 .path1:before {
  content: '\f64c';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-shield-cross1 .path2:before {
  content: '\f64d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-shield-search1 .path1:before {
  content: '\f64e';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-shield-search1 .path2:before {
  content: '\f64f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-shield-search1 .path3:before {
  content: '\f650';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-shield-security .path1:before {
  content: '\f651';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-shield-security .path2:before {
  content: '\f652';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-shield-slash1 .path1:before {
  content: '\f653';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-shield-slash1 .path2:before {
  content: '\f654';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-shield-slash1 .path3:before {
  content: '\f655';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-shield-tick1 .path1:before {
  content: '\f656';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-shield-tick1 .path2:before {
  content: '\f657';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-ship1 .path1:before {
  content: '\f658';
  color: rgb(41, 45, 50);
}

.isax-ship1 .path2:before {
  content: '\f659';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-ship1 .path3:before {
  content: '\f65a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-shop1 .path1:before {
  content: '\f65b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-shop1 .path2:before {
  content: '\f65c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-shop1 .path3:before {
  content: '\f65d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-shop1 .path4:before {
  content: '\f65e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-shop1 .path5:before {
  content: '\f65f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-shop-add1 .path1:before {
  content: '\f660';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-shop-add1 .path2:before {
  content: '\f661';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-shop-add1 .path3:before {
  content: '\f662';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-shop-add1 .path4:before {
  content: '\f663';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-shop-add1 .path5:before {
  content: '\f664';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-shopping-bag1 .path1:before {
  content: '\f665';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-shopping-bag1 .path2:before {
  content: '\f666';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-shopping-bag1 .path3:before {
  content: '\f667';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-shopping-cart1 .path1:before {
  content: '\f668';
  color: rgb(41, 45, 50);
}

.isax-shopping-cart1 .path2:before {
  content: '\f669';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-shopping-cart1 .path3:before {
  content: '\f66a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-shopping-cart1 .path4:before {
  content: '\f66b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-shop-remove1 .path1:before {
  content: '\f66c';
  color: rgb(41, 45, 50);
}

.isax-shop-remove1 .path2:before {
  content: '\f66d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-shop-remove1 .path3:before {
  content: '\f66e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-shop-remove1 .path4:before {
  content: '\f66f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-shop-remove1 .path5:before {
  content: '\f670';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-shuffle1 .path1:before {
  content: '\f671';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-shuffle1 .path2:before {
  content: '\f672';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-shuffle1 .path3:before {
  content: '\f673';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sidebar-bottom1 .path1:before {
  content: '\f674';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sidebar-bottom1 .path2:before {
  content: '\f675';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sidebar-bottom1 .path3:before {
  content: '\f676';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sidebar-left1 .path1:before {
  content: '\f677';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sidebar-left1 .path2:before {
  content: '\f678';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sidebar-left1 .path3:before {
  content: '\f679';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sidebar-right1 .path1:before {
  content: '\f67a';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sidebar-right1 .path2:before {
  content: '\f67b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sidebar-right1 .path3:before {
  content: '\f67c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sidebar-top1 .path1:before {
  content: '\f67d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sidebar-top1 .path2:before {
  content: '\f67e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sidebar-top1 .path3:before {
  content: '\f67f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-signpost1 .path1:before {
  content: '\f680';
  color: rgb(41, 45, 50);
}

.isax-signpost1 .path2:before {
  content: '\f681';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-signpost1 .path3:before {
  content: '\f682';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-signpost1 .path4:before {
  content: '\f683';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-simcard1 .path1:before {
  content: '\f684';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-simcard1 .path2:before {
  content: '\f685';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-simcard1 .path3:before {
  content: '\f686';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-simcard-11 .path1:before {
  content: '\f687';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-simcard-11 .path2:before {
  content: '\f688';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-simcard-11 .path3:before {
  content: '\f689';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-simcard-11 .path4:before {
  content: '\f68a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-simcard-11 .path5:before {
  content: '\f68b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-simcard-21 .path1:before {
  content: '\f68c';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-simcard-21 .path2:before {
  content: '\f68d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-size1 .path1:before {
  content: '\f68e';
  color: rgb(41, 45, 50);
}

.isax-size1 .path2:before {
  content: '\f68f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-slash1 .path1:before {
  content: '\f690';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-slash1 .path2:before {
  content: '\f691';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-slash1 .path3:before {
  content: '\f692';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-slider1 .path1:before {
  content: '\f693';
  color: rgb(41, 45, 50);
}

.isax-slider1 .path2:before {
  content: '\f694';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-slider1 .path3:before {
  content: '\f695';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-slider1 .path4:before {
  content: '\f696';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-slider-horizontal1 .path1:before {
  content: '\f697';
  color: rgb(41, 45, 50);
}

.isax-slider-horizontal1 .path2:before {
  content: '\f698';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-slider-horizontal1 .path3:before {
  content: '\f699';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-slider-horizontal-11 .path1:before {
  content: '\f69a';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-slider-horizontal-11 .path2:before {
  content: '\f69b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-slider-horizontal-11 .path3:before {
  content: '\f69c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-slider-vertical1 .path1:before {
  content: '\f69d';
  color: rgb(41, 45, 50);
}

.isax-slider-vertical1 .path2:before {
  content: '\f69e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-slider-vertical1 .path3:before {
  content: '\f69f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-slider-vertical-11 .path1:before {
  content: '\f6a0';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-slider-vertical-11 .path2:before {
  content: '\f6a1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-slider-vertical-11 .path3:before {
  content: '\f6a2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-smallcaps1 .path1:before {
  content: '\f6a3';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-smallcaps1 .path2:before {
  content: '\f6a4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-smallcaps1 .path3:before {
  content: '\f6a5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-smart-car1 .path1:before {
  content: '\f6a6';
  color: rgb(41, 45, 50);
}

.isax-smart-car1 .path2:before {
  content: '\f6a7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-smart-car1 .path3:before {
  content: '\f6a8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-smart-car1 .path4:before {
  content: '\f6a9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-smart-car1 .path5:before {
  content: '\f6aa';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-smart-car1 .path6:before {
  content: '\f6ab';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-smart-home1 .path1:before {
  content: '\f6ac';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-smart-home1 .path2:before {
  content: '\f6ad';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-smart-home1 .path3:before {
  content: '\f6ae';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-smart-home1 .path4:before {
  content: '\f6af';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-smileys1 .path1:before {
  content: '\f6b0';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-smileys1 .path2:before {
  content: '\f6b1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-smileys1 .path3:before {
  content: '\f6b2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-smileys1 .path4:before {
  content: '\f6b3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-smileys1 .path5:before {
  content: '\f6b4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-smileys1 .path6:before {
  content: '\f6b5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sms1 .path1:before {
  content: '\f6b6';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sms1 .path2:before {
  content: '\f6b7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sms-edit1 .path1:before {
  content: '\f6b8';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sms-edit1 .path2:before {
  content: '\f6b9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sms-edit1 .path3:before {
  content: '\f6ba';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sms-notification1 .path1:before {
  content: '\f6bb';
  color: rgb(41, 45, 50);
}

.isax-sms-notification1 .path2:before {
  content: '\f6bc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sms-notification1 .path3:before {
  content: '\f6bd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sms-search1 .path1:before {
  content: '\f6be';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sms-search1 .path2:before {
  content: '\f6bf';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sms-search1 .path3:before {
  content: '\f6c0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sms-star1 .path1:before {
  content: '\f6c1';
  color: rgb(41, 45, 50);
}

.isax-sms-star1 .path2:before {
  content: '\f6c2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sms-star1 .path3:before {
  content: '\f6c3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sms-tracking1 .path1:before {
  content: '\f6c4';
  color: rgb(41, 45, 50);
}

.isax-sms-tracking1 .path2:before {
  content: '\f6c5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sms-tracking1 .path3:before {
  content: '\f6c6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sort1 .path1:before {
  content: '\f6c7';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sort1 .path2:before {
  content: '\f6c8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sort1 .path3:before {
  content: '\f6c9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sort1 .path4:before {
  content: '\f6ca';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sound1 .path1:before {
  content: '\f6cb';
  color: rgb(41, 45, 50);
}

.isax-sound1 .path2:before {
  content: '\f6cc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sound1 .path3:before {
  content: '\f6cd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sound1 .path4:before {
  content: '\f6ce';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sound1 .path5:before {
  content: '\f6cf';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-speaker1 .path1:before {
  content: '\f6d0';
  color: rgb(41, 45, 50);
}

.isax-speaker1 .path2:before {
  content: '\f6d1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-speaker1 .path3:before {
  content: '\f6d2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-speedometer1 .path1:before {
  content: '\f6d3';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-speedometer1 .path2:before {
  content: '\f6d4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-speedometer1 .path3:before {
  content: '\f6d5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-star1 .path1:before {
  content: '\f6d6';
  color: rgb(41, 45, 50);
}

.isax-star1 .path2:before {
  content: '\f6d7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-star1 .path3:before {
  content: '\f6d8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-star1 .path4:before {
  content: '\f6d9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-star-11 .path1:before {
  content: '\f6da';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-star-11 .path2:before {
  content: '\f6db';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-star-slash1 .path1:before {
  content: '\f6dc';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-star-slash1 .path2:before {
  content: '\f6dd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-star-slash1 .path3:before {
  content: '\f6de';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-status1 .path1:before {
  content: '\f6df';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-status1 .path2:before {
  content: '\f6e0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-status1 .path3:before {
  content: '\f6e1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-status-up1 .path1:before {
  content: '\f6e2';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-status-up1 .path2:before {
  content: '\f6e3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-status-up1 .path3:before {
  content: '\f6e4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-status-up1 .path4:before {
  content: '\f6e5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-status-up1 .path5:before {
  content: '\f6e6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sticker1 .path1:before {
  content: '\f6e7';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sticker1 .path2:before {
  content: '\f6e8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-stickynote1 .path1:before {
  content: '\f6e9';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-stickynote1 .path2:before {
  content: '\f6ea';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-stickynote1 .path3:before {
  content: '\f6eb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-stickynote1 .path4:before {
  content: '\f6ec';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-stickynote1 .path5:before {
  content: '\f6ed';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-stickynote1 .path6:before {
  content: '\f6ee';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-stop1 .path1:before {
  content: '\f6ef';
  color: rgb(41, 45, 50);
}

.isax-stop1 .path2:before {
  content: '\f6f0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-stop-circle1 .path1:before {
  content: '\f6f1';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-stop-circle1 .path2:before {
  content: '\f6f2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-story1 .path1:before {
  content: '\f6f3';
  color: rgb(41, 45, 50);
}

.isax-story1 .path2:before {
  content: '\f6f4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-story1 .path3:before {
  content: '\f6f5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-story1 .path4:before {
  content: '\f6f6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-strongbox1 .path1:before {
  content: '\f6f7';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-strongbox1 .path2:before {
  content: '\f6f8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-strongbox-21 .path1:before {
  content: '\f6f9';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-strongbox-21 .path2:before {
  content: '\f6fa';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-strongbox-21 .path3:before {
  content: '\f6fb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-subtitle1 .path1:before {
  content: '\f6fc';
  color: rgb(41, 45, 50);
}

.isax-subtitle1 .path2:before {
  content: '\f6fd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-subtitle1 .path3:before {
  content: '\f6fe';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-subtitle1 .path4:before {
  content: '\f6ff';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-subtitle1 .path5:before {
  content: '\f700';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sun1 .path1:before {
  content: '\f701';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sun1 .path2:before {
  content: '\f702';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sun1 .path3:before {
  content: '\f703';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sun1 .path4:before {
  content: '\f704';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sun1 .path5:before {
  content: '\f705';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sun1 .path6:before {
  content: '\f706';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sun1 .path7:before {
  content: '\f707';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sun-11 .path1:before {
  content: '\f708';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sun-11 .path2:before {
  content: '\f709';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sun-fog1 .path1:before {
  content: '\f70a';
  color: rgb(41, 45, 50);
}

.isax-sun-fog1 .path2:before {
  content: '\f70b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-sun-fog1 .path3:before {
  content: '\f70c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sun-fog1 .path4:before {
  content: '\f70d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-sun-fog1 .path5:before {
  content: '\f70e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-tag1 .path1:before {
  content: '\f70f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-tag1 .path2:before {
  content: '\f710';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-tag-21 .path1:before {
  content: '\f711';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-tag-21 .path2:before {
  content: '\f712';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-tag-21 .path3:before {
  content: '\f713';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-tag-cross1 .path1:before {
  content: '\f714';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-tag-cross1 .path2:before {
  content: '\f715';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-tag-right1 .path1:before {
  content: '\f716';
  color: rgb(41, 45, 50);
}

.isax-tag-right1 .path2:before {
  content: '\f717';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-tag-user1 .path1:before {
  content: '\f718';
  color: rgb(41, 45, 50);
}

.isax-tag-user1 .path2:before {
  content: '\f719';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-tag-user1 .path3:before {
  content: '\f71a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-task1 .path1:before {
  content: '\f71b';
  color: rgb(41, 45, 50);
}

.isax-task1 .path2:before {
  content: '\f71c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-task1 .path3:before {
  content: '\f71d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-task1 .path4:before {
  content: '\f71e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-task1 .path5:before {
  content: '\f71f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-task1 .path6:before {
  content: '\f720';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-task-square1 .path1:before {
  content: '\f721';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-task-square1 .path2:before {
  content: '\f722';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-task-square1 .path3:before {
  content: '\f723';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-task-square1 .path4:before {
  content: '\f724';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-task-square1 .path5:before {
  content: '\f725';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-teacher1 .path1:before {
  content: '\f726';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-teacher1 .path2:before {
  content: '\f727';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-text1 .path1:before {
  content: '\f728';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-text1 .path2:before {
  content: '\f729';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-textalign-center1 .path1:before {
  content: '\f72a';
  color: rgb(41, 45, 50);
}

.isax-textalign-center1 .path2:before {
  content: '\f72b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-textalign-center1 .path3:before {
  content: '\f72c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-textalign-center1 .path4:before {
  content: '\f72d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-textalign-justifycenter1 .path1:before {
  content: '\f72e';
  color: rgb(41, 45, 50);
}

.isax-textalign-justifycenter1 .path2:before {
  content: '\f72f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-textalign-justifycenter1 .path3:before {
  content: '\f730';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-textalign-justifycenter1 .path4:before {
  content: '\f731';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-textalign-justifyleft1 .path1:before {
  content: '\f732';
  color: rgb(41, 45, 50);
}

.isax-textalign-justifyleft1 .path2:before {
  content: '\f733';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-textalign-justifyleft1 .path3:before {
  content: '\f734';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-textalign-justifyleft1 .path4:before {
  content: '\f735';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-textalign-justifyright1 .path1:before {
  content: '\f736';
  color: rgb(41, 45, 50);
}

.isax-textalign-justifyright1 .path2:before {
  content: '\f737';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-textalign-justifyright1 .path3:before {
  content: '\f738';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-textalign-justifyright1 .path4:before {
  content: '\f739';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-textalign-left1 .path1:before {
  content: '\f73a';
  color: rgb(41, 45, 50);
}

.isax-textalign-left1 .path2:before {
  content: '\f73b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-textalign-left1 .path3:before {
  content: '\f73c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-textalign-left1 .path4:before {
  content: '\f73d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-textalign-right1 .path1:before {
  content: '\f73e';
  color: rgb(41, 45, 50);
}

.isax-textalign-right1 .path2:before {
  content: '\f73f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-textalign-right1 .path3:before {
  content: '\f740';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-textalign-right1 .path4:before {
  content: '\f741';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-text-block1 .path1:before {
  content: '\f742';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-text-block1 .path2:before {
  content: '\f743';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-text-bold1 .path1:before {
  content: '\f744';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-text-bold1 .path2:before {
  content: '\f745';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-text-italic1 .path1:before {
  content: '\f746';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-text-italic1 .path2:before {
  content: '\f747';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-text-underline1 .path1:before {
  content: '\f748';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-text-underline1 .path2:before {
  content: '\f749';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-text-underline1 .path3:before {
  content: '\f74a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-tick-circle1 .path1:before {
  content: '\f74b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-tick-circle1 .path2:before {
  content: '\f74c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-ticket1 .path1:before {
  content: '\f74d';
  color: rgb(41, 45, 50);
}

.isax-ticket1 .path2:before {
  content: '\f74e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-ticket-21 .path1:before {
  content: '\f74f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-ticket-21 .path2:before {
  content: '\f750';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-ticket-21 .path3:before {
  content: '\f751';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-ticket-discount1 .path1:before {
  content: '\f752';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-ticket-discount1 .path2:before {
  content: '\f753';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-ticket-discount1 .path3:before {
  content: '\f754';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-ticket-discount1 .path4:before {
  content: '\f755';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-ticket-expired1 .path1:before {
  content: '\f756';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-ticket-expired1 .path2:before {
  content: '\f757';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-ticket-star1 .path1:before {
  content: '\f758';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-ticket-star1 .path2:before {
  content: '\f759';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-ticket-star1 .path3:before {
  content: '\f75a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-tick-square1 .path1:before {
  content: '\f75b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-tick-square1 .path2:before {
  content: '\f75c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-timer1 .path1:before {
  content: '\f75d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-timer1 .path2:before {
  content: '\f75e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-timer1 .path3:before {
  content: '\f75f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-timer-11 .path1:before {
  content: '\f760';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-timer-11 .path2:before {
  content: '\f761';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-timer-11 .path3:before {
  content: '\f762';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-timer-pause1 .path1:before {
  content: '\f763';
  color: rgb(41, 45, 50);
}

.isax-timer-pause1 .path2:before {
  content: '\f764';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-timer-pause1 .path3:before {
  content: '\f765';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-timer-pause1 .path4:before {
  content: '\f766';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-timer-start1 .path1:before {
  content: '\f767';
  color: rgb(41, 45, 50);
}

.isax-timer-start1 .path2:before {
  content: '\f768';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-timer-start1 .path3:before {
  content: '\f769';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-timer-start1 .path4:before {
  content: '\f76a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-toggle-off1 .path1:before {
  content: '\f76b';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-toggle-off1 .path2:before {
  content: '\f76c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-toggle-off-circle1 .path1:before {
  content: '\f76d';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-toggle-off-circle1 .path2:before {
  content: '\f76e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-toggle-on1 .path1:before {
  content: '\f76f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-toggle-on1 .path2:before {
  content: '\f770';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-toggle-on-circle1 .path1:before {
  content: '\f771';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-toggle-on-circle1 .path2:before {
  content: '\f772';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-trade1 .path1:before {
  content: '\f773';
  color: rgb(41, 45, 50);
}

.isax-trade1 .path2:before {
  content: '\f774';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-trade1 .path3:before {
  content: '\f775';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-trade1 .path4:before {
  content: '\f776';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-transaction-minus1 .path1:before {
  content: '\f777';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-transaction-minus1 .path2:before {
  content: '\f778';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-translate1 .path1:before {
  content: '\f779';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-translate1 .path2:before {
  content: '\f77a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-translate1 .path3:before {
  content: '\f77b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-translate1 .path4:before {
  content: '\f77c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-translate1 .path5:before {
  content: '\f77d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-translate1 .path6:before {
  content: '\f77e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-trash1 .path1:before {
  content: '\f77f';
  color: rgb(41, 45, 50);
}

.isax-trash1 .path2:before {
  content: '\f780';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.3991;
}

.isax-trash1 .path3:before {
  content: '\f781';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-trash1 .path4:before {
  content: '\f782';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-tree1 .path1:before {
  content: '\f783';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-tree1 .path2:before {
  content: '\f784';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-tree1 .path3:before {
  content: '\f785';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-trend-down1 .path1:before {
  content: '\f786';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-trend-down1 .path2:before {
  content: '\f787';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-trend-up1 .path1:before {
  content: '\f788';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-trend-up1 .path2:before {
  content: '\f789';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-triangle1 .path1:before {
  content: '\f78a';
  color: rgb(41, 45, 50);
}

.isax-triangle1 .path2:before {
  content: '\f78b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-triangle1 .path3:before {
  content: '\f78c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-truck-remove1 .path1:before {
  content: '\f78d';
  color: rgb(41, 45, 50);
}

.isax-truck-remove1 .path2:before {
  content: '\f78e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-truck-remove1 .path3:before {
  content: '\f78f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-truck-remove1 .path4:before {
  content: '\f790';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-truck-remove1 .path5:before {
  content: '\f791';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-truck-remove1 .path6:before {
  content: '\f792';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-truck-tick1 .path1:before {
  content: '\f793';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-truck-tick1 .path2:before {
  content: '\f794';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-truck-tick1 .path3:before {
  content: '\f795';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-truck-tick1 .path4:before {
  content: '\f796';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-truck-tick1 .path5:before {
  content: '\f797';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-truck-tick1 .path6:before {
  content: '\f798';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-truck-time1 .path1:before {
  content: '\f799';
  color: rgb(41, 45, 50);
}

.isax-truck-time1 .path2:before {
  content: '\f79a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-truck-time1 .path3:before {
  content: '\f79b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-truck-time1 .path4:before {
  content: '\f79c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-truck-time1 .path5:before {
  content: '\f79d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-truck-time1 .path6:before {
  content: '\f79e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-trush-square1 .path1:before {
  content: '\f79f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-trush-square1 .path2:before {
  content: '\f7a0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-trush-square1 .path3:before {
  content: '\f7a1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-undo1 .path1:before {
  content: '\f7a2';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-undo1 .path2:before {
  content: '\f7a3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-unlimited1 .path1:before {
  content: '\f7a4';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-unlimited1 .path2:before {
  content: '\f7a5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-unlimited1 .path3:before {
  content: '\f7a6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-unlock1 .path1:before {
  content: '\f7a7';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-unlock1 .path2:before {
  content: '\f7a8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-unlock1 .path3:before {
  content: '\f7a9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-user1 .path1:before {
  content: '\f7aa';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-user1 .path2:before {
  content: '\f7ab';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-user-add1 .path1:before {
  content: '\f7ac';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-user-add1 .path2:before {
  content: '\f7ad';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-user-add1 .path3:before {
  content: '\f7ae';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-user-cirlce-add1 .path1:before {
  content: '\f7af';
  color: rgb(41, 45, 50);
}

.isax-user-cirlce-add1 .path2:before {
  content: '\f7b0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-user-cirlce-add1 .path3:before {
  content: '\f7b1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-user-cirlce-add1 .path4:before {
  content: '\f7b2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-user-edit1 .path1:before {
  content: '\f7b3';
  color: rgb(41, 45, 50);
}

.isax-user-edit1 .path2:before {
  content: '\f7b4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-user-edit1 .path3:before {
  content: '\f7b5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-user-minus1 .path1:before {
  content: '\f7b6';
  color: rgb(41, 45, 50);
}

.isax-user-minus1 .path2:before {
  content: '\f7b7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-user-minus1 .path3:before {
  content: '\f7b8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-user-octagon1 .path1:before {
  content: '\f7b9';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-user-octagon1 .path2:before {
  content: '\f7ba';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-user-octagon1 .path3:before {
  content: '\f7bb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-user-remove1 .path1:before {
  content: '\f7bc';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-user-remove1 .path2:before {
  content: '\f7bd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-user-remove1 .path3:before {
  content: '\f7be';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-user-search1 .path1:before {
  content: '\f7bf';
  color: rgb(41, 45, 50);
}

.isax-user-search1 .path2:before {
  content: '\f7c0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-user-search1 .path3:before {
  content: '\f7c1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-user-square1 .path1:before {
  content: '\f7c2';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-user-square1 .path2:before {
  content: '\f7c3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-user-square1 .path3:before {
  content: '\f7c4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-user-tag1 .path1:before {
  content: '\f7c5';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-user-tag1 .path2:before {
  content: '\f7c6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-user-tag1 .path3:before {
  content: '\f7c7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-user-tick1 .path1:before {
  content: '\f7c8';
  color: rgb(41, 45, 50);
}

.isax-user-tick1 .path2:before {
  content: '\f7c9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-user-tick1 .path3:before {
  content: '\f7ca';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-verify1 .path1:before {
  content: '\f7cb';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-verify1 .path2:before {
  content: '\f7cc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-video1 .path1:before {
  content: '\f7cd';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-video1 .path2:before {
  content: '\f7ce';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-video1 .path3:before {
  content: '\f7cf';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-video-add1 .path1:before {
  content: '\f7d0';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-video-add1 .path2:before {
  content: '\f7d1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-video-add1 .path3:before {
  content: '\f7d2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-video-circle1 .path1:before {
  content: '\f7d3';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-video-circle1 .path2:before {
  content: '\f7d4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-video-horizontal1 .path1:before {
  content: '\f7d5';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-video-horizontal1 .path2:before {
  content: '\f7d6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-video-octagon1 .path1:before {
  content: '\f7d7';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-video-octagon1 .path2:before {
  content: '\f7d8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-video-play1 .path1:before {
  content: '\f7d9';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-video-play1 .path2:before {
  content: '\f7da';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-video-play1 .path3:before {
  content: '\f7db';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-video-remove1 .path1:before {
  content: '\f7dc';
  color: rgb(41, 45, 50);
}

.isax-video-remove1 .path2:before {
  content: '\f7dd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-video-remove1 .path3:before {
  content: '\f7de';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-video-slash1 .path1:before {
  content: '\f7df';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-video-slash1 .path2:before {
  content: '\f7e0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-video-slash1 .path3:before {
  content: '\f7e1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-video-slash1 .path4:before {
  content: '\f7e2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-video-slash1 .path5:before {
  content: '\f7e3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-video-slash1 .path6:before {
  content: '\f7e4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-video-square1 .path1:before {
  content: '\f7e5';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-video-square1 .path2:before {
  content: '\f7e6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-video-tick1 .path1:before {
  content: '\f7e7';
  color: rgb(41, 45, 50);
}

.isax-video-tick1 .path2:before {
  content: '\f7e8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-video-tick1 .path3:before {
  content: '\f7e9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-video-time1 .path1:before {
  content: '\f7ea';
  color: rgb(41, 45, 50);
}

.isax-video-time1 .path2:before {
  content: '\f7eb';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-video-time1 .path3:before {
  content: '\f7ec';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-video-vertical1 .path1:before {
  content: '\f7ed';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-video-vertical1 .path2:before {
  content: '\f7ee';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-voice-cricle1 .path1:before {
  content: '\f7ef';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-voice-cricle1 .path2:before {
  content: '\f7f0';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-voice-cricle1 .path3:before {
  content: '\f7f1';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-voice-cricle1 .path4:before {
  content: '\f7f2';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-voice-cricle1 .path5:before {
  content: '\f7f3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-voice-cricle1 .path6:before {
  content: '\f7f4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-voice-square1 .path1:before {
  content: '\f7f5';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-voice-square1 .path2:before {
  content: '\f7f6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-voice-square1 .path3:before {
  content: '\f7f7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-voice-square1 .path4:before {
  content: '\f7f8';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-voice-square1 .path5:before {
  content: '\f7f9';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-voice-square1 .path6:before {
  content: '\f7fa';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-volume-cross1 .path1:before {
  content: '\f7fb';
  color: rgb(41, 45, 50);
}

.isax-volume-cross1 .path2:before {
  content: '\f7fc';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-volume-cross1 .path3:before {
  content: '\f7fd';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-volume-high1 .path1:before {
  content: '\f7fe';
  color: rgb(41, 45, 50);
}

.isax-volume-high1 .path2:before {
  content: '\f7ff';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-volume-high1 .path3:before {
  content: '\f800';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-volume-high1 .path4:before {
  content: '\f801';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-volume-low1 .path1:before {
  content: '\f802';
  color: rgb(41, 45, 50);
}

.isax-volume-low1 .path2:before {
  content: '\f803';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-volume-low1 .path3:before {
  content: '\f804';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-volume-low-11 .path1:before {
  content: '\f805';
  color: rgb(41, 45, 50);
}

.isax-volume-low-11 .path2:before {
  content: '\f806';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-volume-low-11 .path3:before {
  content: '\f807';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-volume-mute1 .path1:before {
  content: '\f808';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-volume-mute1 .path2:before {
  content: '\f809';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-volume-slash1 .path1:before {
  content: '\f80a';
  color: rgb(41, 45, 50);
}

.isax-volume-slash1 .path2:before {
  content: '\f80b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-volume-slash1 .path3:before {
  content: '\f80c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-volume-slash1 .path4:before {
  content: '\f80d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-volume-slash1 .path5:before {
  content: '\f80e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-volume-up1 .path1:before {
  content: '\f80f';
  color: rgb(41, 45, 50);
}

.isax-volume-up1 .path2:before {
  content: '\f810';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-volume-up1 .path3:before {
  content: '\f811';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wallet1 .path1:before {
  content: '\f812';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-wallet1 .path2:before {
  content: '\f813';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wallet1 .path3:before {
  content: '\f814';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wallet-11 .path1:before {
  content: '\f815';
  color: rgb(41, 45, 50);
  opacity: 0.8;
}

.isax-wallet-11 .path2:before {
  content: '\f816';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wallet-11 .path3:before {
  content: '\f817';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-wallet-11 .path4:before {
  content: '\f818';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-wallet-11 .path5:before {
  content: '\f819';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-wallet-21 .path1:before {
  content: '\f81a';
  color: rgb(41, 45, 50);
}

.isax-wallet-21 .path2:before {
  content: '\f81b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-wallet-21 .path3:before {
  content: '\f81c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wallet-31 .path1:before {
  content: '\f81d';
  color: rgb(41, 45, 50);
}

.isax-wallet-31 .path2:before {
  content: '\f81e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-wallet-31 .path3:before {
  content: '\f81f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wallet-31 .path4:before {
  content: '\f820';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wallet-add1 .path1:before {
  content: '\f821';
  color: rgb(41, 45, 50);
}

.isax-wallet-add1 .path2:before {
  content: '\f822';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wallet-add1 .path3:before {
  content: '\f823';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-wallet-add1 .path4:before {
  content: '\f824';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wallet-add-11 .path1:before {
  content: '\f825';
  color: rgb(41, 45, 50);
  opacity: 0.8;
}

.isax-wallet-add-11 .path2:before {
  content: '\f826';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wallet-add-11 .path3:before {
  content: '\f827';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-wallet-add-11 .path4:before {
  content: '\f828';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-wallet-add-11 .path5:before {
  content: '\f829';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-wallet-add-11 .path6:before {
  content: '\f82a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wallet-check1 .path1:before {
  content: '\f82b';
  color: rgb(41, 45, 50);
}

.isax-wallet-check1 .path2:before {
  content: '\f82c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wallet-check1 .path3:before {
  content: '\f82d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-wallet-check1 .path4:before {
  content: '\f82e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wallet-minus1 .path1:before {
  content: '\f82f';
  color: rgb(41, 45, 50);
  opacity: 0.8;
}

.isax-wallet-minus1 .path2:before {
  content: '\f830';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wallet-minus1 .path3:before {
  content: '\f831';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-wallet-minus1 .path4:before {
  content: '\f832';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-wallet-minus1 .path5:before {
  content: '\f833';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.6;
}

.isax-wallet-minus1 .path6:before {
  content: '\f834';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wallet-money1 .path1:before {
  content: '\f835';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-wallet-money1 .path2:before {
  content: '\f836';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wallet-remove1 .path1:before {
  content: '\f837';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-wallet-remove1 .path2:before {
  content: '\f838';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wallet-remove1 .path3:before {
  content: '\f839';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wallet-remove1 .path4:before {
  content: '\f83a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wallet-search1 .path1:before {
  content: '\f83b';
  color: rgb(41, 45, 50);
}

.isax-wallet-search1 .path2:before {
  content: '\f83c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-wallet-search1 .path3:before {
  content: '\f83d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wallet-search1 .path4:before {
  content: '\f83e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-warning-21 .path1:before {
  content: '\f83f';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-warning-21 .path2:before {
  content: '\f840';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-warning-21 .path3:before {
  content: '\f841';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-watch1 .path1:before {
  content: '\f842';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-watch1 .path2:before {
  content: '\f843';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-watch1 .path3:before {
  content: '\f844';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-watch1 .path4:before {
  content: '\f845';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-watch-status1 .path1:before {
  content: '\f846';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-watch-status1 .path2:before {
  content: '\f847';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-watch-status1 .path3:before {
  content: '\f848';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-watch-status1 .path4:before {
  content: '\f849';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-watch-status1 .path5:before {
  content: '\f84a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-watch-status1 .path6:before {
  content: '\f84b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-weight1 .path1:before {
  content: '\f84c';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-weight1 .path2:before {
  content: '\f84d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-weight-11 .path1:before {
  content: '\f84e';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-weight-11 .path2:before {
  content: '\f84f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-weight-11 .path3:before {
  content: '\f850';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-weight-11 .path4:before {
  content: '\f851';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-weight-11 .path5:before {
  content: '\f852';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wifi1 .path1:before {
  content: '\f853';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-wifi1 .path2:before {
  content: '\f854';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wifi1 .path3:before {
  content: '\f855';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wifi1 .path4:before {
  content: '\f856';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-wifi-square1 .path1:before {
  content: '\f857';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-wifi-square1 .path2:before {
  content: '\f858';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wifi-square1 .path3:before {
  content: '\f859';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wifi-square1 .path4:before {
  content: '\f85a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wind1 .path1:before {
  content: '\f85b';
  color: rgb(41, 45, 50);
}

.isax-wind1 .path2:before {
  content: '\f85c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-wind1 .path3:before {
  content: '\f85d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wind-21 .path1:before {
  content: '\f85e';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-wind-21 .path2:before {
  content: '\f85f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-wind-21 .path3:before {
  content: '\f860';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-woman1 .path1:before {
  content: '\f861';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-woman1 .path2:before {
  content: '\f862';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-dcube2:before {
  content: '\f863';
}

.isax-d-cube-scan2:before {
  content: '\f864';
}

.isax-d-rotate2:before {
  content: '\f865';
}

.isax-d-square2:before {
  content: '\f866';
}

.isax-square2:before {
  content: '\f867';
}

.isax-support2:before {
  content: '\f868';
}

.isax-activity2:before {
  content: '\f869';
}

.isax-add2:before {
  content: '\f86a';
}

.isax-add-circle2:before {
  content: '\f86b';
}

.isax-additem2:before {
  content: '\f86c';
}

.isax-add-square2:before {
  content: '\f86d';
}

.isax-airdrop2:before {
  content: '\f86e';
}

.isax-airplane2:before {
  content: '\f86f';
}

.isax-airplane-square2:before {
  content: '\f870';
}

.isax-airpod2:before {
  content: '\f871';
}

.isax-airpods2:before {
  content: '\f872';
}

.isax-alarm2:before {
  content: '\f873';
}

.isax-align-bottom2:before {
  content: '\f874';
}

.isax-align-horizontally2:before {
  content: '\f875';
}

.isax-align-left2:before {
  content: '\f876';
}

.isax-align-right2:before {
  content: '\f877';
}

.isax-align-vertically2:before {
  content: '\f878';
}

.isax-aquarius2:before {
  content: '\f879';
}

.isax-archive2:before {
  content: '\f87a';
}

.isax-archive-12:before {
  content: '\f87b';
}

.isax-archive-22:before {
  content: '\f87c';
}

.isax-archive-add2:before {
  content: '\f87d';
}

.isax-archive-book2:before {
  content: '\f87e';
}

.isax-archive-minus2:before {
  content: '\f87f';
}

.isax-archive-slash2:before {
  content: '\f880';
}

.isax-archive-tick2:before {
  content: '\f881';
}

.isax-arrange-circle2:before {
  content: '\f882';
}

.isax-arrange-circle-22:before {
  content: '\f883';
}

.isax-arrange-square2:before {
  content: '\f884';
}

.isax-arrange-square-22:before {
  content: '\f885';
}

.isax-arrow2:before {
  content: '\f886';
}

.isax-arrow-22:before {
  content: '\f887';
}

.isax-arrow-32:before {
  content: '\f888';
}

.isax-arrow-bottom2:before {
  content: '\f889';
}

.isax-arrow-circle-down2:before {
  content: '\f88a';
}

.isax-arrow-circle-left2:before {
  content: '\f88b';
}

.isax-arrow-circle-right2:before {
  content: '\f88c';
}

.isax-arrow-circle-up2:before {
  content: '\f88d';
}

.isax-arrow-down2:before {
  content: '\f88e';
}

.isax-arrow-down-12:before {
  content: '\f88f';
}

.isax-arrow-down-22:before {
  content: '\f890';
}

.isax-arrow-left2:before {
  content: '\f891';
}

.isax-arrow-left-12:before {
  content: '\f892';
}

.isax-arrow-left-22:before {
  content: '\f893';
}

.isax-arrow-left-32:before {
  content: '\f894';
}

.isax-arrow-right2:before {
  content: '\f895';
}

.isax-arrow-right-12:before {
  content: '\f896';
}

.isax-arrow-right-22:before {
  content: '\f897';
}

.isax-arrow-right-32:before {
  content: '\f898';
}

.isax-arrow-square2:before {
  content: '\f899';
}

.isax-arrow-square-down2:before {
  content: '\f89a';
}

.isax-arrow-square-left2:before {
  content: '\f89b';
}

.isax-arrow-square-right1:before {
  content: '\f89c';
}

.isax-arrow-square-up2:before {
  content: '\f89d';
}

.isax-arrow-swap2:before {
  content: '\f89e';
}

.isax-arrow-swap-horizontal2:before {
  content: '\f89f';
}

.isax-arrow-up2:before {
  content: '\f8a0';
}

.isax-arrow-up-12:before {
  content: '\f8a1';
}

.isax-arrow-up-22:before {
  content: '\f8a2';
}

.isax-arrow-up-32:before {
  content: '\f8a3';
}

.isax-attach-circle2:before {
  content: '\f8a4';
}

.isax-attach-square2:before {
  content: '\f8a5';
}

.isax-audio-square2:before {
  content: '\f8a6';
}

.isax-autobrightness2:before {
  content: '\f8a7';
}

.isax-award2:before {
  content: '\f8a8';
}

.isax-back-square2:before {
  content: '\f8a9';
}

.isax-backward2:before {
  content: '\f8aa';
}

.isax-backward-5-seconds2:before {
  content: '\f8ab';
}

.isax-backward-10-seconds2:before {
  content: '\f8ac';
}

.isax-backward-15-seconds2:before {
  content: '\f8ad';
}

.isax-backward-item2:before {
  content: '\f8ae';
}

.isax-bag2:before {
  content: '\f8af';
}

.isax-bag-22:before {
  content: '\f8b0';
}

.isax-bag-cross2:before {
  content: '\f8b1';
}

.isax-bag-cross-12:before {
  content: '\f8b2';
}

.isax-bag-happy2:before {
  content: '\f8b3';
}

.isax-bag-tick2:before {
  content: '\f8b4';
}

.isax-bag-tick-22:before {
  content: '\f8b5';
}

.isax-bag-timer2:before {
  content: '\f8b6';
}

.isax-bank2:before {
  content: '\f8b7';
}

.isax-barcode2:before {
  content: '\f8b8';
}

.isax-battery-3full2:before {
  content: '\f8b9';
}

.isax-battery-charging2:before {
  content: '\f8ba';
}

.isax-battery-disable2:before {
  content: '\f8bb';
}

.isax-battery-empty2:before {
  content: '\f8bc';
}

.isax-battery-empty-12:before {
  content: '\f8bd';
}

.isax-battery-full2:before {
  content: '\f8be';
}

.isax-bezier2:before {
  content: '\f8bf';
}

.isax-bill2:before {
  content: '\f8c0';
}

.isax-bitcoin-card2:before {
  content: '\f8c1';
}

.isax-bitcoin-convert2:before {
  content: '\f8c2';
}

.isax-bitcoin-refresh2:before {
  content: '\f8c3';
}

.isax-blend2:before {
  content: '\f8c4';
}

.isax-blend-22:before {
  content: '\f8c5';
}

.isax-bluetooth2:before {
  content: '\f8c6';
}

.isax-bluetooth-22:before {
  content: '\f8c7';
}

.isax-bluetooth-circle2:before {
  content: '\f8c8';
}

.isax-bluetooth-rectangle2:before {
  content: '\f8c9';
}

.isax-blur2:before {
  content: '\f8ca';
}

.isax-book2:before {
  content: '\f8cb';
}

.isax-book-12:before {
  content: '\f8cc';
}

.isax-bookmark2:before {
  content: '\f8cd';
}

.isax-bookmark-22:before {
  content: '\f8ce';
}

.isax-book-saved2:before {
  content: '\f8cf';
}

.isax-book-square2:before {
  content: '\f8d0';
}

.isax-box2:before {
  content: '\f8d1';
}

.isax-box-12:before {
  content: '\f8d2';
}

.isax-box-22:before {
  content: '\f8d3';
}

.isax-box-add2:before {
  content: '\f8d4';
}

.isax-box-remove2:before {
  content: '\f8d5';
}

.isax-box-search2:before {
  content: '\f8d6';
}

.isax-box-tick2:before {
  content: '\f8d7';
}

.isax-box-time2:before {
  content: '\f8d8';
}

.isax-briefcase2:before {
  content: '\f8d9';
}

.isax-brifecase-cross2:before {
  content: '\f8da';
}

.isax-brifecase-tick2:before {
  content: '\f8db';
}

.isax-brifecase-timer2:before {
  content: '\f8dc';
}

.isax-broom2:before {
  content: '\f8dd';
}

.isax-brush2:before {
  content: '\f8de';
}

.isax-brush-12:before {
  content: '\f8df';
}

.isax-brush-22:before {
  content: '\f8e0';
}

.isax-brush-32:before {
  content: '\f8e1';
}

.isax-brush-42:before {
  content: '\f8e2';
}

.isax-bubble2:before {
  content: '\f8e3';
}

.isax-bucket2:before {
  content: '\f8e4';
}

.isax-bucket-circle2:before {
  content: '\f8e5';
}

.isax-bucket-square2:before {
  content: '\f8e6';
}

.isax-building2:before {
  content: '\f8e7';
}

.isax-building-32:before {
  content: '\f8e8';
}

.isax-building-42:before {
  content: '\f8e9';
}

.isax-buildings2:before {
  content: '\f8ea';
}

.isax-buildings-22:before {
  content: '\f8eb';
}

.isax-buliding2:before {
  content: '\f8ec';
}

.isax-bus2:before {
  content: '\f8ed';
}

.isax-buy-crypto2:before {
  content: '\f8ee';
}

.isax-cake2:before {
  content: '\f8ef';
}

.isax-calculator2:before {
  content: '\f8f0';
}

.isax-calendar2:before {
  content: '\f8f1';
}

.isax-calendar-12:before {
  content: '\f8f2';
}

.isax-calendar-22:before {
  content: '\f8f3';
}

.isax-calendar-add2:before {
  content: '\f8f4';
}

.isax-calendar-circle2:before {
  content: '\f8f5';
}

.isax-calendar-edit2:before {
  content: '\f8f6';
}

.isax-calendar-remove2:before {
  content: '\f8f7';
}

.isax-calendar-search2:before {
  content: '\f8f8';
}

.isax-calendar-tick2:before {
  content: '\f8f9';
}

.isax-call2:before {
  content: '\f8fa';
}

.isax-call-add2:before {
  content: '\f8fb';
}

.isax-call-calling2:before {
  content: '\f8fc';
}

.isax-call-incoming2:before {
  content: '\f8fd';
}

.isax-call-minus2:before {
  content: '\f8fe';
}

.isax-call-outgoing2:before {
  content: '\f8ff';
}

.isax-call-received2:before {
  content: '\f900';
}

.isax-call-remove2:before {
  content: '\f901';
}

.isax-call-slash2:before {
  content: '\f902';
}

.isax-camera2:before {
  content: '\f903';
}

.isax-camera-slash2:before {
  content: '\f904';
}

.isax-candle2:before {
  content: '\f905';
}

.isax-candle-22:before {
  content: '\f906';
}

.isax-car2:before {
  content: '\f907';
}

.isax-card2:before {
  content: '\f908';
}

.isax-card-add2:before {
  content: '\f909';
}

.isax-card-coin2:before {
  content: '\f90a';
}

.isax-card-edit2:before {
  content: '\f90b';
}

.isax-card-pos2:before {
  content: '\f90c';
}

.isax-card-receive2:before {
  content: '\f90d';
}

.isax-card-remove2:before {
  content: '\f90e';
}

.isax-card-remove-12:before {
  content: '\f90f';
}

.isax-cards2:before {
  content: '\f910';
}

.isax-card-send2:before {
  content: '\f911';
}

.isax-card-slash2:before {
  content: '\f912';
}

.isax-card-tick2:before {
  content: '\f913';
}

.isax-card-tick-12:before {
  content: '\f914';
}

.isax-category2:before {
  content: '\f915';
}

.isax-category-22:before {
  content: '\f916';
}

.isax-cd2:before {
  content: '\f917';
}

.isax-chart2:before {
  content: '\f918';
}

.isax-chart-12:before {
  content: '\f919';
}

.isax-chart-23:before {
  content: '\f91a';
}

.isax-chart-32:before {
  content: '\f91b';
}

.isax-chart-212:before {
  content: '\f91c';
}

.isax-chart-fail1:before {
  content: '\f91d';
}

.isax-chart-square2:before {
  content: '\f91e';
}

.isax-chart-success2:before {
  content: '\f91f';
}

.isax-check2:before {
  content: '\f920';
}

.isax-chrome2:before {
  content: '\f921';
}

.isax-clipboard2:before {
  content: '\f922';
}

.isax-clipboard-close2:before {
  content: '\f923';
}

.isax-clipboard-export2:before {
  content: '\f924';
}

.isax-clipboard-import2:before {
  content: '\f925';
}

.isax-clipboard-text2:before {
  content: '\f926';
}

.isax-clipboard-tick2:before {
  content: '\f927';
}

.isax-clock2:before {
  content: '\f928';
}

.isax-clock-12:before {
  content: '\f929';
}

.isax-close-circle2:before {
  content: '\f92a';
}

.isax-close-square2:before {
  content: '\f92b';
}

.isax-cloud2:before {
  content: '\f92c';
}

.isax-cloud-add2:before {
  content: '\f92d';
}

.isax-cloud-change2:before {
  content: '\f92e';
}

.isax-cloud-connection2:before {
  content: '\f92f';
}

.isax-cloud-cross2:before {
  content: '\f930';
}

.isax-cloud-drizzle2:before {
  content: '\f931';
}

.isax-cloud-fog2:before {
  content: '\f932';
}

.isax-cloud-lightning2:before {
  content: '\f933';
}

.isax-cloud-minus2:before {
  content: '\f934';
}

.isax-cloud-notif2:before {
  content: '\f935';
}

.isax-cloud-plus2:before {
  content: '\f936';
}

.isax-cloud-remove2:before {
  content: '\f937';
}

.isax-cloud-snow2:before {
  content: '\f938';
}

.isax-cloud-sunny2:before {
  content: '\f939';
}

.isax-code2:before {
  content: '\f93a';
}

.isax-code-12:before {
  content: '\f93b';
}

.isax-code-circle2:before {
  content: '\f93c';
}

.isax-coffee2:before {
  content: '\f93d';
}

.isax-coin2:before {
  content: '\f93e';
}

.isax-coin-12:before {
  content: '\f93f';
}

.isax-colorfilter2:before {
  content: '\f940';
}

.isax-colors-square2:before {
  content: '\f941';
}

.isax-color-swatch2:before {
  content: '\f942';
}

.isax-command2:before {
  content: '\f943';
}

.isax-command-square2:before {
  content: '\f944';
}

.isax-component2:before {
  content: '\f945';
}

.isax-computing2:before {
  content: '\f946';
}

.isax-convert2:before {
  content: '\f947';
}

.isax-convert-3d-cube2:before {
  content: '\f948';
}

.isax-convert-card2:before {
  content: '\f949';
}

.isax-convertshape2:before {
  content: '\f94a';
}

.isax-convertshape-22:before {
  content: '\f94b';
}

.isax-copy2:before {
  content: '\f94c';
}

.isax-copyright2:before {
  content: '\f94d';
}

.isax-copy-success2:before {
  content: '\f94e';
}

.isax-courthouse2:before {
  content: '\f94f';
}

.isax-cpu2:before {
  content: '\f950';
}

.isax-cpu-charge2:before {
  content: '\f951';
}

.isax-cpu-setting2:before {
  content: '\f952';
}

.isax-creative-commons2:before {
  content: '\f953';
}

.isax-crop2:before {
  content: '\f954';
}

.isax-crown2:before {
  content: '\f955';
}

.isax-crown-12:before {
  content: '\f956';
}

.isax-cup2:before {
  content: '\f957';
}

.isax-danger2:before {
  content: '\f958';
}

.isax-data2:before {
  content: '\f959';
}

.isax-data-22:before {
  content: '\f95a';
}

.isax-designtools2:before {
  content: '\f95b';
}

.isax-device-message2:before {
  content: '\f95c';
}

.isax-devices2:before {
  content: '\f95d';
}

.isax-devices-12:before {
  content: '\f95e';
}

.isax-diagram2:before {
  content: '\f95f';
}

.isax-diamonds2:before {
  content: '\f960';
}

.isax-direct2:before {
  content: '\f961';
}

.isax-directbox-default2:before {
  content: '\f962';
}

.isax-directbox-notif2:before {
  content: '\f963';
}

.isax-directbox-receive2:before {
  content: '\f964';
}

.isax-directbox-send2:before {
  content: '\f965';
}

.isax-direct-down2:before {
  content: '\f966';
}

.isax-direct-inbox2:before {
  content: '\f967';
}

.isax-direct-left2:before {
  content: '\f968';
}

.isax-direct-normal2:before {
  content: '\f969';
}

.isax-direct-notification2:before {
  content: '\f96a';
}

.isax-direct-right2:before {
  content: '\f96b';
}

.isax-direct-send2:before {
  content: '\f96c';
}

.isax-direct-up2:before {
  content: '\f96d';
}

.isax-discount-circle2:before {
  content: '\f96e';
}

.isax-discount-shape2:before {
  content: '\f96f';
}

.isax-discover2:before {
  content: '\f970';
}

.isax-discover-12:before {
  content: '\f971';
}

.isax-dislike2:before {
  content: '\f972';
}

.isax-document2:before {
  content: '\f973';
}

.isax-document-12:before {
  content: '\f974';
}

.isax-document-cloud2:before {
  content: '\f975';
}

.isax-document-code2:before {
  content: '\f976';
}

.isax-document-code-22:before {
  content: '\f977';
}

.isax-document-copy2:before {
  content: '\f978';
}

.isax-document-download2:before {
  content: '\f979';
}

.isax-document-favorite2:before {
  content: '\f97a';
}

.isax-document-filter2:before {
  content: '\f97b';
}

.isax-document-forward2:before {
  content: '\f97c';
}

.isax-document-like2:before {
  content: '\f97d';
}

.isax-document-normal2:before {
  content: '\f97e';
}

.isax-document-previous2:before {
  content: '\f97f';
}

.isax-document-sketch2:before {
  content: '\f980';
}

.isax-document-text2:before {
  content: '\f981';
}

.isax-document-text-12:before {
  content: '\f982';
}

.isax-document-upload2:before {
  content: '\f983';
}

.isax-dollar-square2:before {
  content: '\f984';
}

.isax-driver2:before {
  content: '\f985';
}

.isax-driver-22:before {
  content: '\f986';
}

.isax-driver-refresh2:before {
  content: '\f987';
}

.isax-driving2:before {
  content: '\f988';
}

.isax-drop1:before {
  content: '\f989';
}

.isax-edit2:before {
  content: '\f98a';
}

.isax-edit-22:before {
  content: '\f98b';
}

.isax-electricity2:before {
  content: '\f98c';
}

.isax-element-22:before {
  content: '\f98d';
}

.isax-element-32:before {
  content: '\f98e';
}

.isax-element-42:before {
  content: '\f98f';
}

.isax-element-equal2:before {
  content: '\f990';
}

.isax-element-plus2:before {
  content: '\f991';
}

.isax-emoji-happy2:before {
  content: '\f992';
}

.isax-emoji-normal2:before {
  content: '\f993';
}

.isax-emoji-sad2:before {
  content: '\f994';
}

.isax-empty-wallet2:before {
  content: '\f995';
}

.isax-empty-wallet-add2:before {
  content: '\f996';
}

.isax-empty-wallet-change2:before {
  content: '\f997';
}

.isax-empty-wallet-remove2:before {
  content: '\f998';
}

.isax-empty-wallet-tick2:before {
  content: '\f999';
}

.isax-empty-wallet-time2:before {
  content: '\f99a';
}

.isax-eraser2:before {
  content: '\f99b';
}

.isax-eraser-12:before {
  content: '\f99c';
}

.isax-export2:before {
  content: '\f99d';
}

.isax-export-12:before {
  content: '\f99e';
}

.isax-export-22:before {
  content: '\f99f';
}

.isax-export-32:before {
  content: '\f9a0';
}

.isax-external-drive2:before {
  content: '\f9a1';
}

.isax-eye1:before {
  content: '\f9a2';
}

.isax-eye-slash2:before {
  content: '\f9a3';
}

.isax-fatrows2:before {
  content: '\f9a4';
}

.isax-favorite-chart2:before {
  content: '\f9a5';
}

.isax-filter2:before {
  content: '\f9a6';
}

.isax-filter-add2:before {
  content: '\f9a7';
}

.isax-filter-edit2:before {
  content: '\f9a8';
}

.isax-filter-remove2:before {
  content: '\f9a9';
}

.isax-filter-search2:before {
  content: '\f9aa';
}

.isax-filter-square2:before {
  content: '\f9ab';
}

.isax-filter-tick2:before {
  content: '\f9ac';
}

.isax-finger-cricle2:before {
  content: '\f9ad';
}

.isax-finger-scan2:before {
  content: '\f9ae';
}

.isax-firstline2:before {
  content: '\f9af';
}

.isax-flag2:before {
  content: '\f9b0';
}

.isax-flag-22:before {
  content: '\f9b1';
}

.isax-flash2:before {
  content: '\f9b2';
}

.isax-flash-12:before {
  content: '\f9b3';
}

.isax-flash-circle2:before {
  content: '\f9b4';
}

.isax-flash-circle-12:before {
  content: '\f9b5';
}

.isax-flash-slash2:before {
  content: '\f9b6';
}

.isax-folder2:before {
  content: '\f9b7';
}

.isax-folder-22:before {
  content: '\f9b8';
}

.isax-folder-add2:before {
  content: '\f9b9';
}

.isax-folder-cloud2:before {
  content: '\f9ba';
}

.isax-folder-connection2:before {
  content: '\f9bb';
}

.isax-folder-cross2:before {
  content: '\f9bc';
}

.isax-folder-favorite2:before {
  content: '\f9bd';
}

.isax-folder-minus2:before {
  content: '\f9be';
}

.isax-folder-open2:before {
  content: '\f9bf';
}

.isax-forbidden2:before {
  content: '\f9c0';
}

.isax-forbidden-22:before {
  content: '\f9c1';
}

.isax-format-circle2:before {
  content: '\f9c2';
}

.isax-format-square2:before {
  content: '\f9c3';
}

.isax-forward2:before {
  content: '\f9c4';
}

.isax-forward-5-seconds2:before {
  content: '\f9c5';
}

.isax-forward-10-seconds2:before {
  content: '\f9c6';
}

.isax-forward-15-seconds2:before {
  content: '\f9c7';
}

.isax-forward-item2:before {
  content: '\f9c8';
}

.isax-forward-square2:before {
  content: '\f9c9';
}

.isax-frame2:before {
  content: '\f9ca';
}

.isax-frame-12:before {
  content: '\f9cb';
}

.isax-frame-22:before {
  content: '\f9cc';
}

.isax-frame-32:before {
  content: '\f9cd';
}

.isax-frame-42:before {
  content: '\f9ce';
}

.isax-frame-5:before {
  content: '\f9cf';
}

.isax-frame-6:before {
  content: '\f9d0';
}

.isax-frame-7:before {
  content: '\f9d1';
}

.isax-gallery2:before {
  content: '\f9d2';
}

.isax-gallery-add2:before {
  content: '\f9d3';
}

.isax-gallery-edit2:before {
  content: '\f9d4';
}

.isax-gallery-export1:before {
  content: '\f9d5';
}

.isax-gallery-favorite2:before {
  content: '\f9d6';
}

.isax-gallery-import2:before {
  content: '\f9d7';
}

.isax-gallery-remove2:before {
  content: '\f9d8';
}

.isax-gallery-slash2:before {
  content: '\f9d9';
}

.isax-gallery-tick2:before {
  content: '\f9da';
}

.isax-game2:before {
  content: '\f9db';
}

.isax-gameboy2:before {
  content: '\f9dc';
}

.isax-gas-station2:before {
  content: '\f9dd';
}

.isax-gemini2:before {
  content: '\f9de';
}

.isax-gemini-22:before {
  content: '\f9df';
}

.isax-ghost2:before {
  content: '\f9e0';
}

.isax-gift2:before {
  content: '\f9e1';
}

.isax-glass2:before {
  content: '\f9e2';
}

.isax-glass-12:before {
  content: '\f9e3';
}

.isax-global2:before {
  content: '\f9e4';
}

.isax-global-edit2:before {
  content: '\f9e5';
}

.isax-global-refresh2:before {
  content: '\f9e6';
}

.isax-global-search2:before {
  content: '\f9e7';
}

.isax-gps2:before {
  content: '\f9e8';
}

.isax-gps-slash2:before {
  content: '\f9e9';
}

.isax-grammerly2:before {
  content: '\f9ea';
}

.isax-graph2:before {
  content: '\f9eb';
}

.isax-grid-12:before {
  content: '\f9ec';
}

.isax-grid-22:before {
  content: '\f9ed';
}

.isax-grid-32:before {
  content: '\f9ee';
}

.isax-grid-42:before {
  content: '\f9ef';
}

.isax-grid-52:before {
  content: '\f9f0';
}

.isax-grid-62:before {
  content: '\f9f1';
}

.isax-grid-72:before {
  content: '\f9f2';
}

.isax-grid-82:before {
  content: '\f9f3';
}

.isax-grid-92:before {
  content: '\f9f4';
}

.isax-grid-edit2:before {
  content: '\f9f5';
}

.isax-grid-eraser2:before {
  content: '\f9f6';
}

.isax-grid-lock2:before {
  content: '\f9f7';
}

.isax-happyemoji2:before {
  content: '\f9f8';
}

.isax-hashtag2:before {
  content: '\f9f9';
}

.isax-hashtag-12:before {
  content: '\f9fa';
}

.isax-hashtag-down2:before {
  content: '\f9fb';
}

.isax-hashtag-up2:before {
  content: '\f9fc';
}

.isax-headphone2:before {
  content: '\f9fd';
}

.isax-headphones2:before {
  content: '\f9fe';
}

.isax-health2:before {
  content: '\f9ff';
}

.isax-heart2:before {
  content: '\fa00';
}

.isax-heart-add2:before {
  content: '\fa01';
}

.isax-heart-circle2:before {
  content: '\fa02';
}

.isax-heart-edit2:before {
  content: '\fa03';
}

.isax-heart-remove2:before {
  content: '\fa04';
}

.isax-heart-search2:before {
  content: '\fa05';
}

.isax-heart-slash2:before {
  content: '\fa06';
}

.isax-heart-tick2:before {
  content: '\fa07';
}

.isax-hierarchy2:before {
  content: '\fa08';
}

.isax-hierarchy-22:before {
  content: '\fa09';
}

.isax-hierarchy-32:before {
  content: '\fa0a';
}

.isax-hierarchy-square2:before {
  content: '\fa0b';
}

.isax-hierarchy-square-22:before {
  content: '\fa0c';
}

.isax-hierarchy-square-32:before {
  content: '\fa0d';
}

.isax-home2:before {
  content: '\fa0e';
}

.isax-home-12:before {
  content: '\fa0f';
}

.isax-home-22:before {
  content: '\fa10';
}

.isax-home-hashtag2:before {
  content: '\fa11';
}

.isax-home-trend-down2:before {
  content: '\fa12';
}

.isax-home-trend-up2:before {
  content: '\fa13';
}

.isax-home-wifi2:before {
  content: '\fa14';
}

.isax-hospital2:before {
  content: '\fa15';
}

.isax-house2:before {
  content: '\fa16';
}

.isax-house-22:before {
  content: '\fa17';
}

.isax-icon1:before {
  content: '\fa18';
}

.isax-icon-1:before {
  content: '\fa19';
}

.isax-image2:before {
  content: '\fa1a';
}

.isax-import2:before {
  content: '\fa1b';
}

.isax-import-12:before {
  content: '\fa1c';
}

.isax-import-22:before {
  content: '\fa1d';
}

.isax-info-circle2:before {
  content: '\fa1e';
}

.isax-information2:before {
  content: '\fa1f';
}

.isax-instagram2:before {
  content: '\fa20';
}

.isax-judge2:before {
  content: '\fa21';
}

.isax-key2:before {
  content: '\fa22';
}

.isax-keyboard2:before {
  content: '\fa23';
}

.isax-keyboard-open2:before {
  content: '\fa24';
}

.isax-key-square2:before {
  content: '\fa25';
}

.isax-lamp2:before {
  content: '\fa26';
}

.isax-lamp-12:before {
  content: '\fa27';
}

.isax-lamp-charge2:before {
  content: '\fa28';
}

.isax-lamp-on2:before {
  content: '\fa29';
}

.isax-lamp-slash2:before {
  content: '\fa2a';
}

.isax-language-circle2:before {
  content: '\fa2b';
}

.isax-language-square2:before {
  content: '\fa2c';
}

.isax-layer2:before {
  content: '\fa2d';
}

.isax-level2:before {
  content: '\fa2e';
}

.isax-lifebuoy2:before {
  content: '\fa2f';
}

.isax-like2:before {
  content: '\fa30';
}

.isax-like-12:before {
  content: '\fa31';
}

.isax-like-dislike2:before {
  content: '\fa32';
}

.isax-like-shapes2:before {
  content: '\fa33';
}

.isax-like-tag2:before {
  content: '\fa34';
}

.isax-link2:before {
  content: '\fa35';
}

.isax-link-12:before {
  content: '\fa36';
}

.isax-link-23:before {
  content: '\fa37';
}

.isax-link-212:before {
  content: '\fa38';
}

.isax-link-circle2:before {
  content: '\fa39';
}

.isax-link-square2:before {
  content: '\fa3a';
}

.isax-location2:before {
  content: '\fa3b';
}

.isax-location-add2:before {
  content: '\fa3c';
}

.isax-location-cross2:before {
  content: '\fa3d';
}

.isax-location-minus2:before {
  content: '\fa3e';
}

.isax-location-slash2:before {
  content: '\fa3f';
}

.isax-location-tick2:before {
  content: '\fa40';
}

.isax-lock2:before {
  content: '\fa41';
}

.isax-lock-12:before {
  content: '\fa42';
}

.isax-lock-circle2:before {
  content: '\fa43';
}

.isax-lock-slash2:before {
  content: '\fa44';
}

.isax-login2:before {
  content: '\fa45';
}

.isax-login-12:before {
  content: '\fa46';
}

.isax-logout2:before {
  content: '\fa47';
}

.isax-logout-12:before {
  content: '\fa48';
}

.isax-lovely2:before {
  content: '\fa49';
}

.isax-magicpen2:before {
  content: '\fa4a';
}

.isax-magic-star2:before {
  content: '\fa4b';
}

.isax-main-component2:before {
  content: '\fa4c';
}

.isax-man2:before {
  content: '\fa4d';
}

.isax-map2:before {
  content: '\fa4e';
}

.isax-map-12:before {
  content: '\fa4f';
}

.isax-mask2:before {
  content: '\fa50';
}

.isax-mask-12:before {
  content: '\fa51';
}

.isax-mask-22:before {
  content: '\fa52';
}

.isax-math2:before {
  content: '\fa53';
}

.isax-maximize2:before {
  content: '\fa54';
}

.isax-maximize-12:before {
  content: '\fa55';
}

.isax-maximize-23:before {
  content: '\fa56';
}

.isax-maximize-32:before {
  content: '\fa57';
}

.isax-maximize-42:before {
  content: '\fa58';
}

.isax-maximize-212:before {
  content: '\fa59';
}

.isax-maximize-circle1:before {
  content: '\fa5a';
}

.isax-medal2:before {
  content: '\fa5b';
}

.isax-medal-star2:before {
  content: '\fa5c';
}

.isax-menu2:before {
  content: '\fa5d';
}

.isax-menu-12:before {
  content: '\fa5e';
}

.isax-menu-board2:before {
  content: '\fa5f';
}

.isax-message2:before {
  content: '\fa60';
}

.isax-message-22:before {
  content: '\fa61';
}

.isax-message-add2:before {
  content: '\fa62';
}

.isax-message-add-12:before {
  content: '\fa63';
}

.isax-message-circle2:before {
  content: '\fa64';
}

.isax-message-edit2:before {
  content: '\fa65';
}

.isax-message-favorite2:before {
  content: '\fa66';
}

.isax-message-minus2:before {
  content: '\fa67';
}

.isax-message-notif2:before {
  content: '\fa68';
}

.isax-message-programming2:before {
  content: '\fa69';
}

.isax-message-question2:before {
  content: '\fa6a';
}

.isax-message-remove2:before {
  content: '\fa6b';
}

.isax-messages2:before {
  content: '\fa6c';
}

.isax-messages-12:before {
  content: '\fa6d';
}

.isax-messages-22:before {
  content: '\fa6e';
}

.isax-messages-32:before {
  content: '\fa6f';
}

.isax-message-search2:before {
  content: '\fa70';
}

.isax-message-square2:before {
  content: '\fa71';
}

.isax-message-text2:before {
  content: '\fa72';
}

.isax-message-text-12:before {
  content: '\fa73';
}

.isax-message-tick2:before {
  content: '\fa74';
}

.isax-message-time2:before {
  content: '\fa75';
}

.isax-microphone2:before {
  content: '\fa76';
}

.isax-microphone-22:before {
  content: '\fa77';
}

.isax-microphone-slash2:before {
  content: '\fa78';
}

.isax-microphone-slash-12:before {
  content: '\fa79';
}

.isax-microscope2:before {
  content: '\fa7a';
}

.isax-milk2:before {
  content: '\fa7b';
}

.isax-mini-music-sqaure2:before {
  content: '\fa7c';
}

.isax-minus2:before {
  content: '\fa7d';
}

.isax-minus-cirlce2:before {
  content: '\fa7e';
}

.isax-minus-square2:before {
  content: '\fa7f';
}

.isax-mirror2:before {
  content: '\fa80';
}

.isax-mirroring-screen2:before {
  content: '\fa81';
}

.isax-mobile2:before {
  content: '\fa82';
}

.isax-mobile-programming2:before {
  content: '\fa83';
}

.isax-money2:before {
  content: '\fa84';
}

.isax-money-22:before {
  content: '\fa85';
}

.isax-money-32:before {
  content: '\fa86';
}

.isax-money-42:before {
  content: '\fa87';
}

.isax-money-add2:before {
  content: '\fa88';
}

.isax-money-change2:before {
  content: '\fa89';
}

.isax-money-forbidden2:before {
  content: '\fa8a';
}

.isax-money-recive2:before {
  content: '\fa8b';
}

.isax-money-remove2:before {
  content: '\fa8c';
}

.isax-moneys2:before {
  content: '\fa8d';
}

.isax-money-send2:before {
  content: '\fa8e';
}

.isax-money-tick2:before {
  content: '\fa8f';
}

.isax-money-time2:before {
  content: '\fa90';
}

.isax-monitor2:before {
  content: '\fa91';
}

.isax-monitor-mobbile2:before {
  content: '\fa92';
}

.isax-monitor-recorder2:before {
  content: '\fa93';
}

.isax-moon2:before {
  content: '\fa94';
}

.isax-more2:before {
  content: '\fa95';
}

.isax-more-22:before {
  content: '\fa96';
}

.isax-more-circle2:before {
  content: '\fa97';
}

.isax-more-square2:before {
  content: '\fa98';
}

.isax-mouse2:before {
  content: '\fa99';
}

.isax-mouse-12:before {
  content: '\fa9a';
}

.isax-mouse-circle2:before {
  content: '\fa9b';
}

.isax-mouse-square2:before {
  content: '\fa9c';
}

.isax-music2:before {
  content: '\fa9d';
}

.isax-music-circle2:before {
  content: '\fa9e';
}

.isax-music-dashboard2:before {
  content: '\fa9f';
}

.isax-music-filter2:before {
  content: '\faa0';
}

.isax-music-library-22:before {
  content: '\faa1';
}

.isax-musicnote2:before {
  content: '\faa2';
}

.isax-music-play2:before {
  content: '\faa3';
}

.isax-music-playlist2:before {
  content: '\faa4';
}

.isax-music-square2:before {
  content: '\faa5';
}

.isax-music-square-add2:before {
  content: '\faa6';
}

.isax-music-square-remove2:before {
  content: '\faa7';
}

.isax-music-square-search2:before {
  content: '\faa8';
}

.isax-next2:before {
  content: '\faa9';
}

.isax-note2:before {
  content: '\faaa';
}

.isax-note-12:before {
  content: '\faab';
}

.isax-note-23:before {
  content: '\faac';
}

.isax-note-212:before {
  content: '\faad';
}

.isax-note-add2:before {
  content: '\faae';
}

.isax-note-favorite2:before {
  content: '\faaf';
}

.isax-note-remove2:before {
  content: '\fab0';
}

.isax-note-square2:before {
  content: '\fab1';
}

.isax-note-text2:before {
  content: '\fab2';
}

.isax-notification2:before {
  content: '\fab3';
}

.isax-notification-12:before {
  content: '\fab4';
}

.isax-notification-bing2:before {
  content: '\fab5';
}

.isax-notification-circle2:before {
  content: '\fab6';
}

.isax-notification-favorite2:before {
  content: '\fab7';
}

.isax-notification-status2:before {
  content: '\fab8';
}

.isax-omega-circle2:before {
  content: '\fab9';
}

.isax-omega-square2:before {
  content: '\faba';
}

.isax-paintbucket2:before {
  content: '\fabb';
}

.isax-paperclip2:before {
  content: '\fabc';
}

.isax-paperclip-22:before {
  content: '\fabd';
}

.isax-password-check2:before {
  content: '\fabe';
}

.isax-path2:before {
  content: '\fabf';
}

.isax-path-22:before {
  content: '\fac0';
}

.isax-path-square2:before {
  content: '\fac1';
}

.isax-pause2:before {
  content: '\fac2';
}

.isax-pause-circle2:before {
  content: '\fac3';
}

.isax-pen-add2:before {
  content: '\fac4';
}

.isax-pen-close2:before {
  content: '\fac5';
}

.isax-pen-remove2:before {
  content: '\fac6';
}

.isax-pen-tool2:before {
  content: '\fac7';
}

.isax-pen-tool-22:before {
  content: '\fac8';
}

.isax-people2:before {
  content: '\fac9';
}

.isax-percentage-circle2:before {
  content: '\faca';
}

.isax-percentage-square2:before {
  content: '\facb';
}

.isax-personalcard2:before {
  content: '\facc';
}

.isax-pet2:before {
  content: '\facd';
}

.isax-pharagraphspacing2:before {
  content: '\face';
}

.isax-picture-frame2:before {
  content: '\facf';
}

.isax-play2:before {
  content: '\fad0';
}

.isax-play-add2:before {
  content: '\fad1';
}

.isax-play-circle2:before {
  content: '\fad2';
}

.isax-play-cricle2:before {
  content: '\fad3';
}

.isax-play-remove2:before {
  content: '\fad4';
}

.isax-presention-chart2:before {
  content: '\fad5';
}

.isax-previous2:before {
  content: '\fad6';
}

.isax-printer2:before {
  content: '\fad7';
}

.isax-printer-slash2:before {
  content: '\fad8';
}

.isax-profile-2user2:before {
  content: '\fad9';
}

.isax-profile-add2:before {
  content: '\fada';
}

.isax-profile-circle2:before {
  content: '\fadb';
}

.isax-profile-delete2:before {
  content: '\fadc';
}

.isax-profile-remove2:before {
  content: '\fadd';
}

.isax-profile-tick2:before {
  content: '\fade';
}

.isax-programming-arrow2:before {
  content: '\fadf';
}

.isax-programming-arrows2:before {
  content: '\fae0';
}

.isax-quote-down2:before {
  content: '\fae1';
}

.isax-quote-down-circle2:before {
  content: '\fae2';
}

.isax-quote-down-square2:before {
  content: '\fae3';
}

.isax-quote-up2:before {
  content: '\fae4';
}

.isax-quote-up-circle2:before {
  content: '\fae5';
}

.isax-quote-up-square2:before {
  content: '\fae6';
}

.isax-radar2:before {
  content: '\fae7';
}

.isax-radar-12:before {
  content: '\fae8';
}

.isax-radar-22:before {
  content: '\fae9';
}

.isax-radio2:before {
  content: '\faea';
}

.isax-ram2:before {
  content: '\faeb';
}

.isax-ram-22:before {
  content: '\faec';
}

.isax-ranking2:before {
  content: '\faed';
}

.isax-ranking-12:before {
  content: '\faee';
}

.isax-receipt2:before {
  content: '\faef';
}

.isax-receipt-12:before {
  content: '\faf0';
}

.isax-receipt-22:before {
  content: '\faf1';
}

.isax-receipt-2-12:before {
  content: '\faf2';
}

.isax-receipt-add2:before {
  content: '\faf3';
}

.isax-receipt-discount2:before {
  content: '\faf4';
}

.isax-receipt-disscount2:before {
  content: '\faf5';
}

.isax-receipt-edit2:before {
  content: '\faf6';
}

.isax-receipt-item2:before {
  content: '\faf7';
}

.isax-receipt-minus2:before {
  content: '\faf8';
}

.isax-receipt-search2:before {
  content: '\faf9';
}

.isax-receipt-square2:before {
  content: '\fafa';
}

.isax-receipt-text2:before {
  content: '\fafb';
}

.isax-received2:before {
  content: '\fafc';
}

.isax-receive-square2:before {
  content: '\fafd';
}

.isax-receive-square-22:before {
  content: '\fafe';
}

.isax-record2:before {
  content: '\faff';
}

.isax-record-circle2:before {
  content: '\fb00';
}

.isax-recovery-convert2:before {
  content: '\fb01';
}

.isax-redo2:before {
  content: '\fb02';
}

.isax-refresh2:before {
  content: '\fb03';
}

.isax-refresh-22:before {
  content: '\fb04';
}

.isax-refresh-circle2:before {
  content: '\fb05';
}

.isax-refresh-left-square2:before {
  content: '\fb06';
}

.isax-refresh-right-square2:before {
  content: '\fb07';
}

.isax-refresh-square-22:before {
  content: '\fb08';
}

.isax-repeat2:before {
  content: '\fb09';
}

.isax-repeat-circle2:before {
  content: '\fb0a';
}

.isax-repeate-music2:before {
  content: '\fb0b';
}

.isax-repeate-one2:before {
  content: '\fb0c';
}

.isax-reserve2:before {
  content: '\fb0d';
}

.isax-rotate-left2:before {
  content: '\fb0e';
}

.isax-rotate-left-12:before {
  content: '\fb0f';
}

.isax-rotate-right2:before {
  content: '\fb10';
}

.isax-rotate-right-12:before {
  content: '\fb11';
}

.isax-route-square2:before {
  content: '\fb12';
}

.isax-routing2:before {
  content: '\fb13';
}

.isax-routing-22:before {
  content: '\fb14';
}

.isax-row-horizontal2:before {
  content: '\fb15';
}

.isax-row-vertical2:before {
  content: '\fb16';
}

.isax-rulerpen2:before {
  content: '\fb17';
}

.isax-ruler2:before {
  content: '\fb18';
}

.isax-safe-home2:before {
  content: '\fb19';
}

.isax-sagittarius2:before {
  content: '\fb1a';
}

.isax-save-22:before {
  content: '\fb1b';
}

.isax-save-add2:before {
  content: '\fb1c';
}

.isax-save-minus2:before {
  content: '\fb1d';
}

.isax-save-remove2:before {
  content: '\fb1e';
}

.isax-scan2:before {
  content: '\fb1f';
}

.isax-scan-barcode2:before {
  content: '\fb20';
}

.isax-scanner2:before {
  content: '\fb21';
}

.isax-scanning2:before {
  content: '\fb22';
}

.isax-scissor2:before {
  content: '\fb23';
}

.isax-scissor-12:before {
  content: '\fb24';
}

.isax-screenmirroring2:before {
  content: '\fb25';
}

.isax-scroll2:before {
  content: '\fb26';
}

.isax-search-favorite2:before {
  content: '\fb27';
}

.isax-search-favorite-12:before {
  content: '\fb28';
}

.isax-search-normal2:before {
  content: '\fb29';
}

.isax-search-normal-12:before {
  content: '\fb2a';
}

.isax-search-status2:before {
  content: '\fb2b';
}

.isax-search-status-12:before {
  content: '\fb2c';
}

.isax-search-zoom-in2:before {
  content: '\fb2d';
}

.isax-search-zoom-in-12:before {
  content: '\fb2e';
}

.isax-search-zoom-out2:before {
  content: '\fb2f';
}

.isax-search-zoom-out-12:before {
  content: '\fb30';
}

.isax-security2:before {
  content: '\fb31';
}

.isax-security-card2:before {
  content: '\fb32';
}

.isax-security-safe2:before {
  content: '\fb33';
}

.isax-security-time2:before {
  content: '\fb34';
}

.isax-security-user2:before {
  content: '\fb35';
}

.isax-send2:before {
  content: '\fb36';
}

.isax-send-12:before {
  content: '\fb37';
}

.isax-send-22:before {
  content: '\fb38';
}

.isax-send-sqaure-22:before {
  content: '\fb39';
}

.isax-send-square2:before {
  content: '\fb3a';
}

.isax-setting2:before {
  content: '\fb3b';
}

.isax-setting-22:before {
  content: '\fb3c';
}

.isax-setting-32:before {
  content: '\fb3d';
}

.isax-setting-42:before {
  content: '\fb3e';
}

.isax-setting-52:before {
  content: '\fb3f';
}

.isax-settings2:before {
  content: '\fb40';
}

.isax-shapes2:before {
  content: '\fb41';
}

.isax-shapes-12:before {
  content: '\fb42';
}

.isax-share2:before {
  content: '\fb43';
}

.isax-shield-cross2:before {
  content: '\fb44';
}

.isax-shield-search2:before {
  content: '\fb45';
}

.isax-shield-slash2:before {
  content: '\fb46';
}

.isax-shield-tick2:before {
  content: '\fb47';
}

.isax-ship2:before {
  content: '\fb48';
}

.isax-shop2:before {
  content: '\fb49';
}

.isax-shop-add2:before {
  content: '\fb4a';
}

.isax-shopping-bag2:before {
  content: '\fb4b';
}

.isax-shopping-cart2:before {
  content: '\fb4c';
}

.isax-shop-remove2:before {
  content: '\fb4d';
}

.isax-shuffle2:before {
  content: '\fb4e';
}

.isax-sidebar-bottom2:before {
  content: '\fb4f';
}

.isax-sidebar-left2:before {
  content: '\fb50';
}

.isax-sidebar-right2:before {
  content: '\fb51';
}

.isax-sidebar-top2:before {
  content: '\fb52';
}

.isax-signpost2:before {
  content: '\fb53';
}

.isax-simcard2:before {
  content: '\fb54';
}

.isax-simcard-12:before {
  content: '\fb55';
}

.isax-simcard-22:before {
  content: '\fb56';
}

.isax-size2:before {
  content: '\fb57';
}

.isax-slash2:before {
  content: '\fb58';
}

.isax-slider2:before {
  content: '\fb59';
}

.isax-slider-horizontal2:before {
  content: '\fb5a';
}

.isax-slider-horizontal-12:before {
  content: '\fb5b';
}

.isax-slider-vertical2:before {
  content: '\fb5c';
}

.isax-slider-vertical-12:before {
  content: '\fb5d';
}

.isax-smallcaps2:before {
  content: '\fb5e';
}

.isax-smart-car2:before {
  content: '\fb5f';
}

.isax-smart-home2:before {
  content: '\fb60';
}

.isax-smileys2:before {
  content: '\fb61';
}

.isax-sms2:before {
  content: '\fb62';
}

.isax-sms-edit2:before {
  content: '\fb63';
}

.isax-sms-notification2:before {
  content: '\fb64';
}

.isax-sms-search2:before {
  content: '\fb65';
}

.isax-sms-star2:before {
  content: '\fb66';
}

.isax-sms-tracking2:before {
  content: '\fb67';
}

.isax-sort2:before {
  content: '\fb68';
}

.isax-sound2:before {
  content: '\fb69';
}

.isax-speaker2:before {
  content: '\fb6a';
}

.isax-speedometer2:before {
  content: '\fb6b';
}

.isax-star2:before {
  content: '\fb6c';
}

.isax-star-12:before {
  content: '\fb6d';
}

.isax-star-slash2:before {
  content: '\fb6e';
}

.isax-status2:before {
  content: '\fb6f';
}

.isax-status-up2:before {
  content: '\fb70';
}

.isax-sticker2:before {
  content: '\fb71';
}

.isax-stickynote2:before {
  content: '\fb72';
}

.isax-stop2:before {
  content: '\fb73';
}

.isax-stop-circle2:before {
  content: '\fb74';
}

.isax-story2:before {
  content: '\fb75';
}

.isax-strongbox2:before {
  content: '\fb76';
}

.isax-strongbox-22:before {
  content: '\fb77';
}

.isax-subtitle2:before {
  content: '\fb78';
}

.isax-sun2:before {
  content: '\fb79';
}

.isax-sun-12:before {
  content: '\fb7a';
}

.isax-sun-fog2:before {
  content: '\fb7b';
}

.isax-tag2:before {
  content: '\fb7c';
}

.isax-tag-22:before {
  content: '\fb7d';
}

.isax-tag-cross2:before {
  content: '\fb7e';
}

.isax-tag-right2:before {
  content: '\fb7f';
}

.isax-tag-user2:before {
  content: '\fb80';
}

.isax-task2:before {
  content: '\fb81';
}

.isax-task-square2:before {
  content: '\fb82';
}

.isax-teacher2:before {
  content: '\fb83';
}

.isax-text2:before {
  content: '\fb84';
}

.isax-textalign-center2:before {
  content: '\fb85';
}

.isax-textalign-justifycenter2:before {
  content: '\fb86';
}

.isax-textalign-justifyleft2:before {
  content: '\fb87';
}

.isax-textalign-justifyright2:before {
  content: '\fb88';
}

.isax-textalign-left2:before {
  content: '\fb89';
}

.isax-textalign-right2:before {
  content: '\fb8a';
}

.isax-text-block2:before {
  content: '\fb8b';
}

.isax-text-bold2:before {
  content: '\fb8c';
}

.isax-text-italic2:before {
  content: '\fb8d';
}

.isax-text-underline2:before {
  content: '\fb8e';
}

.isax-tick-circle2:before {
  content: '\fb8f';
}

.isax-ticket2:before {
  content: '\fb90';
}

.isax-ticket-22:before {
  content: '\fb91';
}

.isax-ticket-discount2:before {
  content: '\fb92';
}

.isax-ticket-expired2:before {
  content: '\fb93';
}

.isax-ticket-star2:before {
  content: '\fb94';
}

.isax-tick-square2:before {
  content: '\fb95';
}

.isax-timer2:before {
  content: '\fb96';
}

.isax-timer-12:before {
  content: '\fb97';
}

.isax-timer-pause2:before {
  content: '\fb98';
}

.isax-timer-start2:before {
  content: '\fb99';
}

.isax-toggle-off2:before {
  content: '\fb9a';
}

.isax-toggle-off-circle2:before {
  content: '\fb9b';
}

.isax-toggle-on2:before {
  content: '\fb9c';
}

.isax-toggle-on-circle2:before {
  content: '\fb9d';
}

.isax-trade2:before {
  content: '\fb9e';
}

.isax-transaction-minus2:before {
  content: '\fb9f';
}

.isax-translate2:before {
  content: '\fba0';
}

.isax-trash2:before {
  content: '\fba1';
}

.isax-tree2:before {
  content: '\fba2';
}

.isax-trend-down2:before {
  content: '\fba3';
}

.isax-trend-up2:before {
  content: '\fba4';
}

.isax-triangle2:before {
  content: '\fba5';
}

.isax-truck1:before {
  content: '\fba6';
}

.isax-truck-fast1:before {
  content: '\fba7';
}

.isax-truck-tick2:before {
  content: '\fba8';
}

.isax-trush-square2:before {
  content: '\fba9';
}

.isax-undo2:before {
  content: '\fbaa';
}

.isax-unlimited2:before {
  content: '\fbab';
}

.isax-unlock2:before {
  content: '\fbac';
}

.isax-user2:before {
  content: '\fbad';
}

.isax-user-add2:before {
  content: '\fbae';
}

.isax-user-cirlce-add2:before {
  content: '\fbaf';
}

.isax-user-edit2:before {
  content: '\fbb0';
}

.isax-user-minus2:before {
  content: '\fbb1';
}

.isax-user-octagon2:before {
  content: '\fbb2';
}

.isax-user-remove2:before {
  content: '\fbb3';
}

.isax-user-search2:before {
  content: '\fbb4';
}

.isax-user-square2:before {
  content: '\fbb5';
}

.isax-user-tag2:before {
  content: '\fbb6';
}

.isax-user-tick2:before {
  content: '\fbb7';
}

.isax-verify2:before {
  content: '\fbb8';
}

.isax-video2:before {
  content: '\fbb9';
}

.isax-video-add2:before {
  content: '\fbba';
}

.isax-video-circle2:before {
  content: '\fbbb';
}

.isax-video-horizontal2:before {
  content: '\fbbc';
}

.isax-video-octagon2:before {
  content: '\fbbd';
}

.isax-video-play2:before {
  content: '\fbbe';
}

.isax-video-remove2:before {
  content: '\fbbf';
}

.isax-video-slash2:before {
  content: '\fbc0';
}

.isax-video-square2:before {
  content: '\fbc1';
}

.isax-video-tick2:before {
  content: '\fbc2';
}

.isax-video-time2:before {
  content: '\fbc3';
}

.isax-video-vertical2:before {
  content: '\fbc4';
}

.isax-voice-cricle2:before {
  content: '\fbc5';
}

.isax-voice-square2:before {
  content: '\fbc6';
}

.isax-volume-cross2:before {
  content: '\fbc7';
}

.isax-volume-high2:before {
  content: '\fbc8';
}

.isax-volume-low2:before {
  content: '\fbc9';
}

.isax-volume-low-12:before {
  content: '\fbca';
}

.isax-volume-mute2:before {
  content: '\fbcb';
}

.isax-volume-slash2:before {
  content: '\fbcc';
}

.isax-volume-up2:before {
  content: '\fbcd';
}

.isax-wallet2:before {
  content: '\fbce';
}

.isax-wallet-12:before {
  content: '\fbcf';
}

.isax-wallet-22:before {
  content: '\fbd0';
}

.isax-wallet-32:before {
  content: '\fbd1';
}

.isax-wallet-add2:before {
  content: '\fbd2';
}

.isax-wallet-add-12:before {
  content: '\fbd3';
}

.isax-wallet-check2:before {
  content: '\fbd4';
}

.isax-wallet-minus2:before {
  content: '\fbd5';
}

.isax-wallet-money2:before {
  content: '\fbd6';
}

.isax-wallet-remove2:before {
  content: '\fbd7';
}

.isax-wallet-search2:before {
  content: '\fbd8';
}

.isax-warning-22:before {
  content: '\fbd9';
}

.isax-watch2:before {
  content: '\fbda';
}

.isax-watch-status2:before {
  content: '\fbdb';
}

.isax-weight2:before {
  content: '\fbdc';
}

.isax-weight-12:before {
  content: '\fbdd';
}

.isax-wifi2:before {
  content: '\fbde';
}

.isax-wifi-square2:before {
  content: '\fbdf';
}

.isax-wind2:before {
  content: '\fbe0';
}

.isax-wind-22:before {
  content: '\fbe1';
}

.isax-woman2:before {
  content: '\fbe2';
}

.isax-dcube3:before {
  content: '\e900';
}

.isax-d-cube-scan3:before {
  content: '\fbe3';
}

.isax-d-rotate3:before {
  content: '\fbe4';
}

.isax-d-square3:before {
  content: '\fbe5';
}

.isax-square3:before {
  content: '\fbe6';
}

.isax-support3:before {
  content: '\fbe7';
}

.isax-activity3:before {
  content: '\fbe8';
}

.isax-add3:before {
  content: '\fbe9';
}

.isax-add-circle3:before {
  content: '\fbea';
}

.isax-additem3:before {
  content: '\fbeb';
}

.isax-add-square3:before {
  content: '\fbec';
}

.isax-airdrop3:before {
  content: '\fbed';
}

.isax-airplane3:before {
  content: '\fbee';
}

.isax-airplane-square3:before {
  content: '\fbef';
}

.isax-airpod3:before {
  content: '\fbf0';
}

.isax-airpods3:before {
  content: '\fbf1';
}

.isax-alarm3:before {
  content: '\fbf2';
}

.isax-align-bottom3:before {
  content: '\fbf3';
}

.isax-align-horizontally3:before {
  content: '\fbf4';
}

.isax-align-left3:before {
  content: '\fbf5';
}

.isax-align-right3:before {
  content: '\fbf6';
}

.isax-align-vertically3:before {
  content: '\fbf7';
}

.isax-aquarius3:before {
  content: '\fbf8';
}

.isax-archive3:before {
  content: '\fbf9';
}

.isax-archive-13:before {
  content: '\fbfa';
}

.isax-archive-add3:before {
  content: '\fbfb';
}

.isax-archive-book3:before {
  content: '\fbfc';
}

.isax-archive-minus3:before {
  content: '\fbfd';
}

.isax-archive-slash3:before {
  content: '\fbfe';
}

.isax-archive-tick3:before {
  content: '\fbff';
}

.isax-arrange-circle3:before {
  content: '\fc00';
}

.isax-arrange-circle-23:before {
  content: '\fc01';
}

.isax-arrange-square3:before {
  content: '\fc02';
}

.isax-arrange-square-23:before {
  content: '\fc03';
}

.isax-arrow3:before {
  content: '\fc04';
}

.isax-arrow-23:before {
  content: '\fc05';
}

.isax-arrow-33:before {
  content: '\fc06';
}

.isax-arrow-bottom3:before {
  content: '\fc07';
}

.isax-arrow-circle-down3:before {
  content: '\fc08';
}

.isax-arrow-circle-left3:before {
  content: '\fc09';
}

.isax-arrow-circle-right3:before {
  content: '\fc0a';
}

.isax-arrow-down3:before {
  content: '\fc0b';
}

.isax-arrow-down-13:before {
  content: '\fc0c';
}

.isax-arrow-down-23:before {
  content: '\fc0d';
}

.isax-arrow-left3:before {
  content: '\fc0e';
}

.isax-arrow-left-13:before {
  content: '\fc0f';
}

.isax-arrow-left-23:before {
  content: '\fc10';
}

.isax-arrow-left-33:before {
  content: '\fc11';
}

.isax-arrow-right3:before {
  content: '\fc12';
}

.isax-arrow-right-13:before {
  content: '\fc13';
}

.isax-arrow-right-23:before {
  content: '\fc14';
}

.isax-arrow-right-33:before {
  content: '\fc15';
}

.isax-arrow-square3:before {
  content: '\fc16';
}

.isax-arrow-square-down3:before {
  content: '\fc17';
}

.isax-arrow-square-left3:before {
  content: '\fc18';
}

.isax-arrow-square-right2:before {
  content: '\fc19';
}

.isax-arrow-square-up3:before {
  content: '\fc1a';
}

.isax-arrow-swap-horizontal3:before {
  content: '\fc1b';
}

.isax-arrow-up3:before {
  content: '\fc1c';
}

.isax-arrow-up-13:before {
  content: '\fc1d';
}

.isax-arrow-up-23:before {
  content: '\fc1e';
}

.isax-arrow-up-33:before {
  content: '\fc1f';
}

.isax-attach-circle3:before {
  content: '\fc20';
}

.isax-attach-square3:before {
  content: '\fc21';
}

.isax-audio-square3:before {
  content: '\fc22';
}

.isax-autobrightness3:before {
  content: '\fc23';
}

.isax-award3:before {
  content: '\fc24';
}

.isax-back-square3:before {
  content: '\fc25';
}

.isax-backward3:before {
  content: '\fc26';
}

.isax-backward-5-seconds3:before {
  content: '\fc27';
}

.isax-backward-10-seconds3:before {
  content: '\fc28';
}

.isax-backward-15-seconds3:before {
  content: '\fc29';
}

.isax-backward-item3:before {
  content: '\fc2a';
}

.isax-bag3:before {
  content: '\fc2b';
}

.isax-bag-23:before {
  content: '\fc2c';
}

.isax-bag-cross3:before {
  content: '\fc2d';
}

.isax-bag-cross-13:before {
  content: '\fc2e';
}

.isax-bag-happy3:before {
  content: '\fc2f';
}

.isax-bag-tick3:before {
  content: '\fc30';
}

.isax-bag-tick-23:before {
  content: '\fc31';
}

.isax-bag-timer3:before {
  content: '\fc32';
}

.isax-bank3:before {
  content: '\fc33';
}

.isax-barcode3:before {
  content: '\fc34';
}

.isax-battery-3full3:before {
  content: '\fc35';
}

.isax-battery-charging3:before {
  content: '\fc36';
}

.isax-battery-disable3:before {
  content: '\fc37';
}

.isax-battery-empty3:before {
  content: '\fc38';
}

.isax-battery-empty-13:before {
  content: '\fc39';
}

.isax-battery-full3:before {
  content: '\fc3a';
}

.isax-bezier3:before {
  content: '\fc3b';
}

.isax-bill3:before {
  content: '\fc3c';
}

.isax-bitcoin-card3:before {
  content: '\fc3d';
}

.isax-bitcoin-convert3:before {
  content: '\fc3e';
}

.isax-bitcoin-refresh3:before {
  content: '\fc3f';
}

.isax-blend3:before {
  content: '\fc40';
}

.isax-blend-23:before {
  content: '\fc41';
}

.isax-bluetooth3:before {
  content: '\fc42';
}

.isax-bluetooth-23:before {
  content: '\fc43';
}

.isax-bluetooth-circle3:before {
  content: '\fc44';
}

.isax-bluetooth-rectangle3:before {
  content: '\fc45';
}

.isax-blur3:before {
  content: '\fc46';
}

.isax-book3:before {
  content: '\fc47';
}

.isax-book-13:before {
  content: '\fc48';
}

.isax-bookmark3:before {
  content: '\fc49';
}

.isax-bookmark-23:before {
  content: '\fc4a';
}

.isax-book-saved3:before {
  content: '\fc4b';
}

.isax-book-square3:before {
  content: '\fc4c';
}

.isax-box3:before {
  content: '\fc4d';
}

.isax-box-13:before {
  content: '\fc4e';
}

.isax-box-23:before {
  content: '\fc4f';
}

.isax-box-add3:before {
  content: '\fc50';
}

.isax-box-remove3:before {
  content: '\fc51';
}

.isax-box-search3:before {
  content: '\fc52';
}

.isax-box-tick3:before {
  content: '\fc53';
}

.isax-box-time3:before {
  content: '\fc54';
}

.isax-briefcase3:before {
  content: '\fc55';
}

.isax-brifecase-cross3:before {
  content: '\fc56';
}

.isax-brifecase-tick3:before {
  content: '\fc57';
}

.isax-brifecase-timer3:before {
  content: '\fc58';
}

.isax-broom3:before {
  content: '\fc59';
}

.isax-brush3:before {
  content: '\fc5a';
}

.isax-brush-13:before {
  content: '\fc5b';
}

.isax-brush-23:before {
  content: '\fc5c';
}

.isax-brush-33:before {
  content: '\fc5d';
}

.isax-brush-43:before {
  content: '\fc5e';
}

.isax-bubble3:before {
  content: '\fc5f';
}

.isax-bucket3:before {
  content: '\fc60';
}

.isax-bucket-circle3:before {
  content: '\fc61';
}

.isax-bucket-square3:before {
  content: '\fc62';
}

.isax-building3:before {
  content: '\fc63';
}

.isax-building-33:before {
  content: '\fc64';
}

.isax-building-43:before {
  content: '\fc65';
}

.isax-buildings3:before {
  content: '\fc66';
}

.isax-buildings-23:before {
  content: '\fc67';
}

.isax-buliding3:before {
  content: '\fc68';
}

.isax-bus3:before {
  content: '\fc69';
}

.isax-buy-crypto3:before {
  content: '\fc6a';
}

.isax-cake3:before {
  content: '\fc6b';
}

.isax-calculator3:before {
  content: '\fc6c';
}

.isax-calendar3:before {
  content: '\fc6d';
}

.isax-calendar-13:before {
  content: '\fc6e';
}

.isax-calendar-23:before {
  content: '\fc6f';
}

.isax-calendar-add3:before {
  content: '\fc70';
}

.isax-calendar-circle3:before {
  content: '\fc71';
}

.isax-calendar-edit3:before {
  content: '\fc72';
}

.isax-calendar-remove3:before {
  content: '\fc73';
}

.isax-calendar-search3:before {
  content: '\fc74';
}

.isax-calendar-tick3:before {
  content: '\fc75';
}

.isax-call3:before {
  content: '\fc76';
}

.isax-call-add3:before {
  content: '\fc77';
}

.isax-call-calling3:before {
  content: '\fc78';
}

.isax-call-incoming3:before {
  content: '\fc79';
}

.isax-call-minus3:before {
  content: '\fc7a';
}

.isax-call-outgoing3:before {
  content: '\fc7b';
}

.isax-call-received3:before {
  content: '\fc7c';
}

.isax-call-remove3:before {
  content: '\fc7d';
}

.isax-call-slash3:before {
  content: '\fc7e';
}

.isax-camera3:before {
  content: '\fc7f';
}

.isax-camera-slash3:before {
  content: '\fc80';
}

.isax-candle3:before {
  content: '\fc81';
}

.isax-candle-23:before {
  content: '\fc82';
}

.isax-car3:before {
  content: '\fc83';
}

.isax-card3:before {
  content: '\fc84';
}

.isax-card-add3:before {
  content: '\fc85';
}

.isax-card-coin3:before {
  content: '\fc86';
}

.isax-card-edit3:before {
  content: '\fc87';
}

.isax-card-pos3:before {
  content: '\fc88';
}

.isax-card-receive3:before {
  content: '\fc89';
}

.isax-card-remove3:before {
  content: '\fc8a';
}

.isax-card-remove-13:before {
  content: '\fc8b';
}

.isax-cards3:before {
  content: '\fc8c';
}

.isax-card-send3:before {
  content: '\fc8d';
}

.isax-card-slash3:before {
  content: '\fc8e';
}

.isax-card-tick3:before {
  content: '\fc8f';
}

.isax-card-tick-13:before {
  content: '\fc90';
}

.isax-category3:before {
  content: '\fc91';
}

.isax-category-23:before {
  content: '\fc92';
}

.isax-cd3:before {
  content: '\fc93';
}

.isax-chart3:before {
  content: '\fc94';
}

.isax-chart-13:before {
  content: '\fc95';
}

.isax-chart-24:before {
  content: '\fc96';
}

.isax-chart-33:before {
  content: '\fc97';
}

.isax-chart-213:before {
  content: '\fc98';
}

.isax-chart-square3:before {
  content: '\fc99';
}

.isax-chart-success3:before {
  content: '\fc9a';
}

.isax-check3:before {
  content: '\fc9b';
}

.isax-chrome3:before {
  content: '\fc9c';
}

.isax-clipboard3:before {
  content: '\fc9d';
}

.isax-clipboard-close3:before {
  content: '\fc9e';
}

.isax-clipboard-export3:before {
  content: '\fc9f';
}

.isax-clipboard-import3:before {
  content: '\fca0';
}

.isax-clipboard-text3:before {
  content: '\fca1';
}

.isax-clipboard-tick3:before {
  content: '\fca2';
}

.isax-clock3:before {
  content: '\fca3';
}

.isax-clock-13:before {
  content: '\fca4';
}

.isax-close-circle3:before {
  content: '\fca5';
}

.isax-close-square3:before {
  content: '\fca6';
}

.isax-cloud3:before {
  content: '\fca7';
}

.isax-cloud-add3:before {
  content: '\fca8';
}

.isax-cloud-change3:before {
  content: '\fca9';
}

.isax-cloud-connection3:before {
  content: '\fcaa';
}

.isax-cloud-cross3:before {
  content: '\fcab';
}

.isax-cloud-drizzle3:before {
  content: '\fcac';
}

.isax-cloud-fog3:before {
  content: '\fcad';
}

.isax-cloud-lightning3:before {
  content: '\fcae';
}

.isax-cloud-minus3:before {
  content: '\fcaf';
}

.isax-cloud-notif3:before {
  content: '\fcb0';
}

.isax-cloud-plus3:before {
  content: '\fcb1';
}

.isax-cloud-remove3:before {
  content: '\fcb2';
}

.isax-cloud-snow3:before {
  content: '\fcb3';
}

.isax-cloud-sunny3:before {
  content: '\fcb4';
}

.isax-code3:before {
  content: '\fcb5';
}

.isax-code-13:before {
  content: '\fcb6';
}

.isax-code-circle3:before {
  content: '\fcb7';
}

.isax-coffee3:before {
  content: '\fcb8';
}

.isax-coin3:before {
  content: '\fcb9';
}

.isax-coin-13:before {
  content: '\fcba';
}

.isax-colorfilter3:before {
  content: '\fcbb';
}

.isax-colors-square3:before {
  content: '\fcbc';
}

.isax-color-swatch3:before {
  content: '\fcbd';
}

.isax-command3:before {
  content: '\fcbe';
}

.isax-command-square3:before {
  content: '\fcbf';
}

.isax-component3:before {
  content: '\fcc0';
}

.isax-computing3:before {
  content: '\fcc1';
}

.isax-convert3:before {
  content: '\fcc2';
}

.isax-convert-3d-cube3:before {
  content: '\fcc3';
}

.isax-convert-card3:before {
  content: '\fcc4';
}

.isax-convertshape3:before {
  content: '\fcc5';
}

.isax-convertshape-23:before {
  content: '\fcc6';
}

.isax-copy3:before {
  content: '\fcc7';
}

.isax-copyright3:before {
  content: '\fcc8';
}

.isax-copy-success3:before {
  content: '\fcc9';
}

.isax-courthouse3:before {
  content: '\fcca';
}

.isax-cpu3:before {
  content: '\fccb';
}

.isax-cpu-charge3:before {
  content: '\fccc';
}

.isax-cpu-setting3:before {
  content: '\fccd';
}

.isax-creative-commons3:before {
  content: '\fcce';
}

.isax-crop3:before {
  content: '\fccf';
}

.isax-crown3:before {
  content: '\fcd0';
}

.isax-crown-13:before {
  content: '\fcd1';
}

.isax-cup3:before {
  content: '\fcd2';
}

.isax-danger3:before {
  content: '\fcd3';
}

.isax-data3:before {
  content: '\fcd4';
}

.isax-data-23:before {
  content: '\fcd5';
}

.isax-designtools3:before {
  content: '\fcd6';
}

.isax-device-message3:before {
  content: '\fcd7';
}

.isax-devices3:before {
  content: '\fcd8';
}

.isax-diagram3:before {
  content: '\fcd9';
}

.isax-diamonds3:before {
  content: '\fcda';
}

.isax-direct3:before {
  content: '\fcdb';
}

.isax-directbox-default3:before {
  content: '\fcdc';
}

.isax-directbox-notif3:before {
  content: '\fcdd';
}

.isax-directbox-receive3:before {
  content: '\fcde';
}

.isax-directbox-send3:before {
  content: '\fcdf';
}

.isax-direct-down3:before {
  content: '\fce0';
}

.isax-direct-inbox3:before {
  content: '\fce1';
}

.isax-direct-left3:before {
  content: '\fce2';
}

.isax-direct-normal3:before {
  content: '\fce3';
}

.isax-direct-notification3:before {
  content: '\fce4';
}

.isax-direct-right3:before {
  content: '\fce5';
}

.isax-direct-send3:before {
  content: '\fce6';
}

.isax-direct-up3:before {
  content: '\fce7';
}

.isax-discount-circle3:before {
  content: '\fce8';
}

.isax-discount-shape3:before {
  content: '\fce9';
}

.isax-discover3:before {
  content: '\fcea';
}

.isax-dislike3:before {
  content: '\fceb';
}

.isax-document3:before {
  content: '\fcec';
}

.isax-document-13:before {
  content: '\fced';
}

.isax-document-cloud3:before {
  content: '\fcee';
}

.isax-document-code3:before {
  content: '\fcef';
}

.isax-document-code-23:before {
  content: '\fcf0';
}

.isax-document-copy3:before {
  content: '\fcf1';
}

.isax-document-download3:before {
  content: '\fcf2';
}

.isax-document-favorite3:before {
  content: '\fcf3';
}

.isax-document-filter3:before {
  content: '\fcf4';
}

.isax-document-forward3:before {
  content: '\fcf5';
}

.isax-document-like3:before {
  content: '\fcf6';
}

.isax-document-previous3:before {
  content: '\fcf7';
}

.isax-document-sketch3:before {
  content: '\fcf8';
}

.isax-document-text3:before {
  content: '\fcf9';
}

.isax-document-text-13:before {
  content: '\fcfa';
}

.isax-document-upload3:before {
  content: '\fcfb';
}

.isax-dollar-circle2:before {
  content: '\fcfc';
}

.isax-dollar-square3:before {
  content: '\fcfd';
}

.isax-driver3:before {
  content: '\fcfe';
}

.isax-driver-23:before {
  content: '\fcff';
}

.isax-driver-refresh3:before {
  content: '\fd00';
}

.isax-driving3:before {
  content: '\fd01';
}

.isax-drop2:before {
  content: '\fd02';
}

.isax-edit3:before {
  content: '\fd03';
}

.isax-edit-23:before {
  content: '\fd04';
}

.isax-electricity3:before {
  content: '\fd05';
}

.isax-element-11:before {
  content: '\fd06';
}

.isax-element-23:before {
  content: '\fd07';
}

.isax-element-33:before {
  content: '\fd08';
}

.isax-element-43:before {
  content: '\fd09';
}

.isax-element-equal3:before {
  content: '\fd0a';
}

.isax-element-plus3:before {
  content: '\fd0b';
}

.isax-emoji-happy3:before {
  content: '\fd0c';
}

.isax-emoji-normal3:before {
  content: '\fd0d';
}

.isax-emoji-sad3:before {
  content: '\fd0e';
}

.isax-empty-wallet3:before {
  content: '\fd0f';
}

.isax-empty-wallet-add3:before {
  content: '\fd10';
}

.isax-empty-wallet-remove3:before {
  content: '\fd11';
}

.isax-empty-wallet-tick3:before {
  content: '\fd12';
}

.isax-eraser3:before {
  content: '\fd13';
}

.isax-eraser-13:before {
  content: '\fd14';
}

.isax-export3:before {
  content: '\fd15';
}

.isax-export-13:before {
  content: '\fd16';
}

.isax-export-23:before {
  content: '\fd17';
}

.isax-export-33:before {
  content: '\fd18';
}

.isax-external-drive3:before {
  content: '\fd19';
}

.isax-eye2:before {
  content: '\fd1a';
}

.isax-eye-slash3:before {
  content: '\fd1b';
}

.isax-fatrows3:before {
  content: '\fd1c';
}

.isax-favorite-chart3:before {
  content: '\fd1d';
}

.isax-filter3:before {
  content: '\fd1e';
}

.isax-filter-add3:before {
  content: '\fd1f';
}

.isax-filter-edit3:before {
  content: '\fd20';
}

.isax-filter-remove3:before {
  content: '\fd21';
}

.isax-filter-search3:before {
  content: '\fd22';
}

.isax-filter-square3:before {
  content: '\fd23';
}

.isax-filter-tick3:before {
  content: '\fd24';
}

.isax-finger-cricle3:before {
  content: '\fd25';
}

.isax-finger-scan3:before {
  content: '\fd26';
}

.isax-firstline3:before {
  content: '\fd27';
}

.isax-flag3:before {
  content: '\fd28';
}

.isax-flag-23:before {
  content: '\fd29';
}

.isax-flash3:before {
  content: '\fd2a';
}

.isax-flash-13:before {
  content: '\fd2b';
}

.isax-flash-circle3:before {
  content: '\fd2c';
}

.isax-flash-circle-13:before {
  content: '\fd2d';
}

.isax-flash-slash3:before {
  content: '\fd2e';
}

.isax-folder3:before {
  content: '\fd2f';
}

.isax-folder-23:before {
  content: '\fd30';
}

.isax-folder-add3:before {
  content: '\fd31';
}

.isax-folder-cloud3:before {
  content: '\fd32';
}

.isax-folder-connection3:before {
  content: '\fd33';
}

.isax-folder-cross3:before {
  content: '\fd34';
}

.isax-folder-favorite3:before {
  content: '\fd35';
}

.isax-folder-minus3:before {
  content: '\fd36';
}

.isax-folder-open3:before {
  content: '\fd37';
}

.isax-forbidden3:before {
  content: '\fd38';
}

.isax-forbidden-23:before {
  content: '\fd39';
}

.isax-format-circle3:before {
  content: '\fd3a';
}

.isax-format-square3:before {
  content: '\fd3b';
}

.isax-forward3:before {
  content: '\fd3c';
}

.isax-forward-5-seconds3:before {
  content: '\fd3d';
}

.isax-forward-10-seconds3:before {
  content: '\fd3e';
}

.isax-forward-15-seconds3:before {
  content: '\fd3f';
}

.isax-forward-item3:before {
  content: '\fd40';
}

.isax-forward-square3:before {
  content: '\fd41';
}

.isax-frame3:before {
  content: '\fd42';
}

.isax-frame-13:before {
  content: '\fd43';
}

.isax-frame-23:before {
  content: '\fd44';
}

.isax-frame-33:before {
  content: '\fd45';
}

.isax-frame-43:before {
  content: '\fd46';
}

.isax-frame-51:before {
  content: '\fd47';
}

.isax-gallery3:before {
  content: '\fd48';
}

.isax-gallery-add3:before {
  content: '\fd49';
}

.isax-gallery-edit3:before {
  content: '\fd4a';
}

.isax-gallery-export2:before {
  content: '\fd4b';
}

.isax-gallery-favorite3:before {
  content: '\fd4c';
}

.isax-gallery-import3:before {
  content: '\fd4d';
}

.isax-gallery-remove3:before {
  content: '\fd4e';
}

.isax-gallery-slash3:before {
  content: '\fd4f';
}

.isax-gallery-tick3:before {
  content: '\fd50';
}

.isax-game3:before {
  content: '\fd51';
}

.isax-gameboy3:before {
  content: '\fd52';
}

.isax-gemini3:before {
  content: '\fd53';
}

.isax-gemini-23:before {
  content: '\fd54';
}

.isax-ghost3:before {
  content: '\fd55';
}

.isax-gift3:before {
  content: '\fd56';
}

.isax-glass3:before {
  content: '\fd57';
}

.isax-glass-13:before {
  content: '\fd58';
}

.isax-global3:before {
  content: '\fd59';
}

.isax-global-edit3:before {
  content: '\fd5a';
}

.isax-global-refresh3:before {
  content: '\fd5b';
}

.isax-global-search3:before {
  content: '\fd5c';
}

.isax-gps3:before {
  content: '\fd5d';
}

.isax-gps-slash3:before {
  content: '\fd5e';
}

.isax-grammerly3:before {
  content: '\fd5f';
}

.isax-graph3:before {
  content: '\fd60';
}

.isax-grid-13:before {
  content: '\fd61';
}

.isax-grid-23:before {
  content: '\fd62';
}

.isax-grid-33:before {
  content: '\fd63';
}

.isax-grid-43:before {
  content: '\fd64';
}

.isax-grid-53:before {
  content: '\fd65';
}

.isax-grid-63:before {
  content: '\fd66';
}

.isax-grid-73:before {
  content: '\fd67';
}

.isax-grid-83:before {
  content: '\fd68';
}

.isax-grid-93:before {
  content: '\fd69';
}

.isax-grid-edit3:before {
  content: '\fd6a';
}

.isax-grid-eraser3:before {
  content: '\fd6b';
}

.isax-grid-lock3:before {
  content: '\fd6c';
}

.isax-happyemoji3:before {
  content: '\fd6d';
}

.isax-hashtag3:before {
  content: '\fd6e';
}

.isax-hashtag-13:before {
  content: '\fd6f';
}

.isax-hashtag-down3:before {
  content: '\fd70';
}

.isax-hashtag-up3:before {
  content: '\fd71';
}

.isax-headphone3:before {
  content: '\fd72';
}

.isax-headphones3:before {
  content: '\fd73';
}

.isax-health3:before {
  content: '\fd74';
}

.isax-heart3:before {
  content: '\fd75';
}

.isax-heart-add3:before {
  content: '\fd76';
}

.isax-heart-circle3:before {
  content: '\fd77';
}

.isax-heart-edit3:before {
  content: '\fd78';
}

.isax-heart-remove3:before {
  content: '\fd79';
}

.isax-heart-search3:before {
  content: '\fd7a';
}

.isax-heart-slash3:before {
  content: '\fd7b';
}

.isax-heart-tick3:before {
  content: '\fd7c';
}

.isax-hierarchy3:before {
  content: '\fd7d';
}

.isax-hierarchy-23:before {
  content: '\fd7e';
}

.isax-hierarchy-33:before {
  content: '\fd7f';
}

.isax-hierarchy-square3:before {
  content: '\fd80';
}

.isax-hierarchy-square-23:before {
  content: '\fd81';
}

.isax-hierarchy-square-33:before {
  content: '\fd82';
}

.isax-home3:before {
  content: '\fd83';
}

.isax-home-13:before {
  content: '\fd84';
}

.isax-home-23:before {
  content: '\fd85';
}

.isax-home-hashtag3:before {
  content: '\fd86';
}

.isax-home-trend-down3:before {
  content: '\fd87';
}

.isax-home-trend-up3:before {
  content: '\fd88';
}

.isax-home-wifi3:before {
  content: '\fd89';
}

.isax-hospital3:before {
  content: '\fd8a';
}

.isax-house3:before {
  content: '\fd8b';
}

.isax-house-23:before {
  content: '\fd8c';
}

.isax-icon2:before {
  content: '\fd8d';
}

.isax-icon-11:before {
  content: '\fd8e';
}

.isax-icon-2:before {
  content: '\fd8f';
}

.isax-icon-3:before {
  content: '\fd90';
}

.isax-image3:before {
  content: '\fd91';
}

.isax-import3:before {
  content: '\fd92';
}

.isax-import-13:before {
  content: '\fd93';
}

.isax-import-23:before {
  content: '\fd94';
}

.isax-info-circle3:before {
  content: '\fd95';
}

.isax-information3:before {
  content: '\fd96';
}

.isax-instagram3:before {
  content: '\fd97';
}

.isax-judge3:before {
  content: '\fd98';
}

.isax-kanban2:before {
  content: '\fd99';
}

.isax-key3:before {
  content: '\fd9a';
}

.isax-keyboard3:before {
  content: '\fd9b';
}

.isax-keyboard-open3:before {
  content: '\fd9c';
}

.isax-key-square3:before {
  content: '\fd9d';
}

.isax-lamp3:before {
  content: '\fd9e';
}

.isax-lamp-13:before {
  content: '\fd9f';
}

.isax-lamp-charge3:before {
  content: '\fda0';
}

.isax-lamp-on3:before {
  content: '\fda1';
}

.isax-lamp-slash3:before {
  content: '\fda2';
}

.isax-language-circle3:before {
  content: '\fda3';
}

.isax-language-square3:before {
  content: '\fda4';
}

.isax-layer3:before {
  content: '\fda5';
}

.isax-level3:before {
  content: '\fda6';
}

.isax-lifebuoy3:before {
  content: '\fda7';
}

.isax-like3:before {
  content: '\fda8';
}

.isax-like-13:before {
  content: '\fda9';
}

.isax-like-dislike3:before {
  content: '\fdaa';
}

.isax-like-shapes3:before {
  content: '\fdab';
}

.isax-like-tag3:before {
  content: '\fdac';
}

.isax-link3:before {
  content: '\fdad';
}

.isax-link-13:before {
  content: '\fdae';
}

.isax-link-24:before {
  content: '\fdaf';
}

.isax-link-213:before {
  content: '\fdb0';
}

.isax-link-circle3:before {
  content: '\fdb1';
}

.isax-link-square3:before {
  content: '\fdb2';
}

.isax-location3:before {
  content: '\fdb3';
}

.isax-location-add3:before {
  content: '\fdb4';
}

.isax-location-cross3:before {
  content: '\fdb5';
}

.isax-location-minus3:before {
  content: '\fdb6';
}

.isax-location-slash3:before {
  content: '\fdb7';
}

.isax-location-tick3:before {
  content: '\fdb8';
}

.isax-lock3:before {
  content: '\fdb9';
}

.isax-lock-13:before {
  content: '\fdba';
}

.isax-lock-circle3:before {
  content: '\fdbb';
}

.isax-lock-slash3:before {
  content: '\fdbc';
}

.isax-login3:before {
  content: '\fdbd';
}

.isax-login-13:before {
  content: '\fdbe';
}

.isax-logout3:before {
  content: '\fdbf';
}

.isax-logout-13:before {
  content: '\fdc0';
}

.isax-lovely3:before {
  content: '\fdc1';
}

.isax-magicpen3:before {
  content: '\fdc2';
}

.isax-magic-star3:before {
  content: '\fdc3';
}

.isax-main-component3:before {
  content: '\fdc4';
}

.isax-man3:before {
  content: '\fdc5';
}

.isax-map3:before {
  content: '\fdc6';
}

.isax-map-13:before {
  content: '\fdc7';
}

.isax-mask3:before {
  content: '\fdc8';
}

.isax-mask-13:before {
  content: '\fdc9';
}

.isax-mask-23:before {
  content: '\fdca';
}

.isax-math3:before {
  content: '\fdcb';
}

.isax-maximize3:before {
  content: '\fdcc';
}

.isax-maximize-13:before {
  content: '\fdcd';
}

.isax-maximize-24:before {
  content: '\fdce';
}

.isax-maximize-33:before {
  content: '\fdcf';
}

.isax-maximize-43:before {
  content: '\fdd0';
}

.isax-maximize-213:before {
  content: '\fdd1';
}

.isax-maximize-circle2 .path1:before {
  content: '\fdd2';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-maximize-circle2 .path2:before {
  content: '\fdd3';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-maximize-circle2 .path3:before {
  content: '\fdd4';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-maximize-circle2 .path4:before {
  content: '\fdd5';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-maximize-circle2 .path5:before {
  content: '\fdd6';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-maximize-circle2 .path6:before {
  content: '\fdd7';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-medal3:before {
  content: '\fdd8';
}

.isax-medal-star3:before {
  content: '\fdd9';
}

.isax-menu3:before {
  content: '\fdda';
}

.isax-menu-13:before {
  content: '\fddb';
}

.isax-menu-board3:before {
  content: '\fddc';
}

.isax-message3:before {
  content: '\fddd';
}

.isax-message-23:before {
  content: '\fdde';
}

.isax-message-add3:before {
  content: '\fddf';
}

.isax-message-add-13:before {
  content: '\fde0';
}

.isax-message-circle3:before {
  content: '\fde1';
}

.isax-message-edit3:before {
  content: '\fde2';
}

.isax-message-favorite3:before {
  content: '\fde3';
}

.isax-message-minus3:before {
  content: '\fde4';
}

.isax-message-notif3:before {
  content: '\fde5';
}

.isax-message-programming3:before {
  content: '\fde6';
}

.isax-message-question3:before {
  content: '\fde7';
}

.isax-message-remove3:before {
  content: '\fde8';
}

.isax-messages3:before {
  content: '\fde9';
}

.isax-messages-13:before {
  content: '\fdea';
}

.isax-messages-23:before {
  content: '\fdeb';
}

.isax-messages-33:before {
  content: '\fdec';
}

.isax-message-search3:before {
  content: '\fded';
}

.isax-message-square3:before {
  content: '\fdee';
}

.isax-message-text3:before {
  content: '\fdef';
}

.isax-message-text-13:before {
  content: '\fdf0';
}

.isax-message-tick3:before {
  content: '\fdf1';
}

.isax-message-time3:before {
  content: '\fdf2';
}

.isax-microphone3:before {
  content: '\fdf3';
}

.isax-microphone-23:before {
  content: '\fdf4';
}

.isax-microphone-slash3:before {
  content: '\fdf5';
}

.isax-microphone-slash-13:before {
  content: '\fdf6';
}

.isax-microscope3:before {
  content: '\fdf7';
}

.isax-milk3:before {
  content: '\fdf8';
}

.isax-mini-music-sqaure3:before {
  content: '\fdf9';
}

.isax-minus3:before {
  content: '\fdfa';
}

.isax-minus-cirlce3:before {
  content: '\fdfb';
}

.isax-minus-square3:before {
  content: '\fdfc';
}

.isax-mirror3:before {
  content: '\fdfd';
}

.isax-mirroring-screen3:before {
  content: '\fdfe';
}

.isax-mobile3:before {
  content: '\fdff';
}

.isax-mobile-programming3:before {
  content: '\fe00';
}

.isax-money3:before {
  content: '\fe01';
}

.isax-money-23:before {
  content: '\fe02';
}

.isax-money-33:before {
  content: '\fe03';
}

.isax-money-43:before {
  content: '\fe04';
}

.isax-money-add3:before {
  content: '\fe05';
}

.isax-money-change3:before {
  content: '\fe06';
}

.isax-money-forbidden3:before {
  content: '\fe07';
}

.isax-money-recive3:before {
  content: '\fe08';
}

.isax-money-remove3:before {
  content: '\fe09';
}

.isax-moneys3:before {
  content: '\fe0a';
}

.isax-money-send3:before {
  content: '\fe0b';
}

.isax-money-tick3:before {
  content: '\fe0c';
}

.isax-money-time3:before {
  content: '\fe0d';
}

.isax-monitor3:before {
  content: '\fe0e';
}

.isax-monitor-mobbile3:before {
  content: '\fe0f';
}

.isax-monitor-recorder3:before {
  content: '\fe10';
}

.isax-moon3:before {
  content: '\fe11';
}

.isax-more3:before {
  content: '\fe12';
}

.isax-more-23:before {
  content: '\fe13';
}

.isax-more-circle3:before {
  content: '\fe14';
}

.isax-more-square3:before {
  content: '\fe15';
}

.isax-mouse3:before {
  content: '\fe16';
}

.isax-mouse-13:before {
  content: '\fe17';
}

.isax-mouse-circle3:before {
  content: '\fe18';
}

.isax-mouse-square3:before {
  content: '\fe19';
}

.isax-music3:before {
  content: '\fe1a';
}

.isax-music-circle3:before {
  content: '\fe1b';
}

.isax-music-dashboard3:before {
  content: '\fe1c';
}

.isax-music-filter3:before {
  content: '\fe1d';
}

.isax-music-library-23:before {
  content: '\fe1e';
}

.isax-musicnote3:before {
  content: '\fe1f';
}

.isax-music-play3:before {
  content: '\fe20';
}

.isax-music-playlist3:before {
  content: '\fe21';
}

.isax-music-square3:before {
  content: '\fe22';
}

.isax-music-square-add3:before {
  content: '\fe23';
}

.isax-music-square-remove3:before {
  content: '\fe24';
}

.isax-music-square-search3:before {
  content: '\fe25';
}

.isax-next3:before {
  content: '\fe26';
}

.isax-note3:before {
  content: '\fe27';
}

.isax-note-13:before {
  content: '\fe28';
}

.isax-note-24:before {
  content: '\fe29';
}

.isax-note-213:before {
  content: '\fe2a';
}

.isax-note-add3:before {
  content: '\fe2b';
}

.isax-note-favorite3:before {
  content: '\fe2c';
}

.isax-note-remove3:before {
  content: '\fe2d';
}

.isax-note-square3:before {
  content: '\fe2e';
}

.isax-note-text3:before {
  content: '\fe2f';
}

.isax-notification3:before {
  content: '\fe30';
}

.isax-notification-13:before {
  content: '\fe31';
}

.isax-notification-bing3:before {
  content: '\fe32';
}

.isax-notification-circle3:before {
  content: '\fe33';
}

.isax-notification-favorite3:before {
  content: '\fe34';
}

.isax-notification-status3:before {
  content: '\fe35';
}

.isax-omega-circle3:before {
  content: '\fe36';
}

.isax-omega-square3:before {
  content: '\fe37';
}

.isax-paintbucket3:before {
  content: '\fe38';
}

.isax-paperclip3:before {
  content: '\fe39';
}

.isax-paperclip-23:before {
  content: '\fe3a';
}

.isax-password-check3:before {
  content: '\fe3b';
}

.isax-path3:before {
  content: '\fe3c';
}

.isax-path-23:before {
  content: '\fe3d';
}

.isax-path-square3:before {
  content: '\fe3e';
}

.isax-pause3:before {
  content: '\fe3f';
}

.isax-pause-circle3:before {
  content: '\fe40';
}

.isax-pen-add3:before {
  content: '\fe41';
}

.isax-pen-close3:before {
  content: '\fe42';
}

.isax-pen-remove3:before {
  content: '\fe43';
}

.isax-pen-tool3:before {
  content: '\fe44';
}

.isax-pen-tool-23:before {
  content: '\fe45';
}

.isax-people3:before {
  content: '\fe46';
}

.isax-percentage-circle3:before {
  content: '\fe47';
}

.isax-percentage-square3:before {
  content: '\fe48';
}

.isax-personalcard3:before {
  content: '\fe49';
}

.isax-pet3:before {
  content: '\fe4a';
}

.isax-pharagraphspacing3:before {
  content: '\fe4b';
}

.isax-picture-frame3:before {
  content: '\fe4c';
}

.isax-play3:before {
  content: '\fe4d';
}

.isax-play-add3:before {
  content: '\fe4e';
}

.isax-play-circle3:before {
  content: '\fe4f';
}

.isax-play-cricle3:before {
  content: '\fe50';
}

.isax-play-remove3:before {
  content: '\fe51';
}

.isax-presention-chart3:before {
  content: '\fe52';
}

.isax-previous3:before {
  content: '\fe53';
}

.isax-printer3:before {
  content: '\fe54';
}

.isax-printer-slash3:before {
  content: '\fe55';
}

.isax-profile1:before {
  content: '\fe56';
}

.isax-profile-2user3:before {
  content: '\fe57';
}

.isax-profile-add3:before {
  content: '\fe58';
}

.isax-profile-circle3:before {
  content: '\fe59';
}

.isax-profile-delete3:before {
  content: '\fe5a';
}

.isax-profile-remove3:before {
  content: '\fe5b';
}

.isax-profile-tick3:before {
  content: '\fe5c';
}

.isax-programming-arrow3:before {
  content: '\fe5d';
}

.isax-programming-arrows3:before {
  content: '\fe5e';
}

.isax-quote-down3:before {
  content: '\fe5f';
}

.isax-quote-down-circle3:before {
  content: '\fe60';
}

.isax-quote-down-square3:before {
  content: '\fe61';
}

.isax-quote-up3:before {
  content: '\fe62';
}

.isax-quote-up-circle3:before {
  content: '\fe63';
}

.isax-quote-up-square3:before {
  content: '\fe64';
}

.isax-radar3:before {
  content: '\fe65';
}

.isax-radar-13:before {
  content: '\fe66';
}

.isax-radar-23:before {
  content: '\fe67';
}

.isax-radio3:before {
  content: '\fe68';
}

.isax-ram3:before {
  content: '\fe69';
}

.isax-ram-23:before {
  content: '\fe6a';
}

.isax-ranking3:before {
  content: '\fe6b';
}

.isax-ranking-13:before {
  content: '\fe6c';
}

.isax-receipt3:before {
  content: '\fe6d';
}

.isax-receipt-13:before {
  content: '\fe6e';
}

.isax-receipt-23:before {
  content: '\fe6f';
}

.isax-receipt-2-13:before {
  content: '\fe70';
}

.isax-receipt-add3:before {
  content: '\fe71';
}

.isax-receipt-discount3:before {
  content: '\fe72';
}

.isax-receipt-disscount3:before {
  content: '\fe73';
}

.isax-receipt-edit3:before {
  content: '\fe74';
}

.isax-receipt-item3:before {
  content: '\fe75';
}

.isax-receipt-minus3:before {
  content: '\fe76';
}

.isax-receipt-search3:before {
  content: '\fe77';
}

.isax-receipt-square3:before {
  content: '\fe78';
}

.isax-receipt-text3:before {
  content: '\fe79';
}

.isax-received3:before {
  content: '\fe7a';
}

.isax-receive-square3:before {
  content: '\fe7b';
}

.isax-receive-square-23:before {
  content: '\fe7c';
}

.isax-record3:before {
  content: '\fe7d';
}

.isax-record-circle3:before {
  content: '\fe7e';
}

.isax-recovery-convert3:before {
  content: '\fe7f';
}

.isax-redo3:before {
  content: '\fe80';
}

.isax-refresh3:before {
  content: '\fe81';
}

.isax-refresh-23:before {
  content: '\fe82';
}

.isax-refresh-circle3:before {
  content: '\fe83';
}

.isax-refresh-left-square3:before {
  content: '\fe84';
}

.isax-refresh-right-square3:before {
  content: '\fe85';
}

.isax-refresh-square-23:before {
  content: '\fe86';
}

.isax-repeat3:before {
  content: '\fe87';
}

.isax-repeat-circle3:before {
  content: '\fe88';
}

.isax-repeate-music3:before {
  content: '\fe89';
}

.isax-repeate-one3:before {
  content: '\fe8a';
}

.isax-reserve3:before {
  content: '\fe8b';
}

.isax-rotate-left3:before {
  content: '\fe8c';
}

.isax-rotate-left-13:before {
  content: '\fe8d';
}

.isax-rotate-right3:before {
  content: '\fe8e';
}

.isax-rotate-right-13:before {
  content: '\fe8f';
}

.isax-route-square3:before {
  content: '\fe90';
}

.isax-routing3:before {
  content: '\fe91';
}

.isax-routing-23:before {
  content: '\fe92';
}

.isax-row-horizontal3:before {
  content: '\fe93';
}

.isax-row-vertical3:before {
  content: '\fe94';
}

.isax-rulerpen3:before {
  content: '\fe95';
}

.isax-ruler3:before {
  content: '\fe96';
}

.isax-safe-home3:before {
  content: '\fe97';
}

.isax-sagittarius3:before {
  content: '\fe98';
}

.isax-save-23:before {
  content: '\fe99';
}

.isax-save-add3:before {
  content: '\fe9a';
}

.isax-save-minus3:before {
  content: '\fe9b';
}

.isax-save-remove3:before {
  content: '\fe9c';
}

.isax-scan3:before {
  content: '\fe9d';
}

.isax-scan-barcode3:before {
  content: '\fe9e';
}

.isax-scanner3:before {
  content: '\fe9f';
}

.isax-scanning3:before {
  content: '\fea0';
}

.isax-scissor3:before {
  content: '\fea1';
}

.isax-scissor-13:before {
  content: '\fea2';
}

.isax-screenmirroring3:before {
  content: '\fea3';
}

.isax-scroll3:before {
  content: '\fea4';
}

.isax-search-favorite3:before {
  content: '\fea5';
}

.isax-search-favorite-13:before {
  content: '\fea6';
}

.isax-search-normal3:before {
  content: '\fea7';
}

.isax-search-normal-13:before {
  content: '\fea8';
}

.isax-search-status3:before {
  content: '\fea9';
}

.isax-search-status-13:before {
  content: '\feaa';
}

.isax-search-zoom-in3:before {
  content: '\feab';
}

.isax-search-zoom-in-13:before {
  content: '\feac';
}

.isax-search-zoom-out3:before {
  content: '\fead';
}

.isax-search-zoom-out-13:before {
  content: '\feae';
}

.isax-security3:before {
  content: '\feaf';
}

.isax-security-card3:before {
  content: '\feb0';
}

.isax-security-safe3:before {
  content: '\feb1';
}

.isax-security-time3:before {
  content: '\feb2';
}

.isax-security-user3:before {
  content: '\feb3';
}

.isax-send3:before {
  content: '\feb4';
}

.isax-send-13:before {
  content: '\feb5';
}

.isax-send-23:before {
  content: '\feb6';
}

.isax-send-sqaure-23:before {
  content: '\feb7';
}

.isax-send-square3:before {
  content: '\feb8';
}

.isax-setting3:before {
  content: '\feb9';
}

.isax-setting-23:before {
  content: '\feba';
}

.isax-setting-33:before {
  content: '\febb';
}

.isax-setting-43:before {
  content: '\febc';
}

.isax-setting-53:before {
  content: '\febd';
}

.isax-settings3:before {
  content: '\febe';
}

.isax-shapes3:before {
  content: '\febf';
}

.isax-shapes-13:before {
  content: '\fec0';
}

.isax-share3:before {
  content: '\fec1';
}

.isax-shield2:before {
  content: '\fec2';
}

.isax-shield-cross3:before {
  content: '\fec3';
}

.isax-shield-search3:before {
  content: '\fec4';
}

.isax-shield-security1:before {
  content: '\fec5';
}

.isax-shield-slash3:before {
  content: '\fec6';
}

.isax-shield-tick3:before {
  content: '\fec7';
}

.isax-ship3:before {
  content: '\fec8';
}

.isax-shop3:before {
  content: '\fec9';
}

.isax-shop-add3:before {
  content: '\feca';
}

.isax-shopping-bag3:before {
  content: '\fecb';
}

.isax-shopping-cart3:before {
  content: '\fecc';
}

.isax-shop-remove3:before {
  content: '\fecd';
}

.isax-shuffle3:before {
  content: '\fece';
}

.isax-sidebar-bottom3:before {
  content: '\fecf';
}

.isax-sidebar-left3:before {
  content: '\fed0';
}

.isax-sidebar-right3:before {
  content: '\fed1';
}

.isax-sidebar-top3:before {
  content: '\fed2';
}

.isax-signpost3:before {
  content: '\fed3';
}

.isax-simcard3:before {
  content: '\fed4';
}

.isax-simcard-13:before {
  content: '\fed5';
}

.isax-simcard-23:before {
  content: '\fed6';
}

.isax-size3:before {
  content: '\fed7';
}

.isax-slash3:before {
  content: '\fed8';
}

.isax-slider3:before {
  content: '\fed9';
}

.isax-slider-horizontal3:before {
  content: '\feda';
}

.isax-slider-horizontal-13:before {
  content: '\fedb';
}

.isax-slider-vertical3:before {
  content: '\fedc';
}

.isax-slider-vertical-13:before {
  content: '\fedd';
}

.isax-smallcaps3:before {
  content: '\fede';
}

.isax-smart-car3:before {
  content: '\fedf';
}

.isax-smart-home3:before {
  content: '\fee0';
}

.isax-smileys3:before {
  content: '\fee1';
}

.isax-sms3:before {
  content: '\fee2';
}

.isax-sms-edit3:before {
  content: '\fee3';
}

.isax-sms-notification3:before {
  content: '\fee4';
}

.isax-sms-search3:before {
  content: '\fee5';
}

.isax-sms-star3:before {
  content: '\fee6';
}

.isax-sms-tracking3:before {
  content: '\fee7';
}

.isax-sort3:before {
  content: '\fee8';
}

.isax-sound3:before {
  content: '\fee9';
}

.isax-speaker3:before {
  content: '\feea';
}

.isax-speedometer3:before {
  content: '\feeb';
}

.isax-star3:before {
  content: '\feec';
}

.isax-star-13:before {
  content: '\feed';
}

.isax-star-slash3:before {
  content: '\feee';
}

.isax-status3:before {
  content: '\feef';
}

.isax-status-up3:before {
  content: '\fef0';
}

.isax-sticker3:before {
  content: '\fef1';
}

.isax-stickynote3:before {
  content: '\fef2';
}

.isax-stop3:before {
  content: '\fef3';
}

.isax-stop-circle3:before {
  content: '\fef4';
}

.isax-story3:before {
  content: '\fef5';
}

.isax-strongbox3:before {
  content: '\fef6';
}

.isax-strongbox-23:before {
  content: '\fef7';
}

.isax-subtitle3:before {
  content: '\fef8';
}

.isax-sun3:before {
  content: '\fef9';
}

.isax-sun-13:before {
  content: '\fefa';
}

.isax-sun-fog3:before {
  content: '\fefb';
}

.isax-tag3:before {
  content: '\fefc';
}

.isax-tag-23:before {
  content: '\fefd';
}

.isax-tag-cross3:before {
  content: '\fefe';
}

.isax-tag-right3:before {
  content: '\feff';
}

.isax-tag-user3:before {
  content: '\ff00';
}

.isax-task3:before {
  content: '\ff01';
}

.isax-task-square3:before {
  content: '\ff02';
}

.isax-teacher3:before {
  content: '\ff03';
}

.isax-text3:before {
  content: '\ff04';
}

.isax-textalign-center3:before {
  content: '\ff05';
}

.isax-textalign-justifycenter3:before {
  content: '\ff06';
}

.isax-textalign-justifyleft3:before {
  content: '\ff07';
}

.isax-textalign-justifyright3:before {
  content: '\ff08';
}

.isax-textalign-left3:before {
  content: '\ff09';
}

.isax-textalign-right3:before {
  content: '\ff0a';
}

.isax-text-block3:before {
  content: '\ff0b';
}

.isax-text-bold3:before {
  content: '\ff0c';
}

.isax-text-italic3:before {
  content: '\ff0d';
}

.isax-text-underline3:before {
  content: '\ff0e';
}

.isax-tick-circle3:before {
  content: '\ff0f';
}

.isax-ticket3:before {
  content: '\ff10';
}

.isax-ticket-23:before {
  content: '\ff11';
}

.isax-ticket-discount3:before {
  content: '\ff12';
}

.isax-ticket-expired3:before {
  content: '\ff13';
}

.isax-ticket-star3:before {
  content: '\ff14';
}

.isax-tick-square3:before {
  content: '\ff15';
}

.isax-timer3:before {
  content: '\ff16';
}

.isax-timer-13:before {
  content: '\ff17';
}

.isax-timer-pause3:before {
  content: '\ff18';
}

.isax-timer-start3:before {
  content: '\ff19';
}

.isax-toggle-off3:before {
  content: '\ff1a';
}

.isax-toggle-off-circle3:before {
  content: '\ff1b';
}

.isax-toggle-on3:before {
  content: '\ff1c';
}

.isax-toggle-on-circle3:before {
  content: '\ff1d';
}

.isax-trade3:before {
  content: '\ff1e';
}

.isax-transaction-minus3:before {
  content: '\ff1f';
}

.isax-translate3:before {
  content: '\ff20';
}

.isax-trash3:before {
  content: '\ff21';
}

.isax-tree3:before {
  content: '\ff22';
}

.isax-trend-down3:before {
  content: '\ff23';
}

.isax-trend-up3:before {
  content: '\ff24';
}

.isax-triangle3:before {
  content: '\ff25';
}

.isax-truck2:before {
  content: '\ff26';
}

.isax-truck-fast2:before {
  content: '\ff27';
}

.isax-truck-tick3:before {
  content: '\ff28';
}

.isax-trush-square3:before {
  content: '\ff29';
}

.isax-undo3:before {
  content: '\ff2a';
}

.isax-unlimited3:before {
  content: '\ff2b';
}

.isax-unlock3:before {
  content: '\ff2c';
}

.isax-user3:before {
  content: '\ff2d';
}

.isax-user-add3:before {
  content: '\ff2e';
}

.isax-user-cirlce-add3:before {
  content: '\ff2f';
}

.isax-user-edit3:before {
  content: '\ff30';
}

.isax-user-minus3:before {
  content: '\ff31';
}

.isax-user-octagon3:before {
  content: '\ff32';
}

.isax-user-remove3:before {
  content: '\ff33';
}

.isax-user-search3:before {
  content: '\ff34';
}

.isax-user-square3:before {
  content: '\ff35';
}

.isax-user-tag3:before {
  content: '\ff36';
}

.isax-user-tick3:before {
  content: '\ff37';
}

.isax-verify3:before {
  content: '\ff38';
}

.isax-video3:before {
  content: '\ff39';
}

.isax-video-add3:before {
  content: '\ff3a';
}

.isax-video-circle3:before {
  content: '\ff3b';
}

.isax-video-horizontal3:before {
  content: '\ff3c';
}

.isax-video-octagon3:before {
  content: '\ff3d';
}

.isax-video-play3:before {
  content: '\ff3e';
}

.isax-video-remove3:before {
  content: '\ff3f';
}

.isax-video-slash3:before {
  content: '\ff40';
}

.isax-video-square3:before {
  content: '\ff41';
}

.isax-video-tick3:before {
  content: '\ff42';
}

.isax-video-time3:before {
  content: '\ff43';
}

.isax-video-vertical3:before {
  content: '\ff44';
}

.isax-voice-cricle3:before {
  content: '\ff45';
}

.isax-voice-square3:before {
  content: '\ff46';
}

.isax-volume:before {
  content: '\ff47';
}

.isax-volume-cross3:before {
  content: '\ff48';
}

.isax-volume-high3:before {
  content: '\ff49';
}

.isax-volume-low3:before {
  content: '\ff4a';
}

.isax-volume-low-13:before {
  content: '\ff4b';
}

.isax-volume-mute3:before {
  content: '\ff4c';
}

.isax-volume-slash3:before {
  content: '\ff4d';
}

.isax-volume-up3:before {
  content: '\ff4e';
}

.isax-wallet3:before {
  content: '\ff4f';
}

.isax-wallet-13:before {
  content: '\ff50';
}

.isax-wallet-23:before {
  content: '\ff51';
}

.isax-wallet-33:before {
  content: '\ff52';
}

.isax-wallet-add3:before {
  content: '\ff53';
}

.isax-wallet-add-13:before {
  content: '\ff54';
}

.isax-wallet-add-2:before {
  content: '\ff55';
}

.isax-wallet-check3:before {
  content: '\ff56';
}

.isax-wallet-minus3:before {
  content: '\ff57';
}

.isax-wallet-money3:before {
  content: '\ff58';
}

.isax-wallet-remove3:before {
  content: '\ff59';
}

.isax-wallet-search3:before {
  content: '\ff5a';
}

.isax-warning-23:before {
  content: '\ff5b';
}

.isax-watch3:before {
  content: '\ff5c';
}

.isax-watch-status3:before {
  content: '\ff5d';
}

.isax-weight3:before {
  content: '\ff5e';
}

.isax-weight-13:before {
  content: '\ff5f';
}

.isax-wifi3:before {
  content: '\ff60';
}

.isax-wifi-square3:before {
  content: '\ff61';
}

.isax-wind3:before {
  content: '\ff62';
}

.isax-wind-23:before {
  content: '\ff63';
}

.isax-woman3:before {
  content: '\ff64';
}

.isax-d-cube-scan4:before {
  content: '\ff65';
}

.isax-d-rotate4:before {
  content: '\ff66';
}

.isax-d-square4:before {
  content: '\ff67';
}

.isax-square4:before {
  content: '\ff68';
}

.isax-support4:before {
  content: '\ff69';
}

.isax-activity4:before {
  content: '\ff6a';
}

.isax-add4:before {
  content: '\ff6b';
}

.isax-add-circle4:before {
  content: '\ff6c';
}

.isax-additem4:before {
  content: '\ff6d';
}

.isax-add-square4:before {
  content: '\ff6e';
}

.isax-airdrop4:before {
  content: '\ff6f';
}

.isax-airplane4:before {
  content: '\ff70';
}

.isax-airplane-square4:before {
  content: '\ff71';
}

.isax-airpod4:before {
  content: '\ff72';
}

.isax-airpods4:before {
  content: '\ff73';
}

.isax-alarm4:before {
  content: '\ff74';
}

.isax-align-bottom4:before {
  content: '\ff75';
}

.isax-align-horizontally4:before {
  content: '\ff76';
}

.isax-align-left4:before {
  content: '\ff77';
}

.isax-align-right4:before {
  content: '\ff78';
}

.isax-align-vertically4:before {
  content: '\ff79';
}

.isax-aquarius4:before {
  content: '\ff7a';
}

.isax-archive4:before {
  content: '\ff7b';
}

.isax-archive-14:before {
  content: '\ff7c';
}

.isax-archive-23:before {
  content: '\ff7d';
}

.isax-archive-add4:before {
  content: '\ff7e';
}

.isax-archive-book4:before {
  content: '\ff7f';
}

.isax-archive-minus4:before {
  content: '\ff80';
}

.isax-archive-slash4:before {
  content: '\ff81';
}

.isax-archive-tick4:before {
  content: '\ff82';
}

.isax-arrange-circle4:before {
  content: '\ff83';
}

.isax-arrange-circle-24:before {
  content: '\ff84';
}

.isax-arrange-square4:before {
  content: '\ff85';
}

.isax-arrange-square-24:before {
  content: '\ff86';
}

.isax-arrow4:before {
  content: '\ff87';
}

.isax-arrow-24:before {
  content: '\ff88';
}

.isax-arrow-34:before {
  content: '\ff89';
}

.isax-arrow-bottom4:before {
  content: '\ff8a';
}

.isax-arrow-circle-down4:before {
  content: '\ff8b';
}

.isax-arrow-circle-left4:before {
  content: '\ff8c';
}

.isax-arrow-circle-right4:before {
  content: '\ff8d';
}

.isax-arrow-circle-up3:before {
  content: '\ff8e';
}

.isax-arrow-down4:before {
  content: '\ff8f';
}

.isax-arrow-down-14:before {
  content: '\ff90';
}

.isax-arrow-down-24:before {
  content: '\ff91';
}

.isax-arrow-left4:before {
  content: '\ff92';
}

.isax-arrow-left-14:before {
  content: '\ff93';
}

.isax-arrow-left-24:before {
  content: '\ff94';
}

.isax-arrow-left-34:before {
  content: '\ff95';
}

.isax-arrow-right4:before {
  content: '\ff96';
}

.isax-arrow-right-14:before {
  content: '\ff97';
}

.isax-arrow-right-24:before {
  content: '\ff98';
}

.isax-arrow-right-34:before {
  content: '\ff99';
}

.isax-arrow-right-41:before {
  content: '\ff9a';
}

.isax-arrow-square4:before {
  content: '\ff9b';
}

.isax-arrow-square-down4:before {
  content: '\ff9c';
}

.isax-arrow-square-left4:before {
  content: '\ff9d';
}

.isax-arrow-square-up4:before {
  content: '\ff9e';
}

.isax-arrow-swap3:before {
  content: '\ff9f';
}

.isax-arrow-swap-horizontal4:before {
  content: '\ffa0';
}

.isax-arrow-up4:before {
  content: '\ffa1';
}

.isax-arrow-up-14:before {
  content: '\ffa2';
}

.isax-arrow-up-24:before {
  content: '\ffa3';
}

.isax-arrow-up-34:before {
  content: '\ffa4';
}

.isax-attach-circle4:before {
  content: '\ffa5';
}

.isax-attach-square4:before {
  content: '\ffa6';
}

.isax-audio-square4:before {
  content: '\ffa7';
}

.isax-autobrightness4:before {
  content: '\ffa8';
}

.isax-award4:before {
  content: '\ffa9';
}

.isax-back-square4:before {
  content: '\ffaa';
}

.isax-backward4:before {
  content: '\ffab';
}

.isax-backward-5-seconds4:before {
  content: '\ffac';
}

.isax-backward-10-seconds4:before {
  content: '\ffad';
}

.isax-backward-15-seconds4:before {
  content: '\ffae';
}

.isax-backward-item4:before {
  content: '\ffaf';
}

.isax-bag4:before {
  content: '\ffb0';
}

.isax-bag-24:before {
  content: '\ffb1';
}

.isax-bag-cross4:before {
  content: '\ffb2';
}

.isax-bag-cross-14:before {
  content: '\ffb3';
}

.isax-bag-happy4:before {
  content: '\ffb4';
}

.isax-bag-tick4:before {
  content: '\ffb5';
}

.isax-bag-tick-24:before {
  content: '\ffb6';
}

.isax-bag-timer4:before {
  content: '\ffb7';
}

.isax-bank4:before {
  content: '\ffb8';
}

.isax-barcode4:before {
  content: '\ffb9';
}

.isax-battery-3full4:before {
  content: '\ffba';
}

.isax-battery-charging4:before {
  content: '\ffbb';
}

.isax-battery-disable4:before {
  content: '\ffbc';
}

.isax-battery-empty4:before {
  content: '\ffbd';
}

.isax-battery-empty-14:before {
  content: '\ffbe';
}

.isax-battery-full4:before {
  content: '\ffbf';
}

.isax-bezier4:before {
  content: '\ffc0';
}

.isax-bill4:before {
  content: '\ffc1';
}

.isax-bitcoin-card4:before {
  content: '\ffc2';
}

.isax-bitcoin-convert4:before {
  content: '\ffc3';
}

.isax-bitcoin-refresh4:before {
  content: '\ffc4';
}

.isax-blend4:before {
  content: '\ffc5';
}

.isax-blend-24:before {
  content: '\ffc6';
}

.isax-bluetooth4:before {
  content: '\ffc7';
}

.isax-bluetooth-24:before {
  content: '\ffc8';
}

.isax-bluetooth-circle4:before {
  content: '\ffc9';
}

.isax-bluetooth-rectangle4:before {
  content: '\ffca';
}

.isax-blur4:before {
  content: '\ffcb';
}

.isax-book4:before {
  content: '\ffcc';
}

.isax-book-14:before {
  content: '\ffcd';
}

.isax-bookmark4:before {
  content: '\ffce';
}

.isax-bookmark-24:before {
  content: '\ffcf';
}

.isax-book-saved4:before {
  content: '\ffd0';
}

.isax-book-square4:before {
  content: '\ffd1';
}

.isax-box4:before {
  content: '\ffd2';
}

.isax-box-14:before {
  content: '\ffd3';
}

.isax-box-24:before {
  content: '\ffd4';
}

.isax-box-add4:before {
  content: '\ffd5';
}

.isax-box-remove4:before {
  content: '\ffd6';
}

.isax-box-search4:before {
  content: '\ffd7';
}

.isax-box-tick4:before {
  content: '\ffd8';
}

.isax-box-time4:before {
  content: '\ffd9';
}

.isax-briefcase4:before {
  content: '\ffda';
}

.isax-brifecase-cross4:before {
  content: '\ffdb';
}

.isax-brifecase-tick4:before {
  content: '\ffdc';
}

.isax-brifecase-timer4:before {
  content: '\ffdd';
}

.isax-broom4:before {
  content: '\ffde';
}

.isax-brush4:before {
  content: '\ffdf';
}

.isax-brush-14:before {
  content: '\ffe0';
}

.isax-brush-24:before {
  content: '\ffe1';
}

.isax-brush-34:before {
  content: '\ffe2';
}

.isax-brush-44:before {
  content: '\ffe3';
}

.isax-bubble4:before {
  content: '\ffe4';
}

.isax-bucket4:before {
  content: '\ffe5';
}

.isax-bucket-circle4:before {
  content: '\ffe6';
}

.isax-bucket-square4:before {
  content: '\ffe7';
}

.isax-building4:before {
  content: '\ffe8';
}

.isax-building-34:before {
  content: '\ffe9';
}

.isax-building-44:before {
  content: '\ffea';
}

.isax-buildings4:before {
  content: '\ffeb';
}

.isax-buildings-24:before {
  content: '\ffec';
}

.isax-buliding4:before {
  content: '\ffed';
}

.isax-bus4:before {
  content: '\ffee';
}

.isax-buy-crypto4:before {
  content: '\ffef';
}

.isax-cake4:before {
  content: '\fff0';
}

.isax-calculator4:before {
  content: '\fff1';
}

.isax-calendar4:before {
  content: '\fff2';
}

.isax-calendar-14:before {
  content: '\fff3';
}

.isax-calendar-24:before {
  content: '\fff4';
}

.isax-calendar-add4:before {
  content: '\fff5';
}

.isax-calendar-circle4:before {
  content: '\fff6';
}

.isax-calendar-edit4:before {
  content: '\fff7';
}

.isax-calendar-remove4:before {
  content: '\fff8';
}

.isax-calendar-search4:before {
  content: '\fff9';
}

.isax-calendar-tick4:before {
  content: '\fffa';
}

.isax-call4:before {
  content: '\fffb';
}

.isax-call-add4:before {
  content: '\fffc';
}

.isax-call-calling4:before {
  content: '\fffd';
}

.isax-call-incoming4:before {
  content: '\fffe';
}

.isax-call-minus4:before {
  content: '\0';
}

.isax-call-outgoing4:before {
  content: '\1';
}

.isax-call-received4:before {
  content: '\2';
}

.isax-call-remove4:before {
  content: '\3';
}

.isax-call-slash4:before {
  content: '\4';
}

.isax-camera4:before {
  content: '\5';
}

.isax-camera-slash4:before {
  content: '\6';
}

.isax-candle4:before {
  content: '\7';
}

.isax-candle-24:before {
  content: '\8';
}

.isax-car4:before {
  content: '\9';
}

.isax-card4:before {
  content: '\a';
}

.isax-card-add4:before {
  content: '\b';
}

.isax-card-coin4:before {
  content: '\c';
}

.isax-card-edit4:before {
  content: '\d';
}

.isax-card-pos4:before {
  content: '\e';
}

.isax-card-receive4:before {
  content: '\f';
}

.isax-card-remove4:before {
  content: '\10';
}

.isax-card-remove-14:before {
  content: '\11';
}

.isax-cards4:before {
  content: '\12';
}

.isax-card-send4:before {
  content: '\13';
}

.isax-card-slash4:before {
  content: '\14';
}

.isax-card-tick4:before {
  content: '\15';
}

.isax-card-tick-14:before {
  content: '\16';
}

.isax-category4:before {
  content: '\17';
}

.isax-category-24:before {
  content: '\18';
}

.isax-cd4:before {
  content: '\19';
}

.isax-chart4:before {
  content: '\1a';
}

.isax-chart-14:before {
  content: '\1b';
}

.isax-chart-25:before {
  content: '\1c';
}

.isax-chart-34:before {
  content: '\1d';
}

.isax-chart-214:before {
  content: '\1e';
}

.isax-chart-fail2:before {
  content: '\1f';
}

.isax-chart-square4:before {
  content: '\20';
}

.isax-chart-success4:before {
  content: '\21';
}

.isax-check4:before {
  content: '\22';
}

.isax-chrome4:before {
  content: '\23';
}

.isax-clipboard4:before {
  content: '\24';
}

.isax-clipboard-close4:before {
  content: '\25';
}

.isax-clipboard-export4:before {
  content: '\26';
}

.isax-clipboard-import4:before {
  content: '\27';
}

.isax-clipboard-text4:before {
  content: '\28';
}

.isax-clipboard-tick4:before {
  content: '\29';
}

.isax-clock4:before {
  content: '\2a';
}

.isax-clock-14:before {
  content: '\2b';
}

.isax-close-circle4:before {
  content: '\2c';
}

.isax-close-square4:before {
  content: '\2d';
}

.isax-cloud4:before {
  content: '\2e';
}

.isax-cloud-add4:before {
  content: '\2f';
}

.isax-cloud-change4:before {
  content: '\30';
}

.isax-cloud-connection4:before {
  content: '\31';
}

.isax-cloud-cross4:before {
  content: '\32';
}

.isax-cloud-drizzle4:before {
  content: '\33';
}

.isax-cloud-fog4:before {
  content: '\34';
}

.isax-cloud-lightning4:before {
  content: '\35';
}

.isax-cloud-minus4:before {
  content: '\36';
}

.isax-cloud-notif4:before {
  content: '\37';
}

.isax-cloud-plus4:before {
  content: '\38';
}

.isax-cloud-remove4:before {
  content: '\39';
}

.isax-cloud-snow4:before {
  content: '\3a';
}

.isax-cloud-sunny4:before {
  content: '\3b';
}

.isax-code4:before {
  content: '\3c';
}

.isax-code-14:before {
  content: '\3d';
}

.isax-code-circle4:before {
  content: '\3e';
}

.isax-coffee4:before {
  content: '\3f';
}

.isax-coin4:before {
  content: '\40';
}

.isax-coin-14:before {
  content: '\41';
}

.isax-colorfilter4:before {
  content: '\42';
}

.isax-colors-square4:before {
  content: '\43';
}

.isax-color-swatch4:before {
  content: '\44';
}

.isax-command4:before {
  content: '\45';
}

.isax-command-square4:before {
  content: '\46';
}

.isax-component4:before {
  content: '\47';
}

.isax-computing4:before {
  content: '\48';
}

.isax-convert4:before {
  content: '\49';
}

.isax-convert-3d-cube4:before {
  content: '\4a';
}

.isax-convert-card4:before {
  content: '\4b';
}

.isax-convertshape4:before {
  content: '\4c';
}

.isax-convertshape-24:before {
  content: '\4d';
}

.isax-copy4:before {
  content: '\4e';
}

.isax-copyright4:before {
  content: '\4f';
}

.isax-copy-success4:before {
  content: '\50';
}

.isax-courthouse4:before {
  content: '\51';
}

.isax-cpu4:before {
  content: '\52';
}

.isax-cpu-charge4:before {
  content: '\53';
}

.isax-cpu-setting4:before {
  content: '\54';
}

.isax-creative-commons4:before {
  content: '\55';
}

.isax-crop4:before {
  content: '\56';
}

.isax-crown4:before {
  content: '\57';
}

.isax-crown-14:before {
  content: '\58';
}

.isax-cup4:before {
  content: '\59';
}

.isax-danger4:before {
  content: '\5a';
}

.isax-data4:before {
  content: '\5b';
}

.isax-data-24 .path1:before {
  content: '\5c';
  color: rgb(41, 45, 50);
}

.isax-data-24 .path2:before {
  content: '\666';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-data-24 .path3:before {
  content: '\667';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-data-24 .path4:before {
  content: '\668';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-data-24 .path5:before {
  content: '\669';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.isax-data-24 .path6:before {
  content: '\66a';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-data-24 .path7:before {
  content: '\66b';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-data-24 .path8:before {
  content: '\66c';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-designtools4:before {
  content: '\5d';
}

.isax-device-message4:before {
  content: '\5e';
}

.isax-devices4:before {
  content: '\5f';
}

.isax-devices-13:before {
  content: '\60';
}

.isax-diagram4:before {
  content: '\61';
}

.isax-diamonds4:before {
  content: '\62';
}

.isax-direct4:before {
  content: '\63';
}

.isax-directbox-default4:before {
  content: '\64';
}

.isax-directbox-notif4:before {
  content: '\65';
}

.isax-directbox-receive4:before {
  content: '\66';
}

.isax-directbox-send4:before {
  content: '\67';
}

.isax-direct-down4:before {
  content: '\68';
}

.isax-direct-inbox4:before {
  content: '\69';
}

.isax-direct-left4:before {
  content: '\6a';
}

.isax-direct-normal4:before {
  content: '\6b';
}

.isax-direct-notification4:before {
  content: '\6c';
}

.isax-direct-right4:before {
  content: '\6d';
}

.isax-direct-send4:before {
  content: '\6e';
}

.isax-direct-up4:before {
  content: '\6f';
}

.isax-discount-circle4:before {
  content: '\70';
}

.isax-discount-shape4:before {
  content: '\71';
}

.isax-discover4:before {
  content: '\72';
}

.isax-discover-13:before {
  content: '\73';
}

.isax-dislike4:before {
  content: '\74';
}

.isax-document4:before {
  content: '\75';
}

.isax-document-14:before {
  content: '\76';
}

.isax-document-cloud4:before {
  content: '\77';
}

.isax-document-code4:before {
  content: '\78';
}

.isax-document-code-24:before {
  content: '\79';
}

.isax-document-copy4:before {
  content: '\7a';
}

.isax-document-download4:before {
  content: '\7b';
}

.isax-document-favorite4:before {
  content: '\7c';
}

.isax-document-filter4:before {
  content: '\7d';
}

.isax-document-forward4:before {
  content: '\7e';
}

.isax-document-like4:before {
  content: '\7f';
}

.isax-document-normal3:before {
  content: '\80';
}

.isax-document-previous4:before {
  content: '\81';
}

.isax-document-sketch4:before {
  content: '\82';
}

.isax-document-text4:before {
  content: '\83';
}

.isax-document-text-14:before {
  content: '\84';
}

.isax-document-upload4:before {
  content: '\85';
}

.isax-dollar-circle3:before {
  content: '\86';
}

.isax-dollar-square4:before {
  content: '\87';
}

.isax-driver4:before {
  content: '\88';
}

.isax-driver-24:before {
  content: '\89';
}

.isax-driver-refresh4:before {
  content: '\8a';
}

.isax-driving4:before {
  content: '\8b';
}

.isax-edit4:before {
  content: '\8c';
}

.isax-edit-24:before {
  content: '\8d';
}

.isax-electricity4:before {
  content: '\8e';
}

.isax-element-24:before {
  content: '\8f';
}

.isax-element-34:before {
  content: '\90';
}

.isax-element-44:before {
  content: '\91';
}

.isax-element-equal4:before {
  content: '\92';
}

.isax-element-plus4:before {
  content: '\93';
}

.isax-emoji-happy4:before {
  content: '\94';
}

.isax-emoji-normal4:before {
  content: '\95';
}

.isax-emoji-sad4:before {
  content: '\96';
}

.isax-empty-wallet4:before {
  content: '\97';
}

.isax-empty-wallet-add4:before {
  content: '\98';
}

.isax-empty-wallet-change3:before {
  content: '\99';
}

.isax-empty-wallet-remove4:before {
  content: '\9a';
}

.isax-empty-wallet-tick4:before {
  content: '\9b';
}

.isax-empty-wallet-time3:before {
  content: '\9c';
}

.isax-eraser4:before {
  content: '\9d';
}

.isax-eraser-14:before {
  content: '\9e';
}

.isax-export4:before {
  content: '\9f';
}

.isax-export-14:before {
  content: '\a0';
}

.isax-export-24:before {
  content: '\a1';
}

.isax-export-34:before {
  content: '\a2';
}

.isax-external-drive4:before {
  content: '\a3';
}

.isax-eye3:before {
  content: '\a4';
}

.isax-eye-slash4:before {
  content: '\a5';
}

.isax-fatrows4:before {
  content: '\a6';
}

.isax-favorite-chart4:before {
  content: '\a7';
}

.isax-filter4:before {
  content: '\a8';
}

.isax-filter-add4:before {
  content: '\a9';
}

.isax-filter-edit4:before {
  content: '\aa';
}

.isax-filter-remove4:before {
  content: '\ab';
}

.isax-filter-search4:before {
  content: '\ac';
}

.isax-filter-square4:before {
  content: '\ad';
}

.isax-filter-tick4:before {
  content: '\ae';
}

.isax-finger-cricle4:before {
  content: '\af';
}

.isax-finger-scan4:before {
  content: '\b0';
}

.isax-firstline4:before {
  content: '\b1';
}

.isax-flag4:before {
  content: '\b2';
}

.isax-flag-24:before {
  content: '\b3';
}

.isax-flash4:before {
  content: '\b4';
}

.isax-flash-14:before {
  content: '\b5';
}

.isax-flash-circle4:before {
  content: '\b6';
}

.isax-flash-circle-14:before {
  content: '\b7';
}

.isax-flash-slash4:before {
  content: '\b8';
}

.isax-folder4:before {
  content: '\b9';
}

.isax-folder-24:before {
  content: '\ba';
}

.isax-folder-add4:before {
  content: '\bb';
}

.isax-folder-cloud4:before {
  content: '\bc';
}

.isax-folder-connection4:before {
  content: '\bd';
}

.isax-folder-cross4:before {
  content: '\be';
}

.isax-folder-favorite4:before {
  content: '\bf';
}

.isax-folder-minus4:before {
  content: '\c0';
}

.isax-folder-open4:before {
  content: '\c1';
}

.isax-forbidden4:before {
  content: '\c2';
}

.isax-forbidden-24:before {
  content: '\c3';
}

.isax-format-circle4:before {
  content: '\c4';
}

.isax-format-square4:before {
  content: '\c5';
}

.isax-forward4:before {
  content: '\c6';
}

.isax-forward-5-seconds4:before {
  content: '\c7';
}

.isax-forward-10-seconds4:before {
  content: '\c8';
}

.isax-forward-15-seconds4:before {
  content: '\c9';
}

.isax-forward-item4:before {
  content: '\ca';
}

.isax-forward-square4:before {
  content: '\cb';
}

.isax-frame4:before {
  content: '\cc';
}

.isax-frame-14:before {
  content: '\cd';
}

.isax-frame-24:before {
  content: '\ce';
}

.isax-frame-34:before {
  content: '\cf';
}

.isax-frame-44:before {
  content: '\d0';
}

.isax-gallery4:before {
  content: '\d1';
}

.isax-gallery-add4:before {
  content: '\d2';
}

.isax-gallery-edit4:before {
  content: '\d3';
}

.isax-gallery-export3:before {
  content: '\d4';
}

.isax-gallery-favorite4:before {
  content: '\d5';
}

.isax-gallery-import4:before {
  content: '\d6';
}

.isax-gallery-remove4:before {
  content: '\d7';
}

.isax-gallery-slash4:before {
  content: '\d8';
}

.isax-gallery-tick4:before {
  content: '\d9';
}

.isax-game4:before {
  content: '\da';
}

.isax-gameboy4:before {
  content: '\db';
}

.isax-gas-station3:before {
  content: '\dc';
}

.isax-gemini4:before {
  content: '\dd';
}

.isax-gemini-24:before {
  content: '\de';
}

.isax-ghost4:before {
  content: '\df';
}

.isax-gift4:before {
  content: '\e0';
}

.isax-glass4:before {
  content: '\e1';
}

.isax-glass-14:before {
  content: '\e2';
}

.isax-global4:before {
  content: '\e3';
}

.isax-global-edit4:before {
  content: '\e4';
}

.isax-global-refresh4:before {
  content: '\e5';
}

.isax-global-search4:before {
  content: '\e6';
}

.isax-gps4:before {
  content: '\e7';
}

.isax-gps-slash4:before {
  content: '\e8';
}

.isax-grammerly4:before {
  content: '\e9';
}

.isax-graph4:before {
  content: '\ea';
}

.isax-grid-14:before {
  content: '\eb';
}

.isax-grid-24:before {
  content: '\ec';
}

.isax-grid-34:before {
  content: '\ed';
}

.isax-grid-44:before {
  content: '\ee';
}

.isax-grid-54:before {
  content: '\ef';
}

.isax-grid-64:before {
  content: '\f0';
}

.isax-grid-74:before {
  content: '\f1';
}

.isax-grid-84:before {
  content: '\f2';
}

.isax-grid-94:before {
  content: '\f3';
}

.isax-grid-edit4:before {
  content: '\f4';
}

.isax-grid-eraser4:before {
  content: '\f5';
}

.isax-grid-lock4:before {
  content: '\f6';
}

.isax-happyemoji4:before {
  content: '\f7';
}

.isax-hashtag4:before {
  content: '\f8';
}

.isax-hashtag-14:before {
  content: '\f9';
}

.isax-hashtag-down4:before {
  content: '\fa';
}

.isax-hashtag-up4:before {
  content: '\fb';
}

.isax-headphone4:before {
  content: '\fc';
}

.isax-headphones4:before {
  content: '\fd';
}

.isax-health4:before {
  content: '\fe';
}

.isax-heart4:before {
  content: '\ff';
}

.isax-heart-add4:before {
  content: '\100';
}

.isax-heart-circle4:before {
  content: '\101';
}

.isax-heart-edit4:before {
  content: '\102';
}

.isax-heart-remove4:before {
  content: '\103';
}

.isax-heart-search4:before {
  content: '\104';
}

.isax-heart-slash4:before {
  content: '\105';
}

.isax-heart-tick4:before {
  content: '\106';
}

.isax-hierarchy4:before {
  content: '\107';
}

.isax-hierarchy-24:before {
  content: '\108';
}

.isax-hierarchy-34:before {
  content: '\109';
}

.isax-hierarchy-square4:before {
  content: '\10a';
}

.isax-hierarchy-square-24:before {
  content: '\10b';
}

.isax-hierarchy-square-34:before {
  content: '\10c';
}

.isax-home4:before {
  content: '\10d';
}

.isax-home-14:before {
  content: '\10e';
}

.isax-home-24:before {
  content: '\10f';
}

.isax-home-hashtag4:before {
  content: '\110';
}

.isax-home-trend-down4:before {
  content: '\111';
}

.isax-home-trend-up4:before {
  content: '\112';
}

.isax-home-wifi4:before {
  content: '\113';
}

.isax-hospital4:before {
  content: '\114';
}

.isax-house4:before {
  content: '\115';
}

.isax-house-24:before {
  content: '\116';
}

.isax-icon3:before {
  content: '\117';
}

.isax-image4:before {
  content: '\118';
}

.isax-import4:before {
  content: '\119';
}

.isax-import-14:before {
  content: '\11a';
}

.isax-import-24:before {
  content: '\11b';
}

.isax-info-circle4:before {
  content: '\11c';
}

.isax-information4:before {
  content: '\11d';
}

.isax-instagram4:before {
  content: '\11e';
}

.isax-judge4:before {
  content: '\11f';
}

.isax-kanban3:before {
  content: '\120';
}

.isax-key4:before {
  content: '\121';
}

.isax-keyboard4:before {
  content: '\122';
}

.isax-keyboard-open4:before {
  content: '\123';
}

.isax-key-square4:before {
  content: '\124';
}

.isax-lamp4:before {
  content: '\125';
}

.isax-lamp-14:before {
  content: '\126';
}

.isax-lamp-charge4:before {
  content: '\127';
}

.isax-lamp-on4:before {
  content: '\128';
}

.isax-lamp-slash4:before {
  content: '\129';
}

.isax-language-circle4:before {
  content: '\12a';
}

.isax-language-square4:before {
  content: '\12b';
}

.isax-layer4:before {
  content: '\12c';
}

.isax-level4:before {
  content: '\12d';
}

.isax-lifebuoy4:before {
  content: '\12e';
}

.isax-like4:before {
  content: '\12f';
}

.isax-like-14:before {
  content: '\130';
}

.isax-like-dislike4:before {
  content: '\131';
}

.isax-like-shapes4:before {
  content: '\132';
}

.isax-like-tag4:before {
  content: '\133';
}

.isax-link4:before {
  content: '\134';
}

.isax-link-14:before {
  content: '\135';
}

.isax-link-25:before {
  content: '\136';
}

.isax-link-214:before {
  content: '\137';
}

.isax-link-circle4:before {
  content: '\138';
}

.isax-link-square4:before {
  content: '\139';
}

.isax-location4:before {
  content: '\13a';
}

.isax-location-add4:before {
  content: '\13b';
}

.isax-location-cross4:before {
  content: '\13c';
}

.isax-location-minus4:before {
  content: '\13d';
}

.isax-location-slash4:before {
  content: '\13e';
}

.isax-location-tick4:before {
  content: '\13f';
}

.isax-lock4:before {
  content: '\140';
}

.isax-lock-14:before {
  content: '\141';
}

.isax-lock-circle4:before {
  content: '\142';
}

.isax-lock-slash4:before {
  content: '\143';
}

.isax-login4:before {
  content: '\144';
}

.isax-login-14:before {
  content: '\145';
}

.isax-logout4:before {
  content: '\146';
}

.isax-logout-14:before {
  content: '\147';
}

.isax-lovely4:before {
  content: '\148';
}

.isax-magicpen4:before {
  content: '\149';
}

.isax-magic-star4:before {
  content: '\14a';
}

.isax-main-component4:before {
  content: '\14b';
}

.isax-man4:before {
  content: '\14c';
}

.isax-map4:before {
  content: '\14d';
}

.isax-map-14:before {
  content: '\14e';
}

.isax-mask4:before {
  content: '\14f';
}

.isax-mask-14:before {
  content: '\150';
}

.isax-mask-24:before {
  content: '\151';
}

.isax-math4:before {
  content: '\152';
}

.isax-maximize4:before {
  content: '\153';
}

.isax-maximize-14:before {
  content: '\154';
}

.isax-maximize-25:before {
  content: '\155';
}

.isax-maximize-34:before {
  content: '\156';
}

.isax-maximize-44:before {
  content: '\157';
}

.isax-maximize-214:before {
  content: '\158';
}

.isax-maximize-circle3:before {
  content: '\159';
}

.isax-medal4:before {
  content: '\15a';
}

.isax-medal-star4:before {
  content: '\15b';
}

.isax-menu4:before {
  content: '\15c';
}

.isax-menu-14:before {
  content: '\15d';
}

.isax-menu-board4:before {
  content: '\15e';
}

.isax-message4:before {
  content: '\15f';
}

.isax-message-24:before {
  content: '\160';
}

.isax-message-add4:before {
  content: '\161';
}

.isax-message-add-14:before {
  content: '\162';
}

.isax-message-circle4:before {
  content: '\163';
}

.isax-message-edit4:before {
  content: '\164';
}

.isax-message-favorite4:before {
  content: '\165';
}

.isax-message-minus4:before {
  content: '\166';
}

.isax-message-notif4:before {
  content: '\167';
}

.isax-message-programming4:before {
  content: '\168';
}

.isax-message-question4:before {
  content: '\169';
}

.isax-message-remove4:before {
  content: '\16a';
}

.isax-messages4:before {
  content: '\16b';
}

.isax-messages-14:before {
  content: '\16c';
}

.isax-messages-24:before {
  content: '\16d';
}

.isax-messages-34:before {
  content: '\16e';
}

.isax-message-search4:before {
  content: '\16f';
}

.isax-message-square4:before {
  content: '\170';
}

.isax-message-text4:before {
  content: '\171';
}

.isax-message-text-14:before {
  content: '\172';
}

.isax-message-tick4:before {
  content: '\173';
}

.isax-message-time4:before {
  content: '\174';
}

.isax-microphone4:before {
  content: '\175';
}

.isax-microphone-24:before {
  content: '\176';
}

.isax-microphone-slash4:before {
  content: '\177';
}

.isax-microphone-slash-14:before {
  content: '\178';
}

.isax-microscope4:before {
  content: '\179';
}

.isax-milk4:before {
  content: '\17a';
}

.isax-mini-music-sqaure4:before {
  content: '\17b';
}

.isax-minus4:before {
  content: '\17c';
}

.isax-minus-cirlce4:before {
  content: '\17d';
}

.isax-minus-square4:before {
  content: '\17e';
}

.isax-mirror4:before {
  content: '\17f';
}

.isax-mirroring-screen4:before {
  content: '\180';
}

.isax-mobile4:before {
  content: '\181';
}

.isax-mobile-programming4:before {
  content: '\182';
}

.isax-money4:before {
  content: '\183';
}

.isax-money-24:before {
  content: '\184';
}

.isax-money-34:before {
  content: '\185';
}

.isax-money-44:before {
  content: '\186';
}

.isax-money-add4:before {
  content: '\187';
}

.isax-money-change4:before {
  content: '\188';
}

.isax-money-forbidden4:before {
  content: '\189';
}

.isax-money-recive4:before {
  content: '\18a';
}

.isax-money-remove4:before {
  content: '\18b';
}

.isax-moneys4:before {
  content: '\18c';
}

.isax-money-send4:before {
  content: '\18d';
}

.isax-money-tick4:before {
  content: '\18e';
}

.isax-money-time4:before {
  content: '\18f';
}

.isax-monitor4:before {
  content: '\190';
}

.isax-monitor-mobbile4:before {
  content: '\191';
}

.isax-monitor-recorder4:before {
  content: '\192';
}

.isax-moon4:before {
  content: '\193';
}

.isax-more4:before {
  content: '\194';
}

.isax-more-24:before {
  content: '\195';
}

.isax-more-circle4:before {
  content: '\196';
}

.isax-more-square4:before {
  content: '\197';
}

.isax-mouse4:before {
  content: '\198';
}

.isax-mouse-14:before {
  content: '\199';
}

.isax-mouse-circle4:before {
  content: '\19a';
}

.isax-mouse-square4:before {
  content: '\19b';
}

.isax-music4:before {
  content: '\19c';
}

.isax-music-circle4:before {
  content: '\19d';
}

.isax-music-dashboard4:before {
  content: '\19e';
}

.isax-music-filter4:before {
  content: '\19f';
}

.isax-music-library-24:before {
  content: '\1a0';
}

.isax-musicnote4:before {
  content: '\1a1';
}

.isax-music-play4:before {
  content: '\1a2';
}

.isax-music-playlist4:before {
  content: '\1a3';
}

.isax-music-square4:before {
  content: '\1a4';
}

.isax-music-square-add4:before {
  content: '\1a5';
}

.isax-music-square-remove4:before {
  content: '\1a6';
}

.isax-music-square-search4:before {
  content: '\1a7';
}

.isax-next4:before {
  content: '\1a8';
}

.isax-note4:before {
  content: '\1a9';
}

.isax-note-14:before {
  content: '\1aa';
}

.isax-note-25:before {
  content: '\1ab';
}

.isax-note-214:before {
  content: '\1ac';
}

.isax-note-add4:before {
  content: '\1ad';
}

.isax-note-favorite4:before {
  content: '\1ae';
}

.isax-note-remove4:before {
  content: '\1af';
}

.isax-note-square4:before {
  content: '\1b0';
}

.isax-note-text4:before {
  content: '\1b1';
}

.isax-notification4:before {
  content: '\1b2';
}

.isax-notification-14:before {
  content: '\1b3';
}

.isax-notification-bing4:before {
  content: '\1b4';
}

.isax-notification-circle4:before {
  content: '\1b5';
}

.isax-notification-favorite4:before {
  content: '\1b6';
}

.isax-notification-status4:before {
  content: '\1b7';
}

.isax-omega-circle4:before {
  content: '\1b8';
}

.isax-omega-square4:before {
  content: '\1b9';
}

.isax-paintbucket4:before {
  content: '\1ba';
}

.isax-paperclip4:before {
  content: '\1bb';
}

.isax-paperclip-24:before {
  content: '\1bc';
}

.isax-password-check4:before {
  content: '\1bd';
}

.isax-path4:before {
  content: '\1be';
}

.isax-path-24:before {
  content: '\1bf';
}

.isax-path-square4:before {
  content: '\1c0';
}

.isax-pause4:before {
  content: '\1c1';
}

.isax-pause-circle4:before {
  content: '\1c2';
}

.isax-pen-add4:before {
  content: '\1c3';
}

.isax-pen-close4:before {
  content: '\1c4';
}

.isax-pen-remove4:before {
  content: '\1c5';
}

.isax-pen-tool4:before {
  content: '\1c6';
}

.isax-pen-tool-24:before {
  content: '\1c7';
}

.isax-people4:before {
  content: '\1c8';
}

.isax-percentage-circle4:before {
  content: '\1c9';
}

.isax-percentage-square4:before {
  content: '\1ca';
}

.isax-personalcard4:before {
  content: '\1cb';
}

.isax-pet4:before {
  content: '\1cc';
}

.isax-pharagraphspacing4:before {
  content: '\1cd';
}

.isax-picture-frame4:before {
  content: '\1ce';
}

.isax-play4:before {
  content: '\1cf';
}

.isax-play-add4:before {
  content: '\1d0';
}

.isax-play-circle4:before {
  content: '\1d1';
}

.isax-play-cricle4:before {
  content: '\1d2';
}

.isax-play-remove4:before {
  content: '\1d3';
}

.isax-presention-chart4:before {
  content: '\1d4';
}

.isax-previous4:before {
  content: '\1d5';
}

.isax-printer4:before {
  content: '\1d6';
}

.isax-printer-slash4:before {
  content: '\1d7';
}

.isax-profile-2user4:before {
  content: '\1d8';
}

.isax-profile-add4:before {
  content: '\1d9';
}

.isax-profile-circle4:before {
  content: '\1da';
}

.isax-profile-delete4:before {
  content: '\1db';
}

.isax-profile-remove4:before {
  content: '\1dc';
}

.isax-profile-tick4:before {
  content: '\1dd';
}

.isax-programming-arrow4:before {
  content: '\1de';
}

.isax-programming-arrows4:before {
  content: '\1df';
}

.isax-quote-down4:before {
  content: '\1e0';
}

.isax-quote-down-circle4:before {
  content: '\1e1';
}

.isax-quote-down-square4:before {
  content: '\1e2';
}

.isax-quote-up4:before {
  content: '\1e3';
}

.isax-quote-up-circle4:before {
  content: '\1e4';
}

.isax-quote-up-square4:before {
  content: '\1e5';
}

.isax-radar4:before {
  content: '\1e6';
}

.isax-radar-14:before {
  content: '\1e7';
}

.isax-radar-24:before {
  content: '\1e8';
}

.isax-radio4:before {
  content: '\1e9';
}

.isax-ram4:before {
  content: '\1ea';
}

.isax-ram-24:before {
  content: '\1eb';
}

.isax-ranking4:before {
  content: '\1ec';
}

.isax-ranking-14:before {
  content: '\1ed';
}

.isax-receipt4:before {
  content: '\1ee';
}

.isax-receipt-14:before {
  content: '\1ef';
}

.isax-receipt-24:before {
  content: '\1f0';
}

.isax-receipt-2-14:before {
  content: '\1f1';
}

.isax-receipt-add4:before {
  content: '\1f2';
}

.isax-receipt-discount4:before {
  content: '\1f3';
}

.isax-receipt-disscount4:before {
  content: '\1f4';
}

.isax-receipt-edit4:before {
  content: '\1f5';
}

.isax-receipt-item4:before {
  content: '\1f6';
}

.isax-receipt-minus4:before {
  content: '\1f7';
}

.isax-receipt-search4:before {
  content: '\1f8';
}

.isax-receipt-square4:before {
  content: '\1f9';
}

.isax-receipt-text4:before {
  content: '\1fa';
}

.isax-received4:before {
  content: '\1fb';
}

.isax-receive-square4:before {
  content: '\1fc';
}

.isax-receive-square-24:before {
  content: '\1fd';
}

.isax-record4:before {
  content: '\1fe';
}

.isax-record-circle4:before {
  content: '\1ff';
}

.isax-recovery-convert4:before {
  content: '\200';
}

.isax-redo4:before {
  content: '\201';
}

.isax-refresh4:before {
  content: '\202';
}

.isax-refresh-24:before {
  content: '\203';
}

.isax-refresh-circle4:before {
  content: '\204';
}

.isax-refresh-left-square4:before {
  content: '\205';
}

.isax-refresh-right-square4:before {
  content: '\206';
}

.isax-refresh-square-24:before {
  content: '\207';
}

.isax-repeat4:before {
  content: '\208';
}

.isax-repeat-circle4:before {
  content: '\209';
}

.isax-repeate-music4:before {
  content: '\20a';
}

.isax-repeate-one4:before {
  content: '\20b';
}

.isax-reserve4:before {
  content: '\20c';
}

.isax-rotate-left4:before {
  content: '\20d';
}

.isax-rotate-left-14:before {
  content: '\20e';
}

.isax-rotate-right4:before {
  content: '\20f';
}

.isax-rotate-right-14:before {
  content: '\210';
}

.isax-route-square4:before {
  content: '\211';
}

.isax-routing4:before {
  content: '\212';
}

.isax-routing-24:before {
  content: '\213';
}

.isax-row-horizontal4:before {
  content: '\214';
}

.isax-row-vertical4:before {
  content: '\215';
}

.isax-rulerpen4:before {
  content: '\216';
}

.isax-ruler4:before {
  content: '\217';
}

.isax-safe-home4:before {
  content: '\218';
}

.isax-sagittarius4:before {
  content: '\219';
}

.isax-save-24:before {
  content: '\21a';
}

.isax-save-add4:before {
  content: '\21b';
}

.isax-save-minus4:before {
  content: '\21c';
}

.isax-save-remove4:before {
  content: '\21d';
}

.isax-scan4:before {
  content: '\21e';
}

.isax-scan-barcode4:before {
  content: '\21f';
}

.isax-scanner4:before {
  content: '\220';
}

.isax-scanning4:before {
  content: '\221';
}

.isax-scissor4:before {
  content: '\222';
}

.isax-scissor-14:before {
  content: '\223';
}

.isax-screenmirroring4:before {
  content: '\224';
}

.isax-scroll4:before {
  content: '\225';
}

.isax-search-favorite4:before {
  content: '\226';
}

.isax-search-favorite-14:before {
  content: '\227';
}

.isax-search-normal4:before {
  content: '\228';
}

.isax-search-normal-14:before {
  content: '\229';
}

.isax-search-status4:before {
  content: '\22a';
}

.isax-search-status-14:before {
  content: '\22b';
}

.isax-search-zoom-in4:before {
  content: '\22c';
}

.isax-search-zoom-in-14:before {
  content: '\22d';
}

.isax-search-zoom-out4:before {
  content: '\22e';
}

.isax-search-zoom-out-14:before {
  content: '\22f';
}

.isax-security4:before {
  content: '\230';
}

.isax-security-card4:before {
  content: '\231';
}

.isax-security-safe4:before {
  content: '\232';
}

.isax-security-time4:before {
  content: '\233';
}

.isax-security-user4:before {
  content: '\234';
}

.isax-send4:before {
  content: '\235';
}

.isax-send-14:before {
  content: '\236';
}

.isax-send-24:before {
  content: '\237';
}

.isax-send-sqaure-24:before {
  content: '\238';
}

.isax-send-square4:before {
  content: '\239';
}

.isax-setting4:before {
  content: '\23a';
}

.isax-setting-24:before {
  content: '\23b';
}

.isax-setting-34:before {
  content: '\23c';
}

.isax-setting-44:before {
  content: '\23d';
}

.isax-setting-54:before {
  content: '\23e';
}

.isax-settings4:before {
  content: '\23f';
}

.isax-shapes4:before {
  content: '\240';
}

.isax-shapes-14:before {
  content: '\241';
}

.isax-share4:before {
  content: '\242';
}

.isax-shield3:before {
  content: '\243';
}

.isax-shield-cross4:before {
  content: '\244';
}

.isax-shield-search4:before {
  content: '\245';
}

.isax-shield-slash4:before {
  content: '\246';
}

.isax-shield-tick4:before {
  content: '\247';
}

.isax-ship4:before {
  content: '\248';
}

.isax-shop4:before {
  content: '\249';
}

.isax-shop-add4:before {
  content: '\24a';
}

.isax-shopping-bag4:before {
  content: '\24b';
}

.isax-shopping-cart4:before {
  content: '\24c';
}

.isax-shop-remove4:before {
  content: '\24d';
}

.isax-shuffle4:before {
  content: '\24e';
}

.isax-sidebar-bottom4:before {
  content: '\24f';
}

.isax-sidebar-left4:before {
  content: '\250';
}

.isax-sidebar-right4:before {
  content: '\251';
}

.isax-sidebar-top4:before {
  content: '\252';
}

.isax-signpost4:before {
  content: '\253';
}

.isax-simcard4:before {
  content: '\254';
}

.isax-simcard-14:before {
  content: '\255';
}

.isax-simcard-24:before {
  content: '\256';
}

.isax-size4:before {
  content: '\257';
}

.isax-slash4:before {
  content: '\258';
}

.isax-slider4:before {
  content: '\259';
}

.isax-slider-horizontal4:before {
  content: '\25a';
}

.isax-slider-horizontal-14:before {
  content: '\25b';
}

.isax-slider-vertical4:before {
  content: '\25c';
}

.isax-slider-vertical-14:before {
  content: '\25d';
}

.isax-smallcaps4:before {
  content: '\25e';
}

.isax-smart-car4:before {
  content: '\25f';
}

.isax-smart-home4:before {
  content: '\260';
}

.isax-smileys4:before {
  content: '\261';
}

.isax-sms4:before {
  content: '\262';
}

.isax-sms-edit4:before {
  content: '\263';
}

.isax-sms-notification4:before {
  content: '\264';
}

.isax-sms-search4:before {
  content: '\265';
}

.isax-sms-star4:before {
  content: '\266';
}

.isax-sms-tracking4:before {
  content: '\267';
}

.isax-sort4:before {
  content: '\268';
}

.isax-sound4:before {
  content: '\269';
}

.isax-speaker4:before {
  content: '\26a';
}

.isax-speedometer4:before {
  content: '\26b';
}

.isax-star4:before {
  content: '\26c';
}

.isax-star-14:before {
  content: '\26d';
}

.isax-star-slash4:before {
  content: '\26e';
}

.isax-status4:before {
  content: '\26f';
}

.isax-status-up4:before {
  content: '\270';
}

.isax-sticker4:before {
  content: '\271';
}

.isax-stickynote4:before {
  content: '\272';
}

.isax-stop4:before {
  content: '\273';
}

.isax-stop-circle4:before {
  content: '\274';
}

.isax-story4:before {
  content: '\275';
}

.isax-strongbox4:before {
  content: '\276';
}

.isax-strongbox-24:before {
  content: '\277';
}

.isax-subtitle4:before {
  content: '\278';
}

.isax-sun4:before {
  content: '\279';
}

.isax-sun-14:before {
  content: '\27a';
}

.isax-sun-fog4:before {
  content: '\27b';
}

.isax-tag4:before {
  content: '\27c';
}

.isax-tag-24:before {
  content: '\27d';
}

.isax-tag-cross4:before {
  content: '\27e';
}

.isax-tag-right4:before {
  content: '\27f';
}

.isax-tag-user4:before {
  content: '\280';
}

.isax-task4:before {
  content: '\281';
}

.isax-task-square4:before {
  content: '\282';
}

.isax-teacher4:before {
  content: '\283';
}

.isax-text4:before {
  content: '\284';
}

.isax-textalign-center4:before {
  content: '\285';
}

.isax-textalign-justifycenter4:before {
  content: '\286';
}

.isax-textalign-justifyleft4:before {
  content: '\287';
}

.isax-textalign-justifyright4:before {
  content: '\288';
}

.isax-textalign-left4:before {
  content: '\289';
}

.isax-textalign-right4:before {
  content: '\28a';
}

.isax-text-block4:before {
  content: '\28b';
}

.isax-text-bold4:before {
  content: '\28c';
}

.isax-text-italic4:before {
  content: '\28d';
}

.isax-text-underline4:before {
  content: '\28e';
}

.isax-tick-circle4:before {
  content: '\28f';
}

.isax-ticket4:before {
  content: '\290';
}

.isax-ticket-24:before {
  content: '\291';
}

.isax-ticket-discount4:before {
  content: '\292';
}

.isax-ticket-expired4:before {
  content: '\293';
}

.isax-ticket-star4:before {
  content: '\294';
}

.isax-tick-square4:before {
  content: '\295';
}

.isax-timer4:before {
  content: '\296';
}

.isax-timer-14:before {
  content: '\297';
}

.isax-timer-pause4:before {
  content: '\298';
}

.isax-timer-start4:before {
  content: '\299';
}

.isax-toggle-off4:before {
  content: '\29a';
}

.isax-toggle-off-circle4:before {
  content: '\29b';
}

.isax-toggle-on4:before {
  content: '\29c';
}

.isax-toggle-on-circle4:before {
  content: '\29d';
}

.isax-trade4:before {
  content: '\29e';
}

.isax-transaction-minus4:before {
  content: '\29f';
}

.isax-translate4:before {
  content: '\2a0';
}

.isax-trash4:before {
  content: '\2a1';
}

.isax-tree4:before {
  content: '\2a2';
}

.isax-trend-down4:before {
  content: '\2a3';
}

.isax-trend-up4:before {
  content: '\2a4';
}

.isax-triangle4:before {
  content: '\2a5';
}

.isax-truck3:before {
  content: '\2a6';
}

.isax-truck-fast3:before {
  content: '\2a7';
}

.isax-truck-remove2:before {
  content: '\2a8';
}

.isax-truck-tick4:before {
  content: '\2a9';
}

.isax-truck-time2:before {
  content: '\2aa';
}

.isax-trush-square4:before {
  content: '\2ab';
}

.isax-undo4:before {
  content: '\2ac';
}

.isax-unlimited4:before {
  content: '\2ad';
}

.isax-unlock4:before {
  content: '\2ae';
}

.isax-user4:before {
  content: '\2af';
}

.isax-user-add4:before {
  content: '\2b0';
}

.isax-user-cirlce-add4:before {
  content: '\2b1';
}

.isax-user-edit4:before {
  content: '\2b2';
}

.isax-user-minus4:before {
  content: '\2b3';
}

.isax-user-octagon4:before {
  content: '\2b4';
}

.isax-user-remove4:before {
  content: '\2b5';
}

.isax-user-search4:before {
  content: '\2b6';
}

.isax-user-square4:before {
  content: '\2b7';
}

.isax-user-tag4:before {
  content: '\2b8';
}

.isax-user-tick4:before {
  content: '\2b9';
}

.isax-verify4:before {
  content: '\2ba';
}

.isax-video4:before {
  content: '\2bb';
}

.isax-video-add4:before {
  content: '\2bc';
}

.isax-video-circle4:before {
  content: '\2bd';
}

.isax-video-horizontal4:before {
  content: '\2be';
}

.isax-video-octagon4:before {
  content: '\2bf';
}

.isax-video-play4:before {
  content: '\2c0';
}

.isax-video-remove4:before {
  content: '\2c1';
}

.isax-video-slash4:before {
  content: '\2c2';
}

.isax-video-square4:before {
  content: '\2c3';
}

.isax-video-tick4:before {
  content: '\2c4';
}

.isax-video-time4:before {
  content: '\2c5';
}

.isax-video-vertical4:before {
  content: '\2c6';
}

.isax-voice-cricle4:before {
  content: '\2c7';
}

.isax-voice-square4:before {
  content: '\2c8';
}

.isax-volume-cross4:before {
  content: '\2c9';
}

.isax-volume-high4:before {
  content: '\2ca';
}

.isax-volume-low4:before {
  content: '\2cb';
}

.isax-volume-low-14:before {
  content: '\2cc';
}

.isax-volume-mute4:before {
  content: '\2cd';
}

.isax-volume-slash4:before {
  content: '\2ce';
}

.isax-volume-up4:before {
  content: '\2cf';
}

.isax-wallet4:before {
  content: '\2d0';
}

.isax-wallet-14:before {
  content: '\2d1';
}

.isax-wallet-24:before {
  content: '\2d2';
}

.isax-wallet-34:before {
  content: '\2d3';
}

.isax-wallet-add4:before {
  content: '\2d4';
}

.isax-wallet-add-14:before {
  content: '\2d5';
}

.isax-wallet-check4:before {
  content: '\2d6';
}

.isax-wallet-minus4:before {
  content: '\2d7';
}

.isax-wallet-money4:before {
  content: '\2d8';
}

.isax-wallet-remove4:before {
  content: '\2d9';
}

.isax-wallet-search4:before {
  content: '\2da';
}

.isax-warning-24:before {
  content: '\2db';
}

.isax-watch4:before {
  content: '\2dc';
}

.isax-watch-status4:before {
  content: '\2dd';
}

.isax-weight4:before {
  content: '\2de';
}

.isax-weight-14:before {
  content: '\2df';
}

.isax-wifi4:before {
  content: '\2e0';
}

.isax-wifi-square4:before {
  content: '\2e1';
}

.isax-wind4:before {
  content: '\2e2';
}

.isax-wind-24:before {
  content: '\2e3';
}

.isax-woman4:before {
  content: '\2e4';
}

.isax-dcube4:before {
  content: '\2e5';
}

.isax-dcube5:before {
  content: '\2e6';
}

.isax-d-cube-scan5:before {
  content: '\2e7';
}

.isax-d-rotate5:before {
  content: '\2e8';
}

.isax-d-square5:before {
  content: '\2e9';
}

.isax-square5:before {
  content: '\2ea';
}

.isax-support5:before {
  content: '\2eb';
}

.isax-activity5:before {
  content: '\2ec';
}

.isax-add5:before {
  content: '\2ed';
}

.isax-add-circle5:before {
  content: '\2ee';
}

.isax-additem5:before {
  content: '\2ef';
}

.isax-add-square5:before {
  content: '\2f0';
}

.isax-airdrop5:before {
  content: '\2f1';
}

.isax-airplane5:before {
  content: '\2f2';
}

.isax-airplane-square5:before {
  content: '\2f3';
}

.isax-airpod5:before {
  content: '\2f4';
}

.isax-airpods5:before {
  content: '\2f5';
}

.isax-alarm5:before {
  content: '\2f6';
}

.isax-align-bottom5:before {
  content: '\2f7';
}

.isax-align-horizontally5:before {
  content: '\2f8';
}

.isax-align-left5:before {
  content: '\2f9';
}

.isax-align-right5:before {
  content: '\2fa';
}

.isax-align-vertically5:before {
  content: '\2fb';
}

.isax-aquarius5:before {
  content: '\2fc';
}

.isax-archive5:before {
  content: '\2fd';
}

.isax-archive-15:before {
  content: '\2fe';
}

.isax-archive-24:before {
  content: '\2ff';
}

.isax-archive-add5:before {
  content: '\300';
}

.isax-archive-book5:before {
  content: '\301';
}

.isax-archive-minus5:before {
  content: '\302';
}

.isax-archive-slash5:before {
  content: '\303';
}

.isax-archive-tick5:before {
  content: '\304';
}

.isax-arrange-circle5:before {
  content: '\305';
}

.isax-arrange-circle-25:before {
  content: '\306';
}

.isax-arrange-square5:before {
  content: '\307';
}

.isax-arrange-square-25:before {
  content: '\308';
}

.isax-arrow5:before {
  content: '\309';
}

.isax-arrow-25:before {
  content: '\30a';
}

.isax-arrow-35:before {
  content: '\30b';
}

.isax-arrow-bottom5:before {
  content: '\30c';
}

.isax-arrow-circle-down5:before {
  content: '\30d';
}

.isax-arrow-circle-left5:before {
  content: '\30e';
}

.isax-arrow-circle-right5:before {
  content: '\30f';
}

.isax-arrow-circle-up4:before {
  content: '\310';
}

.isax-arrow-down5:before {
  content: '\311';
}

.isax-arrow-down-15:before {
  content: '\312';
}

.isax-arrow-down-25:before {
  content: '\313';
}

.isax-arrow-left5:before {
  content: '\314';
}

.isax-arrow-left-15:before {
  content: '\315';
}

.isax-arrow-left-25:before {
  content: '\316';
}

.isax-arrow-left-35:before {
  content: '\317';
}

.isax-arrow-right5:before {
  content: '\318';
}

.isax-arrow-right-15:before {
  content: '\319';
}

.isax-arrow-right-25:before {
  content: '\31a';
}

.isax-arrow-right-35:before {
  content: '\31b';
}

.isax-arrow-square5:before {
  content: '\31c';
}

.isax-arrow-square-down5:before {
  content: '\31d';
}

.isax-arrow-square-left5:before {
  content: '\31e';
}

.isax-arrow-square-right3:before {
  content: '\31f';
}

.isax-arrow-square-up5:before {
  content: '\320';
}

.isax-arrow-swap4:before {
  content: '\321';
}

.isax-arrow-swap-horizontal5:before {
  content: '\322';
}

.isax-arrow-up5:before {
  content: '\323';
}

.isax-arrow-up-15:before {
  content: '\324';
}

.isax-arrow-up-25:before {
  content: '\325';
}

.isax-arrow-up-35:before {
  content: '\326';
}

.isax-attach-circle5:before {
  content: '\327';
}

.isax-attach-square5:before {
  content: '\328';
}

.isax-audio-square5:before {
  content: '\329';
}

.isax-autobrightness5:before {
  content: '\32a';
}

.isax-award5:before {
  content: '\32b';
}

.isax-back-square5:before {
  content: '\32c';
}

.isax-backward5:before {
  content: '\32d';
}

.isax-backward-5-seconds5:before {
  content: '\32e';
}

.isax-backward-10-seconds5:before {
  content: '\32f';
}

.isax-backward-15-seconds5:before {
  content: '\330';
}

.isax-backward-item5:before {
  content: '\331';
}

.isax-bag5:before {
  content: '\332';
}

.isax-bag-25:before {
  content: '\333';
}

.isax-bag-cross5:before {
  content: '\334';
}

.isax-bag-cross-15:before {
  content: '\335';
}

.isax-bag-happy5:before {
  content: '\336';
}

.isax-bag-tick5:before {
  content: '\337';
}

.isax-bag-tick-25:before {
  content: '\338';
}

.isax-bag-timer5:before {
  content: '\339';
}

.isax-bank5:before {
  content: '\33a';
}

.isax-barcode5:before {
  content: '\33b';
}

.isax-battery-3full5:before {
  content: '\33c';
}

.isax-battery-charging5:before {
  content: '\33d';
}

.isax-battery-disable5:before {
  content: '\33e';
}

.isax-battery-empty5:before {
  content: '\33f';
}

.isax-battery-empty-15:before {
  content: '\340';
}

.isax-battery-full5:before {
  content: '\341';
}

.isax-bezier5:before {
  content: '\342';
}

.isax-bill5:before {
  content: '\343';
}

.isax-bitcoin-card5:before {
  content: '\344';
}

.isax-bitcoin-convert5:before {
  content: '\345';
}

.isax-bitcoin-refresh5:before {
  content: '\346';
}

.isax-blend5:before {
  content: '\347';
}

.isax-blend-25:before {
  content: '\348';
}

.isax-bluetooth5:before {
  content: '\349';
}

.isax-bluetooth-25:before {
  content: '\34a';
}

.isax-bluetooth-circle5:before {
  content: '\34b';
}

.isax-bluetooth-rectangle5:before {
  content: '\34c';
}

.isax-blur5:before {
  content: '\34d';
}

.isax-book5:before {
  content: '\34e';
}

.isax-book-15:before {
  content: '\34f';
}

.isax-bookmark5:before {
  content: '\350';
}

.isax-bookmark-25:before {
  content: '\351';
}

.isax-book-saved5:before {
  content: '\352';
}

.isax-book-square5:before {
  content: '\353';
}

.isax-box5:before {
  content: '\354';
}

.isax-box-15:before {
  content: '\355';
}

.isax-box-25:before {
  content: '\356';
}

.isax-box-add5:before {
  content: '\357';
}

.isax-box-remove5:before {
  content: '\358';
}

.isax-box-search5:before {
  content: '\359';
}

.isax-box-tick5:before {
  content: '\35a';
}

.isax-box-time5:before {
  content: '\35b';
}

.isax-briefcase5:before {
  content: '\35c';
}

.isax-brifecase-cross5:before {
  content: '\35d';
}

.isax-brifecase-tick5:before {
  content: '\35e';
}

.isax-brifecase-timer5:before {
  content: '\35f';
}

.isax-broom5:before {
  content: '\360';
}

.isax-brush5:before {
  content: '\361';
}

.isax-brush-15:before {
  content: '\362';
}

.isax-brush-25:before {
  content: '\363';
}

.isax-brush-35:before {
  content: '\364';
}

.isax-brush-45:before {
  content: '\365';
}

.isax-bubble5:before {
  content: '\366';
}

.isax-bucket5:before {
  content: '\367';
}

.isax-bucket-circle5:before {
  content: '\368';
}

.isax-bucket-square5:before {
  content: '\369';
}

.isax-building5:before {
  content: '\36a';
}

.isax-building-35:before {
  content: '\36b';
}

.isax-building-45:before {
  content: '\36c';
}

.isax-buildings5:before {
  content: '\36d';
}

.isax-buildings-25:before {
  content: '\36e';
}

.isax-buliding5:before {
  content: '\36f';
}

.isax-bus5:before {
  content: '\370';
}

.isax-buy-crypto5:before {
  content: '\371';
}

.isax-cake5:before {
  content: '\372';
}

.isax-calculator5:before {
  content: '\373';
}

.isax-calendar5:before {
  content: '\374';
}

.isax-calendar-15:before {
  content: '\375';
}

.isax-calendar-25:before {
  content: '\376';
}

.isax-calendar-add5:before {
  content: '\377';
}

.isax-calendar-circle5:before {
  content: '\378';
}

.isax-calendar-edit5:before {
  content: '\379';
}

.isax-calendar-remove5:before {
  content: '\37a';
}

.isax-calendar-search5:before {
  content: '\37b';
}

.isax-calendar-tick5:before {
  content: '\37c';
}

.isax-call5:before {
  content: '\37d';
}

.isax-call-add5:before {
  content: '\37e';
}

.isax-call-calling5:before {
  content: '\37f';
}

.isax-call-incoming5:before {
  content: '\380';
}

.isax-call-minus5:before {
  content: '\381';
}

.isax-call-outgoing5:before {
  content: '\382';
}

.isax-call-received5:before {
  content: '\383';
}

.isax-call-remove5:before {
  content: '\384';
}

.isax-call-slash5:before {
  content: '\385';
}

.isax-camera5:before {
  content: '\386';
}

.isax-camera-slash5:before {
  content: '\387';
}

.isax-candle5:before {
  content: '\388';
}

.isax-candle-25:before {
  content: '\389';
}

.isax-car5:before {
  content: '\38a';
}

.isax-card5:before {
  content: '\38b';
}

.isax-card-add5:before {
  content: '\38c';
}

.isax-card-coin5:before {
  content: '\38d';
}

.isax-card-edit5:before {
  content: '\38e';
}

.isax-card-pos5:before {
  content: '\38f';
}

.isax-card-receive5:before {
  content: '\390';
}

.isax-card-remove5:before {
  content: '\391';
}

.isax-card-remove-15:before {
  content: '\392';
}

.isax-cards5:before {
  content: '\393';
}

.isax-card-send5:before {
  content: '\394';
}

.isax-card-slash5:before {
  content: '\395';
}

.isax-card-tick5:before {
  content: '\396';
}

.isax-card-tick-15:before {
  content: '\397';
}

.isax-category5:before {
  content: '\398';
}

.isax-category-25:before {
  content: '\399';
}

.isax-cd5:before {
  content: '\39a';
}

.isax-chart5:before {
  content: '\39b';
}

.isax-chart-15:before {
  content: '\39c';
}

.isax-chart-26:before {
  content: '\39d';
}

.isax-chart-35:before {
  content: '\39e';
}

.isax-chart-215:before {
  content: '\39f';
}

.isax-chart-fail3:before {
  content: '\3a0';
}

.isax-chart-square5:before {
  content: '\3a1';
}

.isax-chart-success5:before {
  content: '\3a2';
}

.isax-check5:before {
  content: '\3a3';
}

.isax-chrome5:before {
  content: '\3a4';
}

.isax-clipboard5:before {
  content: '\3a5';
}

.isax-clipboard-close5:before {
  content: '\3a6';
}

.isax-clipboard-export5:before {
  content: '\3a7';
}

.isax-clipboard-import5:before {
  content: '\3a8';
}

.isax-clipboard-text5:before {
  content: '\3a9';
}

.isax-clipboard-tick5:before {
  content: '\3aa';
}

.isax-clock5:before {
  content: '\3ab';
}

.isax-clock-15:before {
  content: '\3ac';
}

.isax-close-circle5:before {
  content: '\3ad';
}

.isax-close-square5:before {
  content: '\3ae';
}

.isax-cloud5:before {
  content: '\3af';
}

.isax-cloud-add5:before {
  content: '\3b0';
}

.isax-cloud-change5:before {
  content: '\3b1';
}

.isax-cloud-connection5:before {
  content: '\3b2';
}

.isax-cloud-cross5:before {
  content: '\3b3';
}

.isax-cloud-drizzle5:before {
  content: '\3b4';
}

.isax-cloud-fog5:before {
  content: '\3b5';
}

.isax-cloud-lightning5:before {
  content: '\3b6';
}

.isax-cloud-minus5:before {
  content: '\3b7';
}

.isax-cloud-notif5:before {
  content: '\3b8';
}

.isax-cloud-plus5:before {
  content: '\3b9';
}

.isax-cloud-remove5:before {
  content: '\3ba';
}

.isax-cloud-snow5:before {
  content: '\3bb';
}

.isax-cloud-sunny5:before {
  content: '\3bc';
}

.isax-code5:before {
  content: '\3bd';
}

.isax-code-15:before {
  content: '\3be';
}

.isax-code-circle5:before {
  content: '\3bf';
}

.isax-coffee5:before {
  content: '\3c0';
}

.isax-coin5:before {
  content: '\3c1';
}

.isax-coin-15:before {
  content: '\3c2';
}

.isax-colorfilter5:before {
  content: '\3c3';
}

.isax-colors-square5:before {
  content: '\3c4';
}

.isax-color-swatch5:before {
  content: '\3c5';
}

.isax-command5:before {
  content: '\3c6';
}

.isax-command-square5:before {
  content: '\3c7';
}

.isax-component5:before {
  content: '\3c8';
}

.isax-computing5:before {
  content: '\3c9';
}

.isax-convert5:before {
  content: '\3ca';
}

.isax-convert-3d-cube5:before {
  content: '\3cb';
}

.isax-convert-card5:before {
  content: '\3cc';
}

.isax-convertshape5:before {
  content: '\3cd';
}

.isax-convertshape-25:before {
  content: '\3ce';
}

.isax-copy5:before {
  content: '\3cf';
}

.isax-copyright5:before {
  content: '\3d0';
}

.isax-copy-success5:before {
  content: '\3d1';
}

.isax-courthouse5:before {
  content: '\3d2';
}

.isax-cpu5:before {
  content: '\3d3';
}

.isax-cpu-charge5:before {
  content: '\3d4';
}

.isax-cpu-setting5:before {
  content: '\3d5';
}

.isax-creative-commons5:before {
  content: '\3d6';
}

.isax-crop5:before {
  content: '\3d7';
}

.isax-crown5:before {
  content: '\3d8';
}

.isax-crown-15:before {
  content: '\3d9';
}

.isax-cup5:before {
  content: '\3da';
}

.isax-danger5:before {
  content: '\3db';
}

.isax-data5:before {
  content: '\3dc';
}

.isax-data-25 .path1:before {
  content: '\3dd';
  color: rgb(41, 45, 50);
}

.isax-data-25 .path2:before {
  content: '\66d';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-data-25 .path3:before {
  content: '\66e';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-data-25 .path4:before {
  content: '\66f';
  margin-left: -1em;
  color: rgb(41, 45, 50);
  opacity: 0.96;
}

.isax-data-25 .path5:before {
  content: '\670';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-designtools5:before {
  content: '\3de';
}

.isax-device-message5:before {
  content: '\3df';
}

.isax-devices5:before {
  content: '\3e0';
}

.isax-devices-14:before {
  content: '\3e1';
}

.isax-diagram5:before {
  content: '\3e2';
}

.isax-diamonds5:before {
  content: '\3e3';
}

.isax-direct5:before {
  content: '\3e4';
}

.isax-directbox-default5:before {
  content: '\3e5';
}

.isax-directbox-notif5:before {
  content: '\3e6';
}

.isax-directbox-receive5:before {
  content: '\3e7';
}

.isax-directbox-send5:before {
  content: '\3e8';
}

.isax-direct-down5:before {
  content: '\3e9';
}

.isax-direct-inbox5:before {
  content: '\3ea';
}

.isax-direct-left5:before {
  content: '\3eb';
}

.isax-direct-normal5:before {
  content: '\3ec';
}

.isax-direct-notification5:before {
  content: '\3ed';
}

.isax-direct-right5:before {
  content: '\3ee';
}

.isax-direct-send5:before {
  content: '\3ef';
}

.isax-direct-up5:before {
  content: '\3f0';
}

.isax-discount-circle5:before {
  content: '\3f1';
}

.isax-discount-shape5:before {
  content: '\3f2';
}

.isax-discover5:before {
  content: '\3f3';
}

.isax-discover-14:before {
  content: '\3f4';
}

.isax-dislike5:before {
  content: '\3f5';
}

.isax-document5:before {
  content: '\3f6';
}

.isax-document-15:before {
  content: '\3f7';
}

.isax-document-cloud5:before {
  content: '\3f8';
}

.isax-document-code5:before {
  content: '\3f9';
}

.isax-document-code-25:before {
  content: '\3fa';
}

.isax-document-copy5:before {
  content: '\3fb';
}

.isax-document-download5:before {
  content: '\3fc';
}

.isax-document-favorite5:before {
  content: '\3fd';
}

.isax-document-filter5:before {
  content: '\3fe';
}

.isax-document-forward5:before {
  content: '\3ff';
}

.isax-document-like5:before {
  content: '\400';
}

.isax-document-normal4:before {
  content: '\401';
}

.isax-document-previous5:before {
  content: '\402';
}

.isax-document-sketch5:before {
  content: '\403';
}

.isax-document-text5:before {
  content: '\404';
}

.isax-document-text-15:before {
  content: '\405';
}

.isax-document-upload5:before {
  content: '\406';
}

.isax-dollar-circle4:before {
  content: '\407';
}

.isax-dollar-square5:before {
  content: '\408';
}

.isax-driver5:before {
  content: '\409';
}

.isax-driver-25:before {
  content: '\40a';
}

.isax-driver-refresh5:before {
  content: '\40b';
}

.isax-driving5:before {
  content: '\40c';
}

.isax-drop3:before {
  content: '\40d';
}

.isax-edit5:before {
  content: '\40e';
}

.isax-edit-25:before {
  content: '\40f';
}

.isax-electricity5:before {
  content: '\410';
}

.isax-element-25:before {
  content: '\411';
}

.isax-element-35:before {
  content: '\412';
}

.isax-element-45:before {
  content: '\413';
}

.isax-element-equal5:before {
  content: '\414';
}

.isax-element-plus5:before {
  content: '\415';
}

.isax-emoji-happy5:before {
  content: '\416';
}

.isax-emoji-normal5:before {
  content: '\417';
}

.isax-emoji-sad5:before {
  content: '\418';
}

.isax-empty-wallet5:before {
  content: '\419';
}

.isax-empty-wallet-add5:before {
  content: '\41a';
}

.isax-empty-wallet-change4:before {
  content: '\41b';
}

.isax-empty-wallet-remove5:before {
  content: '\41c';
}

.isax-empty-wallet-tick5:before {
  content: '\41d';
}

.isax-empty-wallet-time4:before {
  content: '\41e';
}

.isax-eraser5:before {
  content: '\41f';
}

.isax-eraser-15:before {
  content: '\420';
}

.isax-export5:before {
  content: '\421';
}

.isax-export-15:before {
  content: '\422';
}

.isax-export-25:before {
  content: '\423';
}

.isax-export-35:before {
  content: '\424';
}

.isax-external-drive5:before {
  content: '\425';
}

.isax-eye4:before {
  content: '\426';
}

.isax-eye-slash5:before {
  content: '\427';
}

.isax-fatrows5:before {
  content: '\428';
}

.isax-favorite-chart5:before {
  content: '\429';
}

.isax-filter5:before {
  content: '\42a';
}

.isax-filter-add5:before {
  content: '\42b';
}

.isax-filter-edit5:before {
  content: '\42c';
}

.isax-filter-remove5:before {
  content: '\42d';
}

.isax-filter-search5:before {
  content: '\42e';
}

.isax-filter-square5:before {
  content: '\42f';
}

.isax-filter-tick5:before {
  content: '\430';
}

.isax-finger-cricle5:before {
  content: '\431';
}

.isax-finger-scan5:before {
  content: '\432';
}

.isax-firstline5:before {
  content: '\433';
}

.isax-flag5:before {
  content: '\434';
}

.isax-flag-25:before {
  content: '\435';
}

.isax-flash5:before {
  content: '\436';
}

.isax-flash-15:before {
  content: '\437';
}

.isax-flash-circle5:before {
  content: '\438';
}

.isax-flash-circle-15:before {
  content: '\439';
}

.isax-flash-slash5:before {
  content: '\43a';
}

.isax-folder5:before {
  content: '\43b';
}

.isax-folder-25:before {
  content: '\43c';
}

.isax-folder-add5:before {
  content: '\43d';
}

.isax-folder-cloud5:before {
  content: '\43e';
}

.isax-folder-connection5:before {
  content: '\43f';
}

.isax-folder-cross5:before {
  content: '\440';
}

.isax-folder-favorite5:before {
  content: '\441';
}

.isax-folder-minus5:before {
  content: '\442';
}

.isax-folder-open5:before {
  content: '\443';
}

.isax-forbidden5:before {
  content: '\444';
}

.isax-forbidden-25:before {
  content: '\445';
}

.isax-format-circle5:before {
  content: '\446';
}

.isax-format-square5:before {
  content: '\447';
}

.isax-forward5:before {
  content: '\448';
}

.isax-forward-5-seconds5:before {
  content: '\449';
}

.isax-forward-10-seconds5:before {
  content: '\44a';
}

.isax-forward-15-seconds5:before {
  content: '\44b';
}

.isax-forward-item5:before {
  content: '\44c';
}

.isax-forward-square5:before {
  content: '\44d';
}

.isax-frame5:before {
  content: '\44e';
}

.isax-frame-15:before {
  content: '\44f';
}

.isax-frame-25:before {
  content: '\450';
}

.isax-frame-35:before {
  content: '\451';
}

.isax-frame-45:before {
  content: '\452';
}

.isax-gallery5:before {
  content: '\453';
}

.isax-gallery-add5:before {
  content: '\454';
}

.isax-gallery-edit5:before {
  content: '\455';
}

.isax-gallery-export4:before {
  content: '\456';
}

.isax-gallery-favorite5:before {
  content: '\457';
}

.isax-gallery-import5:before {
  content: '\458';
}

.isax-gallery-remove5:before {
  content: '\459';
}

.isax-gallery-slash5:before {
  content: '\45a';
}

.isax-gallery-tick5:before {
  content: '\45b';
}

.isax-game5:before {
  content: '\45c';
}

.isax-gameboy5:before {
  content: '\45d';
}

.isax-gas-station4:before {
  content: '\45e';
}

.isax-gemini5:before {
  content: '\45f';
}

.isax-gemini-25:before {
  content: '\460';
}

.isax-ghost5:before {
  content: '\461';
}

.isax-gift5:before {
  content: '\462';
}

.isax-glass5:before {
  content: '\463';
}

.isax-glass-15:before {
  content: '\464';
}

.isax-global5:before {
  content: '\465';
}

.isax-global-edit5:before {
  content: '\466';
}

.isax-global-refresh5:before {
  content: '\467';
}

.isax-global-search5:before {
  content: '\468';
}

.isax-gps5:before {
  content: '\469';
}

.isax-gps-slash5:before {
  content: '\46a';
}

.isax-grammerly5:before {
  content: '\46b';
}

.isax-graph5:before {
  content: '\46c';
}

.isax-grid-15:before {
  content: '\46d';
}

.isax-grid-25:before {
  content: '\46e';
}

.isax-grid-35:before {
  content: '\46f';
}

.isax-grid-45:before {
  content: '\470';
}

.isax-grid-55:before {
  content: '\471';
}

.isax-grid-65:before {
  content: '\472';
}

.isax-grid-75:before {
  content: '\473';
}

.isax-grid-85:before {
  content: '\474';
}

.isax-grid-95:before {
  content: '\475';
}

.isax-grid-edit5:before {
  content: '\476';
}

.isax-grid-eraser5:before {
  content: '\477';
}

.isax-grid-lock5:before {
  content: '\478';
}

.isax-happyemoji5:before {
  content: '\479';
}

.isax-hashtag5:before {
  content: '\47a';
}

.isax-hashtag-15:before {
  content: '\47b';
}

.isax-hashtag-down5:before {
  content: '\47c';
}

.isax-hashtag-up5:before {
  content: '\47d';
}

.isax-headphone5:before {
  content: '\47e';
}

.isax-headphones5:before {
  content: '\47f';
}

.isax-health5:before {
  content: '\480';
}

.isax-heart5:before {
  content: '\481';
}

.isax-heart-add5:before {
  content: '\482';
}

.isax-heart-circle5:before {
  content: '\483';
}

.isax-heart-edit5:before {
  content: '\484';
}

.isax-heart-remove5:before {
  content: '\485';
}

.isax-heart-search5:before {
  content: '\486';
}

.isax-heart-slash5:before {
  content: '\487';
}

.isax-heart-tick5:before {
  content: '\488';
}

.isax-hierarchy5:before {
  content: '\489';
}

.isax-hierarchy-25:before {
  content: '\48a';
}

.isax-hierarchy-35:before {
  content: '\48b';
}

.isax-hierarchy-square5:before {
  content: '\48c';
}

.isax-hierarchy-square-25:before {
  content: '\48d';
}

.isax-hierarchy-square-35:before {
  content: '\48e';
}

.isax-home5:before {
  content: '\48f';
}

.isax-home-15:before {
  content: '\490';
}

.isax-home-25:before {
  content: '\491';
}

.isax-home-hashtag5:before {
  content: '\492';
}

.isax-home-trend-down5:before {
  content: '\493';
}

.isax-home-trend-up5:before {
  content: '\494';
}

.isax-home-wifi5:before {
  content: '\495';
}

.isax-hospital5:before {
  content: '\496';
}

.isax-house5:before {
  content: '\497';
}

.isax-house-25:before {
  content: '\498';
}

.isax-image5:before {
  content: '\499';
}

.isax-import5:before {
  content: '\49a';
}

.isax-import-15:before {
  content: '\49b';
}

.isax-import-25:before {
  content: '\49c';
}

.isax-import-3:before {
  content: '\49d';
}

.isax-info-circle5:before {
  content: '\49e';
}

.isax-information5:before {
  content: '\49f';
}

.isax-instagram5:before {
  content: '\4a0';
}

.isax-judge5:before {
  content: '\4a1';
}

.isax-kanban4:before {
  content: '\4a2';
}

.isax-key5:before {
  content: '\4a3';
}

.isax-keyboard5:before {
  content: '\4a4';
}

.isax-keyboard-open5:before {
  content: '\4a5';
}

.isax-key-square5:before {
  content: '\4a6';
}

.isax-lamp5:before {
  content: '\4a7';
}

.isax-lamp-15:before {
  content: '\4a8';
}

.isax-lamp-charge5:before {
  content: '\4a9';
}

.isax-lamp-on5:before {
  content: '\4aa';
}

.isax-lamp-slash5:before {
  content: '\4ab';
}

.isax-language-circle5:before {
  content: '\4ac';
}

.isax-language-square5:before {
  content: '\4ad';
}

.isax-layer5:before {
  content: '\4ae';
}

.isax-level5:before {
  content: '\4af';
}

.isax-lifebuoy5:before {
  content: '\4b0';
}

.isax-like5:before {
  content: '\4b1';
}

.isax-like-15:before {
  content: '\4b2';
}

.isax-like-dislike5:before {
  content: '\4b3';
}

.isax-like-shapes5:before {
  content: '\4b4';
}

.isax-like-tag5:before {
  content: '\4b5';
}

.isax-link5:before {
  content: '\4b6';
}

.isax-link-15:before {
  content: '\4b7';
}

.isax-link-26:before {
  content: '\4b8';
}

.isax-link-215:before {
  content: '\4b9';
}

.isax-link-circle5:before {
  content: '\4ba';
}

.isax-link-square5:before {
  content: '\4bb';
}

.isax-location5:before {
  content: '\4bc';
}

.isax-location-add5:before {
  content: '\4bd';
}

.isax-location-cross5:before {
  content: '\4be';
}

.isax-location-minus5:before {
  content: '\4bf';
}

.isax-location-slash5:before {
  content: '\4c0';
}

.isax-location-tick5:before {
  content: '\4c1';
}

.isax-lock5:before {
  content: '\4c2';
}

.isax-lock-15:before {
  content: '\4c3';
}

.isax-lock-circle5:before {
  content: '\4c4';
}

.isax-lock-slash5:before {
  content: '\4c5';
}

.isax-login5:before {
  content: '\4c6';
}

.isax-login-15:before {
  content: '\4c7';
}

.isax-logout5:before {
  content: '\4c8';
}

.isax-logout-15:before {
  content: '\4c9';
}

.isax-lovely5:before {
  content: '\4ca';
}

.isax-magicpen5:before {
  content: '\4cb';
}

.isax-magic-star5:before {
  content: '\4cc';
}

.isax-main-component5:before {
  content: '\4cd';
}

.isax-man5:before {
  content: '\4ce';
}

.isax-map5:before {
  content: '\4cf';
}

.isax-map-15:before {
  content: '\4d0';
}

.isax-mask5:before {
  content: '\4d1';
}

.isax-mask-15:before {
  content: '\4d2';
}

.isax-mask-25:before {
  content: '\4d3';
}

.isax-math5:before {
  content: '\4d4';
}

.isax-maximize5:before {
  content: '\4d5';
}

.isax-maximize-15:before {
  content: '\4d6';
}

.isax-maximize-26:before {
  content: '\4d7';
}

.isax-maximize-35:before {
  content: '\4d8';
}

.isax-maximize-45:before {
  content: '\4d9';
}

.isax-maximize-215:before {
  content: '\4da';
}

.isax-maximize-circle4:before {
  content: '\4db';
}

.isax-medal5:before {
  content: '\4dc';
}

.isax-medal-star5:before {
  content: '\4dd';
}

.isax-menu5:before {
  content: '\4de';
}

.isax-menu-15:before {
  content: '\4df';
}

.isax-menu-board5:before {
  content: '\4e0';
}

.isax-message5:before {
  content: '\4e1';
}

.isax-message-25:before {
  content: '\4e2';
}

.isax-message-add5:before {
  content: '\4e3';
}

.isax-message-add-15:before {
  content: '\4e4';
}

.isax-message-circle5:before {
  content: '\4e5';
}

.isax-message-edit5:before {
  content: '\4e6';
}

.isax-message-favorite5:before {
  content: '\4e7';
}

.isax-message-minus5:before {
  content: '\4e8';
}

.isax-message-notif5:before {
  content: '\4e9';
}

.isax-message-programming5:before {
  content: '\4ea';
}

.isax-message-question5:before {
  content: '\4eb';
}

.isax-message-remove5:before {
  content: '\4ec';
}

.isax-messages5:before {
  content: '\4ed';
}

.isax-messages-15:before {
  content: '\4ee';
}

.isax-messages-25:before {
  content: '\4ef';
}

.isax-messages-35:before {
  content: '\4f0';
}

.isax-message-search5:before {
  content: '\4f1';
}

.isax-message-square5:before {
  content: '\4f2';
}

.isax-message-text5:before {
  content: '\4f3';
}

.isax-message-text-15:before {
  content: '\4f4';
}

.isax-message-tick5:before {
  content: '\4f5';
}

.isax-message-time5:before {
  content: '\4f6';
}

.isax-microphone5:before {
  content: '\4f7';
}

.isax-microphone-25:before {
  content: '\4f8';
}

.isax-microphone-slash5:before {
  content: '\4f9';
}

.isax-microphone-slash-15:before {
  content: '\4fa';
}

.isax-microscope5:before {
  content: '\4fb';
}

.isax-milk5:before {
  content: '\4fc';
}

.isax-mini-music-sqaure5 .path1:before {
  content: '\4fd';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-mini-music-sqaure5 .path2:before {
  content: '\671';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-mini-music-sqaure5 .path3:before {
  content: '\672';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-minus5:before {
  content: '\4fe';
}

.isax-minus-cirlce5:before {
  content: '\4ff';
}

.isax-minus-square5:before {
  content: '\500';
}

.isax-mirror5:before {
  content: '\501';
}

.isax-mirroring-screen5:before {
  content: '\502';
}

.isax-mobile5:before {
  content: '\503';
}

.isax-mobile-programming5:before {
  content: '\504';
}

.isax-money5:before {
  content: '\505';
}

.isax-money-25:before {
  content: '\506';
}

.isax-money-35:before {
  content: '\507';
}

.isax-money-45:before {
  content: '\508';
}

.isax-money-add5:before {
  content: '\509';
}

.isax-money-change5:before {
  content: '\50a';
}

.isax-money-forbidden5:before {
  content: '\50b';
}

.isax-money-recive5:before {
  content: '\50c';
}

.isax-money-remove5:before {
  content: '\50d';
}

.isax-moneys5:before {
  content: '\50e';
}

.isax-money-send5:before {
  content: '\50f';
}

.isax-money-tick5:before {
  content: '\510';
}

.isax-money-time5:before {
  content: '\511';
}

.isax-monitor5:before {
  content: '\512';
}

.isax-monitor-mobbile5:before {
  content: '\513';
}

.isax-monitor-recorder5:before {
  content: '\514';
}

.isax-moon5:before {
  content: '\515';
}

.isax-more5:before {
  content: '\516';
}

.isax-more-25:before {
  content: '\517';
}

.isax-more-circle5:before {
  content: '\518';
}

.isax-more-square5:before {
  content: '\519';
}

.isax-mouse5:before {
  content: '\51a';
}

.isax-mouse-15:before {
  content: '\51b';
}

.isax-mouse-circle5:before {
  content: '\51c';
}

.isax-mouse-square5:before {
  content: '\51d';
}

.isax-music5:before {
  content: '\51e';
}

.isax-music-circle5:before {
  content: '\51f';
}

.isax-music-dashboard5:before {
  content: '\520';
}

.isax-music-filter5:before {
  content: '\521';
}

.isax-music-library-25:before {
  content: '\522';
}

.isax-musicnote5:before {
  content: '\523';
}

.isax-music-play5:before {
  content: '\524';
}

.isax-music-playlist5:before {
  content: '\525';
}

.isax-music-square5:before {
  content: '\526';
}

.isax-music-square-add5:before {
  content: '\527';
}

.isax-music-square-remove5:before {
  content: '\528';
}

.isax-music-square-search5:before {
  content: '\529';
}

.isax-next5:before {
  content: '\52a';
}

.isax-note5:before {
  content: '\52b';
}

.isax-note-15:before {
  content: '\52c';
}

.isax-note-26:before {
  content: '\52d';
}

.isax-note-215:before {
  content: '\52e';
}

.isax-note-add5:before {
  content: '\52f';
}

.isax-note-favorite5:before {
  content: '\530';
}

.isax-note-remove5:before {
  content: '\531';
}

.isax-note-square5:before {
  content: '\532';
}

.isax-note-text5:before {
  content: '\533';
}

.isax-notification5:before {
  content: '\534';
}

.isax-notification-15:before {
  content: '\535';
}

.isax-notification-bing5:before {
  content: '\536';
}

.isax-notification-favorite5:before {
  content: '\537';
}

.isax-notification-status5:before {
  content: '\538';
}

.isax-omega-circle5:before {
  content: '\539';
}

.isax-omega-square5:before {
  content: '\53a';
}

.isax-paintbucket5:before {
  content: '\53b';
}

.isax-paperclip5:before {
  content: '\53c';
}

.isax-paperclip-25:before {
  content: '\53d';
}

.isax-password-check5:before {
  content: '\53e';
}

.isax-path5:before {
  content: '\53f';
}

.isax-path-25:before {
  content: '\540';
}

.isax-path-square5:before {
  content: '\541';
}

.isax-pause5:before {
  content: '\542';
}

.isax-pause-circle5:before {
  content: '\543';
}

.isax-pen-add5:before {
  content: '\544';
}

.isax-pen-close5:before {
  content: '\545';
}

.isax-pen-remove5:before {
  content: '\546';
}

.isax-pen-tool5:before {
  content: '\547';
}

.isax-pen-tool-25:before {
  content: '\548';
}

.isax-people5:before {
  content: '\549';
}

.isax-percentage-circle5:before {
  content: '\54a';
}

.isax-percentage-square5:before {
  content: '\54b';
}

.isax-personalcard5:before {
  content: '\54c';
}

.isax-pet5:before {
  content: '\54d';
}

.isax-pharagraphspacing5:before {
  content: '\54e';
}

.isax-picture-frame5:before {
  content: '\54f';
}

.isax-play5:before {
  content: '\550';
}

.isax-play-add5:before {
  content: '\551';
}

.isax-play-circle5:before {
  content: '\552';
}

.isax-play-cricle5:before {
  content: '\553';
}

.isax-play-remove5:before {
  content: '\554';
}

.isax-presention-chart5:before {
  content: '\555';
}

.isax-previous5:before {
  content: '\556';
}

.isax-printer5:before {
  content: '\557';
}

.isax-printer-slash5:before {
  content: '\558';
}

.isax-profile-2user5:before {
  content: '\559';
}

.isax-profile-add5:before {
  content: '\55a';
}

.isax-profile-circle5:before {
  content: '\55b';
}

.isax-profile-delete5:before {
  content: '\55c';
}

.isax-profile-remove5:before {
  content: '\55d';
}

.isax-profile-tick5:before {
  content: '\55e';
}

.isax-programming-arrow5:before {
  content: '\55f';
}

.isax-programming-arrows5:before {
  content: '\560';
}

.isax-quote-down5:before {
  content: '\561';
}

.isax-quote-down-circle5:before {
  content: '\562';
}

.isax-quote-down-square5:before {
  content: '\563';
}

.isax-quote-up5:before {
  content: '\564';
}

.isax-quote-up-circle5:before {
  content: '\565';
}

.isax-quote-up-square5:before {
  content: '\566';
}

.isax-radar5:before {
  content: '\567';
}

.isax-radar-15:before {
  content: '\568';
}

.isax-radar-25:before {
  content: '\569';
}

.isax-radio5:before {
  content: '\56a';
}

.isax-ram5:before {
  content: '\56b';
}

.isax-ram-25:before {
  content: '\56c';
}

.isax-ranking5:before {
  content: '\56d';
}

.isax-ranking-15:before {
  content: '\56e';
}

.isax-receipt5:before {
  content: '\56f';
}

.isax-receipt-15:before {
  content: '\570';
}

.isax-receipt-25:before {
  content: '\571';
}

.isax-receipt-2-15:before {
  content: '\572';
}

.isax-receipt-add5:before {
  content: '\573';
}

.isax-receipt-discount5:before {
  content: '\574';
}

.isax-receipt-disscount5:before {
  content: '\575';
}

.isax-receipt-edit5:before {
  content: '\576';
}

.isax-receipt-item5:before {
  content: '\577';
}

.isax-receipt-minus5:before {
  content: '\578';
}

.isax-receipt-search5:before {
  content: '\579';
}

.isax-receipt-square5:before {
  content: '\57a';
}

.isax-receipt-text5:before {
  content: '\57b';
}

.isax-received5:before {
  content: '\57c';
}

.isax-receive-square5:before {
  content: '\57d';
}

.isax-receive-square-25:before {
  content: '\57e';
}

.isax-record5:before {
  content: '\57f';
}

.isax-record-circle5:before {
  content: '\580';
}

.isax-recovery-convert5:before {
  content: '\581';
}

.isax-redo5:before {
  content: '\582';
}

.isax-refresh5:before {
  content: '\583';
}

.isax-refresh-25:before {
  content: '\584';
}

.isax-refresh-circle5:before {
  content: '\585';
}

.isax-refresh-left-square5:before {
  content: '\586';
}

.isax-refresh-right-square5:before {
  content: '\587';
}

.isax-refresh-square-25:before {
  content: '\588';
}

.isax-repeat5:before {
  content: '\589';
}

.isax-repeat-circle5:before {
  content: '\58a';
}

.isax-repeate-music5:before {
  content: '\58b';
}

.isax-repeate-one5:before {
  content: '\58c';
}

.isax-reserve5:before {
  content: '\58d';
}

.isax-rotate-left5:before {
  content: '\58e';
}

.isax-rotate-left-15:before {
  content: '\58f';
}

.isax-rotate-right5:before {
  content: '\590';
}

.isax-rotate-right-15:before {
  content: '\591';
}

.isax-route-square5:before {
  content: '\592';
}

.isax-routing5:before {
  content: '\593';
}

.isax-routing-25:before {
  content: '\594';
}

.isax-row-horizontal5:before {
  content: '\595';
}

.isax-row-vertical5:before {
  content: '\596';
}

.isax-rulerpen5:before {
  content: '\597';
}

.isax-ruler5:before {
  content: '\598';
}

.isax-safe-home5:before {
  content: '\599';
}

.isax-sagittarius5:before {
  content: '\59a';
}

.isax-save-25:before {
  content: '\59b';
}

.isax-save-add5:before {
  content: '\59c';
}

.isax-save-minus5:before {
  content: '\59d';
}

.isax-save-remove5:before {
  content: '\59e';
}

.isax-scan5:before {
  content: '\59f';
}

.isax-scan-barcode5:before {
  content: '\5a0';
}

.isax-scanner5:before {
  content: '\5a1';
}

.isax-scanning5:before {
  content: '\5a2';
}

.isax-scissor5:before {
  content: '\5a3';
}

.isax-scissor-15:before {
  content: '\5a4';
}

.isax-screenmirroring5:before {
  content: '\5a5';
}

.isax-scroll5:before {
  content: '\5a6';
}

.isax-search-favorite5:before {
  content: '\5a7';
}

.isax-search-favorite-15:before {
  content: '\5a8';
}

.isax-search-normal5:before {
  content: '\5a9';
}

.isax-search-normal-15:before {
  content: '\5aa';
}

.isax-search-status5:before {
  content: '\5ab';
}

.isax-search-status-15:before {
  content: '\5ac';
}

.isax-search-zoom-in5:before {
  content: '\5ad';
}

.isax-search-zoom-in-15:before {
  content: '\5ae';
}

.isax-search-zoom-out5:before {
  content: '\5af';
}

.isax-search-zoom-out-15:before {
  content: '\5b0';
}

.isax-security5:before {
  content: '\5b1';
}

.isax-security-card5:before {
  content: '\5b2';
}

.isax-security-safe5:before {
  content: '\5b3';
}

.isax-security-time5:before {
  content: '\5b4';
}

.isax-security-user5:before {
  content: '\5b5';
}

.isax-send5:before {
  content: '\5b6';
}

.isax-send-15:before {
  content: '\5b7';
}

.isax-send-25:before {
  content: '\5b8';
}

.isax-send-sqaure-25:before {
  content: '\5b9';
}

.isax-send-square5:before {
  content: '\5ba';
}

.isax-setting5:before {
  content: '\5bb';
}

.isax-setting-25:before {
  content: '\5bc';
}

.isax-setting-35:before {
  content: '\5bd';
}

.isax-setting-45:before {
  content: '\5be';
}

.isax-setting-55:before {
  content: '\5bf';
}

.isax-settings5:before {
  content: '\5c0';
}

.isax-shapes5:before {
  content: '\5c1';
}

.isax-shapes-15:before {
  content: '\5c2';
}

.isax-share5:before {
  content: '\5c3';
}

.isax-shield-cross5:before {
  content: '\5c4';
}

.isax-shield-search5:before {
  content: '\5c5';
}

.isax-shield-security2:before {
  content: '\5c6';
}

.isax-shield-slash5:before {
  content: '\5c7';
}

.isax-shield-tick5:before {
  content: '\5c8';
}

.isax-ship5:before {
  content: '\5c9';
}

.isax-shop5:before {
  content: '\5ca';
}

.isax-shop-add5:before {
  content: '\5cb';
}

.isax-shopping-bag5:before {
  content: '\5cc';
}

.isax-shopping-cart5:before {
  content: '\5cd';
}

.isax-shop-remove5:before {
  content: '\5ce';
}

.isax-shuffle5:before {
  content: '\5cf';
}

.isax-sidebar-bottom5:before {
  content: '\5d0';
}

.isax-sidebar-left5:before {
  content: '\5d1';
}

.isax-sidebar-right5:before {
  content: '\5d2';
}

.isax-sidebar-top5:before {
  content: '\5d3';
}

.isax-signpost5:before {
  content: '\5d4';
}

.isax-simcard5:before {
  content: '\5d5';
}

.isax-simcard-15:before {
  content: '\5d6';
}

.isax-simcard-25:before {
  content: '\5d7';
}

.isax-size5:before {
  content: '\5d8';
}

.isax-slash5:before {
  content: '\5d9';
}

.isax-slider5:before {
  content: '\5da';
}

.isax-slider-horizontal5:before {
  content: '\5db';
}

.isax-slider-horizontal-15:before {
  content: '\5dc';
}

.isax-slider-vertical5:before {
  content: '\5dd';
}

.isax-slider-vertical-15:before {
  content: '\5de';
}

.isax-smallcaps5:before {
  content: '\5df';
}

.isax-smart-car5:before {
  content: '\5e0';
}

.isax-smart-home5:before {
  content: '\5e1';
}

.isax-smileys5:before {
  content: '\5e2';
}

.isax-sms5:before {
  content: '\5e3';
}

.isax-sms-edit5:before {
  content: '\5e4';
}

.isax-sms-notification5:before {
  content: '\5e5';
}

.isax-sms-search5:before {
  content: '\5e6';
}

.isax-sms-star5:before {
  content: '\5e7';
}

.isax-sms-tracking5:before {
  content: '\5e8';
}

.isax-sort5:before {
  content: '\5e9';
}

.isax-sound5:before {
  content: '\5ea';
}

.isax-speaker5:before {
  content: '\5eb';
}

.isax-speedometer5:before {
  content: '\5ec';
}

.isax-star5:before {
  content: '\5ed';
}

.isax-star-15:before {
  content: '\5ee';
}

.isax-star-slash5:before {
  content: '\5ef';
}

.isax-status5:before {
  content: '\5f0';
}

.isax-status-up5:before {
  content: '\5f1';
}

.isax-sticker5:before {
  content: '\5f2';
}

.isax-stickynote5:before {
  content: '\5f3';
}

.isax-stop5:before {
  content: '\5f4';
}

.isax-stop-circle5:before {
  content: '\5f5';
}

.isax-story5:before {
  content: '\5f6';
}

.isax-strongbox5:before {
  content: '\5f7';
}

.isax-strongbox-25:before {
  content: '\5f8';
}

.isax-subtitle5:before {
  content: '\5f9';
}

.isax-sun5:before {
  content: '\5fa';
}

.isax-sun-15:before {
  content: '\5fb';
}

.isax-sun-fog5:before {
  content: '\5fc';
}

.isax-tag5:before {
  content: '\5fd';
}

.isax-tag-25:before {
  content: '\5fe';
}

.isax-tag-cross5:before {
  content: '\5ff';
}

.isax-tag-right5:before {
  content: '\600';
}

.isax-tag-user5:before {
  content: '\601';
}

.isax-task5:before {
  content: '\602';
}

.isax-task-square5:before {
  content: '\603';
}

.isax-teacher5:before {
  content: '\604';
}

.isax-text5:before {
  content: '\605';
}

.isax-textalign-center5:before {
  content: '\606';
}

.isax-textalign-justifycenter5:before {
  content: '\607';
}

.isax-textalign-justifyleft5:before {
  content: '\608';
}

.isax-textalign-justifyright5:before {
  content: '\609';
}

.isax-textalign-left5:before {
  content: '\60a';
}

.isax-textalign-right5:before {
  content: '\60b';
}

.isax-text-block5:before {
  content: '\60c';
}

.isax-text-bold5:before {
  content: '\60d';
}

.isax-text-italic5:before {
  content: '\60e';
}

.isax-text-underline5:before {
  content: '\60f';
}

.isax-tick-circle5:before {
  content: '\610';
}

.isax-ticket5:before {
  content: '\611';
}

.isax-ticket-25:before {
  content: '\612';
}

.isax-ticket-discount5:before {
  content: '\613';
}

.isax-ticket-expired5:before {
  content: '\614';
}

.isax-ticket-star5:before {
  content: '\615';
}

.isax-tick-square5:before {
  content: '\616';
}

.isax-timer5:before {
  content: '\617';
}

.isax-timer-15:before {
  content: '\618';
}

.isax-timer-pause5:before {
  content: '\619';
}

.isax-timer-start5:before {
  content: '\61a';
}

.isax-toggle-off5:before {
  content: '\61b';
}

.isax-toggle-off-circle5:before {
  content: '\61c';
}

.isax-toggle-on5:before {
  content: '\61d';
}

.isax-toggle-on-circle5:before {
  content: '\61e';
}

.isax-trade5:before {
  content: '\61f';
}

.isax-transaction-minus5:before {
  content: '\620';
}

.isax-translate5:before {
  content: '\621';
}

.isax-trash5:before {
  content: '\622';
}

.isax-tree5:before {
  content: '\623';
}

.isax-trend-down5:before {
  content: '\624';
}

.isax-trend-up5:before {
  content: '\625';
}

.isax-triangle5:before {
  content: '\626';
}

.isax-truck4:before {
  content: '\627';
}

.isax-truck-fast4:before {
  content: '\628';
}

.isax-truck-remove3:before {
  content: '\629';
}

.isax-truck-tick5:before {
  content: '\62a';
}

.isax-truck-time3:before {
  content: '\62b';
}

.isax-trush-square5:before {
  content: '\62c';
}

.isax-undo5:before {
  content: '\62d';
}

.isax-unlimited5:before {
  content: '\62e';
}

.isax-unlock5:before {
  content: '\62f';
}

.isax-user5:before {
  content: '\630';
}

.isax-user-add5:before {
  content: '\631';
}

.isax-user-cirlce-add5:before {
  content: '\632';
}

.isax-user-edit5:before {
  content: '\633';
}

.isax-user-minus5:before {
  content: '\634';
}

.isax-user-octagon5:before {
  content: '\635';
}

.isax-user-remove5:before {
  content: '\636';
}

.isax-user-search5:before {
  content: '\637';
}

.isax-user-square5:before {
  content: '\638';
}

.isax-user-tag5:before {
  content: '\639';
}

.isax-user-tick5:before {
  content: '\63a';
}

.isax-verify5:before {
  content: '\63b';
}

.isax-video5:before {
  content: '\63c';
}

.isax-video-add5:before {
  content: '\63d';
}

.isax-video-circle5:before {
  content: '\63e';
}

.isax-video-horizontal5:before {
  content: '\63f';
}

.isax-video-octagon5:before {
  content: '\640';
}

.isax-video-play5:before {
  content: '\641';
}

.isax-video-remove5:before {
  content: '\642';
}

.isax-video-slash5 .path1:before {
  content: '\643';
  color: rgb(41, 45, 50);
  opacity: 0.4;
}

.isax-video-slash5 .path2:before {
  content: '\673';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-video-slash5 .path3:before {
  content: '\674';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-video-slash5 .path4:before {
  content: '\675';
  margin-left: -1em;
  color: rgb(41, 45, 50);
}

.isax-video-square5:before {
  content: '\644';
}

.isax-video-tick5:before {
  content: '\645';
}

.isax-video-time5:before {
  content: '\646';
}

.isax-video-vertical5:before {
  content: '\647';
}

.isax-voice-cricle5:before {
  content: '\648';
}

.isax-voice-square5:before {
  content: '\649';
}

.isax-volume-cross5:before {
  content: '\64a';
}

.isax-volume-high5:before {
  content: '\64b';
}

.isax-volume-low5:before {
  content: '\64c';
}

.isax-volume-low-15:before {
  content: '\64d';
}

.isax-volume-mute5:before {
  content: '\64e';
}

.isax-volume-slash5:before {
  content: '\64f';
}

.isax-volume-up5:before {
  content: '\650';
}

.isax-wallet5:before {
  content: '\651';
}

.isax-wallet-15:before {
  content: '\652';
}

.isax-wallet-25:before {
  content: '\653';
}

.isax-wallet-35:before {
  content: '\654';
}

.isax-wallet-add5:before {
  content: '\655';
}

.isax-wallet-add-15:before {
  content: '\656';
}

.isax-wallet-check5:before {
  content: '\657';
}

.isax-wallet-minus5:before {
  content: '\658';
}

.isax-wallet-money5:before {
  content: '\659';
}

.isax-wallet-remove5:before {
  content: '\65a';
}

.isax-wallet-search5:before {
  content: '\65b';
}

.isax-warning-25:before {
  content: '\65c';
}

.isax-watch5:before {
  content: '\65d';
}

.isax-watch-status5:before {
  content: '\65e';
}

.isax-weight5:before {
  content: '\65f';
}

.isax-weight-15:before {
  content: '\660';
}

.isax-wifi5:before {
  content: '\661';
}

.isax-wifi-square5:before {
  content: '\662';
}

.isax-wind5:before {
  content: '\663';
}

.isax-wind-25:before {
  content: '\664';
}

.isax-woman5:before {
  content: '\665';
}
