.btn {
  position: relative;
  padding: 12px 24px;
  border-radius: 40px;
  box-sizing: border-box;
  font-weight: 500;
  font-family: inherit;
  text-align: center;
  cursor: pointer;
  transition: all 200ms ease;
  display: flex;
  outline: none;
  border: none;

  &.is-primary {
    &:not(.is-disabled) {
      background-color: var(--n-800);
      border-color: var(--n-800);
      color: #fff;

      &:hover {
        background-color: var(--n-800);
        color: var(--n-400);
      }

      &:active {
        background-color: var(--n-800);
        color: var(--n-500);
      }
    }

    &.is-disabled {
      border-color: var(--n-300);
      color: var(--n-600);
    }
  }

  &:not(.is-loading).icon {
    align-items: center;

    i {
      &.icon-left {
        margin-right: 16px;
      }

      &.icon-right {
        margin-left: 16px;
      }
    }

    &.is-icon-small {
      i {
        font-size: 11px;
      }
    }

    &.is-icon-tiny {
      i {
        font-size: 9px;
      }
    }
  }

  &:active {
    box-shadow: 0px 0px 0px rgba(70, 118, 231, 0.21);
  }

  &.is-default {
    border: 1px solid #dfdfdf;
    background-color: #ffffff;
    color: #000000;

    &:hover {
      background-color: #e4e4e4;
      color: #000000;
      border: 1px solid #e4e4e4;
    }

    &.is-light {
      background-color: transparent;
      border: none;
      box-shadow: none;
      color: black;

      &:hover {
        background-color: #d6d6d6;
      }
    }
  }

  &.is-muted {
    border: none;
    background-color: rgba(196, 196, 196, 0.4);
    color: white;

    &:hover {
      background-color: rgba(129, 129, 129, 0.4);
    }
  }

  &.is-primary {
    background-color: #1260eb;
    color: #ffffff;

    &:hover {
      background-color: transparentize($color: #1260eb, $amount: 0.2);
    }

    &:active {
      background-color: #1260eb;
    }

    &.is-inverted {
      background-color: #ffffff;
      color: #1260eb;

      &:hover {
        background-color: #1260eb;
        color: white;
      }
    }
  }

  &.is-secondary {
    border: 1px solid #ef7c4b;
    background-color: #ffffff;
    color: #ef7c4b;

    &:hover {
      background-color: #ef7c4b;
      color: #ffffff;
    }

    &.is-inverted {
      border: 1px solid #ef7c4b;
      background-color: #ef7c4b;
      color: white;

      &:hover {
        background-color: white;
        color: #ef7c4b;
      }
    }
  }

  &.is-disabled {
    color: black;
    background: none;
    background-color: rgb(160, 160, 160);
    border-color: transparent;
    cursor: no-drop;

    &:hover {
      &:not(.is-primary) {
        background: none;
        background-color: rgb(196, 196, 196);
        color: black;
      }
    }
  }

  &.is-not-rounded {
    border-radius: 6px;
  }

  &.is-fullwidth {
    width: 100%;
  }

  &.is-loading.is-loading.is-loading {
    // Wtf is this?!
    position: relative;
    cursor: default;
    text-shadow: none !important;
    color: transparent !important;
    opacity: 1;
    pointer-events: auto;
    -webkit-transition: all 0s linear, opacity 0.1s ease;
    transition: all 0s linear, opacity 0.1s ease;

    &::before {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      margin: -0.64285714em 0 0 -0.64285714em;
      width: 1em;
      height: 1em;
      border-radius: 500rem;
      border: 0.2em solid rgba(0, 0, 0, 0.15);
    }

    &::after {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      margin: -0.64285714em 0 0 -0.64285714em;
      width: 1em;
      height: 1em;
      -webkit-animation: button-spin 0.6s linear;
      animation: button-spin 0.6s linear;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      border-radius: 500rem;
      border-color: #fff transparent transparent;
      border-style: solid;
      border-width: 0.2em;
      -webkit-box-shadow: 0 0 0 1px transparent;
      box-shadow: 0 0 0 1px transparent;
    }

    .icon {
      background-color: transparent;
      box-shadow: none;
    }

    .emoji {
      visibility: hidden;
    }
  }

  &.is-thin {
    padding: 4px 8px;
  }

  &.is-large {
    padding: 16px 47px;
  }

  &.is-icon-only {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    font-size: 11px;
  }

  &.is-default {
    &:not(.is-disabled) {
      background-color: #fff;
      border-color: var(--n-300);
      color: var(--n-800);

      &:hover {
        background-color: var(--n-100);
      }

      &:active {
        background-color: var(--n-300);
        color: var(--n-700);
      }
    }

    &.is-disabled {
      border-color: var(--n-300);
      color: var(--n-500);
      background-color: #fff;
    }
  }

}

@keyframes button-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
