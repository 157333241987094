.widget-feedback-empty-state {
  flex: 1 auto;
  background: #fff;
  padding: 24px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  min-height: 200px;
  -webkit-animation: 400ms fade-in-bottom 500ms ease-out both;
  animation: 400ms fade-in-bottom 500ms ease-out both;
}
