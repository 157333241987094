@import '../../Variables';

.post-block-user {
  display: flex;
  align-items: center;
  position: relative;
  gap: 8px;

  .pb-user-avatar {
    width: 28px;
    height: 28px;
    border-radius: 8px;
    background-size: cover;
    background-position: center;
  }

  .pb-user-infos {
    font-family: Inter;
    display: flex;
    flex: 1;
    flex-direction: column;
    color: #797979;
    font-size: 10.5px;
    font-weight: 500;
    line-height: 15.75px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    strong {
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      text-align: left;
      color: var(--Neutral-neutral-800, #071331);
    }

    span {
      font-family: Inter;
      font-size: 10.5px;
      font-weight: 500;
      line-height: 15.75px;
      text-align: left;
      color: var(--Neutral-neutral-500, #9CA1AD);
    }
  }

  .timestamp {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: var(--Neutral-neutral-700, #4D637B);
  }

  &.is-collapsed {
    .pb-user-avatar {
      width: 20px;
      height: 20px;
      border-radius: 12px;
      background-size: cover;
      background-position: center;
    }

    .pb-user-infos {
      display: flex;
      flex: 1;
      color: #797979;
      font-size: 10.5px;
      font-weight: 500;
      line-height: 15.75px;
      text-align: left;
      gap: 4px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      strong {
        text-overflow: ellipsis;
        color: var(--Neutral-neutral-700, #4D637B);
      }

      span {
        text-overflow: ellipsis;
        color: var(--Neutral-neutral-600, #7C8A99);
      }
    }

    .timestamp {
      font-size: 10.5px;
      font-weight: 500;
      line-height: 15.75px;
      text-align: left;
      color: var(--Neutral-neutral-600, #7C8A99);
    }
  }
}
