@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&family=Nunito:wght@200;300;400;500;600;700&family=Montserrat:wght@200;300;400;500;600;700&display=swap');

@import './shared/front/icons/Icons';
@import './shared/front/scss/Animations';
@import './Layouts';
@import './Animations';
@import './Utils';
@import './Themes';
@import './assets/iconsax/styles';

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

* {
  scrollbar-color: transparent rgba(0, 0, 0, 0.5);
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 10px;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}

body {
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  box-sizing: border-box;
  background-color: var(--bg-color);

  &:not(.dark):not(.light) {
    background-color: #fff;
  }

  &.widget-mode {
    background-color: #fff;

    &::-webkit-scrollbar {
      background-color: rgba(0, 0, 0, 0.7);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
    }

    #root {
      background: RGB(var(--widgetPrimaryColor));
      overflow: hidden;
    }
  }

  &.preview-widget-bg {
    background-color: transparent;
  }

  &.boosted-mode {
    background-color: transparent !important;
    height: fit-content;
    width: fit-content;
    overflow: hidden;

    &.preview-widget-bg {
      background-color: rgba(0, 0, 0, 0.7) !important;
    }

    &.boosted-pop-in {
      // height: 100%;
    }

    #root {
      width: fit-content;
    }
  }

  &.poke-in-concept {
    background-color: transparent !important;
  }

  &.poke-mode {
    background-color: transparent !important;
    height: 100%;
  }
}

#root {
  height: 100%;
  position: relative;
}

a {
  color: inherit;
  text-decoration: none;
}

.app__loading {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  .loader {
    border: 2px #121212b3 solid;
    border-top: 2px #0000001c solid;
  }
}

.app__project-not-found {
  height: 100%;
  position: relative;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  text-align: center;
  background-color: #1d1f2d;

  .title {
    font-size: 34px;
    font-weight: 200;
    margin-bottom: 12px;
    color: rgba(255, 255, 255, 0.5);
  }

  button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
  }
}

/** Stage indicator */
.stage-indicator {
  position: fixed;
  top: 0;
  left: 0;
  background-color: orange;
  height: 5px;
  width: 100%;
  color: #071331;
  font-weight: 700;
  text-align: center;
  line-height: 38px;
  z-index: 1000000;
  overflow: hidden;
  transition: all ease 100ms;

  &:hover {
    height: 38px;
  }
}
