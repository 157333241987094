.s-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  text-align: center;

  a {
    color: blue;
    text-decoration: underline;
  }
}
