.post-detail-block-user {
  display: flex;
  align-items: center;
  position: relative;
  cursor: default;

  .pb-user-avatar {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    margin-right: 8px;
  }

  .pb-user-infos {
    display: flex;
    flex-direction: column;
    color: #797979;
    font-family: Inter;
    flex: 1;

    strong {
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: left;
      color: var(--Neutral-neutral-700, #4D637B);
    }

    span {
      font-size: 12px;
      line-height: 18px;
      text-align: left;
      color: var(--Neutral-neutral-500, #9CA1AD);
    }
  }
}
