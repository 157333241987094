@import '../../Mixin';
@import './../../Responsive';

.text-area-group {
  display: block;
  position: relative;
  font-size: 16px;

  &.disabled {
    textarea {
      background-color: rgb(235, 235, 235);
    }
  }

  .icon-left {
    position: absolute;
    left: 17px;
    top: 25px;
    z-index: 20;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 21px;
  }

  textarea {
    @include placeholder {
      color: #b5b5b5;
    }

    position: relative;
    width: 100%;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 12px 16px;
    border-radius: 6px;
    box-sizing: border-box;
    font-weight: 200;
    transition: all 150ms ease-in-out;
    font-family: inherit;
    outline: none;
    box-shadow: none;
    resize: none;
  }

  &.has-icon {
    textarea {
      text-indent: 32px;
    }
  }

  &.is-typing {
    textarea {
      text-indent: 0;
    }
  }

  textarea:focus {
    @include placeholder {
      color: #b5b5b5;
    }

    border: 1px solid #071331;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.02);
  }
}

/* Remove iOS top css shadow */
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
