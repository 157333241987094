@import './../../Mixin';
@import '../../Responsive';

.input-group {
  display: block;
  position: relative;
  font-size: 16px;

  &.labeled {
    display: inline-flex;

    .label {
      padding: 15px 20px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 0.1);
      font-weight: 100;
      &.label-left {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      &.label-right {
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }

    &.labeled-left {
      .input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    &.labeled-right {
      .input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .input {
    @include placeholder {
      color: #b5b5b5;
    }
    position: relative;
    width: 100%;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px 20px;
    border-radius: 6px;
    box-sizing: border-box;
    font-weight: 300;
    font-family: inherit;
    transition: all 150ms ease-in-out;
    &[type='number'] {
      width: 70px;
      padding-left: 10px;
      padding-right: 0px;
    }
    box-shadow: none;
  }

  .input:focus {
    @include placeholder {
      color: #b5b5b5;
    }
    border: 1px solid #071331;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.02);
  }
}

.autocomplete-popover {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 5px 0;
  box-shadow: 0px 13px 54px rgba(0, 0, 0, 0.05);

  .autocomplete-item {
    padding: 8px 16px;
    cursor: pointer;
    &:not(.is-disable) {
      &:hover {
        color: #0022ff;
      }
    }
    &.is-disable {
      color: #e2e2e2;
    }
  }
}

/* Remove iOS top css shadow */
input[type='text'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
