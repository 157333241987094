@import './../../../../Responsive';

.modal-identify {
  background-color: #ffffff;
  max-width: 384px;
  padding: 24px;

  .close-wrapper {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  .emoji {
    margin-left: auto;
    margin-right: auto;
  }
  .title {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 24px;
  }
  p {
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    margin-top: 8px;
  }
  form {
    .input-group {
      margin: 12px;
    }
    .btn {
      margin-left: auto;
      margin-right: auto;
      margin-top: 24px;
    }
  }

  @media (max-width: $screen-sm) {
    position: fixed;
    bottom: 8px;
    box-sizing: border-box;
    left: 8px;
    width: calc(100% - 16px);
  }
}
