.widget-feed {
  padding: 16px;
  padding-top: 0px;
  min-height: 0;
  overflow-y: auto;
  flex: 1 auto;

  &.is-preview {
    .evolutions-wrapper {
      pointer-events: none;
    }
  }

  .empty-state-widget-feed {
    text-align: center;
    -webkit-animation: 400ms fade-in-bottom 800ms ease-out both;
    animation: 400ms fade-in-bottom 800ms ease-out both;
  }

  .btn-close-view {
    position: fixed;
    right: 24px;
    top: 24px;
    padding: 0;
    background: rgba(7, 19, 49, 0.3);
    width: 24px;
    height: 24px;
    z-index: 3;
    right: 24px;
  }

  .evolutions-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &.is-intercom {
      margin-top: 18px;
    }
  }

  &:not(.has-whitelabel) {
    margin-bottom: 36px;
  }
}
