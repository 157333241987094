.widget-feedback {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  overflow: hidden;
  &.has-announcements-enabled {
    & > .top {
      .header-title {
        padding-right: 0;
      }
    }
  }
  &.has-preview-disabled {
    .evolution-list-wrapper {
      .feedback-title {
        color: #fff;
      }
    }
  }
  &.is-preview {
    pointer-events: none;
  }

  & > .top {
    -webkit-animation: 500ms fade-in-right 500ms ease-out both;
    animation: 500ms fade-in-right 500ms ease-out both;
    padding: 24px 24px 40px 24px;

    .header-title {
      font-size: 24px;
      font-weight: 300;
      text-wrap: pretty;
      overflow: hidden;
      padding-right: 24px;
    }
    .project-members-avatar {
      margin-bottom: 14px;
      margin-top: 14px;
    }
  }

  &.is-intercom {
    .top {
      .header-title {
        color: #000;
      }
      p {
        color: rgba(0, 0, 0, 0.7);
      }
    }
    .feedback-title {
      color: #000;
    }
  }

  .feedback-title {
    color: #fff;
    font-size: 18px;
    margin-bottom: 12px;
    padding: 0 24px;
  }
  .story-list-prototype-wrapper {
    -webkit-animation: 500ms fade-in-right 800ms ease-out both;
    animation: 500ms fade-in-right 800ms ease-out both;
    margin-bottom: 40px;

    .story-list-prototype {
      & > .overlay {
        display: none;
      }
      .list {
        padding: 0 24px;
        width: calc(100% - 48px);
      }
    }
  }
  .evolution-list-wrapper {
    padding: 0px 24px;
    &.has-animation {
      -webkit-animation: 500ms fade-in-right 900ms ease-out both;
      animation: 500ms fade-in-right 900ms ease-out both;
    }

    .section-wrapper {
      &:first-child {
        .feedback-title {
          color: #fff;
        }
      }
    }
    .feedback-title {
      color: #000;
      padding: 0;
    }
    .card-push {
      margin-bottom: 16px;
    }
  }
}
