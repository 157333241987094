.max-width-600 {
  max-width: 600px;
}

.flex-col {
  display: flex;
  flex-flow: column;
}

.m-r-auto {
  margin-right: auto;
}

.m-l-auto {
  margin-left: auto;
}
