.md-renderer {
  font-size: 16px;
  padding: 8px 0;

  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  .img-wrapper {
    margin: auto;
    position: relative;
    width: fit-content;
    overflow: hidden;
    cursor: pointer;
    &.has-interactions-disabled {
      cursor: default;
    }
    &:not(.has-interactions-disabled) {
      &:hover {
        img {
          transform: scale(1.02);
        }
        i.icon-search {
          opacity: 1;
        }
      }
    }

    img {
      position: relative;
      transition: all 100ms ease;
      max-width: 100%;
    }
    i.icon-search {
      opacity: 0;
      position: absolute;
      bottom: 8px;
      right: 8px;
      border-radius: 8px;
      padding: 8px;
      background-color: rgba(0, 0, 0, 0.3);
      color: #ffffff;
      transition: all 100ms ease;
    }
  }
  .iframe-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;

    &:hover {
      iframe {
        transform: scale(1.01);
      }
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
      transition: all 100ms ease;
    }
  }
  a {
    color: #0063cc;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.modal-image-fullscreen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  background: none;
  padding: 0;

  @media screen and (max-width: 600px) {
    width: 95%;
  }
  @media screen and (min-width: 601px) and (max-width: 1200px) {
    width: 80%;
  }

  img {
    flex: 1 0 auto;
    max-width: 100%;
    max-height: 80%;
  }
}
