@import '../../Variables';

.post-block-label {
  display: flex;
  animation: block-label-in $animationBlockInDuration ease-in-out
    $animationBlockInDelay both;

  .tags-wrapper {
    flex: 1;
    gap: 8px;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-flow: wrap;

    .item-tag {
      padding: 4px 8px;
      white-space: nowrap;
      border-radius: 8px;

      font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      line-height: 14.52px;
      text-align: left;
    }
  }

  .timestamp {
    color: rgba(0, 0, 0, 0.5);
    margin-left: 8px;
    margin-left: auto;
    margin-bottom: auto;
    margin-top: auto;

    font-family: Inter;
    font-size: 10.5px;
    font-weight: 500;
    line-height: 15.75px;
    text-align: left;
  }
}

@-webkit-keyframes block-label-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes block-label-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes block-label-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes block-label-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
