@import './../../Responsive';

.jimo-stormwind-toast-container {
  width: unset;
  min-width: 400px;
  display: flex;

  @media (max-width: $screen-sm) {
    min-width: unset;
    width: unset;
    bottom: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.Toastify__toast {
  @media (max-width: $screen-sm) {
    min-height: unset;
  }
}

// This fix styles made at 6.0.8v for 7.0.3v
.Toastify__toast-body {
  margin: unset;
  padding: unset;
  width: 100%;
}

.jimo-toast-wrapper-v2 {
  color: #ffffff;
  font-family: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  border-radius: 500px;
  bottom: 137px;
  min-height: unset;
  &.toast--info {
    background-color: #0f65d0;
  }
  &.toast--success {
    background: var(--g-500);
  }
  &.toast--danger {
    background-color: #c9242d;
  }
  &.toast--warning {
    background-color: orange;
  }

  .toast-content {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;

    .left-element {
      display: flex;
      justify-content: center;
      align-items: center;

      .icon-wrapper {
        font-size: 20px;
      }
    }

    .center-element {
      font-weight: 400;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;

      .action {
        .btn {
          background-color: transparent;
          padding: 12px;
          border-radius: 4px;
          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }
        }
      }

      .second-line {
        font-size: 14px;
        font-weight: 300;
      }
    }

    .right-element {
      font-size: 8px;
      display: flex;
      justify-content: center;
      display: none;

      i {
        padding: 12px;
        border-radius: 3px;
        transition: all ease 100ms;
        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}

.jimo-toast-wrapper {
  padding: unset;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  min-height: unset;
  border-radius: 6px;
  min-width: 400px;
  color: #ffffff;
  font-family: inherit;
  &.toast--info {
    background-color: #0f65d0;
  }
  &.toast--success {
    background-color: #13805c;
  }
  &.toast--danger {
    background-color: #c9242d;
  }
  &.toast--warning {
    background-color: orange;
  }

  @media (max-width: $screen-sm) {
    border-radius: 0px;
  }

  .toast-content {
    font-size: 16px;
    display: grid;
    grid-template-columns: 50px calc(100% - 100px) 50px;
    justify-content: center;
    align-items: center;
    max-width: 430px;

    .left-element {
      background-color: rgba(255, 255, 255, 0.1);
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon-wrapper {
        font-size: 18px;
      }
    }

    .center-element {
      padding: 12px 16px;
      font-weight: 400;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .action {
        .btn {
          background-color: transparent;
          padding: 12px;
          border-radius: 4px;
          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
          }
        }
      }

      .second-line {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 300;
      }
    }

    .right-element {
      font-size: 8px;
      display: flex;
      justify-content: center;
      border-left: 1px solid rgba(255, 255, 255, 0.2);

      i {
        padding: 12px;
        border-radius: 3px;
        transition: all ease 100ms;
        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}
