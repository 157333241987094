@import '../../Variables';

.poke-block-media {
  position: relative;
  z-index: 2;
  height: 260px;
  animation: block-media-in $animationBlockInDuration ease-in-out
    $animationBlockInDelay both;

  &.is-disable-animation {
    animation: none;
  }

  &.is-animating-out {
    animation: block-media-out $animationBlockOutDuration ease-in-out both;
  }

  &.poke-block-clickable > iframe {
    pointer-events: none;
  }

  &.is-clickable {
    position: relative;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      img,
      & > div {
        transform: scale(1.02);
      }
    }

    img,
    & > div {
      transition: all 100ms ease;
    }
  }

  &.is-thumbnail {
    margin: 0;
  }

  i.icon-search {
    position: absolute;
    bottom: 8px;
    right: 8px;
    border-radius: 8px;
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.3);
    color: #ffffff;
    transition: all 100ms ease;
  }
}

// Image
.poke-block-media.is-image,
.poke-block-media.is-empty {
  &.is-fit,
  &.is-empty {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .media-file {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

// Video
.poke-block-media.is-video {
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}

@-webkit-keyframes block-media-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes block-media-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes block-media-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes block-media-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
