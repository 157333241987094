@import '../../Variables';

.post-block-title {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 24.3px;
  text-align: left;

  display: flex;
  flex-direction: column;
  gap: 12px;

  cursor: default;
  word-break: break-word;

  &.is-collapsed {
    cursor: pointer;
    display: flex;

    span {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .timestamp {
    color: rgba(0, 0, 0, 0.5);

    font-family: Inter;
    font-size: 10.5px;
    font-weight: 500;
    line-height: 15.75px;
    text-align: left;
  }
}
