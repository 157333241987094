.standalone-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
  height: 36px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--footer-border);
  @supports (
    (-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))
  ) {
    -webkit-backdrop-filter: blur(2em);
    backdrop-filter: blur(2em);
  }
  i {
    margin-right: 8px;
    font-size: 22px;
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
