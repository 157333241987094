@charset "UTF-8";

@font-face {
  font-family: 'jimo-icons';
  src: url('./fonts/jimo_icons.woff?80x2m2') format('woff');
  // url('./fonts/jimo_icons.ttf?80x2m2') format('truetype') Only for browser that do not support WOFF but very rare (might be needed if some customers are complaining)
  // url('./fonts/jimo_icons.svg?80x2m2#jimo-icons') format('svg') Only for old safari but not needed anymore
  // url('./fonts/jimo_icons.eot?80x2m2#iefix') format('embedded-opentype') Only for old IE but not needed anymore
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'jimo-icons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-side-badge:before {
  content: '\e90c';
}
.icon-float-trigger:before {
  content: '\e90d';
}
.icon-label-icon:before {
  content: '\e90b';
}
.icon-ai:before {
  content: '\e90a';
}
.icon-slot-top-bar-bold:before {
  content: '\e907';
}
.icon-slot-dot-bold:before {
  content: '\e908';
}
.icon-billing:before {
  content: '\e9e2';
}
.icon-pencil-edit-outline:before {
  content: '\e9e3';
}
.icon-user-invite:before {
  content: '\e9e4';
}
.icon-user-remove:before {
  content: '\e9e5';
}
.icon-analytics:before {
  content: '\e9e1';
}
.icon-answered:before {
  content: '\e9de';
}
.icon-not-completed:before {
  content: '\e9df';
}
.icon-not-seen:before {
  content: '\e9e0';
}
.icon-leave:before {
  content: '\e9dd';
}
.icon-view-row-compact:before {
  content: '\e9da';
}
.icon-view-row:before {
  content: '\e9db';
}
.icon-view-grid:before {
  content: '\e9dc';
}
.icon-tick-round-o:before {
  content: '\e9d9';
}
.icon-hotspot-shape-1:before {
  content: '\e9d7';
}
.icon-hotspot-shape-2:before {
  content: '\e9d8';
}
.icon-theme:before {
  content: '\e9d3';
}
.icon-download:before {
  content: '\e9d2';
}
.icon-merge:before {
  content: '\e9d1';
}
.icon-align-t::before {
  content: '\e909';
  color: #d9d9d9;
}
.icon-align-t::after {
  content: '\e96a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-align-b::before {
  content: '\e9ae';
  color: #d9d9d9;
}
.icon-align-b::after {
  content: '\e9af';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-align-b-r::before {
  content: '\e9b0';
  color: #d9d9d9;
}
.icon-align-b-r::after {
  content: '\e9b1';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-align-b-c::before {
  content: '\e9b2';
  color: #d9d9d9;
}
.icon-align-b-c::after {
  content: '\e9b3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-align-b-l::before {
  content: '\e9b4';
  color: #d9d9d9;
}
.icon-align-b-l::after {
  content: '\e9b5';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-double-vertical-menu:before {
  content: '\e9b6';
}
.icon-text-align-r:before {
  content: '\e9b7';
}
.icon-text-align-c:before {
  content: '\e9b8';
}
.icon-text-align-l:before {
  content: '\e9b9';
}
.icon-text-underline1:before {
  content: '\e9ba';
}
.icon-text-italic1:before {
  content: '\e9bb';
}
.icon-text-bold1:before {
  content: '\e9bc';
}
.icon-horizontal-menu:before {
  content: '\e9bd';
}
.icon-image-o:before {
  content: '\e9be';
}
.icon-pointer:before {
  content: '\e9bf';
}
.icon-pin:before {
  content: '\e9c0';
}
.icon-text:before {
  content: '\e9c1';
}
.icon-border-radius:before {
  content: '\e9c2';
}
.icon-stepper-line .path1:before {
  content: '\e9c3';
  color: #c1c1c1;
}
.icon-stepper-line .path2:before {
  content: '\e9c4';
  color: rgb(0, 0, 0);
}
.icon-stepper-text:before {
  content: '\e9c5';
}
.icon-stepper-line-point::before {
  content: '\e9c6';
  color: #c1c1c1;
}
.icon-stepper-line-point::after {
  content: '\e9c7';
  margin-left: -1em;
  color: #c1c1c1;
}
.icon-stepper-line-point::after {
  content: '\e9c8';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-translate:before {
  content: '\e9c9';
}
.icon-play-o:before {
  content: '\e9ca';
}
.icon-layers:before {
  content: '\e9cb';
}
.icon-go-next:before {
  content: '\e9cc';
}
.icon-heart-o:before {
  content: '\e9cd';
}
.icon-upload-file:before {
  content: '\e9ce';
}
.icon-light:before {
  content: '\e9cf';
}
.icon-close-circle:before {
  content: '\e9d0';
}
.icon-code:before {
  content: '\e98e';
}
.icon-angular-js:before {
  content: '\e9a8';
}
.icon-react-js:before {
  content: '\e9aa';
}
.icon-vue-js:before {
  content: '\e9ab';
}
.icon-vanilla-js:before {
  content: '\e9ac';
}
.icon-email:before {
  content: '\e9ad';
}
.icon-exclamation-triangle-o:before {
  content: '\e9a7';
}
.icon-usage-portal:before {
  content: '\e9a6';
}
.icon-thumbs-up:before {
  content: '\e93a';
}
.icon-usage-tooltip:before {
  content: '\e9a4';
}
.icon-usage-widget:before {
  content: '\e9a5';
}
.icon-usage-announcement:before {
  content: '\e9a9';
}
.icon-minus:before {
  content: '\e99f';
}
.icon-move:before {
  content: '\e9a0';
}
.icon-tour:before {
  content: '\e9a1';
}
.icon-forward:before {
  content: '\e9a2';
}
.icon-slide-down:before {
  content: '\e9d4';
}
.icon-slide-up:before {
  content: '\e9d5';
}
.icon-backward:before {
  content: '\e9d6';
}
.icon-target:before {
  content: '\e9a3';
}
.icon-checkbox-a:before {
  content: '\e99d';
}
.icon-checkbox-a:after {
  content: '\e99e';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-slot-snippet:before {
  content: '\e975';
}
.icon-plus-rounded:before {
  content: '\e976';
}
.icon-back:before {
  content: '\e977';
}
.icon-slot-pop-in:before {
  content: '\e997';
}
.icon-slot-tooltip:before {
  content: '\e998';
}
.icon-slot-top-bar:before {
  content: '\e902';
}
.icon-edit-outline:before {
  content: '\e99a';
}
.icon-play-rounded:before {
  content: '\e99b';
}
.icon-pause-rounded:before {
  content: '\e99c';
}
.icon-expand:before {
  content: '\e98f';
}
.icon-settings:before {
  content: '\e996';
}
.icon-users:before {
  content: '\e932';
}
.icon-portal:before {
  content: '\e92d';
}
.icon-send-o:before {
  content: '\e995';
}
.icon-upload-o:before {
  content: '\e990';
}
.icon-start-4:before {
  content: '\e991';
}
.icon-start-3:before {
  content: '\e992';
}
.icon-start-2:before {
  content: '\e993';
}
.icon-start-1:before {
  content: '\e994';
}
.icon-user-satisfaction:before {
  content: '\e98d';
}
.icon-duplicate:before {
  content: '\e98c';
}
.icon-custom-filter:before {
  content: '\e986';
}
.icon-double-tick:before {
  content: '\e987';
}
.icon-chat-string:before {
  content: '\e988';
}
.icon-at:before {
  content: '\e989';
}
.icon-string:before {
  content: '\e98a';
}
.icon-interaction:before {
  content: '\e98b';
}
.icon-customize:before {
  content: '\e984';
}
.icon-slack-brands:before {
  content: '\e985';
}
.icon-checkbox-o:before {
  content: '\e982';
}
.icon-radiobox-o:before {
  content: '\e983';
}
.icon-slot-dot:before {
  content: '\e905';
}
.icon-user-interview:before {
  content: '\e96d';
}
.icon-vote-nps:before {
  content: '\e97e';
}
.icon-vote-slider:before {
  content: '\e97f';
}
.icon-chat:before {
  content: '\e97d';
}
.icon-emoji:before {
  content: '\e97c';
}
.icon-google:before {
  content: '\ea89';
}
.icon-video:before {
  content: '\e97b';
}
.icon-calendar:before {
  content: '\e97a';
}
.icon-checkbox:before {
  content: '\e978';
}
.icon-radiobox:before {
  content: '\e979';
}
.icon-filter-announcement:before {
  content: '\e96c';
}
.icon-filter-preview:before {
  content: '\e980';
}
.icon-filter-ideation:before {
  content: '\e96e';
}
.icon-filter-all:before {
  content: '\e96f';
}
.icon-vote-open-question:before {
  content: '\e970';
}
.icon-vote-multi-choice:before {
  content: '\e971';
}
.icon-vote-opinion:before {
  content: '\e972';
}
.icon-integration-widget:before {
  content: '\e973';
}
.icon-integration-portal:before {
  content: '\e974';
}
.icon-heart:before {
  content: '\e96b';
}
.icon-give-feedback:before {
  content: '\e968';
}
.icon-view-roadmap:before {
  content: '\e969';
}
.icon-roadmap:before {
  content: '\e967';
}
.icon-question-circle-o:before {
  content: '\e916';
}
.icon-bell-o:before {
  content: '\e966';
}
.icon-feed:before {
  content: '\e965';
}
.icon-evolutions:before {
  content: '\e963';
}
.icon-inbox:before {
  content: '\e964';
}
.icon-options:before {
  content: '\e962';
}
.icon-line-corner:before {
  content: '\e961';
}
.icon-line-straight:before {
  content: '\e95f';
}
.icon-line-round:before {
  content: '\e960';
}
.icon-star:before {
  content: '\e95e';
}
.icon-arrow-up:before {
  content: '\e95d';
}
.icon-eye:before {
  content: '\e944';
}
.icon-question-circle:before {
  content: '\e900';
}
.icon-exclamation-circle:before {
  content: '\e901';
}
.icon-image:before {
  content: '\e951';
}
.icon-text-bold:before {
  content: '\e952';
}
.icon-text-italic:before {
  content: '\e953';
}
.icon-text-underline:before {
  content: '\e954';
}
.icon-text-strike:before {
  content: '\e955';
}
.icon-list-bullet:before {
  content: '\e956';
}
.icon-alt-link:before {
  content: '\e957';
}
.icon-list-numbered:before {
  content: '\e958';
}
.icon-info-circle-o:before {
  content: '\e904';
}
.icon-tick:before {
  content: '\e950';
}
.icon-lock:before {
  content: '\e94f';
}
.icon-copy:before {
  content: '\e917';
  color: rgb(196, 196, 196);
}
.icon-copy:after {
  content: '\e918';
  margin-left: -0.94140625em;
  color: rgb(229, 229, 232);
}
.icon-bell:before {
  content: '\e94c';
}
.icon-comment-o:before {
  content: '\e94d';
}
.icon-detect:before {
  content: '\e94b';
}
.icon-upload:before {
  content: '\e94a';
}
.icon-send:before {
  content: '\e949';
}
.icon-comment:before {
  content: '\e948';
}
.icon-close:before {
  content: '\e935';
}
.icon-goto:before {
  content: '\e947';
}
.icon-link:before {
  content: '\e946';
}
.icon-jimo:before {
  content: '\e945';
}
.icon-info-circle:before {
  content: '\e941';
}
.icon-tick-circle:before {
  content: '\e942';
}
.icon-trash:before {
  content: '\e903';
}
.icon-edit:before {
  content: '\e92b';
}
.icon-overview:before {
  content: '\e93f';
}
.icon-evolution:before {
  content: '\e940';
}
.icon-figma-brands:before {
  content: '\e93d';
}
.icon-invision-brands:before {
  content: '\e93e';
}
.icon-union:before {
  content: '\e959';
}
.icon-time:before {
  content: '\e939';
}
.icon-thumbs-up:before {
  content: '\e93a';
}
.icon-play:before {
  content: '\e93b';
}
.icon-startup:before {
  content: '\e93c';
}
.icon-filter:before {
  content: '\e938';
}
.icon-goto-rect:before {
  content: '\e937';
}
.icon-configuration:before {
  content: '\e936';
}
.icon-info:before {
  content: '\e933';
}
.icon-exclamation:before {
  content: '\e934';
}
.icon-plan:before {
  content: '\e92e';
}
.icon-integrations:before {
  content: '\e92f';
}
.icon-ip-blocking:before {
  content: '\e930';
}
.icon-profile:before {
  content: '\e931';
}
.icon-projects:before {
  content: '\e92c';
}
.icon-brand-chrome:before {
  content: '\e906';
}
.icon-quotation:before {
  content: '\e90c';
}
.icon-event-click:before {
  content: '\e90d';
}
.icon-clock:before {
  content: '\e90e';
}
.icon-event-naviguate:before {
  content: '\e90f';
}
.icon-share:before {
  content: '\e910';
}
.icon-arrow-left:before {
  content: '\e911';
}
.icon-arrow-right:before {
  content: '\e912';
}
.icon-fullscreen:before {
  content: '\e913';
}
.icon-tag:before {
  content: '\e914';
}
.icon-menu-vertical:before {
  content: '\e915';
}
.icon-search:before {
  content: '\e919';
}
.icon-chevron-left:before {
  content: '\e91a';
}
.icon-chevron-right:before {
  content: '\e91b';
}
.icon-brand-opera:before {
  content: '\e91c';
}
.icon-message:before {
  content: '\e91d';
}
.icon-heatmap:before {
  content: '\e91e';
}
.icon-replay:before {
  content: '\e91f';
}
.icon-live:before {
  content: '\e920';
}
.icon-overview-old:before {
  content: '\e921';
}
.icon-tablet:before {
  content: '\e922';
}
.icon-mobile:before {
  content: '\e923';
}
.icon-brand-windows:before {
  content: '\e924';
}
.icon-brand-linux:before {
  content: '\e925';
}
.icon-brand-safari:before {
  content: '\e926';
}
.icon-brand-firefox:before {
  content: '\e927';
}
.icon-brand-explorer:before {
  content: '\e928';
}
.icon-brand-edge:before {
  content: '\e929';
}
.icon-browser:before {
  content: '\e92a';
}
.icon-brand-android:before {
  content: '\e94e';
}
.icon-camera:before {
  content: '\e95a';
}
.icon-chevron-bottom:before {
  content: '\e95b';
}
.icon-plus:before {
  content: '\e95c';
}
