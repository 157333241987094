.widget-bg-circle-wrapper {
  overflow: hidden;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  animation: appear-top 1000ms ease-in-out;
  &.for-feed {
    &.is-empty {
      .widget-bg-circle {
        padding-bottom: 90%;
      }
    }
    .widget-bg-circle {
      padding-bottom: 50%;
      transform-origin: center 115%;
    }
  }
  &.for-feedback {
    padding-bottom: 130%;
    .widget-bg-circle {
      transform-origin: center 90%;
    }
  }
  &.for-evolution {
    padding-bottom: 130%;
    .widget-bg-circle {
      background-color: transparent;
      padding-bottom: 31%;
      transform-origin: center 115%;
      border-radius: 35%;
    }
  }
  &.for-roadmap {
    .widget-bg-circle {
      padding-bottom: 90%;
      transform-origin: center 115%;
    }
  }

  .widget-bg-circle {
    width: 100%;
    padding-bottom: 100%;
    background-color: RGB(var(--widgetSecondaryColor));
    border-radius: var(--widgetRoundnessHeader);
    z-index: 1;
    transform: scale(3);
    transition: all 500ms ease;
  }
}

.widget-gradient {
  position: absolute;
  z-index: 0;
  top: 0;
  right: -30px;
  top: -35%;
  transition: all 300ms ease-in-out;
  animation: block-gradient-move 3.2s ease-in-out infinite;
  border-radius: 20000px;
  filter: blur(32px);
  width: 60%;
  height: 66%;
}

@-webkit-keyframes block-gradient-move {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes block-gradient-move {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
