.card {
  background-color: #fff;
  border: 1px solid #fff;
  box-shadow: 0 0.75rem 1rem rgba(12, 23, 37, 0.02);
  border-radius: 8px;
  padding: 24px;

  &.muted {
    background-color: #F0F2F5;
    border-color: transparent;
    padding: 12px 16px 12px 16px;
    gap: 4px;
    border-radius: 16px;

  }
}
