@import './../../../../Responsive';

.standalone-home-top-nav {
  background-color: var(--top-nav-bg);
  border-bottom: 1px solid rgba(var(--base-reverse), 0.1);
  text-transform: uppercase;
  justify-content: center;
  font-weight: 500;
  margin-bottom: 32px;

  &.is-hidden {
    visibility: hidden;
    height: 0;
  }

  &.has-only-one-item {
    .content {
      padding: 8px 0;
    }

    .box.middle {
      visibility: hidden;
    }
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .item-wrapper {
    display: flex;

    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 1 0px;
      padding: 30px 0;
      color: rgba(var(--text-color), 0.5);
      cursor: pointer;
      border-bottom: 3px solid transparent;
      transition: all ease-in-out 150ms;

      &:not(:last-child) {
        margin-right: 12px;
      }

      &.is-active {
        color: var(--top-nav-item-color);
        border-bottom: 3px solid var(--top-nav-item-color);
      }

      &:hover {
        color: var(--top-nav-item-color);
        border-bottom: 3px solid var(--top-nav-item-color);
      }

      .icon-feed {
        margin-right: 6px;
      }
    }
  }

  .content {
    margin: auto;
    max-width: 1140px;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-auto-flow: column;

    @media (max-width: $screen-lg) {
      margin: 0;
    }

    .box {
      display: flex;
      flex: 1;
      justify-content: center;
    }

    .box.item-wrapper {
      flex-basis: auto;
    }

    .project-wrapper {
      margin-right: auto;
      margin-left: 15px;
      align-self: center;
    }

    .project-logo {
      display: flex;
      flex: 0 0 150px;
      height: 40px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .avatar {
      display: flex;
      flex: 0 0 36px;
      height: 36px;
      cursor: pointer;
      background-color: #f87740;
      border-radius: 50%;
      font-weight: 300;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-size: 25px;
      color: #ffffff;
      margin-left: auto;
      margin-right: 15px;
      align-self: center;

      &.not-identified {
        background-image: url(./imgs/avatar.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-color: unset;
        border-radius: unset;
      }
    }
  }

  @media (max-width: $screen-md) {
    & {
      flex: 0 0 38px;
      border-bottom: unset;
      box-sizing: border-box;
    }

    &>.row>div {
      padding: 0;
    }
  }

  @media (max-width: $screen-sm) {
    border-bottom: none;
    margin-bottom: 16px;
    font-size: 14px;
    padding: 0 20px;

    &:not(.no-padding-top) {
      padding-top: 20px;
    }

    &.is-hidden-mobile {
      display: none;
    }

    .item-wrapper {
      padding: 0 16px;

      .item {
        &.is-active {
          color: var(--top-nav-item-color);
          border-bottom: 3px solid var(--top-nav-item-color);
        }

        &:hover {
          color: var(--top-nav-item-color);
          border-bottom: 3px solid var(--top-nav-item-color);
        }
      }
    }

    .content {
      flex-wrap: wrap;

      .left {
        width: 50%;
      }

      .middle {
        width: 100%;
        order: 3;

        .item {
          padding: 23px 0;
        }
      }

      .right {
        width: 50%;
        order: 2;
      }
    }
  }
}
