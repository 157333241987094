@import '../../../Responsive';

.s-standalone-evolution {
  overflow: hidden;
  padding-bottom: 52px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.is-white-labeled {
    padding-bottom: 18px;
  }

  &>.row {
    overflow: hidden;
    height: 100%;
    text-align: left;
  }

  &.is-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .col-card {
    height: 100%;
    position: relative;
    padding: 0;
    flex: 1;
  }

  .btn-back {
    position: absolute;
    z-index: 2;
    left: -72px;
  }

  .card-push {
    overflow-y: hidden;
    height: 100%;
    padding: unset;
    display: flex;
    flex-flow: column;
  }

  @media (max-width: $screen-sm) {
    position: relative;
    padding-bottom: 32px;
    height: 100%;

    &>.row {
      margin: 0;
    }

    .col-card {
      padding: 0;
    }

    .btn-back {
      background-color: rgba(7, 19, 49, 0.3);
      left: 16px;
      top: 16px;
    }

    .card-evolution-content {
      border-radius: 0;
    }

    .card-push {
      .survey {
        padding-top: 42px;
      }
    }
  }
}

.survey-modal {
  background-color: white;
  padding: 0;
}
