@import '../../Variables';

.post-block-cta {
  display: inline-flex;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  border: 1px solid transparent;
  white-space: nowrap;
  margin: auto;
  padding: 12px 24px;

  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}
