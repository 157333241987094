@import '../../../Responsive';

.s-standalone-feedback {
  flex: 1 1 auto;
  margin: auto;
  max-width: 1140px;
  padding-bottom: 40px;

  @media (max-width: 1146px) {
    margin: 0;
  }

  &.is-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.has-no-evolutions {
    padding-bottom: 74px;

    &>.row {
      margin-left: 0;
      margin-right: 0;
      padding: 0 16px;
    }
  }

  &.modal-submit-request-open {
    overflow: hidden;
  }

  .feedback-content {
    padding: 20px;

    @media (max-width: $screen-sm) {
      padding: 0 20px;
      margin-top: 16px;
    }
  }

  .row-content {
    margin-left: 0;
    margin-right: 0;
    text-align: left;

    &>div {
      position: relative;
    }

    @media (max-width: $screen-sm) {
      padding: 0 16px;

      &>div {
        padding: 0;
        width: 100%;
      }
    }
  }

  .feedback-empty-state {
    font-size: 18px;

    .title {
      font-size: 24px;
    }

    p {
      margin: 16px 0;
      font-weight: 300;
    }

    .project-members-avatar {
      margin-bottom: 26px;
    }
  }

  .btn-submit-request {
    position: relative;
    left: 0;
    width: 100%;
    margin-bottom: 24px;

    @media (min-width: $screen-xl) {
      display: none;
    }

    @media (max-width: $screen-sm) {
      max-width: unset;
      width: 100%;
      margin-bottom: 16px;

      .title {
        font-size: 20px;
      }

      .project-members-avatar {
        margin: 12px 0 18px 0;
      }

      .btn {
        font-size: 14px;
      }
    }
  }

  .submit-idea-btn {
    font-size: 18px;
  }

  .vote-features-btn {
    font-size: 18px;
    box-shadow: 0px 16px 16px rgba(12, 23, 37, 0.02);
    border-color: transparent;

    @media (max-width: $screen-sm) {
      display: none;
    }
  }

  .section-title {
    font-size: 18px;
    color: RGB(var(--text-color));
    margin-bottom: 16px;
    margin-top: 32px;

    &:nth-child(2),
    &:nth-child(1) {
      margin-top: 0;
    }

    @media (max-width: $screen-md) {
      margin-top: 32px;
    }
  }

  .feedback-header {
    padding: 40px 0;
    margin: 0;
    margin-bottom: 60px;
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;

    .left {
      align-self: center;
      padding: 0;

      .title {
        font-size: 48px;
        color: rgb(var(--text-color));

        @media (max-width: $screen-sm) {
          font-size: 36px;
          font-weight: 500;
        }
      }

      .subtitle {
        font-size: 18px;
        padding: 12px 0;
        color: rgb(var(--text-color));
      }

      .actions {
        display: flex;
        padding: 12px 0;

        button {
          border-radius: 16px;
          margin-right: 24px;
          padding: 16px;
        }
      }
    }

    @media (max-width: $screen-md) {
      padding: 0;
      margin-bottom: 32px;
    }
  }

  .right {
    height: 315px;
    position: relative;

    @media (max-width: $screen-md) {
      display: none;
    }

    .face {
      border-radius: 1000px;
      position: absolute;
    }

    .face1 {
      left: 150px;
      top: 40px;
      animation: 800ms fade-in 1100ms ease-out both;
    }

    .face2 {
      left: 160px;
      top: 240px;
      animation: 800ms fade-in 900ms ease-out both;
    }

    .face3 {
      left: 270px;
      top: 160px;
      animation: 800ms fade-in 500ms ease-out both;
    }

    .face4 {
      left: 360px;
      top: 0px;
      animation: 800ms fade-in 700ms ease-out both;
    }

    .face5 {
      left: 400px;
      top: 200px;
      animation: 800ms fade-in 300ms ease-out both;
    }
  }

  .feedback-row {
    display: flex;
    margin: 15px 0;

    .section-title {
      font-size: 24px;
      display: flex;
      flex: 1 1 auto;
      width: 100%;
      margin: 0 0 36px 0;

      .divider {
        width: auto;
        flex: 1 1 auto;
        align-self: center;
        margin-left: 100px;
      }

      @media (max-width: $screen-sm) {
        font-size: 16px;
        margin: 0 0 16px 0;

        .divider {
          display: none;
        }
      }
    }

    .prototype-list {
      display: inline-flex;
      width: 100%;
      margin-bottom: 30px;

      .list {
        width: calc(100% + 8px);
        margin-right: -4px;
        margin-left: -4px;

        .shift-wrapper {
          width: 100%;
        }

        .prototype-carousel-card {
          width: 33.33%;
          min-height: 400px;
          height: auto;
          margin-right: 0px;
          padding: 8px;
          box-sizing: border-box;
          background-clip: content-box;
          border-radius: 18px;
          background-color: rgb(226, 226, 226, 0.6);

          .card {
            height: 100%;
            box-sizing: border-box;

            .infos {
              .content {
                align-items: start;

                p {
                  overflow: hidden;
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                }
              }
            }
          }
        }
      }
    }

    .ideations {
      width: 100%;
    }

    .ideation-list {
      margin-bottom: 48px;
      margin-left: -4px;
      margin-right: -4px;
      display: flex;
      flex-wrap: wrap;

      ul {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-template-rows: 1fr min-content;
        grid-gap: 16px;
        list-style: none;
        padding: 0;
        margin: 0 8px;

        li {
          height: auto;
          position: relative;

          .ideas-card {
            height: 240px;

            .card {
              box-sizing: border-box;

              .content-wrapper {
                height: 100%;

                .survey {
                  height: 100%;

                  .text-block-wrapper {
                    .md-renderer {
                      -webkit-line-clamp: unset;
                    }
                  }
                }
              }
            }

            &:not(.focused) {
              .survey-scroll-wrapper {
                display: flex;
                flex-direction: column;
                flex: 1 auto;

                .text-block-wrapper {
                  flex: 1 auto;
                  display: flex;
                  flex-direction: column;

                  .evolution-stats {
                    margin-top: auto;
                  }
                }
              }
            }

            &.focused {
              .text-block-wrapper {
                .md-renderer {
                  -webkit-line-clamp: unset;
                }
              }
            }
          }

          .overlay-read-more {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg,
                rgba(255, 255, 255, 0) 0%,
                #ffffff 76.56%);
            display: flex;
            justify-content: center;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;

            .btn {
              align-self: flex-end;
              margin-bottom: 12px;
              background-color: #e5e5e5;
              padding: 8px;
              text-transform: uppercase;
              font-size: 14px;

              &:hover {
                background-color: #c9c9c9;
              }
            }
          }
        }

        @media (max-width: $screen-md) {
          margin: 0;
        }

        li {
          .card {
            .main-wrapper {
              flex: 1 1 auto;
            }

            p {
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;
            }
          }
        }
      }

      @media (max-width: $screen-md) {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.modal-submit-request.centered {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 100%;

  .card-submit-request {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 360px;

    @media (max-width: $screen-sm) {
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      transform: none;
      box-sizing: border-box;
    }

    @media (min-width: $screen-sm) {
      max-width: 384px;
    }
  }

  @media (max-width: $screen-sm) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
