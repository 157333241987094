.jimo-post {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #fff;
  box-sizing: border-box;
  border: 1.07px solid var(--border-minimal, #E5E5E5);
  box-shadow: 0px 5.34px 16.03px 0px #00000012;

  &.is-feed {
    border: none;
  }

  &.is-collapsed {
    min-height: 50px;
    cursor: pointer;

    .jimo-post-content {
      display: flex;
      padding: 16px;

      flex-direction: column;
      gap: 12px;
      box-sizing: border-box;
    }
  }

  .jimo-post-content {
    display: flex;
    padding: 12px 16px 20px 16px;

    flex-direction: column;
    gap: 12px;
    box-sizing: border-box;
  }

  .overlay-read-more {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 50%, #fff 80%);
    display: flex;
    justify-content: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .btn {
      align-self: flex-end;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      font-family: Inter;
      font-size: 12px;
      font-weight: 600;
      border-radius: 8px;

      i {
        margin-left: 6px;
        font-size: 9px;
      }

      &:hover {
        background-color: #f2f2f2;
      }

    }
  }
}
