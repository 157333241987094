/* BREAKPOINTS */
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;

/* DISPLAY */
.hidden-xs {
  display: none;
}
.visible-xs {
  display: block;
}
@media (min-width: 0) and (max-width: $screen-sm) {
  .hidden-xs-only {
    display: none;
  }
}
@media (min-width: $screen-sm) {
  .hidden-sm {
    display: none;
  }
  .visible-sm {
    display: block;
  }
}
@media (min-width: $screen-md) {
  .hidden-md {
    display: none;
  }
  .visible-md {
    display: block;
  }
}
@media (min-width: $screen-lg) {
  .hidden-lg {
    display: none;
  }
  .visible-lg {
    display: block;
  }
}
@media (min-width: $screen-xl) {
  .hidden-xl {
    display: none;
  }
  .visible-xl {
    display: block;
  }
}
