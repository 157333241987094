/* Title */
.title-1,
.title-2,
.title-3,
.title-4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  line-height: 120%;
}

.title-1 {
  font-size: 48px;
}

.title-2 {
  font-size: 40px;
}

.title-3 {
  font-size: 32px;
}

.title-4 {
  font-size: 24px;
}

/* Subtitle */
.subtitle-1,
.subtitle-2,
.subtitle-3,
.subtitle-4 {
  font-family: 'Montserrat', sans-serif;
  line-height: 125%;
}

.subtitle-1,
.subtitle-2,
.subtitle-3,
.subtitle-4 {
  font-weight: 600;
}

.subtitle-1 {
  font-size: 24px;
}

.subtitle-2 {
  font-size: 20px;
}

.subtitle-3 {
  font-size: 16px;
}

.subtitle-4 {
  font-size: 14px;
}

/* Body */
.body-1,
.body-2,
.body-3,
.body-4 {
  font-family: 'Inter', sans-serif;
  line-height: 150%;
  font-weight: 500;
}

.body-1 {
  font-size: 20px;
}

.body-2 {
  font-size: 16px;
}

.body-3 {
  font-size: 14px;
}

.body-4 {
  font-size: 12px;
}

/* Shadow */
.sw-1 {
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.04);
}

.sw-2 {
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
}

.sw-3 {
  box-shadow: 0px 5px 28px 0px rgba(0, 0, 0, 0.09);
}

.sw-4 {
  box-shadow: 0px 12px 38px 0px rgba(0, 0, 0, 0.2);
}

/* Colors */
$colors: (
  'white': '#fff',

  'n-50': '#FBFBFB',
  'n-100': '#F0F2F4',
  'n-200': '#ECECEC',
  'n-300': '#E5E5E5',
  'n-400': '#CCCCCC',
  'n-500': '#9CA1AD',
  'n-600': '#7C8A99',
  'n-700': '#4D637B',
  'n-800': '#071331',

  'b-100': '#F3F7FE',
  'b-150': '#DAEEFF',
  'b-200': '#BCDDFF',
  'b-300': '#85B1FF',
  'b-400': '#1260EB',
  'b-500': '#012ABB',

  'o-100': '#FFF9F3',
  'o-200': '#FDF2E6',
  'o-300': '#FCE4C8',
  'o-400': '#FFB968',
  'o-500': '#F88601',

  'y-100': '#FFFCF5',
  'y-200': '#FDF8C9',
  'y-300': '#FFE7A4',
  'y-400': '#F9D777',
  'y-500': '#FFC319',

  'p-100': '#F5F2FE',
  'p-200': '#ECE6FD',
  'p-300': '#CEB1E5',
  'p-400': '#BB68FF',
  'p-500': '#590E95',

  'g-100': '#E6FDE8',
  'g-200': '#C4E9BF',
  'g-300': '#98D28F',
  'g-400': '#159201',
  'g-500': '#167322',

  'r-100': '#FCF5F7',
  'r-200': '#FFD7D7',
  'r-300': '#FA7575',
  'r-400': '#FF4646',
  'r-500': '#B90808',
);

body {
  @each $name, $color in $colors {
    --#{$name}: #{$color};
  }
}

.white {
  color: var(--white);
}

.n-50 {
  color: var(--n-50);
}

.n-100 {
  color: var(--n-100);
}

.n-200 {
  color: var(--n-200);
}

.n-300 {
  color: var(--n-300);
}

.n-400 {
  color: var(--n-400);
}

.n-500 {
  color: var(--n-500);
}

.n-600 {
  color: var(--n-600);
}

.n-700 {
  color: var(--n-700);
}

.n-800 {
  color: var(--n-800);
}

.b-100 {
  color: var(--b-100);
}

.b-200 {
  color: var(--b-200);
}

.b-300 {
  color: var(--b-300);
}

.b-400 {
  color: var(--b-400);
}

.b-500 {
  color: var(--b-500);
}

.o-100 {
  color: var(--o-100);
}

.o-200 {
  color: var(--o-200);
}

.o-300 {
  color: var(--o-300);
}

.o-400 {
  color: var(--o-400);
}

.o-500 {
  color: var(--o-500);
}

.y-100 {
  color: var(--y-100);
}

.y-200 {
  color: var(--y-200);
}

.y-300 {
  color: var(--y-300);
}

.y-400 {
  color: var(--y-400);
}

.y-500 {
  color: var(--y-500);
}

.p-100 {
  color: var(--p-100);
}

.p-200 {
  color: var(--p-200);
}

.p-300 {
  color: var(--p-300);
}

.p-400 {
  color: var(--p-400);
}

.p-500 {
  color: var(--p-500);
}

.g-100 {
  color: var(--g-100);
}

.g-200 {
  color: var(--g-200);
}

.g-300 {
  color: var(--g-300);
}

.g-400 {
  color: var(--g-400);
}

.g-500 {
  color: var(--g-500);
}

.r-100 {
  color: var(--r-100);
}

.r-200 {
  color: var(--r-200);
}

.r-300 {
  color: var(--r-300);
}

.r-400 {
  color: var(--r-400);
}

.r-500 {
  color: var(--r-500);
}
