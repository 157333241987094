.post-details {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &.standalone {
    background-color: #fff;
    border-radius: 8px;

    .jimo-post-detail {
      padding-top: 0 !important;
      box-shadow: none;
      background-color: #fff !important;
    }
  }

  &:not(.standalone) {
    .jimo-post-detail {
      border-radius: 0 !important;
      box-shadow: none !important;
      padding-top: 60px !important;
      background-color: #fff !important;
    }
  }

  .post-scrollable-wrapper {
    overflow-y: auto;
    flex: 1;
    overflow-x: hidden;

    .comments-wrapper {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding: 28px 24px 16px 24px;
      word-break: break-all;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.is-loading {
        display: flex;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;

        .loader {
          border-top: 2px #071431 solid;
        }
      }

    }
  }

  .form-comment-wrapper {
    box-shadow: 0px -6px 6px 0px #00000005;
    background: var(--Neutral-neutral-100, #F0F2F4);
    padding: 12px 16px;
    gap: 12px;
    border-top: 1px solid #00000014;

    &.evolution-has-no-comments {
      box-shadow: unset;
    }
  }
}
