.evolution-wrapper {
  display: flex;
  flex-flow: column;
  height: 100%;
  padding-bottom: 36px;
  box-sizing: border-box;
  background-color: #fff;

  &.is-loading {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;

    .loader {
      border-top: 2px #071431 solid;
    }
  }

  &.no-padding-bottom {
    padding-bottom: 0;
  }

  .card-push {
    height: 100%;
    box-sizing: border-box;

    &>.card {
      padding-top: 48px !important;
      box-sizing: border-box;
    }
  }

  .divider {
    margin-top: 32px;
  }

  .card-push {
    border-radius: 0 !important;
    box-shadow: none !important;

    .card {
      border-radius: 0 !important;
      box-shadow: none !important;
      border: none !important;
      background: #fff !important;
    }
  }
}
