@import '../../../../Responsive';

.btn-submit-request {
  background-color: RGB(var(--welcome-bg-color));
  padding: 24px;
  box-sizing: border-box;
  border-radius: 8px;
  min-width: 360px;

  &.is-fixed {
    position: fixed;
    bottom: 52px;
    right: 16px;
    z-index: 1;
    width: 22%;
    &.is-low-bottom {
      bottom: 16px;
    }
  }
  @media (max-width: $screen-xl) {
    &.is-fixed {
      display: none;
    }
  }
  @media (max-width: $screen-md) {
    right: unset;
    width: 100%;
    padding: 16px;
    padding-bottom: 16px;
    &.is-hidden-mobile {
      display: none;
    }
  }

  .title {
    font-size: 24px;
    color: rgba(var(--base-reverse), 1);
  }
  p {
    color: rgba(var(--base-reverse), 0.7);
    margin-bottom: 0;
  }
  .project-members-avatar {
    margin: 24px 0 0 0;
  }

  .btn {
    width: 100%;
    justify-content: center;
    border-radius: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 18px;
    margin-top: 24px;
  }
}

.modal-submit-request {
  width: unset;
  left: unset;
  padding: 0;
  min-width: 360px;
  position: fixed;
  bottom: 52px;
  right: 16px;
  z-index: 2;
  background-color: transparent;
  &.is-low-bottom {
    bottom: 16px;
  }
  @media (max-width: $screen-md) {
    width: calc(100% - 12px);
    right: 6px;
    bottom: 6px;
  }

  .card-submit-request {
    padding: 16px;
    border: none;
  }
}
.overlay-modal-submit-request {
  @media (min-width: $screen-md) {
    position: unset;
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;
    background-color: unset;
    display: unset;
    align-items: unset;
    justify-content: unset;
    z-index: unset;
  }
}
