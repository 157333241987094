.loader {
  display: block;
  height: 50px;
  width: 50px;
  border: 2px rgba(var(--base-reverse), 0.1) solid;
  border-top: 2px RGB(var(--base-reverse)) solid;
  border-radius: 50%;
  -webkit-animation: loader-spin 650ms infinite linear;
  animation: loader-spin 650ms infinite linear;
}

@-webkit-keyframes loader-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes loader-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
