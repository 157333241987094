@import './../../../Responsive';

.s-standalone-feed {
  @media (max-width: $screen-md) {
    padding: 0 16px;

    .evolutions-wrapper {
      & > div {
        margin-bottom: 16px !important;
      }
    }
  }
  .evolutions-wrapper {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 72px;
    & > div {
      margin-bottom: 24px;
    }
  }
  .feed-empty-state {
    font-size: 18px;

    .title {
      font-size: 24px;
    }
    p {
      margin: 16px 0;
      font-weight: 300;
    }
  }
}
