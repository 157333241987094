.project-members-avatar {
  display: flex;

  .member {
    position: relative;
    text-transform: uppercase;
    border-radius: 50%;
    background-color: white;
    color: #000;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    &.has-avatar {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    &:nth-child(n + 1) {
      background-color: #e6f5fd;
    }
    &:nth-child(n + 2) {
      background-color: #fdeee6;
    }
    &:nth-child(n + 3) {
      background-color: #eff2f5;
    }
    &:nth-child(n + 4) {
      background-color: #fafafa;
    }
    // Explain this loop (shift on the right)
    $count: 2;
    @while $count < 50 {
      &:nth-child(#{$count}) {
        left: (-6 * ($count - 1)) + px;
      }
      $count: $count + 1;
    }
    // Explain this loop (z-index effect from left to right, default is from right to left)
    $count: 1;
    @while $count < 50 {
      &:nth-child(#{$count}) {
        z-index: $count;
      }
      $count: $count + 1;
    }
  }
}
