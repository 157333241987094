@import '../../Responsive';

.btn.vote-clap {
  position: relative;
  padding: 4px 8px;
  padding-left: 24px;
  border-radius: 8px;
  color: #000 !important;
  align-items: center;
  font-weight: 500;
  background-color: transparent;
  font-size: 16px;
  height: 26px;

  &:hover {
    border-color: rgb(182, 182, 182);
    background-color: transparent;
  }

  & > div {
    // div added by lottie
    position: absolute !important;
    left: -10px;
    // top: -10px;
  }

  svg {
    margin-right: 0;
  }

  .like-text-full {
    display: none;
  }

  .like-text {
    display: inline-block;
    font-size: 12px;
    font-family: Inter;
    font-weight: 500;
    margin-right: 0;
  }
}
