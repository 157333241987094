.comment-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.comment {
  display: flex;
  gap: 8px;
  flex: 1;

  .avatar-img-wrapper {
    margin-top: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 22px;
    }
  }

  .comment-content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .item {
      .card {
        display: flex;
        flex-direction: column;
        padding: 12px 16px 12px 16px;
        gap: 4px;
        border-radius: 16px;
        background: var(--n-100);

        .top-wrapper {
          display: flex;
          justify-content: space-between;

          .author {
            font-weight: 700;
            color: var(--n-600);
          }

          .date-posted {
            font-weight: 500;
            color: var(--n-600);
          }
        }

        .message {
          font-weight: 500;
          white-space: pre-wrap;
          word-break: break-word;
        }
      }

      .actions {
        display: flex;

        .btn {
          font-weight: 500;
          color: var(--n-500);
        }

        .btn-like {
          &.is-liked {
            color: var(--r-500);
          }
        }
      }
    }

    .replies {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .reply-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .input-comment-reply-wrapper {
        display: flex;
        gap: 8px;
        align-items: center;

        .profile {
          margin-top: unset;
        }

        .input-comment-reply {
          flex: 1;

          .input {
            border-radius: 8px;
            font-weight: 500;
            font-family: Inter;
            padding: 8px 12px;
            &::placeholder {
              color: var(--n-500);
            }
          }
        }
      }

      .button-group-actions {
        display: flex;
        justify-content: flex-end;
        gap: 12px;

        .btn {
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 600;
          line-height: 17.5px;
          padding: 8px 12px;
          border-radius: 8px;
          display: flex;
          align-items: center;
        }

        .is-primary {
          background: var(--n-800);
        }
      }
    }
  }
}
