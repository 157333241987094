.btn-widget-make-suggestion {
  width: 100%;
  justify-content: center;
  border-radius: var(--widgetRoundnessCta);
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 18px;
  align-items: center;
  -webkit-animation: 200ms fade-in-top ease-out both;
  animation: 200ms fade-in-top ease-out both;
  &.is-primary {
    background-color: rgba(var(--widgetPrimaryColor), 1);

    &:hover {
      background-color: rgba(var(--widgetPrimaryColor), 0.9);
    }
    &:active {
      background-color: rgba(var(--widgetPrimaryColor), 1);
    }
  }
  &.fixed {
    position: fixed;
    left: 12px;
    top: 84px;
    width: calc(100% - 24px);
    z-index: 5;
  }

  i {
    font-size: 24px;
  }
}
