// Let's define some themes
// Using RGB syntax so CSS variables works with rgba
$themes: (
  'light': (
    'bg-color': #f3f5f7,
    'text-color': (
      0,
      0,
      0,
    ),
    'base': (
      255,
      255,
      255,
    ),
    'base-reverse': (
      0,
      0,
      0,
    ),
    'story-list-prototype-gradient-1': (
      240,
      242,
      245,
    ),
    'story-list-prototype-gradient-2': (
      255,
      255,
      255,
    ),
    'welcome-bg-color': (
      255,
      255,
      255,
    ),
    'footer-gradient-1': (
      247,
      247,
      247,
    ),
    'footer-gradient-2': (
      255,
      255,
      255,
    ),
    'footer-border': #f0f0f0,
    'standalone-header-bg': #fff,
    'top-nav-bg': #fff,
    'top-nav-item-color': #1260eb,
  ),
  'dark': (
    'bg-color': #1d1f2d,
    'text-color': (
      255,
      255,
      255,
    ),
    'base': (
      0,
      0,
      0,
    ),
    'base-reverse': (
      255,
      255,
      255,
    ),
    'story-list-prototype-gradient-1': (
      29,
      31,
      45,
    ),
    'story-list-prototype-gradient-2': (
      29,
      31,
      45,
    ),
    'welcome-bg-color': (
      32,
      42,
      63,
    ),
    'footer-gradient-1': (
      0,
      0,
      0,
    ),
    'footer-gradient-2': (
      84,
      84,
      84,
    ),
    'footer-border': transparent,
    'standalone-header-bg': #292c40,
    'top-nav-bg': #292c40,
    'top-nav-item-color': #fff,
  ),
);

// Let's define some CSS variable to use later
@each $theme-name, $theme-var in $themes {
  body.#{$theme-name} {
    @each $name, $color in $theme-var {
      --#{$name}: #{$color};
    }
  }
}
