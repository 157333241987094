@import './../../Mixin';

.form-comment {
  display: flex;
  align-items: center;
  &.is-disabled {
    .text-area-group {
      textarea {
        @include placeholder {
          color: rgba(0, 0, 0, 0.3);
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }

  .text-area-group {
    flex: 1 1 auto;

    textarea {
      width: 100%;
      border-radius: 12px;
      font-weight: 500;
      border: none;
      font-family: Inter;
      &::placeholder {
        font-weight: 400;
        color: var(--n-700);
      }
    }
  }

  .icon-wrapper {
    cursor: pointer;
    font-size: 24px;
    color: rgba(var(--widgetPrimaryColor), 1);
    margin-left: 8px;
    padding: 8px;
    padding-right: 0;
    background-color: unset;
    border: unset;
    &:hover {
      .icon-send {
        transform: rotate(45deg);
      }
    }

    .icon-send {
      display: block;
      transition: all ease-in-out 100ms;
    }
  }
}
