.avatar-img-wrapper {
  position: relative;
  .badge-team {
    position: absolute;
    bottom: -1px;
    right: -8px;
    border-radius: 50%;
    background-color: var(--b-400);
    color: var(--white);
    display: flex;
    width: 14px;
    height: 14px;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--white);
    i {
      font-size: 8px;
    }
  }
}
