@-webkit-keyframes placeholder-fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes placeholder-fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes background-circle-feedback-enter {
  0% {
    transform-origin: center 115%;
  }
  100% {
    transform-origin: center 90%;
  }
}
@keyframes background-circle-feedback-enter {
  0% {
    transform-origin: center 115%;
  }
  100% {
    transform-origin: center 90%;
  }
}

@-webkit-keyframes fade-in-bottom-right-corner {
  0% {
    -webkit-transform: translateY(50px) translateX(50px);
    transform: translateY(50px) translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom-right-corner {
  0% {
    -webkit-transform: translateY(50px) translateX(50px);
    transform: translateY(50px) translateX(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes appear-top {
  0% {
    top: -20%;
  }
  100% {
    top: 0;
  }
}
@keyframes appear-top {
  0% {
    top: -20%;
  }
  100% {
    top: 0;
  }
}
