@import './../../Responsive';

.card-submit-request-wrapper {
  .card-submit-request {
    &.has-close-button {
      .btn-close {
        position: absolute;
        right: 24px;
        top: 18px;
        z-index: 2;

        @media (max-width: $screen-sm) {
          display: none;
        }
      }
      .title {
        max-width: 90%;
      }

      form {
        @media (max-width: $screen-sm) {
          margin-top: 60px;
        }
      }
    }
    &.is-flat {
      padding: 0;
      background-color: transparent;
      border: none;

      .text-area-group {
        textarea {
          min-height: 180px;
        }
      }
      .btn {
        width: 100%;
        justify-content: center;
        border-radius: 16px;
        padding-top: 16px;
        padding-bottom: 16px;
        font-size: 18px;
        align-items: center;
        text-wrap: nowrap;

        &.is-primary {
          background-color: rgba(var(--widgetPrimaryColor), 1);

          &:hover {
            background-color: rgba(var(--widgetPrimaryColor), 0.9);
          }
          &:active {
            background-color: rgba(var(--widgetPrimaryColor), 1);
          }
        }
      }
    }

    form {
      .title {
        font-weight: 500;
        font-size: 18px;

        @media (max-width: $screen-sm) {
          font-size: 20px;
        }
      }
      font-size: 18px;

      .btn {
        color: black;
      }
    }
    .input-group {
      margin-bottom: 16px;
      input {
        font-size: 18px;
      }
    }
    .text-area-group {
      margin-bottom: 16px;

      textarea {
        min-height: 95px;
        font-size: 18px;
      }
    }
    .btn {
      margin-left: auto;
      margin-right: auto;
    }
    .request-created {
      display: flex;
      flex-flow: column;
      align-items: center;

      .emoji {
        margin: 8px 0;
      }
      p {
        text-align: center;
        font-weight: 500;
      }
    }
    .request-created-ask-identification {
      text-align: center;

      .emoji {
        margin-left: auto;
        margin-right: auto;
      }
      p.title {
        font-weight: 18px;
        font-weight: 500;
      }
    }
    .actions {
      display: flex;
      justify-content: center;

      .btn {
        margin-right: unset;
        margin-left: unset;
        &:first-child {
          margin-right: 8px;
        }
        &:last-child {
          margin-left: 8px;
        }
      }
    }
  }

  .btn-back {
    position: absolute;
    z-index: 2;
    left: 16px;
    top: 16px;
    display: none;
    background-color: rgba(7, 19, 49, 0.3);

    &:hover {
      background-color: rgba(7, 19, 49, 0.3);
    }

    @media (max-width: $screen-sm) {
      display: block;
    }
  }
}
