@import '../../shared/front/scss/Responsive';

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(17, 17, 17, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.modal {
  width: 100%;
  outline: none;
  background-color: #111111;
  border-radius: 8px;
  padding: 32px;
  &.modal-confirm {
    font-weight: 200;

    .actions {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
    }
  }

  .actions {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 32px;
  }

  @media (min-width: 0) and (max-width: $screen-sm) {
    & {
      width: 80%;
      left: 10%;
      &.is-large {
        width: 80%;
        left: 10%;
      }
      &.is-small {
        width: 50%;
        left: 25%;
      }
    }
  }
  @media (min-width: $screen-sm) {
    & {
      width: 70%;
      left: 15%;
      &.is-large {
        width: 80%;
        left: 10%;
      }
      &.is-small {
        width: 50%;
        left: 25%;
      }
    }
  }
  @media (min-width: $screen-md) {
    & {
      width: 40%;
      left: 30%;
      &.is-large {
        width: 70%;
        left: 15%;
      }
      &.is-small {
        width: 30%;
        left: 35%;
      }
    }
  }
  @media (min-width: $screen-lg) {
    & {
      width: 30%;
      left: 35%;
      &.is-large {
        width: 50%;
        left: 25%;
      }
      &.is-small {
        width: 20%;
        left: 40%;
      }
    }
  }
  @media (min-width: $screen-xl) {
    & {
      width: 30%;
      left: 35%;
      &.is-large {
        width: 50%;
        left: 25%;
      }
      &.is-small {
        width: 20%;
        left: 40%;
      }
    }
  }
}
