@import '../../Responsive';

.evolution-stats {
  display: flex;
  justify-content: space-between;
  &.is-extended {
    align-items: center;
    justify-content: center;

    .stat-comment {
      span {
        .comment-text {
          display: flex;
        }
      }
    }
  }

  .vote-clap-wrapper,
  .stat-comment {
    box-sizing: border-box;
    display: flex;

    .vote-clap {
      width: 100%;
    }
  }

  .actions {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  span {
    font-size: 24px;
    margin: 0 8px;
    margin-right: 0;
  }

  .stat-comment {
    padding: 4px 8px;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    &:hover {
      border-color: rgb(182, 182, 182);
      background-color: transparent;
    }
    &>a {
      display: flex;
      align-items: center;
    }

    span {
      font-size: 12px;
      font-family: Inter;
      font-weight: 500;
      display: flex;
      gap: 4px;

      span {
        margin-left: 0;
      }

      .comment-text {
        display: none;
      }
    }
  }

  .icon-comment-o,
  .icon-heart-o,
  .icon-heart {
    font-size: 16px;
    color: #000;
  }

  .stat-read-more {
    .btn {
      align-self: flex-end;
      display: flex;
      align-items: center;
      font-weight: 600;
      border-radius: 8px;

      i {
        margin-left: 6px;
        font-size: 9px;
      }
    }
  }
}
