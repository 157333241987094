.layout-empty {
  position: relative;
  height: 100%;
}

.layout-widget-basic {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  z-index: 2;
}

.layout-widget-detail {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  z-index: 2;
}

.layout-standalone-basic {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.layout-enter {
  opacity: 0;
  background-color: white;
}

.layout-enter-active {
  opacity: 0;
  background-color: red;
}

.layout-exit {
  opacity: 0;
}

.layout-exit-active {
  opacity: 0;
}
