.empty-state-widget-feed {
  text-align: center;

  img {
    width: 50%;
    margin-bottom: 48px;
  }
  .input-group {
    margin: 24px 0;
  }
  .btn {
    width: 100%;
    justify-content: center;
    border-radius: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 18px;
    align-items: center;
    text-transform: capitalize;
    &.is-primary {
      background-color: rgba(var(--widgetPrimaryColor), 1);

      &:hover {
        background-color: rgba(var(--widgetPrimaryColor), 0.9);
      }
      &:active {
        background-color: rgba(var(--widgetPrimaryColor), 1);
      }
    }
  }
  .card-identified {
    padding: 24px;
    background-color: rgba(31, 39, 61, 0.04);
    border-radius: 12px;
    margin-top: 18px;
    font-size: 18px;

    .title {
      font-weight: 600;
      margin-top: 8px;
    }
    p {
      font-weight: 300;
      margin: 8px 0;
    }
  }
}
