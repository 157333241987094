.post-detail-block-title {
  display: flex;
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  cursor: default;
  word-break: break-word;

  .timestamp {
    color: rgba(0, 0, 0, 0.5);
    margin-left: auto;
    margin-bottom: auto;
    margin-top: auto;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
  }
}
