@import '../../../../Responsive';

.card-subscribe-wrapper {
  position: fixed;
  bottom: 60px;
  right: 12px;
  text-align: center;
  z-index: 2;
  width: 22%;
  &.is-low-bottom {
    bottom: 16px;
  }
  @media (max-width: $screen-xl) {
    display: none;
  }

  .card-subscribe {
    max-width: 384px;
    box-sizing: border-box;
    padding: 16px;

    .icon-bell {
      display: block;
      font-size: 32px;
      margin: 12px auto;
    }
    .title {
      font-weight: 500;
      font-size: 18px;
    }
    p {
      margin-top: 4px;
    }
    .input-group {
      margin-top: 24px;
      margin-bottom: 24px;
      input {
        font-size: 18px;
      }
    }
    .btn {
      margin-left: auto;
      margin-right: auto;
      text-transform: capitalize;
    }
  }
}
