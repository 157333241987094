.jimo-post-detail {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 24px 94px 0px rgba(0, 0, 0, 0.07);
  box-sizing: border-box;

  .jimo-post-detail-content {
    display: flex;
    padding: 24px 64px;
    flex-direction: column;
    gap: 16px;
    box-sizing: border-box;
  }
}
